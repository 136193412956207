import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2883_6500)">
        <path
          d="M11 7V13H17"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.37665 22.87L2.68079 17.073L8.11405 18.659"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.68077 17.073C3.86364 19.4602 5.8404 21.3107 8.23732 22.2745C10.6342 23.2384 13.2852 23.2488 15.6889 22.3038C18.0927 21.3589 20.0827 19.524 21.2826 17.1462C22.4826 14.7683 22.8093 12.0123 22.2011 9.39917C21.5929 6.78605 20.0918 4.49694 17.9818 2.96464C15.8717 1.43234 13.2989 0.763053 10.7497 1.08332C8.20057 1.40359 5.85176 2.69121 4.14738 4.70274C2.443 6.71428 1.50118 9.3103 1.5 12"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2883_6500">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
