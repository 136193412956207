import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3816_1977)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.08544 1.57623C4.81005 1.58786 4.50134 1.69828 4.28992 1.85456L4.28565 1.85772L4.28564 1.8577C2.5623 3.11275 1.49182 5.18217 1.49182 7.51065C1.49182 11.3466 4.40673 14.4247 8.03927 14.4247C11.0081 14.4247 13.5666 12.2936 14.3451 9.41381C14.4635 8.87408 14.5898 7.91181 14.4396 6.96725C14.291 6.03328 13.891 5.19847 13.0142 4.71679C11.6739 4.05822 10.4359 4.38119 9.19749 4.78823C9.12698 4.8114 9.05518 4.83533 8.9824 4.8596C8.45718 5.03468 7.88016 5.22704 7.35957 5.2768C6.7036 5.33951 6.01086 5.19045 5.47715 4.51414L5.46771 4.50194C5.16106 4.09717 5.13925 3.64056 5.17938 3.29829C5.21244 3.01632 5.30026 2.7217 5.36449 2.50619C5.37259 2.47902 5.38032 2.4531 5.38751 2.42863C5.46412 2.16795 5.50457 2.00051 5.51012 1.86805C5.51443 1.76514 5.49588 1.73727 5.47715 1.71353C5.46016 1.692 5.44426 1.66952 5.42954 1.6462L5.4295 1.64613C5.42914 1.64556 5.42772 1.64324 5.42194 1.63867C5.41514 1.63329 5.40015 1.62322 5.37272 1.6124C5.31471 1.58952 5.21886 1.57059 5.08544 1.57623ZM6.64791 0.737078C6.23705 0.121127 5.54742 -0.0198876 5.02581 0.00214504C4.47333 0.0254816 3.88798 0.229486 3.43668 0.56232C1.31561 2.10802 0 4.65891 0 7.51065C0 12.2166 3.58282 16 8.03927 16C11.695 16 14.8343 13.3784 15.7852 9.82532C15.7882 9.81412 15.791 9.80285 15.7935 9.79152C15.9392 9.13763 16.107 7.93975 15.9108 6.70625C15.7119 5.45643 15.1202 4.08904 13.6847 3.30942C13.6767 3.30508 13.6686 3.30089 13.6605 3.29686C11.7567 2.3532 10.0149 2.86968 8.75412 3.28406C8.67956 3.30857 8.60754 3.3324 8.53788 3.35544C7.9796 3.54013 7.57311 3.6746 7.22507 3.70787C6.92408 3.73665 6.77772 3.68038 6.65577 3.54445C6.65609 3.53225 6.65718 3.51506 6.6599 3.49185C6.67525 3.36095 6.71964 3.21021 6.79307 2.9609C6.79927 2.93984 6.80568 2.91808 6.81229 2.89556C6.88486 2.64863 6.98531 2.29971 7.00048 1.93769C7.01645 1.55663 6.94026 1.12524 6.64791 0.737078ZM6.65664 3.56936C6.65659 3.56943 6.65637 3.56837 6.65616 3.56599C6.65659 3.56811 6.6567 3.5693 6.65664 3.56936Z"
          fill={color}
        />
        <path
          d="M11 10C12.1046 10 13 9.10457 13 8C13 6.89543 12.1046 6 11 6C9.89543 6 9 6.89543 9 8C9 9.10457 9.89543 10 11 10Z"
          fill={color}
        />
        <path
          d="M3.73511 9.81055C4.28739 9.81055 4.73511 9.36283 4.73511 8.81055C4.73511 8.25826 4.28739 7.81055 3.73511 7.81055C3.18282 7.81055 2.73511 8.25826 2.73511 8.81055C2.73511 9.36283 3.18282 9.81055 3.73511 9.81055Z"
          fill={color}
        />
        <path
          d="M6.39246 12.3113C6.94474 12.3113 7.39246 11.8636 7.39246 11.3113C7.39246 10.759 6.94474 10.3113 6.39246 10.3113C5.84017 10.3113 5.39246 10.759 5.39246 11.3113C5.39246 11.8636 5.84017 12.3113 6.39246 12.3113Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3816_1977">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
