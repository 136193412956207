import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 24 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5.5C13.5273 5.49906 15.0056 6.03877 16.173 7.0235L19.443 3.9095C18.1877 2.75205 16.6799 1.9031 15.0393 1.43003C13.3987 0.956964 11.6704 0.872801 9.99161 1.18422C8.31279 1.49564 6.72967 2.19407 5.36787 3.22409C4.00608 4.2541 2.90311 5.58734 2.14648 7.118L5.83298 9.9595C6.26292 8.66304 7.08986 7.53472 8.19669 6.73435C9.30351 5.93398 10.6341 5.50214 12 5.5Z"
        fill="#D94F3D"
      />
      <path
        d="M5.5 12C5.50092 11.3064 5.61335 10.6174 5.833 9.9595L2.1465 7.118C1.39242 8.63495 1 10.306 1 12C1 13.694 1.39242 15.365 2.1465 16.882L5.833 14.0405C5.61335 13.3826 5.50092 12.6936 5.5 12Z"
        fill="#F2C042"
      />
      <path
        d="M22.55 10H12.05V14.5H18C17.6461 15.7724 16.8352 16.8694 15.7225 17.581L19.3805 20.401C21.7181 18.303 23.0915 14.8915 22.55 10Z"
        fill="#5085ED"
      />
      <path
        d="M15.721 17.581C14.5905 18.228 13.3017 18.5463 12 18.5C10.6341 18.4979 9.30351 18.066 8.19669 17.2656C7.08986 16.4653 6.26292 15.337 5.83298 14.0405L2.14648 16.882C3.05705 18.7175 4.46171 20.2626 6.20246 21.3434C7.9432 22.4243 9.95099 22.998 12 23C14.6962 23.0732 17.3243 22.1472 19.379 20.4L15.721 17.581Z"
        fill="#57A75C"
      />
    </svg>
  );
}
