import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 21C11.25 21.4142 11.5858 21.75 12 21.75C12.4142 21.75 12.75 21.4142 12.75 21H11.25ZM11.25 4V21H12.75V4H11.25Z"
        fill={color}
      />
      <path
        d="M17.5 2C14.5 2 12 3.3 12 5C12 3.3 9.5 2 6.5 2C3.5 2 1 3.3 1 5V22C1 20.3 3.5 19 6.5 19C9.5 19 12 20.3 12 22C12 20.3 14.5 19 17.5 19C20.5 19 23 20.3 23 22V5C23 3.3 20.5 2 17.5 2Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
