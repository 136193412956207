import { urlFor } from '@/urls';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { useKnockFeed } from '@knocklabs/react';
import { Icon16 } from '@spaceduck/icons';
import { useEffect } from 'react';
import MenuLink from '../MenuLink';
import styles from './MenuMainNavSection.module.scss';
import { useWorkspace } from '@/api/workspace';
import { isCapable } from '@/api/util';

const {
  InboxNotifications,
  Project,
  ProjectCategory,
  RepositoryBrowse,
  ResearchChatAI,
} = Icon16;

// TODO: Disabled pending feature decision @ari
const ENABLE_INBOX_MENU_LINK = false;

const KnockCounter = () => {
  const feed = useKnockFeed();
  useEffect(() => {
    feed?.feedClient.fetch();
  }, [feed?.feedClient]);
  if (!feed) {
    return 0;
  }
  return feed.useFeedStore((state) => state.metadata.unread_count);
};

const InboxMenuLink = ({ workspaceId }: { workspaceId: string | null }) => {
  if (!ENABLE_INBOX_MENU_LINK) {
    return null;
  }
  if (!workspaceId) {
    return null;
  }

  return (
    <MenuLink
      icon={<InboxNotifications />}
      url={urlFor('workspaceInbox', { workspaceId })}
    >
      <span className={styles.inboxLink}>
        Inbox
        <span className={styles.inboxCounter}>
          <KnockCounter />
        </span>{' '}
      </span>
    </MenuLink>
  );
};

export default function MenuMainNavSection() {
  const workspaceId = useWorkspaceId();

  return (
    <nav className={styles.mainNav}>
      {workspaceId && (
        <>
          <MenuLink
            icon={<RepositoryBrowse />}
            url={urlFor('workspaceRepository', { workspaceId })}
          >
            Browse
          </MenuLink>
          <ResearchAssistantLink />
          <MenuLink
            icon={<ProjectCategory />}
            url={urlFor('workspaceCategories', { workspaceId })}
          >
            Categories
          </MenuLink>
          <InboxMenuLink workspaceId={workspaceId} />
          <MenuLink icon={<Project />} url={urlFor('workspaceSpaces', { workspaceId })}>
            Spaces
          </MenuLink>
        </>
      )}
    </nav>
  );
}

const ResearchAssistantLink = () => {
  const workspaceId = useWorkspaceId();
  const { data } = useWorkspace(workspaceId);

  if (!workspaceId) {
    return null;
  }

  const canCreate = data
    ? isCapable('researchAssistantSessionCreate', data?.workspace.capabilities).capable
    : false;

  return (
    <MenuLink
      icon={<ResearchChatAI />}
      url={urlFor('workspaceResearchAssistant', { workspaceId })}
      disabled={!canCreate}
    >
      Research assistant
    </MenuLink>
  );
};
