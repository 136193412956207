import { Icon16, Icon24, Icon32, type IconProps } from '@spaceduck/icons';
import { clsx } from 'clsx';

import { pluralize, type AllOrNone } from '@spaceduck/utils';
import Button from '@ui/Button';
import Collapsible from '@ui/Collapsible';
import Tooltip from '@ui/Tooltip';
import styles from './Toast.module.scss';

const { LoadingDisc } = Icon32;
const { Close, Info, StatusDanger, StatusInfo, StatusSuccess, StatusWarning } = Icon16;
const {
  AlertInfo,
  AlertSuccess,
  AlertWarning,
  Close: CloseLight,
  Down,
  Folder,
  Remove,
  Up,
} = Icon24;

export type ToastIconVariants =
  | 'danger'
  | 'info'
  | 'loading'
  | 'none'
  | 'primary'
  | 'success'
  | 'warning';

export type ToastProcessState = 'processing' | 'failed' | 'success' | 'canceled';

export type ToastProcessItem = {
  id?: string;
  icon?: React.ReactNode;
  text: string;
  status: ToastProcessState;
  onCancel?: (id?: string) => void;
  onRetry?: (id?: string) => void;
  onOpen?: (id?: string) => void;
};

export type ToastProps = {
  buttonText?: React.ReactNode;
  body?: string;
  children?: React.ReactNode;
  className?: string;
  iconVariant?: ToastIconVariants;
  onClick?: () => void;
  onClose?: () => void;
  title?: string;
  processList?: ToastProcessItem[];
  unwrapChild?: boolean;
} & AllOrNone<{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const toastIconVariant: Record<
  ToastIconVariants,
  ((props: IconProps) => JSX.Element) | null
> = {
  primary: Info,
  success: StatusSuccess,
  info: StatusInfo,
  warning: StatusWarning,
  danger: StatusDanger,
  loading: null,
  none: null,
};

export default function Toast(props: ToastProps) {
  const {
    buttonText,
    body,
    children,
    className,
    iconVariant = 'primary',
    onClick,
    onClose,
    title,
    unwrapChild = false,
  } = props;

  if (iconVariant === 'loading') return <ProcessingToast {...props} />;
  if (!(title || body || children)) return null;
  const ToastIconVariant = toastIconVariant[iconVariant];

  return (
    <div className={styles.standardToast}>
      <div className={styles.wrapper}>
        {ToastIconVariant && <ToastIconVariant className={styles.icon} />}
        <div className={clsx(styles.content, className)}>
          <div className={styles.header}>
            {title ? (
              <h4 className="title4">{title}</h4>
            ) : body ? (
              <p className="body5">{body}</p>
            ) : (
              <div className="body5">{children}</div>
            )}
            {onClose && (
              <Button
                isSquare
                onClick={() => onClose()}
                size="xs"
                type="button"
                variant="ghost"
              >
                <Close />
              </Button>
            )}
          </div>
          {title && body && <p className="body5">{body}</p>}
          {(title || body) &&
            children &&
            (unwrapChild ? children : <div className="body5">{children}</div>)}
          {onClick && (
            <div className={styles.action}>
              <Button onClick={() => onClick()} type="button" size="xs" variant="ghost">
                {buttonText}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const ProcessingToastItem = ({
  status,
  text,
  icon,
  onCancel,
  onOpen,
  onRetry,
}: ToastProcessItem) => {
  return (
    <div className={styles.processingToastItem}>
      {icon && <div className={styles.icon}>{icon}</div>}
      <div className={styles.text}>{text}</div>
      <div className={clsx(styles.status, styles[status])}>
        {status === 'processing' && onCancel && (
          <Tooltip content="Cancel upload" variant="tertiary">
            <Button isSquare onClick={() => onCancel()} type="button" variant="ghost">
              <Remove size={20} className={styles.hoverIcon} />
              <LoadingDisc size={28} className={styles.spin} />
            </Button>
          </Tooltip>
        )}
        {status === 'processing' && !onCancel && (
          <LoadingDisc size={28} className={styles.spin} />
        )}
        {status === 'failed' && onRetry && (
          <Tooltip content="Failed to upload, try again." variant="tertiary">
            <Button isSquare onClick={() => onRetry()} type="button" variant="ghost">
              <AlertWarning size={20} />
            </Button>
          </Tooltip>
        )}
        {status === 'failed' && !onRetry && <AlertWarning size={20} />}
        {status === 'success' && onOpen && (
          <Tooltip content="Go to file" variant="tertiary">
            <Button isSquare onClick={() => onOpen()} type="button" variant="ghost">
              <Folder size={20} className={styles.hoverIcon} />
              <AlertSuccess size={20} />
            </Button>
          </Tooltip>
        )}
        {status === 'success' && !onOpen && <AlertSuccess size={20} />}
        {status === 'canceled' && (
          <Tooltip content="Upload canceled" variant="tertiary">
            <Button isSquare type="button" variant="ghost">
              <AlertInfo size={20} />
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

function getHeaderText(processList: ToastProcessItem[]) {
  const pendingCount = processList.filter(
    ({ status }) => status === 'processing'
  ).length;
  const successCount = processList.filter(({ status }) => status === 'success').length;

  if (pendingCount) {
    return `Uploading ${pendingCount} item${pluralize(pendingCount !== 1)}`;
  }

  if (successCount) {
    return `${successCount} upload${pluralize(successCount !== 1)} completed!`;
  }

  return 'No uploads successful';
}

const ProcessingToast = (props: ToastProps) => {
  const { processList, onClose, isOpen, setIsOpen } = props;

  if (!processList?.length || isOpen === undefined || !setIsOpen) return null;

  const headerText = getHeaderText(processList);

  return (
    <div className={styles.processingToast}>
      <Collapsible
        contentClassName={styles.body}
        hasUnstyledTrigger
        initiallyExpanded
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        triggerContent={
          <div className={styles.header}>
            <div className={clsx(styles.text, onClose && styles.hasOnClose)}>
              {headerText}
            </div>
            <Button isSquare size="sm" type="button" variant="ghost">
              <Tooltip content="Maximize" variant="tertiary">
                <span className={styles.isNotOpen}>
                  <Up size={24} />
                </span>
              </Tooltip>
              <Tooltip content="Minimize" variant="tertiary">
                <span className={styles.isOpen}>
                  <Down size={24} />
                </span>
              </Tooltip>
            </Button>
            {onClose && (
              <Tooltip content="Close" variant="tertiary">
                <Button
                  isSquare
                  onClick={(ev) => {
                    ev.preventDefault();
                    onClose();
                  }}
                  size="sm"
                  type="button"
                  variant="ghost"
                >
                  <CloseLight size={24} />
                </Button>
              </Tooltip>
            )}
          </div>
        }
      >
        {processList.map((props, idx) => (
          <ProcessingToastItem {...props} key={props.id ?? idx} />
        ))}
      </Collapsible>
    </div>
  );
};
