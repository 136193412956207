import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 32,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_443_3874)">
        <path
          d="M27.6314 32.0002H4.36841C3.61236 32.0002 3 31.3776 3 30.6089V1.39131C3 0.622613 3.61236 0 4.36841 0H20.7893L28.9998 8.34789V30.6089C28.9998 31.3776 28.3874 32.0002 27.6314 32.0002Z"
          fill={color}
        />
        <path
          d="M20.7896 0V6.95657C20.7896 7.72528 21.4019 8.34789 22.158 8.34789H29L20.7896 0Z"
          fill="#7A7E9F"
        />
      </g>
      <defs>
        <clipPath id="clip0_443_3874">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
