import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 24 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1596_12015)">
        <path
          d="M11.6666 12C11.6666 11.116 12.0178 10.2681 12.6429 9.64298C13.2681 9.01786 14.1159 8.66667 15 8.66667C15.884 8.66667 16.7319 9.01786 17.357 9.64298C17.9821 10.2681 18.3333 11.116 18.3333 12C18.3333 12.8841 17.9821 13.7319 17.357 14.357C16.7319 14.9821 15.884 15.3333 15 15.3333C14.1159 15.3333 13.2681 14.9821 12.6429 14.357C12.0178 13.7319 11.6666 12.8841 11.6666 12Z"
          fill="#1ABCFE"
        />
        <path
          d="M5 18.6667C5 17.7826 5.35119 16.9348 5.97631 16.3096C6.60143 15.6845 7.44928 15.3333 8.33333 15.3333H11.6667V18.6667C11.6667 19.5507 11.3155 20.3986 10.6904 21.0237C10.0652 21.6488 9.21739 22 8.33333 22C7.44928 22 6.60143 21.6488 5.97631 21.0237C5.35119 20.3986 5 19.5507 5 18.6667V18.6667Z"
          fill="#0ACF83"
        />
        <path
          d="M11.6666 2V8.66667H15C15.884 8.66667 16.7319 8.31548 17.357 7.69036C17.9821 7.06523 18.3333 6.21739 18.3333 5.33333C18.3333 4.44928 17.9821 3.60143 17.357 2.97631C16.7319 2.35119 15.884 2 15 2L11.6666 2Z"
          fill="#FF7262"
        />
        <path
          d="M5 5.33333C5 6.21739 5.35119 7.06523 5.97631 7.69036C6.60143 8.31548 7.44928 8.66667 8.33333 8.66667H11.6667V2H8.33333C7.44928 2 6.60143 2.35119 5.97631 2.97631C5.35119 3.60143 5 4.44928 5 5.33333V5.33333Z"
          fill="#F24E1E"
        />
        <path
          d="M5 12C5 12.8841 5.35119 13.7319 5.97631 14.357C6.60143 14.9821 7.44928 15.3333 8.33333 15.3333H11.6667V8.66667H8.33333C7.44928 8.66667 6.60143 9.01786 5.97631 9.64298C5.35119 10.2681 5 11.116 5 12V12Z"
          fill="#A259FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1596_12015">
          <rect width="13.3333" height="20" fill="white" transform="translate(5 2)" />
        </clipPath>
      </defs>
    </svg>
  );
}
