import * as Popover from '@radix-ui/react-popover';
import clsx from 'clsx';
import {
  type MouseEvent,
  type ReactNode,
  type RefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Icon24 } from '@spaceduck/icons';
import styles from './InputDropdownMenu.module.scss';
const { Loading } = Icon24;

type InputDropdownMenuProps = {
  className?: string;
  children?: ReactNode;
  open: boolean;
  inputRef: RefObject<HTMLElement>;
  numberOfItems: number;
  onSelectIndex: (index: number) => void;
  isLoading: boolean;
  onHighlightedIndexChange?: (index: number) => void;
  onIndexPreview: (index: number) => void;
  onMouseDown?: (e: MouseEvent<HTMLElement>) => void;
  onEscapeKeyDown?: () => void;
  onInteractOutside?: () => void;
  anchor?: ReactNode;
  containerRef?: React.MutableRefObject<HTMLDivElement | null>;
};

type InputDropdownMenuItemProps<T> = {
  onSelect: (suggestion: T) => void;
  suggestion: T;
  children?: ReactNode;
  onMouseOver: () => void;
};

export const InputPopoverMenuItem = function InputPopoverMenuItem<T>({
  onSelect,
  suggestion,
  children,
  onMouseOver,
}: InputDropdownMenuItemProps<T>) {
  return (
    <div
      onMouseOver={onMouseOver}
      onMouseDown={(e) => {
        e.preventDefault();
        onSelect(suggestion);
      }}
    >
      {children}
    </div>
  );
};

const InputDropDownPopover = ({
  className,
  open,
  inputRef,
  onSelectIndex,
  isLoading,
  onHighlightedIndexChange,
  children,
  numberOfItems,
  onIndexPreview,
  onMouseDown,
  onEscapeKeyDown,
  onInteractOutside,
  anchor,
  containerRef,
}: InputDropdownMenuProps) => {
  const [highlightedIndex, setHighlightedIndex] = useState<number>();
  const handleInputKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === 'ArrowUp') {
        ev.preventDefault();
        if (highlightedIndex === undefined) {
          setHighlightedIndex(numberOfItems - 1);
          onHighlightedIndexChange?.(numberOfItems - 1);
          onIndexPreview(numberOfItems - 1);
          return;
        }
        if (highlightedIndex <= 0) {
          setHighlightedIndex(numberOfItems - 1);
          onHighlightedIndexChange?.(numberOfItems - 1);
          onIndexPreview(numberOfItems - 1);
          return;
        }
        setHighlightedIndex(highlightedIndex - 1);
        onHighlightedIndexChange?.(highlightedIndex - 1);
        onIndexPreview(highlightedIndex - 1);
      }
      if (ev.key === 'ArrowDown') {
        ev.preventDefault();
        if (highlightedIndex === undefined) {
          setHighlightedIndex(0);
          onHighlightedIndexChange?.(0);
          onIndexPreview(0);
          return;
        }
        if (highlightedIndex >= numberOfItems - 1) {
          setHighlightedIndex(0);
          onHighlightedIndexChange?.(0);
          onIndexPreview(0);
          return;
        }
        setHighlightedIndex(highlightedIndex + 1);
        onHighlightedIndexChange?.(highlightedIndex + 1);
        onIndexPreview(highlightedIndex + 1);
      }
      if (ev.key === 'Enter' && highlightedIndex !== undefined && numberOfItems > 0) {
        ev.preventDefault();
        onSelectIndex(highlightedIndex);
      }
    },
    [setHighlightedIndex, highlightedIndex, numberOfItems, onHighlightedIndexChange]
  );

  useEffect(() => {
    inputRef?.current?.addEventListener('keydown', handleInputKeyDown);
    return () => {
      inputRef.current?.removeEventListener('keydown', handleInputKeyDown);
    };
  }, [
    inputRef,
    setHighlightedIndex,
    highlightedIndex,
    onHighlightedIndexChange,
    numberOfItems,
  ]);

  return (
    <Popover.Root open={open} onOpenChange={() => setHighlightedIndex(undefined)}>
      {anchor && <Popover.Anchor asChild>{anchor}</Popover.Anchor>}
      {!anchor && <Popover.Anchor />}
      <Popover.Portal container={containerRef?.current}>
        <Popover.Content
          onMouseDown={onMouseDown}
          align="start"
          onOpenAutoFocus={(e) => {
            e.preventDefault();
            inputRef?.current?.focus();
          }}
          className={clsx(styles.dropdownMenuContent, className)}
          onEscapeKeyDown={onEscapeKeyDown}
          onInteractOutside={onInteractOutside}
        >
          {children}
          {!isLoading && !numberOfItems && (
            <div className={styles.notFound}>
              <span>No matching options</span>
            </div>
          )}
          {isLoading && (
            <div className={styles.loading}>
              <div className={styles.spin}>
                <Loading />
              </div>
              <span>Loading...</span>
            </div>
          )}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default InputDropDownPopover;
