import { clsx } from 'clsx';
import useEmblaCarousel from 'embla-carousel-react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from '@react-hook/window-size';
import Lightbox, { type ZoomRef } from 'yet-another-react-lightbox';
import LightboxZoomPlugin from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';

import type { MediaGroupDetailDTO } from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';
import { MediaType } from '@spaceduck/utils';

import ErrorDuck from '@assets/img/ErrorDuck';
import { useMediaGroupDetail } from '@api/mediaGroup';
import Placeholder from '@assets/img/Placeholder';
import { FileIcon } from '@components/FileIcon';
import lightboxStyles from '@components/lightboxModal/LightboxModal.module.scss';
import LightboxModalHeader from '@components/lightboxModal/LightboxModalHeader';
import AnnotatedMedia from '@detailsModal/comments/AnnotatedMedia';
import { EmbedView } from '@detailsModal/contentView/EmbedView';
import { IframeView } from '@detailsModal/contentView/IFrameView';
import { ReadingModeView } from '@detailsModal/contentView/ReadingModeView';
import { SummaryModeView } from '@detailsModal/contentView/SummaryModeView';
import BookmarkPlaceholder from '@detailsModal/media/BookmarkPlaceholder';
import ExtractPlaceholder from '@detailsModal/media/ExtractPlaceholder';
import { FilePreview } from '@detailsModal/media/FilePreview';
import Image from '@detailsModal/media/Image';
import PdfView from '@detailsModal/media/PdfView';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import BunnyStreamEmbed from '@ui/BunnyStreamEmbed';
import { ButtonLink } from '@ui/Button';
import Tooltip from '@ui/Tooltip';
import {
  FALLBACK_VIDEO_HEIGHT,
  FALLBACK_VIDEO_WIDTH,
  LANDSCAPE_MAX_WIDTH,
  PORTRAIT_MAX_WIDTH,
  getOrientation,
} from '@utils/carouselVideos';
import { type SlideVideo, createLightboxSlide } from '@utils/createLightboxSlide';
import { AudioPlayer } from './media/AudioPlayer';

import type { DetailsModelTab } from '@/types/MediaGroup';
import { urlFor } from '@/urls';
import styles from './DetailsModalStandardContent.module.scss';

const { Play } = Icon16;

const mainCarouselOptions = {
  slidesToScroll: 1,
  skipSnaps: false,
  active: false,
};

const UnknownView = ({
  mediaGroupId,
  activeTab,
}: { mediaGroupId: string; activeTab: DetailsModelTab }) => {
  return (
    <div className={styles.unknownView}>
      <div className={styles.icon}>
        <ErrorDuck />
      </div>
      <div>
        <h3>Unknown content</h3>
        <div>The selected content view is unknown. Please try again later.</div>
      </div>
      <div className={styles.actions}>
        <ButtonLink
          to={urlFor('mediaGroupModal', { mediaGroupId, activeTab, view: null })}
        >
          Back to default content
        </ButtonLink>
      </div>
    </div>
  );
};

export default function DetailsModalStandardContent({
  mediaGroupId,
  activeTab,
  view,
}: {
  mediaGroupId: string;
  activeTab: DetailsModelTab;
  view: string | null;
}) {
  const { data, isLoading } = useMediaGroupDetail(mediaGroupId);
  if (isLoading || !data) return null;

  const { mediaGroup } = data;

  if (mediaGroup.embed) {
    return (
      <div className={styles.embedWrapper}>
        <EmbeddedContent embed={mediaGroup.embed} />
      </div>
    );
  }

  if (view === 'reading') {
    return <ReadingModeView mediaGroup={mediaGroup} />;
  }

  if (view === 'iframe') {
    return <IframeView mediaGroup={mediaGroup} />;
  }

  if (view === 'summary') {
    return <SummaryModeView mediaGroup={mediaGroup} />;
  }

  if (view === 'embed') {
    return <EmbedView mediaGroup={mediaGroup} />;
  }

  if (view === null || view === 'media') {
    return <Carousel mediaGroup={mediaGroup} activeTab={activeTab} />;
  }

  return <UnknownView mediaGroupId={mediaGroup.id} activeTab={activeTab} />;
}

const Carousel = ({
  mediaGroup,
  activeTab,
}: {
  mediaGroup: MediaGroupDetailDTO;
  activeTab: DetailsModelTab;
}) => {
  const location = useLocation();
  const { setIsInLightboxView, topRef } = useDetailsModalStore((state) => ({
    setIsInLightboxView: state.setIsInLightboxView,
    topRef: state.topRef,
  }));
  const { isCommenting } = useDetailsModalStore();
  const [showLightbox, setShowLightbox] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel(mainCarouselOptions, [
    WheelGesturesPlugin(),
  ]);
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: false,
  });
  const zoomRef = useRef<ZoomRef | null>(null);
  const [canZoomIn, setCanZoomIn] = useState(false);
  const [canZoomOut, setCanZoomOut] = useState(false);

  const watchZoom = () => {
    setCanZoomIn(
      !!(zoomRef.current && zoomRef.current?.zoom < zoomRef.current?.maxZoom)
    );
    setCanZoomOut(!!(zoomRef.current && zoomRef.current?.zoom > 1));
  };

  const resetScroll = () => {
    topRef?.current?.scrollTo({ top: -57, behavior: 'smooth' });
  };

  const handleItemClick = (idx: number) => {
    if (isCommenting) return;
    setClickedIndex(idx);
    setShowLightbox(true);
  };

  const handleClose = () => {
    setShowLightbox(false);
  };

  const handleThumbnailClick = useCallback(
    (idx: number) => {
      if (!emblaMainApi || !emblaThumbsApi) return null;
      emblaMainApi.scrollTo(idx);
      resetScroll();
    },
    [emblaMainApi, emblaThumbsApi]
  );

  useEffect(() => {
    // defer init to fix size calculation within modal
    setTimeout(() => {
      emblaMainApi?.reInit({ ...mainCarouselOptions, active: true });
    }, 0);
  }, [emblaMainApi]);

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) return null;
    setSelectedIndex(emblaMainApi.selectedScrollSnap());
    emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap());
    resetScroll();
  }, [emblaMainApi, emblaThumbsApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on('select', onSelect);
    emblaMainApi.on('reInit', onSelect);
  }, [emblaMainApi, onSelect]);

  useHotkeys(
    'Shift+ArrowLeft, Shift+ArrowRight',
    (ev) => {
      if (ev.key === 'ArrowLeft') {
        ev.preventDefault();
        if (emblaMainApi?.canScrollPrev()) {
          emblaMainApi.scrollPrev();
        } else {
          emblaMainApi?.scrollTo(emblaMainApi.slideNodes().length - 1);
        }
      }
      if (ev.key === 'ArrowRight') {
        ev.preventDefault();
        if (emblaMainApi?.canScrollNext()) {
          emblaMainApi.scrollNext();
        } else {
          emblaMainApi?.scrollTo(0);
        }
      }
    },
    {
      enabled: !!emblaMainApi,
    },
    [emblaMainApi]
  );

  useEffect(() => {
    emblaMainApi?.scrollTo(0);
  }, [mediaGroup.id, emblaMainApi]);

  useEffect(() => {
    setShowLightbox(false);
  }, [location]);

  const { media } = mediaGroup;
  const isCarousel = media.length > 1;

  return (
    <>
      <div className={styles.carousel}>
        <div className={styles.viewport} ref={isCarousel ? emblaMainRef : undefined}>
          <div className={styles.container}>
            <BookmarkPlaceholder mediaGroup={mediaGroup} />
            <ExtractPlaceholder mediaGroup={mediaGroup} />
            {media.map((media, idx) => (
              <MediaDisplay
                key={media.id}
                mediaGroupId={mediaGroup.id}
                media={media}
                activeTab={activeTab}
                onClick={() => handleItemClick(idx)}
              />
            ))}
          </div>
        </div>
      </div>
      {media?.length > 1 && (
        <Tooltip
          content="Control Slide"
          icons={['⇧', '←', 'or', '⇧', '→']}
          size="medium"
          variant="secondary"
        >
          <div className={styles.thumbnails}>
            <div className={styles.centerWrapper}>
              <div className={styles.viewport} ref={emblaThumbsRef}>
                <div className={styles.container}>
                  {media.map((media, idx) => (
                    <MediaThumbnail
                      selected={selectedIndex === idx}
                      onClick={() => handleThumbnailClick(idx)}
                      media={media}
                      key={media.id}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Tooltip>
      )}
      <Lightbox
        className={lightboxStyles.lightbox}
        close={handleClose}
        on={{
          entered: () => {
            watchZoom();
            setIsInLightboxView(true);
          },
          exited: () => {
            setIsInLightboxView(false);
          },
          view: ({ index }) => {
            setClickedIndex(index);
            emblaMainApi?.scrollTo(index);
          },
          zoom: () => watchZoom(),
        }}
        open={showLightbox}
        index={clickedIndex}
        plugins={[LightboxZoomPlugin]}
        render={{
          slide: (data) => {
            const slide = data.slide;
            if (slide.type === 'image') return;
            const { poster, height, src, width } = slide as SlideVideo;
            const source = src || null;

            return (
              <BunnyStreamEmbed
                autoplay={true}
                height={height}
                isFullScreen
                knownSource={source}
                preview={poster}
                source={null}
                width={width}
              />
            );
          },
        }}
        slides={createLightboxSlide(media)}
        toolbar={{
          buttons: [
            <LightboxModalHeader
              canZoomIn={canZoomIn}
              canZoomOut={canZoomOut}
              key="header"
              zoomRef={zoomRef}
            />,
          ],
        }}
        zoom={{ ref: zoomRef }}
      />
    </>
  );
};

type MediaThumbnailProps = {
  selected?: boolean;
  onClick?: () => void;
  media: {
    mediaType: string;
    assetUrl: string;
    posterUrl: string | null;
    width: number;
    height: number;
  };
};

const MediaDisplay = ({
  mediaGroupId,
  media: { assetUrl, assetName, mediaType, height, id, posterUrl, source, width },
  activeTab,
  onClick,
}: {
  mediaGroupId: string;
  media: MediaGroupDetailDTO['media'][number];
  activeTab: DetailsModelTab;
  onClick: () => void;
}) => {
  const isAudio = useMemo(() => {
    if (!mediaType.startsWith('audio/')) {
      return false;
    }
    const element = document.createElement('audio');
    return element.canPlayType(mediaType) !== '';
  }, [mediaType]);
  const orientation = getOrientation(width, height);
  const displayWidth =
    orientation === 'portrait'
      ? Math.min(PORTRAIT_MAX_WIDTH, width)
      : Math.min(LANDSCAPE_MAX_WIDTH, width);
  const displayHeight = (height / width) * displayWidth;
  const isImage = mediaType.startsWith('image/');
  const isVideo = mediaType.startsWith('video/');
  const isSvg = mediaType === MediaType.SVG;
  const isPdf = mediaType === MediaType.PDF;

  return (
    <div className={clsx(styles.slide, orientation && styles[orientation])}>
      <div
        className={clsx(
          isVideo && styles.videoContainer,
          isPdf && styles.pdfContainer,
          !isVideo && !isPdf && styles.imageContainer
        )}
      >
        {isVideo && (
          <BunnyStreamEmbed
            height={displayHeight || FALLBACK_VIDEO_HEIGHT}
            onClick={onClick}
            preview={posterUrl}
            responsive={true}
            source={source}
            width={displayWidth || FALLBACK_VIDEO_WIDTH}
          />
        )}
        {isImage && activeTab === 'comment' && (
          <AnnotatedMedia mediaGroupId={mediaGroupId} mediaId={id}>
            <Image
              className={styles.svgImageWrapper}
              height={displayHeight}
              isSvg={isSvg}
              onClick={onClick}
              src={assetUrl}
              width={displayWidth}
            />
          </AnnotatedMedia>
        )}
        {isImage && activeTab !== 'comment' && (
          <Image
            height={height}
            isSvg={isSvg}
            onClick={onClick}
            src={assetUrl}
            width={width}
          />
        )}
        {isAudio && <AudioPlayer src={assetUrl} />}
        {isPdf && <PdfView name={assetName} url={assetUrl} />}
        {!isImage && !isVideo && !isPdf && !isAudio && (
          <FilePreview mediaType={mediaType} url={assetUrl} name={assetName} />
        )}
      </div>
    </div>
  );
};

const MediaThumbnail = ({
  selected,
  onClick,
  media: { mediaType, assetUrl, posterUrl, width, height },
}: MediaThumbnailProps) => {
  return (
    <div className={clsx(styles.slide, selected ? styles.selected : '')}>
      <button onClick={onClick} className={styles.button} type="button">
        {mediaType.startsWith('video/') && (
          <>
            {posterUrl ? (
              <img alt="" width={width} height={height} src={posterUrl} />
            ) : (
              <Placeholder
                className={styles.posterPlaceholder}
                width={width}
                height={height}
              />
            )}
            <Play className={styles.videoIcon} />
          </>
        )}
        {mediaType.startsWith('image/') && (
          <img src={assetUrl} alt="" width={width} height={height} />
        )}
        {!mediaType.startsWith('image/') && !mediaType.startsWith('video/') && (
          <div>
            <FileIcon mediaType={mediaType} />
          </div>
        )}
      </button>
    </div>
  );
};

const EmbeddedContent = ({
  embed,
}: {
  embed: NonNullable<MediaGroupDetailDTO['embed']>;
}) => {
  return <YoutubeEmbed videoId={embed.videoId} />;
};

const _YOUTUBE_NATIVE_WIDTH = 560;
const _YOUTUBE_NATIVE_HEIGHT = 315;

const YoutubeEmbed = ({ videoId }: { videoId: string }) => {
  const [windowWidth, windowHeight] = useWindowSize();

  // Keep the view as an integer ratio of the recommended size
  const [width, height] = useMemo(() => {
    const widthRatio = windowWidth / _YOUTUBE_NATIVE_WIDTH;
    const heightRatio = windowHeight / _YOUTUBE_NATIVE_HEIGHT;
    const ratio = Math.max(1, Math.floor(Math.min(widthRatio, heightRatio)));
    return [_YOUTUBE_NATIVE_WIDTH * ratio, _YOUTUBE_NATIVE_HEIGHT * ratio];
  }, [windowWidth, windowHeight]);

  return (
    <iframe
      width={width}
      height={height}
      src={`https://www.youtube.com/embed/${encodeURIComponent(videoId)}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    />
  );
};
