const ErrorEgg = ({
  className,
  height = 158,
  width = 126,
}: {
  className?: string;
  height?: number;
  width?: number;
}) => (
  <svg
    className={className}
    fill="none"
    height={height}
    viewBox="0 0 126 158"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63 155.778C96.4745 155.778 123.611 128.642 123.611 95.167C123.611 78.5335 116.9 55.5098 106.118 36.6482C100.719 27.2032 94.2512 18.7173 87.0074 12.5748C79.7638 6.43242 71.6471 2.55599 63 2.55599C54.3529 2.55599 46.2362 6.43242 38.9926 12.5748C31.7488 18.7173 25.2815 27.2032 19.8821 36.6482C9.09959 55.5098 2.389 78.5335 2.389 95.167C2.389 128.642 29.5255 155.778 63 155.778Z"
      fill="url(#gradient)"
      stroke="#2B2C3B"
      strokeWidth="3.222"
    />
    <defs>
      <radialGradient
        id="gradient"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(21.1429 34.7384) rotate(25.9189) scale(135.962 135.962)"
      >
        <stop offset="0.399722" stopColor="white" />
        <stop offset="0.741656" stopColor="#F0BDFF" />
        <stop offset="1" stopColor="#54D0FE" />
      </radialGradient>
    </defs>
  </svg>
);

export default ErrorEgg;
