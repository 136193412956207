import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.38462 3.80645C7.38462 2.53263 8.39483 1.5 9.64106 1.5H15.3846C15.7245 1.5 16 1.78163 16 2.12903C16 2.47643 15.7245 2.75807 15.3846 2.75807H9.64106C9.07461 2.75807 8.61539 3.22745 8.61539 3.80645V12.1936C8.61539 13.4674 7.60516 14.5 6.35897 14.5H0.615385C0.275515 14.5 0 14.2184 0 13.871C0 13.5235 0.275515 13.2419 0.615385 13.2419H6.35897C6.92542 13.2419 7.38462 12.7726 7.38462 12.1936V3.80645Z"
        fill={color}
      />
    </svg>
  );
}
