import { Helmet } from 'react-helmet-async';

type HeadProps = {
  children?: React.ReactNode;
  title: string;
};

export default function Head({ children, title }: HeadProps) {
  return (
    <Helmet>
      <title>{title} - Spaceduck</title>
      {children}
    </Helmet>
  );
}
