import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 24 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7608 11.4399L12.8629 2.8343L12.0003 2L5.30174 8.47892L2.23968 11.4399C1.92011 11.7489 1.92011 12.2504 2.23968 12.56L8.3591 18.4784L12.0003 21.9999L18.6988 15.5215L18.8027 15.421L21.7603 12.5605C22.0799 12.2514 22.0799 11.75 21.7603 11.4404L21.7608 11.4399ZM12.0003 14.9568L8.9429 11.9999L12.0008 9.04252L15.0581 11.9994L12.0003 14.9568Z"
        fill="#1D7AFC"
      />
      <path
        d="M12.0003 9.04303C9.99667 7.10526 9.99093 3.96905 11.9794 2.02269L5.29028 8.49205L8.93143 12.0136L12.0013 9.04454L12.0003 9.04303Z"
        fill="url(#paint0_linear_1602_12510)"
      />
      <path
        d="M15.0665 11.9925L12.0002 14.958C14.0106 16.9028 14.0106 20.0557 12.0002 22L18.7071 15.514L15.066 11.9925H15.0665Z"
        fill="url(#paint1_linear_1602_12510)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1602_12510"
          x1="11.4551"
          y1="6.05127"
          x2="7.32784"
          y2="10.3182"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.176" stopColor="#0055CC" />
          <stop offset="1" stopColor="#1D7AFC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1602_12510"
          x1="12.5851"
          y1="17.9134"
          x2="16.7038"
          y2="13.6547"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.176" stopColor="#0055CC" />
          <stop offset="1" stopColor="#1D7AFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
