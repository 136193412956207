import { urlFor } from '@/urls';
import MenuLink from '@layouts/sidebar/MenuLink';

export default function MainMenu() {
  return (
    <>
      <MenuLink url={urlFor('userSettingsProfile')}>Profile</MenuLink>
      <MenuLink url={urlFor('userSettingsWorkspaces')}>Workspaces</MenuLink>
    </>
  );
}
