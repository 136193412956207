import clsx from 'clsx';

import { Icon16 } from '@spaceduck/icons';

import type { ConnectionLineColor } from '../types/colors';
import styles from './Swatch.module.scss';

const { ShapeColor } = Icon16;

export default function Swatch({ color }: { color: ConnectionLineColor }) {
  return <ShapeColor className={clsx(styles.swatch, styles[color])} />;
}
