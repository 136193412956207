import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.95048 1H2.99585V4"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7539 4V1H15.7993"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7236 22V13C20.7236 11.9 19.8372 11 18.7539 11H12.8446V5C12.8446 3.9 11.9582 3 10.8749 3C9.7915 3 8.90512 3.9 8.90512 5V16L6.24595 13.9C5.45805 13.3 4.47317 13.1 3.58678 13.5C2.79888 13.8 2.50342 14.7 2.89737 15.3L6.93536 22"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
