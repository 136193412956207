import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3764_7662)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.260373 0.260373C0.498446 0.0223004 0.850594 -0.0608305 1.17 0.0456389L15.3922 4.78637C15.7333 4.90006 15.9723 5.20801 15.9978 5.56663C16.0232 5.92525 15.8302 6.2639 15.5086 6.42469L9.45266 9.45266L6.42469 15.5086C6.2639 15.8302 5.92525 16.0232 5.56663 15.9978C5.20801 15.9723 4.90006 15.7333 4.78637 15.3922L0.0456389 1.17C-0.0608305 0.850594 0.0223004 0.498446 0.260373 0.260373ZM2.29436 2.29436L5.7943 12.7942L7.99508 8.39261C8.0811 8.22058 8.22058 8.0811 8.39261 7.99508L12.7942 5.7943L2.29436 2.29436Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3764_7662">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
