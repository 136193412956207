import { Icon24 } from '@spaceduck/icons';

import { ButtonLink } from '@ui/Button';
import styles from './SupportButton.module.scss';

const { Support } = Icon24;

export default function SupportButton() {
  return (
    <div className={styles.container}>
      <ButtonLink
        className={styles.button}
        size="md"
        target="_blank"
        to="https://spaceduck.com/learn"
        variant="icon"
      >
        <Support />
      </ButtonLink>
    </div>
  );
}
