import { Mark, mergeAttributes } from '@tiptap/core';

export interface CommentOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    comment: {
      setComment: () => ReturnType;
      toggleComment: () => ReturnType;
      unsetComment: () => ReturnType;
    };
  }
}

export default Mark.create<CommentOptions>({
  name: 'comment',
  exitable: true,
  priority: 500,

  addAttributes() {
    return {
      'data-comment-id': {
        default: null,
      },
    };
  },

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [
      {
        tag: 'comment',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        { 'data-comment': true },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      0,
    ];
  },

  addCommands() {
    return {
      setComment:
        () =>
        ({ commands }) => {
          return commands.setMark(this.name);
        },
      toggleComment:
        () =>
        ({ commands }) => {
          return commands.toggleMark(this.name);
        },
      unsetComment:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
    };
  },
});
