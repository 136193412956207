import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15C13.7314 15 15.1351 13.6569 15.1351 12C15.1351 10.3431 13.7314 9 12 9C10.2685 9 8.86487 10.3431 8.86487 12C8.86487 13.6569 10.2685 15 12 15Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3603 12C20.359 11.4239 20.2931 10.8496 20.1638 10.287L21.3 9.46374C22.2611 8.76734 22.5286 7.45078 21.9155 6.43453V6.43453C21.3361 5.47423 20.1451 5.07678 19.1051 5.49664L17.6254 6.094C16.7359 5.3137 15.6802 4.72723 14.5311 4.375L14.3609 3.07244C14.2059 1.88682 13.1957 1 12 1V1C10.8043 1 9.79406 1.88682 9.63914 3.07245L9.46894 4.375C8.31976 4.72723 7.26406 5.3137 6.3746 6.094L4.89491 5.49664C3.85491 5.07678 2.66395 5.47423 2.08454 6.43453V6.43453C1.47136 7.45078 1.73885 8.76734 2.69999 9.46374L3.83622 10.287C3.57432 11.4153 3.57432 12.5847 3.83622 13.713L2.69999 14.5363C1.73885 15.2327 1.47136 16.5492 2.08454 17.5655V17.5655C2.66395 18.5258 3.85491 18.9232 4.89491 18.5034L6.3746 17.906C7.26406 18.6863 8.31976 19.2728 9.46894 19.625L9.63914 20.9276C9.79406 22.1132 10.8043 23 12 23V23C13.1957 23 14.2059 22.1132 14.3609 20.9276L14.5311 19.625C15.6802 19.2728 16.7359 18.6863 17.6254 17.906L19.1051 18.5034C20.1451 18.9232 21.3361 18.5258 21.9155 17.5655V17.5655C22.5286 16.5492 22.2612 15.2327 21.3 14.5363L20.1638 13.713C20.2931 13.1504 20.359 12.5761 20.3603 12Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
