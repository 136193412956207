import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1602_12461)">
        <path
          d="M8.99501 7.005C10.369 8.379 10.369 10.606 8.99501 11.98L7.00501 13.97C5.63101 15.344 3.40401 15.344 2.03001 13.97C0.656012 12.596 0.656012 10.369 2.03001 8.995L3.77801 7.297"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.00499 8.99501C5.63099 7.62101 5.63099 5.39401 7.00499 4.02001L8.99499 2.03001C10.369 0.656012 12.596 0.656012 13.97 2.03001C15.344 3.40401 15.344 5.63101 13.97 7.00501L12.222 8.70301"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1602_12461">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
