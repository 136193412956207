import { useEffect, useRef } from 'react';

import type { MediaGroupDTO } from '@spaceduck/api';

import { css } from '@lib/css';

type DraggableCardProps = {
  anchorMediaGroupId?: string | null;
  children: React.ReactNode;
  className?: string;
  mediaGroup: MediaGroupDTO;
  onClick?: () => void;
  setAnchorMediaGroupId?: React.Dispatch<React.SetStateAction<string | null>>;
};

export default function DraggableCard({
  anchorMediaGroupId,
  children,
  className,
  mediaGroup,
  onClick,
  setAnchorMediaGroupId,
}: DraggableCardProps) {
  const ref = useRef<HTMLDivElement>(null);
  const handleDragStart = (ev: React.DragEvent) => {
    const bounds = ev.currentTarget.getBoundingClientRect();
    const x = ev.clientX - bounds.left;
    const y = ev.clientY - bounds.top;
    ev.dataTransfer.setDragImage(ev.currentTarget, x, y);
    ev.dataTransfer.setData('mediaGroup', JSON.stringify(mediaGroup));
  };

  useEffect(() => {
    if (anchorMediaGroupId === mediaGroup.id) {
      ref.current?.scrollIntoView({ behavior: 'instant', block: 'start' });
      setAnchorMediaGroupId?.(null);
    }
  }, [anchorMediaGroupId, setAnchorMediaGroupId]);

  return (
    <div
      className={className}
      draggable={true}
      onClick={onClick}
      onDragStart={handleDragStart}
      ref={ref}
      // Prevent drag image from including overflow height
      style={css({
        transform: 'translate3d(0, 0, 0)',
      })}
    >
      {children}
    </div>
  );
}
