import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.59358 2.50843C8.89592 2.20608 9.38611 2.20608 9.68846 2.50843L13.4916 6.31153C13.7939 6.61387 13.7939 7.10406 13.4916 7.40641C13.1892 7.70875 12.699 7.70875 12.3967 7.40641L8.59358 3.6033C8.29124 3.30096 8.29124 2.81077 8.59358 2.50843Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5808 1.76717C12.2891 1.47547 11.8163 1.47547 11.5246 1.76717L2.2577 11.0341L1.71605 14.2839L4.96593 13.7423L14.2328 4.47539C14.5245 4.18369 14.5245 3.71094 14.2328 3.41924L12.5808 1.76717ZM10.4297 0.67229C11.3261 -0.224097 12.7793 -0.224097 13.6756 0.67229L15.3277 2.32436C16.2241 3.22074 16.2241 4.67388 15.3277 5.57027L5.88537 15.0126C5.77127 15.1267 5.62437 15.2023 5.46521 15.2288L0.901482 15.9895C0.654857 16.0306 0.403562 15.95 0.226766 15.7732C0.0499704 15.5964 -0.0305607 15.3451 0.0105435 15.0985L0.771164 10.5348C0.797691 10.3756 0.873288 10.2287 0.987387 10.1146L10.4297 0.67229Z"
        fill={color}
      />
    </svg>
  );
}
