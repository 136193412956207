import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8 7.20001C23.8 7.20001 23.6 5.50001 22.8 4.80001C21.9 3.80001 20.9 3.80001 20.4 3.80001C17 3.60001 12 3.60001 12 3.60001C12 3.60001 7 3.60001 3.6 3.80001C3.1 3.90001 2.1 3.90001 1.2 4.80001C0.5 5.50001 0.2 7.20001 0.2 7.20001C0.2 7.20001 0 9.10001 0 11.1V12.9C0 14.8 0.2 16.8 0.2 16.8C0.2 16.8 0.4 18.5 1.2 19.2C2.1 20.2 3.3 20.1 3.8 20.2C5.7 20.4 12 20.4 12 20.4C12 20.4 17 20.4 20.4 20.1C20.9 20 21.9 20 22.8 19.1C23.5 18.4 23.8 16.7 23.8 16.7C23.8 16.7 24 14.8 24 12.8V11C24 9.10001 23.8 7.20001 23.8 7.20001ZM9.5 15.1V8.40001L16 11.8L9.5 15.1Z"
        fill={color}
      />
    </svg>
  );
}
