import { useEffect, useState } from 'react';

export const useHasExtension = () => {
  const [hasExtension, setHasExtension] = useState(false);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      if (event.data?.kind === 'spaceduckExtensionPong') {
        setHasExtension(true);
      }
    };

    window.addEventListener('message', handleMessage);
    window.postMessage({ kind: 'spaceduckExtensionPing' }, window.location.origin);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  });

  return hasExtension;
};
