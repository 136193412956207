import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon / 16x16 / Send" clipPath="url(#clip0_1315_2539)">
        <path
          id="Vector"
          d="M14.7338 7.89939C14.7331 7.55877 14.5369 7.24627 14.2281 7.10128L2.35339 1.47639C2.03402 1.32452 1.65403 1.37827 1.38966 1.61264C1.12466 1.84763 1.02529 2.217 1.13716 2.55387L2.57088 6.85253L8.85013 7.89939L2.56963 8.94624L1.13591 13.2449C1.03091 13.5636 1.11466 13.9136 1.35028 14.1493C1.36341 14.1624 1.37653 14.1743 1.38966 14.1861C1.65465 14.4211 2.03402 14.4755 2.35464 14.3236L14.2294 8.69875C14.5369 8.5525 14.7338 8.24063 14.7338 7.89939Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1315_2539">
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
