import { Outlet } from 'react-router-dom';

import AuthWrapper from '@components/AuthWrapper';

export default function AuthLayout() {
  return (
    <AuthWrapper>
      <main>
        <Outlet />
      </main>
    </AuthWrapper>
  );
}
