import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 32,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      >
        <path d="M22.876 11.207h.834a1.666 1.666 0 0 1 1.666 1.667v6.666a1.667 1.667 0 0 1-1.666 1.667H12.043" />
        <path d="m15.376 17.875-3.333 3.333 3.333 3.334M9.543 21.207H8.71a1.667 1.667 0 0 1-1.667-1.667v-6.666a1.667 1.667 0 0 1 1.667-1.667h11.666" />
        <path d="m17.043 7.875 3.333 3.333-3.333 3.334" />
      </g>
    </svg>
  );
}
