import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2196_10008)">
        <path
          d="M15 0.75V6.75H21"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 9.75V6.75L15 0.75H3V9.75"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 20.25V23.25H21V20.25"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.85 17.25L11.625 16.425H10.2L10.05 17.25H8.77502L10.2 12.975H11.775L13.2 17.25H11.85ZM11.4 15.525L11.25 14.775C11.175 14.625 11.175 14.4 11.1 14.175C11.025 13.95 11.025 13.725 10.95 13.65C10.95 13.725 10.875 13.95 10.875 14.175C10.875 14.4 10.725 14.85 10.5 15.525H11.4Z"
          fill={color}
        />
        <path d="M13.575 17.25V12.975H14.775V17.25H13.575Z" fill={color} />
        <path
          d="M23.25 9.75H0.75V20.25H23.25V9.75Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2196_10008">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
