import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8475_5111)">
        <path
          d="M11.4008 10.8132C11.4224 10.9388 11.4411 11.0672 11.4411 11.2116C11.4411 12.5989 9.7341 13.541 8.15271 13.6188C7.1272 13.6769 5.28708 13.4342 4.50154 11.2875C4.32343 10.801 3.78349 10.5507 3.29886 10.7298C2.81235 10.9079 2.563 11.4459 2.7411 11.9324C3.56602 14.185 5.48206 15.5002 7.90711 15.5002C8.01866 15.5002 8.13115 15.4974 8.24457 15.4917C10.7371 15.3699 13.3159 13.7351 13.3159 11.2116C13.3159 11.0719 13.3037 10.9426 13.2934 10.8132H11.4008Z"
          fill={color}
        />
        <path
          d="M8.93601 8.00081H11.4979C10.568 7.44399 9.41971 7.14777 8.38763 6.88437C6.00101 6.27693 4.95488 5.86635 4.95488 4.48462C4.95488 3.1957 6.51002 2.39985 8.01267 2.37641C9.43189 2.35017 10.629 3.14039 11.2017 4.42744C11.4126 4.90083 11.9666 5.11362 12.4391 4.9027C12.9125 4.69179 13.1252 4.13872 12.9143 3.66534C12.0322 1.67993 10.1115 0.449121 7.98361 0.501615C5.18829 0.545673 3.08008 2.2583 3.08008 4.48462C3.08008 6.44754 4.24433 7.41212 5.65511 8.00081H8.93601Z"
          fill={color}
        />
        <path
          d="M14.561 8.93827H1.4374C0.919955 8.93827 0.5 8.51832 0.5 8.00088C0.5 7.48343 0.919955 7.06348 1.4374 7.06348H14.561C15.0784 7.06348 15.4984 7.48343 15.4984 8.00088C15.4984 8.51832 15.0784 8.93827 14.561 8.93827Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_8475_5111">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
