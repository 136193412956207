import { useId } from 'react';

import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 32 }: IconProps) {
  // Hiding instance of SVG with CSS affects other instances because of non-unique ID
  // https://stackoverflow.com/questions/66774523/hiding-react-svg-component-with-lineargradient-in-header-also-hides-the-svg-outs
  const id = useId();

  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16"
        cy="16"
        r="14"
        stroke={`url(#paint0_linear_1339_6213_${id})`}
        strokeWidth="4"
      />
      <defs>
        <linearGradient
          id={`paint0_linear_1339_6213_${id}`}
          x1="0"
          y1="16"
          x2="32"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6F53F9" />
          <stop offset="1" stopColor="#04D8DD" />
        </linearGradient>
      </defs>
    </svg>
  );
}
