import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 32 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.3334 31.3333H4.66671C3.93004 31.3333 3.33337 30.7366 3.33337 30V1.99996C3.33337 1.26329 3.93004 0.666626 4.66671 0.666626H20.6667L28.6667 8.66663V30C28.6667 30.7366 28.07 31.3333 27.3334 31.3333Z"
        fill="#43A6DD"
      />
      <path
        d="M20.6667 0.666626V7.33329C20.6667 8.06996 21.2634 8.66663 22.0001 8.66663H28.6667L20.6667 0.666626Z"
        fill="#CBEAFB"
      />
      <path
        d="M14 14.6666C15.1046 14.6666 16 13.7712 16 12.6666C16 11.5621 15.1046 10.6666 14 10.6666C12.8954 10.6666 12 11.5621 12 12.6666C12 13.7712 12.8954 14.6666 14 14.6666Z"
        fill="#FFD764"
      />
      <path
        d="M23.7937 21.2969L19.3494 16.2856C19.0116 15.9048 18.3218 15.9048 17.9841 16.2856L14.2589 20.4859L12.2669 17.9895C11.9318 17.5685 11.1798 17.5685 10.8447 17.9895L8.17815 21.3303C7.97549 21.5834 7.94349 21.9217 8.0937 22.2048C8.24392 22.4879 8.55236 22.6667 8.88924 22.6667H23.111C23.4559 22.6667 23.7697 22.4787 23.9163 22.1856C24.0621 21.8924 24.015 21.5458 23.7937 21.2969Z"
        fill="#CBEAFB"
      />
    </svg>
  );
}
