import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1943_3417)">
        <path
          d="M11.5 8.5H8.5V11.5H11.5V8.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 8.5H7.5C7.23478 8.5 6.98043 8.39464 6.79289 8.20711C6.60536 8.01957 6.5 7.76522 6.5 7.5C6.5 7.23478 6.60536 6.98043 6.79289 6.79289C6.98043 6.60536 7.23478 6.5 7.5 6.5C7.76522 6.5 8.01957 6.60536 8.20711 6.79289C8.39464 6.98043 8.5 7.23478 8.5 7.5V8.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5 8.5H12.5C12.7652 8.5 13.0196 8.39464 13.2071 8.20711C13.3946 8.01957 13.5 7.76522 13.5 7.5C13.5 7.23478 13.3946 6.98043 13.2071 6.79289C13.0196 6.60536 12.7652 6.5 12.5 6.5C12.2348 6.5 11.9804 6.60536 11.7929 6.79289C11.6054 6.98043 11.5 7.23478 11.5 7.5V8.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 11.5H7.5C7.23478 11.5 6.98043 11.6054 6.79289 11.7929C6.60536 11.9804 6.5 12.2348 6.5 12.5C6.5 12.7652 6.60536 13.0196 6.79289 13.2071C6.98043 13.3946 7.23478 13.5 7.5 13.5C7.76522 13.5 8.01957 13.3946 8.20711 13.2071C8.39464 13.0196 8.5 12.7652 8.5 12.5V11.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5 11.5H12.5C12.7652 11.5 13.0196 11.6054 13.2071 11.7929C13.3946 11.9804 13.5 12.2348 13.5 12.5C13.5 12.7652 13.3946 13.0196 13.2071 13.2071C13.0196 13.3946 12.7652 13.5 12.5 13.5C12.2348 13.5 11.9804 13.3946 11.7929 13.2071C11.6054 13.0196 11.5 12.7652 11.5 12.5V11.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 4.5H5.5C4.94772 4.5 4.5 4.94772 4.5 5.5V14.5C4.5 15.0523 4.94772 15.5 5.5 15.5H14.5C15.0523 15.5 15.5 15.0523 15.5 14.5V5.5C15.5 4.94772 15.0523 4.5 14.5 4.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 11.5H1.5C1.23478 11.5 0.98043 11.3946 0.792893 11.2071C0.605357 11.0196 0.5 10.7652 0.5 10.5V1.5C0.5 1.23478 0.605357 0.98043 0.792893 0.792893C0.98043 0.605357 1.23478 0.5 1.5 0.5H10.5C10.7652 0.5 11.0196 0.605357 11.2071 0.792893C11.3946 0.98043 11.5 1.23478 11.5 1.5V2.5"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1943_3417">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
