import clsx from 'clsx';
import Spinner from '@ui/Spinner';

import styles from './LoadingPlaceholder.module.scss';

const LoadingPlaceholder = ({
  message = 'Loading your stuff',
  center = false,
}: {
  message?: string;
  center?: boolean;
}) => {
  return (
    <div className={clsx(styles.content, center && styles.center)}>
      <Spinner size={24} />
      {message}
    </div>
  );
};

export default LoadingPlaceholder;
