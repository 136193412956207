import type { BillingPlan } from '@spaceduck/api';

export type Feature = {
  title: string;
  tooltip?: string;
};

export const freePlanFeatures: Feature[] = [
  {
    title: '200 AI words per Day',
  },
  {
    title: '5 AI Assists per Day',
  },
  {
    title: 'Up to 5 AI Summaries',
  },
  {
    title: 'Up to 50 Items',
  },
  {
    title: 'Up to 5 Spaces',
  },
  {
    title: '2GB Workspace storage',
  },
  {
    title: '25MB upload size',
  },
  {
    title: 'AI Tagging & Organization',
  },
  {
    title: 'AI-Powered Search',
  },
];

// TODO: Get data from backend
export const proPlanFeatures: Feature[] = [
  {
    title: 'Unlimited AI Writer',
  },
  {
    title: 'Unlimited AI Research Assistant',
  },
  {
    title: 'Unlimited AI Summaries',
  },
  {
    title: 'Unlimited Items',
  },
  {
    title: 'Unlimited Spaces',
  },
  {
    title: '500GB Workspace storage',
  },
  {
    title: '500MB upload size',
  },
  {
    title: 'AI Tagging & Organization',
  },
  {
    title: 'AI-Powered Search',
  },
];

export const getPlanDisplayName = (plan: BillingPlan) => {
  if (plan === 'starter') {
    return 'starter';
  }
  return 'pro';
};

export const getFeaturesTitle = (plan: BillingPlan) => {
  if (plan === 'starter') {
    return 'Free includes...';
  }
  return 'Pro includes...';
};

export const getFeatures = (plan: BillingPlan): Feature[] | null => {
  switch (plan) {
    case 'pro':
      return proPlanFeatures;
    case 'starter':
      return freePlanFeatures;
    default:
      return null;
  }
};

export const getPricing = (plan: BillingPlan) => {
  switch (plan) {
    case 'pro':
      return {
        monthly: {
          normal: '40',
          launchDiscounted: '30',
        },
        yearly: {
          normal: '20',
          launchDiscounted: '15',
        },
      };
    default:
      return null;
  }
};
