import { useCallback } from 'react';

import { Icon16, Icon24 } from '@spaceduck/icons';

import Button, { ButtonLink } from '@/components/ui/Button';
import { useHasExtension } from '@/hooks/useHasExtension';
import { useFeedbackModal } from '@/components/ui/FeedbackModal';
import Tooltip from '@/components/ui/Tooltip';

import styles from './HelpSection.module.scss';

const { Changelog, Feedback } = Icon16;
const { SpaceduckExtension } = Icon24;

export const HelpSection = () => {
  const { open } = useFeedbackModal();

  const handleClick = useCallback(() => {
    open();
  }, [open]);

  return (
    <div className={styles.helpSection}>
      <ExtensionLink />
      <div className={styles.links}>
        <Tooltip side="top" content="Changelog">
          <ButtonLink
            to="https://www.spaceduck.com/blog"
            target="_blank"
            variant="ghost"
            size="xs"
          >
            <Changelog />
          </ButtonLink>
        </Tooltip>
        <Tooltip side="top" content="Feedback">
          <Button variant="ghost" size="xs" onClick={handleClick}>
            <Feedback />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

const ExtensionLink = () => {
  const hasExtension = useHasExtension();

  if (hasExtension) {
    return null;
  }

  return (
    <ButtonLink
      to="https://chromewebstore.google.com/detail/spaceduck-web-clipper/bmiaedpgbkkhjgcjmkbhmjibipalamep"
      target="_blank"
      className={styles.extension}
      variant="ghost"
      size="sm"
      iconBefore={<SpaceduckExtension />}
    >
      <span className={styles.label}>Browser Extension</span>
    </ButtonLink>
  );
};
