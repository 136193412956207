import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9592_58719)">
        <path
          d="M8.9955 7.00586C10.3695 8.37986 10.3695 10.6069 8.9955 11.9809L7.0055 13.9709C5.6315 15.3449 3.4045 15.3449 2.0305 13.9709C0.6565 12.5969 0.6565 10.3699 2.0305 8.99586L3.7785 7.29786"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.00706 8.9955C5.63306 7.6215 5.63306 5.3945 7.00706 4.0205L8.99706 2.0305C10.3711 0.6565 12.5981 0.6565 13.9721 2.0305C15.3461 3.4045 15.3461 5.6315 13.9721 7.0055L12.2241 8.7035"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9592_58719">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
