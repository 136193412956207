export class UnreachableError extends Error {
  public constructor(message: string, impossibleValue: never) {
    console.debug('Unreachable value was', impossibleValue);
    super(message);
    this.name = 'UnreachableError';
  }
}

export const unreachable = (
  message: string,
  impossibleValue: never
): UnreachableError => new UnreachableError(message, impossibleValue);

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type Subset<K, T extends K> = T;

export type AllOrNone<T> = T | { [K in keyof T]?: never };

export type PartialExcept<T, K extends keyof T> = Partial<T> & Pick<T, K>;

// For typing key of an object where value is string
// E.g. KeysMatching<MediaGroupDetailDTO, 'string'>
export type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];
