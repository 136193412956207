import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 15C13.5 15 9 10.5 9 5C9 4.1 9.1 3.2 9.4 2.4C5.1 3.5 2 7.4 2 12C2 17.5 6.5 22 12 22C16.6 22 20.5 18.9 21.6 14.6C20.8 14.9 19.9 15 19 15Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
