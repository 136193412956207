import InputDropDownPopover, {
  InputPopoverMenuItem,
} from '@/components/ui/InputDropdownMenu';
import { type ReactNode, type RefObject, useState } from 'react';

import clsx from 'clsx';
import styles from './ResearchSourcesDropdown.module.scss';
import { Icon16 } from '@spaceduck/icons';

const { RepositoryBrowse, Project, Links } = Icon16;
type ResearchSourcesDropdownProps = {
  inputRef: RefObject<HTMLInputElement>;
  setSelectedSource: (source: ResearchSource) => void;
  filter: string;
  onDismiss: () => void;
};

export type ResearchSource = 'library' | 'space' | 'link';

export type SourceOption = {
  label: ResearchSource;
  icon: ReactNode;
  helperText: string;
};

const options: SourceOption[] = [
  {
    label: 'library',
    helperText: 'Search within your library content',
    icon: <RepositoryBrowse />,
  },
  {
    label: 'space',
    helperText: 'Search content inside of a space',
    icon: <Project />,
  },
  {
    label: 'link',
    helperText: 'Search a website link',
    icon: <Links />,
  },
];

const SingleOption = ({
  active,
  helperText,
  icon,
  label,
}: SourceOption & { active: boolean }) => {
  return (
    <div className={clsx(styles.singleOption, active ? styles.active : undefined)}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.info}>
        <p className={styles.label}>{label}:</p>
        <p className={styles.helperText}>{helperText}</p>
      </div>
    </div>
  );
};

export const ResearchSourcesDropdown = (props: ResearchSourcesDropdownProps) => {
  const { inputRef, setSelectedSource, filter, onDismiss } = props;
  const [activeSource, setActiveSource] = useState<SourceOption['label']>();
  const selectSource = (source?: SourceOption['label']) => {
    if (!source) {
      return;
    }
    setActiveSource(undefined);
    setSelectedSource(source);
  };
  const filteredOptions = options.filter((option) => option.label.includes(filter));
  return (
    <InputDropDownPopover
      inputRef={inputRef}
      numberOfItems={filteredOptions.length}
      isLoading={false}
      onIndexPreview={(index) => setActiveSource(filteredOptions[index]?.label)}
      onSelectIndex={(index) => selectSource(filteredOptions[index]?.label)}
      open={true}
      onEscapeKeyDown={() => {
        onDismiss();
      }}
      className={styles.container}
    >
      <div className={styles.title}>Filter patterns</div>
      {filteredOptions.map((option, i) => (
        <InputPopoverMenuItem<SourceOption>
          key={i}
          onSelect={(selected) => selectSource(selected.label)}
          onMouseOver={() => setActiveSource(option.label)}
          suggestion={option}
        >
          <SingleOption {...option} active={activeSource === option.label} key={i} />
        </InputPopoverMenuItem>
      ))}
    </InputDropDownPopover>
  );
};
