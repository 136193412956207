import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5977_31358)">
        <path
          d="M5.5 0.5V15.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="1" y="1" width="4" height="14" fill={color} />
        <path
          d="M15.5 5.5H5.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5 10.5H5.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 15.5H14C14.828 15.5 15.5 14.828 15.5 14V2C15.5 1.172 14.828 0.5 14 0.5H2C1.172 0.5 0.5 1.172 0.5 2V14C0.5 14.828 1.172 15.5 2 15.5Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5977_31358">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
