import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.47704 2.12193L2.82117 7.41611L7.94221 5.96768"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.43658 17.7113C3.06893 17.1656 2.75521 16.5844 2.5 15.9761"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.42093 21.558C7.94829 21.3942 7.48845 21.1945 7.04531 20.9607C6.92535 20.8998 6.80779 20.8347 6.69263 20.7653"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7889 21.5562C14.1865 21.7639 13.5659 21.9125 12.9355 22"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.774 17.7168C19.3959 18.2791 18.9624 18.8008 18.4802 19.2739"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.29077 6.59418C4.5116 4.67613 6.33976 3.23752 8.47396 2.51545C10.6082 1.79337 12.921 1.83093 15.0314 2.62193C17.1417 3.41292 18.9236 4.91015 20.0834 6.86685C21.2432 8.82356 21.7116 11.123 21.4115 13.3861"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
