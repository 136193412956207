import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1596_7626)">
        <path
          d="M5.5 0.5H1.5C0.947715 0.5 0.5 0.947715 0.5 1.5V7.5C0.5 8.05228 0.947715 8.5 1.5 8.5H5.5C6.05228 8.5 6.5 8.05228 6.5 7.5V1.5C6.5 0.947715 6.05228 0.5 5.5 0.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 11.5H1.5C0.947715 11.5 0.5 11.9477 0.5 12.5V14.5C0.5 15.0523 0.947715 15.5 1.5 15.5H5.5C6.05228 15.5 6.5 15.0523 6.5 14.5V12.5C6.5 11.9477 6.05228 11.5 5.5 11.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 0.5H10.5C9.94772 0.5 9.5 0.947715 9.5 1.5V4.5C9.5 5.05228 9.94772 5.5 10.5 5.5H14.5C15.0523 5.5 15.5 5.05228 15.5 4.5V1.5C15.5 0.947715 15.0523 0.5 14.5 0.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 8.5H10.5C9.94772 8.5 9.5 8.94772 9.5 9.5V14.5C9.5 15.0523 9.94772 15.5 10.5 15.5H14.5C15.0523 15.5 15.5 15.0523 15.5 14.5V9.5C15.5 8.94772 15.0523 8.5 14.5 8.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1596_7626">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
