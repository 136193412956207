export default function AddImage() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M15.5458 10.0908L11.0004 14.6363L7.36399 11.909L4.63672 14.6363"
          stroke="#9798B1"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M11.9091 20.0909H1V1H20.0909V11.9091"
          stroke="#9798B1"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M8.27228 8.27308C9.27644 8.27308 10.0905 7.45905 10.0905 6.4549C10.0905 5.45075 9.27644 4.63672 8.27228 4.63672C7.26813 4.63672 6.4541 5.45075 6.4541 6.4549C6.4541 7.45905 7.26813 8.27308 8.27228 8.27308Z"
          stroke="#9798B1"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M18.2725 15.5459V21.0004"
          stroke="#9798B1"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M15.5459 18.2725H21.0004"
          stroke="#9798B1"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
