import type { IconProps } from '../types/IconProps';

export default function Icon({ className, color = '#1266ED', size = 48 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 48 48"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="15" y="18" width="18" height="21" fill="white" />
      <path
        d="M28.4991 4.5C24.0636 4.50194 19.7612 6.01601 16.302 8.79237C12.8428 11.5687 10.4335 15.4415 9.47165 19.7715C6.57794 20.3977 4.01787 22.0716 2.28371 24.4713C0.54954 26.8709 -0.236297 29.827 0.0772954 32.771C0.390888 35.7151 1.78177 38.4393 3.9825 40.4198C6.18324 42.4003 9.03845 43.4974 11.9991 43.5H28.4991C33.6709 43.5 38.6308 41.4455 42.2877 37.7886C45.9447 34.1316 47.9991 29.1717 47.9991 24C47.9991 18.8283 45.9447 13.8684 42.2877 10.2114C38.6308 6.55446 33.6709 4.5 28.4991 4.5ZM32.8567 29.136C32.7358 29.3941 32.544 29.6124 32.3036 29.7654C32.0631 29.9184 31.7841 29.9998 31.4991 30H25.4991V37.5C25.4991 37.8978 25.3411 38.2794 25.0598 38.5607C24.7785 38.842 24.397 39 23.9991 39C23.6013 39 23.2198 38.842 22.9385 38.5607C22.6572 38.2794 22.4991 37.8978 22.4991 37.5V30H16.4991C16.214 30.0002 15.9347 29.9191 15.694 29.7662C15.4533 29.6133 15.2612 29.395 15.1401 29.1368C15.019 28.8786 14.9741 28.5913 15.0105 28.3085C15.0469 28.0257 15.1632 27.7591 15.3456 27.54L22.8456 18.54C22.9914 18.3786 23.1693 18.2496 23.368 18.1613C23.5667 18.073 23.7817 18.0273 23.9991 18.0273C24.2166 18.0273 24.4316 18.073 24.6303 18.1613C24.829 18.2496 25.0069 18.3786 25.1526 18.54L32.6526 27.54C32.8347 27.7592 32.9506 28.0257 32.9867 28.3083C33.0229 28.591 32.9777 28.8781 32.8567 29.136Z"
        fill={color}
      />
    </svg>
  );
}
