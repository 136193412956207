import { Icon16, Icon24 } from '@spaceduck/icons';
import type { Editor } from '@tiptap/react';
import { useEffect } from 'react';

import { useMediaGroupDetail } from '@api/mediaGroup';
import { css } from '@lib/css';
import ScrollArea from '@ui/ScrollArea';
import Button from './Button';
import styles from './EmbedViewOptions.module.scss';
import type { ContentBlockNode, MenuView } from '../DocumentWrapper';

const { Link, LinkCard, LinkEmbed } = Icon16;
const { LeftArrow } = Icon24;

export default function EmbedViewOptions({
  editor,
  mediaGroupId,
  setView,
}: {
  editor: Editor;
  mediaGroupId: string | null;
  setView: React.Dispatch<React.SetStateAction<MenuView>>;
}) {
  const { data, isFetching } = useMediaGroupDetail(mediaGroupId);
  const { selection } = editor.view.state;

  useEffect(() => {
    if (!isFetching) {
      if (!data) {
        setView('blockMenu');
      }

      if (data?.mediaGroup.contentType === 'document') {
        setView('blockMenu');
      }
    }
  }, [isFetching, data]);

  if (!('node' in selection)) return null;

  const mediaGroup = data?.mediaGroup;

  if (!mediaGroup) return null;

  const node = selection.node as ContentBlockNode;
  const isOutgoingLink = node.type?.name === 'outgoing-link';
  const isEmbeddedView = node.attrs?.['data-minimal-view'] === false;

  const hasEmbeddedView =
    ['image', 'video'].includes(mediaGroup.contentType ?? '') ||
    (mediaGroup.contentType === 'bookmark' && mediaGroup.media.length > 0);

  const { id, label, contentType } = mediaGroup;

  const toggleView = (displayAsMinimalView: boolean) => {
    if (isOutgoingLink) {
      editor
        .chain()
        .deleteSelection()
        .insertContent([
          {
            type: 'content-block',
            attrs: {
              id,
              label,
              contentType,
              'data-minimal-view': displayAsMinimalView,
            },
          },
        ])
        .run();

      return;
    }

    editor
      .chain()
      .updateAttributes('content-block', {
        'data-minimal-view': displayAsMinimalView,
      })
      .run();
  };

  const convertToOutgoingLink = () => {
    editor
      .chain()
      .deleteSelection()
      .insertContent([
        {
          type: 'outgoing-link',
          attrs: {
            id,
            label,
            contentType,
          },
        },
      ])
      .run();
  };

  return (
    <div className={styles.embedViewOptions}>
      <div className={styles.back}>
        <Button onClick={() => setView('blockMenu')}>
          <LeftArrow size={20} />
        </Button>
      </div>
      <ScrollArea
        orientation="horizontal"
        style={css({
          height: '100%',
          maxWidth: 'calc(100% - var(--size-14))',
        })}
      >
        <div className={styles.scrollable}>
          <Button onClick={convertToOutgoingLink} disabled={isOutgoingLink}>
            <Link size={20} />
          </Button>
          <Button
            onClick={() => toggleView(false)}
            disabled={(isEmbeddedView && !isOutgoingLink) || !hasEmbeddedView}
          >
            <LinkEmbed size={20} />
          </Button>
          <Button
            onClick={() => toggleView(true)}
            disabled={!isEmbeddedView && !isOutgoingLink}
          >
            <LinkCard size={20} />
          </Button>
        </div>
      </ScrollArea>
    </div>
  );
}
