import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1596_12075)">
        <path
          d="M0.5 15.5L2.525 13.474"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.499 7.5L5 9"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 9.5L7 11"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.52506 8.525C1.86865 9.18141 1.49988 10.0717 1.49988 11C1.49988 11.9283 1.86865 12.8186 2.52506 13.475C3.18147 14.1314 4.07175 14.5002 5.00006 14.5002C5.92836 14.5002 6.81865 14.1314 7.47506 13.475L8.47506 12.475L3.50006 7.5L2.52506 8.525Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5 0.5L13.475 2.526"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.475 7.475C14.1314 6.81858 14.5002 5.9283 14.5002 5C14.5002 4.07169 14.1314 3.18141 13.475 2.525C12.8186 1.86858 11.9283 1.49982 11 1.49982C10.0717 1.49982 9.18143 1.86858 8.52502 2.525L7.52502 3.525L12.5 8.5L13.475 7.475Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1596_12075">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
