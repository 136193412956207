import clsx from 'clsx';
import styles from './Section.module.scss';

type SectionProps = {
  children: React.ReactNode;
  className?: string;
};

export default function Section({ children, className }: SectionProps) {
  return <div className={clsx(styles.container, className)}>{children}</div>;
}
