import { useCallback, type PropsWithChildren } from 'react';
import type { UseQueryResult } from '@tanstack/react-query';
import { urlFor } from '@/urls';
import { useGetChatSessionList, useRemoveChatSession } from '@/api/ai';
import MenuLink from '@/components/layouts/sidebar/MenuLink';

import styles from './ChatHistory.module.scss';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import Button from '@/components/ui/Button';
import clsx from 'clsx';
import { Icon16, Icon24 } from '@spaceduck/icons';
import Spinner from '@/components/Spinner';
import createToast from '@/utils/createToast';
import ScrollArea from '@ui/ScrollArea';
import { css } from '@/lib/css';

const { Delete } = Icon16;
const { ChatHistory: ChatHistoryIcon } = Icon24;

type ExtractData<T> = T extends UseQueryResult<infer D, infer _E> ? D : never;
type Data = ExtractData<ReturnType<typeof useGetChatSessionList>>;
type Chat = Data['chats'][number];

export const ChatHistory = ({
  workspaceId,
  onSelectChatSession,
}: { workspaceId: string; onSelectChatSession?: (chatSessionId: string) => void }) => {
  const { status, data, refetch } = useGetChatSessionList(workspaceId);

  if (status === 'pending') {
    return <PendingPanel />;
  }

  if (status === 'error') {
    return <ErrorPanel refetch={refetch} />;
  }

  const { chats } = data;

  return <DataPanel onSelectChatSession={onSelectChatSession} chats={chats} />;
};

const Panel = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
  return (
    <div className={clsx(styles.chatHistory, className)}>
      <ScrollArea
        orientation="vertical"
        style={css({
          '--width': '100%',
          '--maxHeight': '100%',
        })}
      >
        <div className={styles.content}>{children}</div>
      </ScrollArea>
    </div>
  );
};

const PendingPanel = () => {
  return (
    <Panel>
      <LoadingPlaceholder center />
    </Panel>
  );
};

const ErrorPanel = ({ refetch }: { refetch: () => void }) => {
  const handleClick = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <Panel className={styles.error}>
      <div>Failed to fetch chat history. Please try again later.</div>
      <div className={styles.actions}>
        <Button onClick={handleClick}>Retry</Button>
      </div>
    </Panel>
  );
};

const EmptyPanel = () => {
  return (
    <Panel className={styles.fullHeight}>
      <div className={styles.empty}>
        <div className={styles.icon}>
          <ChatHistoryIcon />
        </div>
        <p>
          As you interact with the AI, your session history will be displayed here,
          allowing you to easily manage and review past conversations.
        </p>
      </div>
    </Panel>
  );
};

const DataPanel = ({
  chats,
  onSelectChatSession,
}: { chats: Chat[]; onSelectChatSession?: (chatSessionId: string) => void }) => {
  if (chats.length === 0) {
    return <EmptyPanel />;
  }

  return (
    <Panel className={styles.data}>
      <ul>
        {chats.map((chat) => (
          <ChatItem
            onSelectChatSession={onSelectChatSession}
            key={chat.id}
            chat={chat}
          />
        ))}
      </ul>
    </Panel>
  );
};

const ChatItem = ({
  chat: { id: chatId, label },
  onSelectChatSession,
}: { chat: Chat; onSelectChatSession?: (chatSessionId: string) => void }) => {
  const { mutateAsync: removeChatSession, status } = useRemoveChatSession();

  const handleDelete = useCallback(async () => {
    try {
      await removeChatSession(chatId);
    } catch (error) {
      console.error('Failed to delete chat session', error);
      createToast({
        iconVariant: 'warning',
        titleText: 'Failed to delete chat session',
        bodyText:
          'An error occurred while trying the remove the chat session. Please try again later.',
      });
    }
  }, [removeChatSession, chatId]);

  return (
    <li className={styles.chatItem}>
      <MenuLink
        className={clsx(styles.label, status === 'pending' && styles.pendingRemove)}
        url={urlFor('researchAssistantChat', { chatId })}
        asButton={!!onSelectChatSession}
        onClick={() => onSelectChatSession?.(chatId)}
      >
        {label}
      </MenuLink>
      <div className={styles.actions}>
        <Button
          variant="ghost"
          onClick={status === 'pending' ? undefined : handleDelete}
          disabled={status === 'pending'}
        >
          {status === 'pending' ? <Spinner size={16} /> : <Delete />}
        </Button>
      </div>
    </li>
  );
};
