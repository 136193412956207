import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { exists, MediaType } from '@spaceduck/utils';

import AddImage from '@figma/components/icons/AddImage';
import {
  consumeUploadRequest,
  createMediaGroup,
  createUploadRequest,
  getProcessingRequestDetails,
  getProjects,
  listWorkspaces,
  uploadRequestIntoProcessingRequest,
} from '@figma/lib/api';
import type { ExportableBytes } from '@figma/lib/interfaces';
import { useAuth } from '@figma/modules/auth/AuthContext';
import ReorderableImageRow from '@integrations/shared/ReorderableImageRow';
import Button from '@ui/Button';
import Select from '@ui/Select';
import styles from './Upload.module.scss';

function typedArrayToBuffer(array: Uint8Array) {
  return array.buffer.slice(array.byteOffset, array.byteLength + array.byteOffset);
}

function exportTypeToBlobType(type: string) {
  switch (type) {
    case 'PDF':
      return MediaType.PDF;
    case 'SVG':
      return MediaType.SVG;
    case 'PNG':
      return MediaType.PNG;
    case 'JPG':
      return MediaType.JPEG;
    default:
      return MediaType.PNG;
  }
}

function exportTypeToFileExtension(type: string) {
  switch (type) {
    case 'PDF':
      return '.pdf';
    case 'SVG':
      return '.svg';
    case 'PNG':
      return '.png';
    case 'JPG':
      return '.jpg';
    default:
      return '.png';
  }
}

type FormModel = {
  title: string;
  description: string;
  projectId: string;
};

function Upload({ exportedData }: { exportedData: ExportableBytes[] }) {
  const { token } = useAuth();
  const [projects, setProjects] = useState<{ id: string; label: string }[]>([]);
  const [_, setWorkspaces] = useState<{ id: string; label: string }[]>([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState<{
    id: string;
    label: string;
  }>();
  const [images, setImages] = useState<{ url: string; filename: string; blob: Blob }[]>(
    []
  );
  const [uploadState, setUploadState] = useState<
    'idle' | 'uploading' | 'creating' | 'failed'
  >('idle');
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm<FormModel>();

  const onSend = async (data: FormModel) => {
    setUploadState('uploading');

    const processingRequestIds = await Promise.all(
      images.map(async (image) => {
        const { uploadRequest } = await createUploadRequest(token, {
          sizeBytes: image.blob.size,
          mediaType: image.blob.type,
        });
        await consumeUploadRequest(image.blob, uploadRequest);
        const {
          processingRequest: { id },
        } = await uploadRequestIntoProcessingRequest(token, uploadRequest.id);
        return id;
      })
    );

    const checkProcessingDone = async () => {
      const details = await getProcessingRequestDetails(token, processingRequestIds);
      const done = details.processingRequests.every((request) => request.state === 'S');

      if (done) {
        clearInterval(interval);
        setUploadState('creating');
        const assetIds = details.processingRequests
          .map((request) => request.result?.id)
          .filter(exists);
        await createMediaGroup(token, {
          label: data.title,
          description: data.description,
          projectId: data.projectId,
          assets: assetIds,
          kind: 'gallery', // TODO: user can toggle
          tags: [],
        });
        setUploadState('idle');
        setImages([]);
        reset({
          description: '',
          projectId: '',
          title: '',
        });
      }

      const failed = details.processingRequests.some(
        (request) => request.state === 'F' || request.state === 'R'
      );

      if (failed) {
        clearInterval(interval);
        setUploadState('failed');
        // TODO(@hill): error message
      }
    };
    const interval = setInterval(checkProcessingDone, 1000);
  };

  useEffect(() => {
    listWorkspaces(token).then((res) => {
      setWorkspaces(res.workspaces);
      setSelectedWorkspace(res.workspaces[0]);
    });
  }, [token]);

  useEffect(() => {
    if (!token || !selectedWorkspace) return;
    getProjects(token, selectedWorkspace.id).then((res) => {
      setProjects(res.projects);
    });
  }, [token, selectedWorkspace]);

  useEffect(() => {
    if (!exportedData) return;
    const assets = exportedData.map((exportableByte) => {
      const { format, bytes, dimensions } = exportableByte;
      const blob = new Blob([typedArrayToBuffer(bytes) as BlobPart], {
        type: exportTypeToBlobType(format),
      });
      const url = URL.createObjectURL(blob);
      const filename = `export${exportTypeToFileExtension(format)}`;
      return { url, filename, dimensions, blob };
    });
    setImages(assets);
  }, [exportedData]);

  return (
    <main className={styles.upload}>
      <header>
        <p className="nav5">Spaceduck</p>
      </header>
      <div className={styles.uploadArea}>
        {images.length === 0 && (
          <>
            <AddImage />
            <p className="nav6">Select the things you want to upload</p>
          </>
        )}
        <ReorderableImageRow
          assets={images}
          onChange={(newOrder) => setImages(newOrder)}
        />
      </div>
      <footer>
        <input
          type="text"
          placeholder="Title"
          {...register('title', { required: true })}
        />
        {errors.title && <span>Title is required</span>}
        <textarea placeholder="Description (Optional)" {...register('description')} />
        <Select
          className={styles.select}
          placeholder={<p>Select Space</p>}
          onValueChange={(value) => setValue('projectId', value)}
          selectGroups={[
            {
              label: 'Spaces',
              options: projects.map((project) => ({
                label: project.label,
                value: project.id,
              })),
            },
          ]}
          value={getValues('projectId')}
        />
        <Button
          disabled={uploadState !== 'idle'}
          className={styles.postBtn}
          onClick={handleSubmit(onSend)}
        >
          {
            {
              idle: 'Post',
              uploading: 'Uploading...',
              creating: 'Creating Design...',
              failed: 'Failed :(',
            }[uploadState]
          }
        </Button>
      </footer>
    </main>
  );
}

export default Upload;
