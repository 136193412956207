import clsx from 'clsx';

import { css } from '@lib/css';
import ScrollArea from '@ui/ScrollArea';
import styles from './Container.module.scss';

type ContainerProps = {
  children?: React.ReactNode;
  className?: string;
  title: string;
};

export default function Container({ children, className, title }: ContainerProps) {
  return (
    <ScrollArea
      className={clsx(styles.container, className)}
      orientation="vertical"
      style={css({
        '--width': '100%',
        '--maxHeight': '100%',
      })}
    >
      {!!title && <h3 className={styles.title}>{title}</h3>}
      <div className={styles.content}>{children}</div>
    </ScrollArea>
  );
}
