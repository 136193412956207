import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 9C19.8011 9 19.6103 8.92098 19.4697 8.78033C19.329 8.63968 19.25 8.44891 19.25 8.25C19.25 7.85218 19.092 7.47064 18.8107 7.18934C18.5294 6.90804 18.1478 6.75 17.75 6.75C17.5511 6.75 17.3603 6.67098 17.2197 6.53033C17.079 6.38968 17 6.19891 17 6C17 5.80109 17.079 5.61032 17.2197 5.46967C17.3603 5.32902 17.5511 5.25 17.75 5.25C18.1478 5.25 18.5294 5.09196 18.8107 4.81066C19.092 4.52936 19.25 4.14782 19.25 3.75C19.25 3.55109 19.329 3.36032 19.4697 3.21967C19.6103 3.07902 19.8011 3 20 3C20.1989 3 20.3897 3.07902 20.5303 3.21967C20.671 3.36032 20.75 3.55109 20.75 3.75C20.75 4.14782 20.908 4.52936 21.1893 4.81066C21.4706 5.09196 21.8522 5.25 22.25 5.25C22.4489 5.25 22.6397 5.32902 22.7803 5.46967C22.921 5.61032 23 5.80109 23 6C23 6.19891 22.921 6.38968 22.7803 6.53033C22.6397 6.67098 22.4489 6.75 22.25 6.75C21.8522 6.75 21.4706 6.90804 21.1893 7.18934C20.908 7.47064 20.75 7.85218 20.75 8.25C20.75 8.44891 20.671 8.63968 20.5303 8.78033C20.3897 8.92098 20.1989 9 20 9Z"
        fill={color}
      />
      <path
        d="M9 22C8.64638 22 8.30724 21.8595 8.05719 21.6095C7.80714 21.3594 7.66667 21.0203 7.66667 20.6667C7.66667 19.2522 7.10476 17.8956 6.10457 16.8954C5.10438 15.8952 3.74782 15.3333 2.33333 15.3333C1.97971 15.3333 1.64057 15.1929 1.39052 14.9428C1.14048 14.6928 1 14.3536 1 14C1 13.6464 1.14048 13.3072 1.39052 13.0572C1.64057 12.8071 1.97971 12.6667 2.33333 12.6667C3.74782 12.6667 5.10438 12.1048 6.10457 11.1046C7.10476 10.1044 7.66667 8.74782 7.66667 7.33333C7.66667 6.97971 7.80714 6.64057 8.05719 6.39052C8.30724 6.14048 8.64638 6 9 6C9.35362 6 9.69276 6.14048 9.94281 6.39052C10.1929 6.64057 10.3333 6.97971 10.3333 7.33333C10.3333 8.74782 10.8952 10.1044 11.8954 11.1046C12.8956 12.1048 14.2522 12.6667 15.6667 12.6667C16.0203 12.6667 16.3594 12.8071 16.6095 13.0572C16.8595 13.3072 17 13.6464 17 14C17 14.3536 16.8595 14.6928 16.6095 14.9428C16.3594 15.1929 16.0203 15.3333 15.6667 15.3333C14.2522 15.3333 12.8956 15.8952 11.8954 16.8954C10.8952 17.8956 10.3333 19.2522 10.3333 20.6667C10.3333 21.0203 10.1929 21.3594 9.94281 21.6095C9.69276 21.8595 9.35362 22 9 22Z"
        fill={color}
      />
    </svg>
  );
}
