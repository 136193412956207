import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8149 2.18507C14.1187 2.48881 14.1187 2.98127 13.8149 3.28501L2.32776 14.7722C2.02401 15.0759 1.53155 15.0759 1.22781 14.7722C0.924064 14.4684 0.924064 13.976 1.22781 13.6722L12.715 2.18507C13.0187 1.88132 13.5112 1.88132 13.8149 2.18507Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 1.73387C4.5 1.32857 4.82857 1 5.23387 1H14.2661C14.6714 1 15 1.32857 15 1.73387V10.7661C15 11.1714 14.6714 11.5 14.2661 11.5C13.8608 11.5 13.5323 11.1714 13.5323 10.7661V2.46775H5.23387C4.82857 2.46775 4.5 2.13918 4.5 1.73387Z"
        fill={color}
      />
    </svg>
  );
}
