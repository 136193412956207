import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 11.5L4.5 2.5L1 11.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.16699 8.5H6.83299"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 13.5C9.5 13.5 10.408 14.625 12 14.5C13.395 14.39 14.501 13.553 14.501 12.213V5.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.001 11.512C13.3823 11.512 14.502 10.1662 14.502 8.506C14.502 6.84583 13.3823 5.5 12.001 5.5C10.6197 5.5 9.5 6.84583 9.5 8.506C9.5 10.1662 10.6197 11.512 12.001 11.512Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
