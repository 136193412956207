import CodeBlock from '@tiptap/extension-code-block';

export default CodeBlock.extend({
  addNodeView() {
    return () => {
      const pre = document.createElement('pre');
      pre.classList.add('codeblock');
      const code = document.createElement('code');
      pre.appendChild(code);

      return {
        dom: pre,
        contentDOM: code,
      };
    };
  },
});
