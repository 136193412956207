import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1589_4789)">
        <path
          d="M10 0.5V4.5H14"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 6.5V4.5L10 0.5H2V6.5"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 13.5V15.5H14V13.5"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.14998 10.65C6.14998 10.85 6.09998 11 5.99998 11.1C5.89998 11.2 5.79998 11.35 5.59998 11.4C5.39998 11.45 5.24998 11.5 4.99998 11.5C4.79998 11.5 4.64998 11.5 4.49998 11.45C4.34998 11.4 4.24998 11.4 4.09998 11.3V10.6C4.24998 10.7 4.39998 10.75 4.54998 10.8C4.69998 10.85 4.84998 10.85 4.99998 10.85C5.09998 10.85 5.19998 10.85 5.24998 10.8C5.29998 10.75 5.34998 10.7 5.34998 10.65C5.34998 10.6 5.34998 10.6 5.29998 10.55C5.24998 10.5 5.24998 10.5 5.19998 10.45C5.14998 10.4 5.04998 10.35 4.79998 10.25C4.59998 10.15 4.44998 10.1 4.39998 10C4.34998 9.90001 4.24998 9.85001 4.19998 9.75001C4.14998 9.65001 4.14998 9.55001 4.14998 9.40001C4.14998 9.15001 4.24998 8.95001 4.44998 8.80001C4.64998 8.65001 4.89998 8.60001 5.24998 8.60001C5.54998 8.60001 5.84998 8.65001 6.14998 8.80001L5.89998 9.40001C5.64998 9.30001 5.39998 9.20001 5.19998 9.20001C5.09998 9.20001 5.04998 9.20001 4.99998 9.25001C4.94998 9.30001 4.94998 9.35001 4.94998 9.40001C4.94998 9.45001 4.99998 9.50001 5.04998 9.55001C5.09998 9.60001 5.24998 9.65001 5.49998 9.80001C5.74998 9.90001 5.89998 10.05 5.99998 10.15C6.09998 10.25 6.14998 10.45 6.14998 10.65Z"
          fill={color}
        />
        <path
          d="M8.20005 8.64999H9.05005L8.15005 11.5H7.25005L6.30005 8.64999H7.15005L7.55005 10.1C7.65005 10.4 7.65005 10.65 7.70005 10.75C7.70005 10.65 7.75005 10.55 7.75005 10.4C7.75005 10.25 7.80005 10.15 7.80005 10.1L8.20005 8.64999Z"
          fill={color}
        />
        <path
          d="M10.5 9.84999H11.75V11.4C11.4 11.5 11.05 11.55 10.65 11.55C10.2 11.55 9.85005 11.4 9.65005 11.15C9.45005 10.9 9.30005 10.55 9.30005 10.05C9.30005 9.59999 9.45005 9.24999 9.70005 8.94999C9.95005 8.64999 10.35 8.54999 10.8 8.54999C11 8.54999 11.15 8.54999 11.3 8.59999C11.45 8.64999 11.6 8.69999 11.7 8.74999L11.45 9.34999C11.25 9.24999 11 9.19999 10.75 9.19999C10.5 9.19999 10.35 9.24999 10.25 9.39999C10.15 9.54999 10.05 9.74999 10.05 10.05C10.05 10.3 10.1 10.5 10.2 10.65C10.3 10.8 10.45 10.85 10.7 10.85C10.8 10.85 10.9 10.85 11 10.8V10.35H10.5V9.84999Z"
          fill={color}
        />
        <path
          d="M15.5 6.5H0.5V13.5H15.5V6.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1589_4789">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
