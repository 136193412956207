import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3772_7686)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5618 3.5955C7.00859 3.5955 7.37079 3.9577 7.37079 4.40449V8.7191C7.37079 9.16589 7.00859 9.52809 6.5618 9.52809C6.115 9.52809 5.75281 9.16589 5.75281 8.7191V4.40449C5.75281 3.9577 6.115 3.5955 6.5618 3.5955Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.59558 6.5618C3.59558 6.115 3.95778 5.75281 4.40457 5.75281H8.71918C9.16597 5.75281 9.52816 6.115 9.52816 6.5618C9.52816 7.00859 9.16597 7.37079 8.71918 7.37079H4.40457C3.95778 7.37079 3.59558 7.00859 3.59558 6.5618Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.057 10.057C10.3729 9.74106 10.8852 9.74106 11.2011 10.057L15.7631 14.619C16.079 14.9349 16.079 15.4471 15.7631 15.7631C15.4471 16.079 14.9349 16.079 14.619 15.7631L10.057 11.2011C9.74109 10.8851 9.74109 10.3729 10.057 10.057Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5618 1.61798C3.8314 1.61798 1.61798 3.8314 1.61798 6.5618C1.61798 9.29219 3.8314 11.5056 6.5618 11.5056C9.29219 11.5056 11.5056 9.29219 11.5056 6.5618C11.5056 3.8314 9.29219 1.61798 6.5618 1.61798ZM0 6.5618C0 2.93782 2.93782 0 6.5618 0C10.1858 0 13.1236 2.93782 13.1236 6.5618C13.1236 10.1858 10.1858 13.1236 6.5618 13.1236C2.93782 13.1236 0 10.1858 0 6.5618Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3772_7686">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
