import { useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';

import type { MediaGroupDetailDTO } from '@spaceduck/api';

import { useMediaGroupReadableSummary } from '@api/mediaGroup';
import LoadingPlaceholder from '@components/LoadingPlaceholder';
import { usePoll } from '@hooks/usePoll';
import Button from '@ui/Button';
import styles from './ReadingModeView.module.scss';

const LoadingPane = () => (
  <div className={styles.pane}>
    <div className={styles.content}>
      <LoadingPlaceholder message="Loading readable content..." />
    </div>
  </div>
);

const ErrorPane = ({ onRetry }: { onRetry: () => Promise<void> }) => (
  <div className={styles.pane}>
    <div className={styles.content}>
      <div>Error fetching reading mode content. Please try again later.</div>
      <div className={styles.actions}>
        <Button onClick={onRetry}>Retry</Button>
      </div>
    </div>
  </div>
);

const NotAvailablePane = () => (
  <div className={styles.pane}>
    <div className={styles.content}>
      <div>
        Reading mode content not available or not supported for this webpage. Sorry!
      </div>
    </div>
  </div>
);

export const ReadingModeView = ({
  className,
  mediaGroup,
}: {
  className?: string;
  mediaGroup: MediaGroupDetailDTO;
}) => {
  const { data, status, refetch } = useMediaGroupReadableSummary(mediaGroup.id);

  const handleRetry = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const html = data?.kind === 'success' ? data.html : null;
  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }
    wrapperRef.current.querySelectorAll('svg').forEach((svg) => {
      if (svg.getAttribute('width') || svg.getAttribute('height')) {
        return;
      }
      const viewBox = svg.viewBox?.baseVal;
      if (!viewBox) {
        return viewBox;
      }
      svg.setAttribute('width', `${viewBox.width}`);
      svg.setAttribute('height', `${viewBox.height}`);
    });
  }, [html]);

  const shouldPoll =
    status === 'success' && data.kind === 'readableSummaryNotAvailable' && data.pending;
  const pollFn = useCallback(() => refetch(), []);
  usePoll(5000, shouldPoll, pollFn);

  if (status === 'error') {
    return <ErrorPane onRetry={handleRetry} />;
  }

  if (status === 'pending') {
    return <LoadingPane />;
  }

  if (data.kind === 'readableSummaryNotAvailable') {
    if (data.pending) {
      return <LoadingPane />;
    }
    return <NotAvailablePane />;
  }

  return (
    <div className={clsx(styles.container, className)}>
      <div ref={wrapperRef} className={styles.wrapper}>
        {/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
        <div dangerouslySetInnerHTML={{ __html: data.html }} />
      </div>
    </div>
  );
};
