import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.57901 15L4.73601 12H11.264L12.421 15H14.565L9.16701 1H6.83301L1.43301 15H3.57901ZM8.00001 3.532L10.493 10H5.50701L8.00001 3.532Z"
        fill={color}
      />
    </svg>
  );
}
