import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9999 17.4C14.5103 17.4 16.5454 15.3853 16.5454 12.9C16.5454 10.4147 14.5103 8.40002 11.9999 8.40002C9.48954 8.40002 7.45447 10.4147 7.45447 12.9C7.45447 15.3853 9.48954 17.4 11.9999 17.4Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1818 21H3.81818C3.33597 21 2.87351 20.8104 2.53253 20.4728C2.19156 20.1352 2 19.6774 2 19.2V7.5C2 7.02261 2.19156 6.56477 2.53253 6.22721C2.87351 5.88964 3.33597 5.7 3.81818 5.7H7.45455L9.27273 3H14.7273L16.5455 5.7H20.1818C20.664 5.7 21.1265 5.88964 21.4675 6.22721C21.8084 6.56477 22 7.02261 22 7.5V19.2C22 19.6774 21.8084 20.1352 21.4675 20.4728C21.1265 20.8104 20.664 21 20.1818 21Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.72733 9.3C5.22941 9.3 5.63642 8.89706 5.63642 8.4C5.63642 7.90294 5.22941 7.5 4.72733 7.5C4.22525 7.5 3.81824 7.90294 3.81824 8.4C3.81824 8.89706 4.22525 9.3 4.72733 9.3Z"
        fill={color}
      />
    </svg>
  );
}
