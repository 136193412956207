import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.808993C0 0.3622 0.362197 3.8147e-06 0.808989 3.8147e-06H15.191C15.6378 3.8147e-06 16 0.3622 16 0.808993V6.5618C16 7.00859 15.6378 7.37079 15.191 7.37079H0.808989C0.362197 7.37079 0 7.00859 0 6.5618V0.808993ZM1.61798 1.61798V5.75281H14.382V1.61798H1.61798Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.43821C0 8.99142 0.362197 8.62922 0.808989 8.62922H6.5618C7.00859 8.62922 7.37079 8.99142 7.37079 9.43821C7.37079 9.885 7.00859 10.2472 6.5618 10.2472H0.808989C0.362197 10.2472 0 9.885 0 9.43821Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.62927 9.43821C8.62927 8.99142 8.99147 8.62922 9.43826 8.62922H15.1911C15.6379 8.62922 16.0001 8.99142 16.0001 9.43821C16.0001 9.885 15.6379 10.2472 15.1911 10.2472H9.43826C8.99147 10.2472 8.62927 9.885 8.62927 9.43821Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.3146C0 11.8678 0.362197 11.5056 0.808989 11.5056H6.5618C7.00859 11.5056 7.37079 11.8678 7.37079 12.3146C7.37079 12.7614 7.00859 13.1236 6.5618 13.1236H0.808989C0.362197 13.1236 0 12.7614 0 12.3146Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.62927 12.3146C8.62927 11.8678 8.99147 11.5056 9.43826 11.5056H15.1911C15.6379 11.5056 16.0001 11.8678 16.0001 12.3146C16.0001 12.7614 15.6379 13.1236 15.1911 13.1236H9.43826C8.99147 13.1236 8.62927 12.7614 8.62927 12.3146Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 15.191C0 14.7442 0.362197 14.382 0.808989 14.382H6.5618C7.00859 14.382 7.37079 14.7442 7.37079 15.191C7.37079 15.6378 7.00859 16 6.5618 16H0.808989C0.362197 16 0 15.6378 0 15.191Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.62927 15.191C8.62927 14.7442 8.99147 14.382 9.43826 14.382H15.1911C15.6379 14.382 16.0001 14.7442 16.0001 15.191C16.0001 15.6378 15.6379 16 15.1911 16H9.43826C8.99147 16 8.62927 15.6378 8.62927 15.191Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.87634 3.6854C2.87634 3.23861 3.23854 2.87642 3.68533 2.87642H6.56174C7.00853 2.87642 7.37073 3.23861 7.37073 3.6854C7.37073 4.1322 7.00853 4.49439 6.56174 4.49439H3.68533C3.23854 4.49439 2.87634 4.1322 2.87634 3.6854Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.62927 3.6854C8.62927 3.23861 8.99147 2.87642 9.43826 2.87642H12.3147C12.7615 2.87642 13.1237 3.23861 13.1237 3.6854C13.1237 4.1322 12.7615 4.49439 12.3147 4.49439H9.43826C8.99147 4.49439 8.62927 4.1322 8.62927 3.6854Z"
        fill={color}
      />
    </svg>
  );
}
