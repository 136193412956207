import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3332 8.66669H15.3332C14.414 8.66669 13.6665 9.41419 13.6665 10.3334V20.3334C13.6665 21.2525 14.414 22 15.3332 22H20.3332C21.2523 22 21.9998 21.2525 21.9998 20.3334V10.3334C21.9998 9.41419 21.2523 8.66669 20.3332 8.66669ZM20.3332 18.6667H15.3332V10.3334H20.3332V18.6667Z"
        fill={color}
      />
      <path
        d="M19.5 2H3.66667C2.7475 2 2 2.7475 2 3.66667V15.3333C2 16.2525 2.7475 17 3.66667 17H12V14.5H7.83333C7.37333 14.5 7 14.1275 7 13.6667V5.33333C7 4.8725 7.37333 4.5 7.83333 4.5H17.8333C18.2933 4.5 18.6667 4.8725 18.6667 5.33333V7H21.1667V3.66667C21.1667 2.7475 20.4192 2 19.5 2ZM4.5 10.3333C4.04 10.3333 3.66667 9.96 3.66667 9.5C3.66667 9.04 4.04 8.66667 4.5 8.66667C4.96 8.66667 5.33333 9.04 5.33333 9.5C5.33333 9.96 4.96 10.3333 4.5 10.3333Z"
        fill={color}
      />
    </svg>
  );
}
