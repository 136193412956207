import clsx from 'clsx';

import styles from './Header.module.scss';

export default function Header({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <header className={clsx(styles.header, className)}>{children}</header>;
}
