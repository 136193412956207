import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 19.562C12.926 19.844 13.938 20 15 20C15.59 20 16.163 19.949 16.716 19.858L21 22V17.96C22.241 16.903 23 15.52 23 14C23 13.659 22.962 13.325 22.888 13"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 1C5.477 1 1 4.582 1 9C1 10.797 1.75 12.45 3 13.785V19L7.833 16.584C8.829 16.85 9.892 17 11 17C16.523 17 21 13.418 21 9C21 4.582 16.523 1 11 1Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
