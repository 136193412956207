import { useCallback, useEffect, useRef } from 'react';

/**
 * A hook that allows you to poll a function at a given interval.
 * This is safe to use with async functions.
 *
 * @param interval - The interval in milliseconds between each poll
 * @param enabled - Whether the poll should be enabled or not
 * @param fn - The function to be called on each poll
 *
 * @returns A function that can be called to trigger a poll
 *
 * @example
 * ```tsx
 * const trigger = usePoll(1000, true, async () => {
 *  const response = await fetch('https://example.com');
 *  const data = await response.json();
 *  console.log(data);
 * });
 *
 * trigger();
 * ```
 */
export const usePoll = <T, K>(
  interval: number,
  enabled: boolean,
  fn: (params?: K) => Promise<T>
) => {
  type State = {
    interval: number;
    enabled: boolean;
    fn: (params?: K) => Promise<T>;
    timeoutId?: ReturnType<typeof setTimeout>;
    params?: K;
  };
  const state = useRef<State>({
    interval,
    enabled,
    fn,
    timeoutId: undefined,
    params: undefined,
  });

  useEffect(() => {
    state.current.fn = fn;
  }, [fn]);

  useEffect(() => {
    state.current.enabled = enabled;
    clear();
    if (enabled) {
      enqueue(state.current.params);
    }
  }, [enabled]);

  useEffect(() => {
    state.current.interval = interval;
  }, [interval]);

  useEffect(() => {
    return () => {
      clearTimeout(state.current.timeoutId);
      state.current.enabled = false;
    };
  }, []);

  const clear = useCallback(() => {
    clearTimeout(state.current.timeoutId);
    state.current.timeoutId = undefined;
  }, []);

  const enqueue = useCallback((params?: K) => {
    state.current.timeoutId = setTimeout(
      () => trigger.apply(null, [params]),
      state.current.interval
    );
  }, []);

  const trigger = useCallback(async (params?: K) => {
    clear();
    try {
      state.current.params = params;
      await state.current.fn.apply(null, [params]);
    } catch (error) {}
    if (state.current.enabled) {
      enqueue(params);
    }
  }, []);

  return trigger;
};
