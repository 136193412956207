import {
  type CategoryFieldKindSettings,
  type MediaGroupCategoryDetail,
  type MediaGroupWithCategoryFields,
  mediaGroupCategoryFieldKinds,
} from '@spaceduck/api';
import { upperFirst } from 'lodash';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import type { SelectableTypes, TableColumnMeta } from '@/types/Category';
import { usePatchMediaGroup } from '@api/mediaGroup';
import { useListMediaGroupCategories } from '@api/workspace';
import { useModalManager } from '@context/ModalManagerContext';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Button from '@ui/Button';
import Dialog from '@ui/Dialog';
import Select from '@ui/Select';
import createToast from '@utils/createToast';
import styles from './Forms.module.scss';

export type CreatePropertyFormData = Pick<TableColumnMeta, 'label'> &
  CategoryFieldKindSettings;
export type OnCreateSubmit = (payload: CreatePropertyFormData) => void;
export type EditPropertyFormData = Pick<TableColumnMeta, 'label'>;
export type OnEditSubmit = (payload: EditPropertyFormData) => void;
export type EditAliasFormData = Pick<MediaGroupCategoryDetail, 'labelAlias'>;
export type OnEditAliasSubmit = (payload: EditAliasFormData) => void;
export type OnDeleteSubmit = (payload: string) => void;

const CreatePropertyModal = ({
  closeModal,
  onSubmit,
  type,
}: {
  closeModal: () => void;
  onSubmit: OnCreateSubmit;
  type: SelectableTypes;
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm<CreatePropertyFormData>();

  const workspaceId = useWorkspaceId();
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useListMediaGroupCategories(workspaceId);
  const categories = data?.pages.flatMap((page) => page.categories);

  useEffect(() => {
    setFocus('label');
  }, []);

  const formSubmit = (data: CreatePropertyFormData) => {
    reset();
    const kind = type ?? data.kind;

    if (kind === 'select' || kind === 'multi-select') {
      onSubmit({
        label: data.label,
        kind: kind,
        settings: {
          options: [],
        },
      });
    } else {
      onSubmit({
        label: data.label,
        kind: kind,
        settings: data.settings || null,
      } as CreatePropertyFormData);
    }
    closeModal();
  };

  return (
    <Dialog
      className={styles.propertyModal}
      closeModal={closeModal}
      headerPadding={0}
      isOpen={true}
      maxWidth="32.5rem"
      modalHeading={'New property'}
      padding="lg"
    >
      <form
        className={styles.propertyForm}
        onSubmit={handleSubmit((data) => formSubmit(data))}
      >
        <div className="formGroup">
          <label htmlFor="propertyName">Property name</label>
          <input
            id="propertyName"
            type="text"
            {...register('label', {
              required: 'Property name is required',
              validate: (value) => {
                if (value.trim().length === 0) {
                  return 'Cannot be empty';
                }
              },
            })}
          />
          {errors?.label?.message && (
            <p className="errorMessage">{errors.label.message}</p>
          )}
        </div>
        {type === 'relationship' && categories && (
          <div className="formGroup">
            <label htmlFor="propertyName">Select category</label>
            <Controller
              control={control}
              name="settings.mediaGroupCategoryId"
              render={({ field: { onChange, value } }) => (
                <Select
                  className={styles.select}
                  contentClassName={styles.selectContent}
                  data={{
                    isPaginated: true,
                    canLoadMore: hasNextPage && !isFetchingNextPage,
                    loadMore: () => fetchNextPage(),
                  }}
                  triggerClassName={styles.selectTrigger}
                  onValueChange={(value) => onChange(value)}
                  placeholder="-- Select --"
                  selectGroups={[
                    {
                      options: categories.map(({ id, label }) => ({
                        label: label,
                        value: id,
                      })),
                    },
                  ]}
                  value={value ?? undefined}
                />
              )}
              rules={{ required: 'Property type is required' }}
            />
            {errors?.kind?.message && (
              <p className="errorMessage fieldError">{errors.kind.message}</p>
            )}
          </div>
        )}
        {!type && (
          <div className="formGroup">
            <label htmlFor="propertyName">Property type</label>
            <Controller
              control={control}
              name="kind"
              render={({ field: { onChange, value } }) => (
                <Select
                  className={styles.select}
                  contentClassName={styles.selectContent}
                  triggerClassName={styles.selectTrigger}
                  onValueChange={(value) => onChange(value)}
                  placeholder="-- Select --"
                  selectGroups={[
                    {
                      options: mediaGroupCategoryFieldKinds.map((option) => ({
                        label: upperFirst(option),
                        value: option,
                      })),
                    },
                  ]}
                  value={value ?? undefined}
                />
              )}
              rules={{ required: 'Property type is required' }}
            />
            {errors?.kind?.message && (
              <p className="errorMessage fieldError">{errors.kind.message}</p>
            )}
          </div>
        )}
        <div className={styles.propertyFormFooter}>
          <Button size="sm" type="button" variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="sm" type="submit" variant="primary">
            Create
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export function useCreatePropertyModal() {
  const { openModal, closeModal } = useModalManager();
  return {
    open: ({
      onSubmit,
      type,
    }: {
      onSubmit: OnCreateSubmit;
      type: SelectableTypes;
    }) => {
      openModal({
        component: (
          <CreatePropertyModal
            closeModal={closeModal}
            onSubmit={onSubmit}
            type={type}
          />
        ),
      });
    },
    close: closeModal,
  };
}

const EditPropertyModel = ({
  categoryProperties,
  closeModal,
  onSubmit,
  property,
}: {
  categoryProperties: TableColumnMeta[];
  closeModal: () => void;
  onSubmit: OnEditSubmit;
  property: TableColumnMeta;
}) => {
  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm<EditPropertyFormData>({
    defaultValues: {
      label: property.label,
    },
  });

  useEffect(() => {
    setFocus('label');
  }, []);

  const formSubmit = (data: EditPropertyFormData) => {
    reset();
    onSubmit({
      label: data.label,
    });
    closeModal();
  };

  return (
    <Dialog
      className={styles.propertyModal}
      closeModal={closeModal}
      headerPadding={0}
      isOpen={true}
      maxWidth="32.5rem"
      modalHeading={'Create property'}
      padding="lg"
    >
      <form className={styles.propertyForm} onSubmit={handleSubmit(formSubmit)}>
        <div className="formGroup">
          <label htmlFor="propertyName">Property name</label>
          <input
            id="propertyName"
            type="text"
            {...register('label', {
              required: 'Property name is required',
              validate: {
                unique: (value) => {
                  const lowerValue = value.toLowerCase();
                  const existing = categoryProperties.find((c) => {
                    return c.id !== property.id && c.label.toLowerCase() === lowerValue;
                  });
                  if (existing) {
                    return 'Property name must be unique';
                  }
                  return true;
                },
              },
            })}
          />
          {errors?.label?.message && (
            <p className="errorMessage">{errors.label.message}</p>
          )}
        </div>
        <div className={styles.propertyFormFooter}>
          <Button size="sm" type="button" variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button disabled={!isDirty} size="sm" type="submit" variant="primary">
            Save
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export function useEditPropertyModal() {
  const { openModal, closeModal } = useModalManager();
  return {
    open: ({
      categoryProperties,
      onSubmit,
      property,
    }: {
      categoryProperties: TableColumnMeta[];
      onSubmit: OnEditSubmit;
      property: TableColumnMeta;
    }) => {
      openModal({
        component: (
          <EditPropertyModel
            categoryProperties={categoryProperties}
            closeModal={closeModal}
            onSubmit={onSubmit}
            property={property}
          />
        ),
      });
    },
    close: closeModal,
  };
}

const EditAliasModal = ({
  category,
  categoryProperties,
  closeModal,
  onSubmit,
}: {
  category: MediaGroupCategoryDetail;
  categoryProperties: TableColumnMeta[];
  closeModal: () => void;
  onSubmit: OnEditAliasSubmit;
}) => {
  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm<EditAliasFormData>({
    defaultValues: {
      labelAlias: category.labelAlias ?? category.label,
    },
  });

  useEffect(() => {
    setFocus('labelAlias');
  }, []);

  const formSubmit = (data: EditAliasFormData) => {
    reset();
    onSubmit({
      labelAlias: data.labelAlias,
    });
    closeModal();
  };

  return (
    <Dialog
      className={styles.propertyModal}
      closeModal={closeModal}
      headerPadding={0}
      isOpen={true}
      maxWidth="32.5rem"
      modalHeading={'Create property'}
      padding="lg"
    >
      <form className={styles.propertyForm} onSubmit={handleSubmit(formSubmit)}>
        <div className="formGroup">
          <label htmlFor="propertyName">Property name</label>
          <input
            id="propertyName"
            type="text"
            {...register('labelAlias', {
              required: 'Property name is required',
              validate: {
                unique: (value) => {
                  if (!value) return true;
                  const lowerValue = value.toLowerCase();
                  const existing = categoryProperties.find((c) => {
                    return c.id !== category.id && c.label.toLowerCase() === lowerValue;
                  });
                  if (existing) {
                    return 'Property name must be unique';
                  }
                  return true;
                },
              },
            })}
          />
          {errors?.labelAlias?.message && (
            <p className="errorMessage">{errors.labelAlias.message}</p>
          )}
        </div>
        <div className={styles.propertyFormFooter}>
          <Button size="sm" type="button" variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button disabled={!isDirty} size="sm" type="submit" variant="primary">
            Save
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export function useEditAliasModal() {
  const { openModal, closeModal } = useModalManager();
  return {
    open: ({
      category,
      categoryProperties,
      onSubmit,
    }: {
      category: MediaGroupCategoryDetail;
      categoryProperties: TableColumnMeta[];
      onSubmit: OnEditAliasSubmit;
    }) => {
      openModal({
        component: (
          <EditAliasModal
            category={category}
            categoryProperties={categoryProperties}
            closeModal={closeModal}
            onSubmit={onSubmit}
          />
        ),
      });
    },
    close: closeModal,
  };
}

type EditMediaGroupLabelFormData = {
  label: string;
};

const EditMediaGroupLabelModal = ({
  closeModal,
  mediaGroup,
}: {
  closeModal: () => void;
  mediaGroup: MediaGroupWithCategoryFields;
}) => {
  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
  } = useForm<EditMediaGroupLabelFormData>({
    defaultValues: {
      label: mediaGroup.label,
    },
  });

  const { mutateAsync: patchMediaGroup, isPending } = usePatchMediaGroup();

  const formSubmit = async (data: EditMediaGroupLabelFormData) => {
    if (!data.label) return;
    const { label } = data;

    const patchData = {
      label,
    };

    const res = await patchMediaGroup({
      mediaGroupId: mediaGroup.id,
      patch: patchData,
    });

    if (res.kind === 'success') {
      createToast({
        titleText: 'Item renamed',
        bodyText: 'Your item has been renamed successfully!',
        iconVariant: 'success',
      });
      closeModal();
    } else {
      createToast({
        titleText: 'Update failed',
        bodyText: `${upperFirst(label)} could not be updated.`,
        iconVariant: 'danger',
      });
    }
  };

  return (
    <Dialog
      className={styles.propertyModal}
      closeModal={closeModal}
      headerPadding={0}
      isOpen={true}
      maxWidth="32.5rem"
      modalHeading={'Create property'}
      padding="lg"
    >
      <form className={styles.propertyForm} onSubmit={handleSubmit(formSubmit)}>
        <div className="formGroup">
          <label htmlFor="mediaGroupLabel">Rename item</label>
          <input
            id="mediaGroupLabel"
            type="text"
            {...register('label', {
              required: 'Item name is required',
            })}
          />
          {errors?.label?.message && (
            <p className="errorMessage">{errors.label.message}</p>
          )}
        </div>
        <div className={styles.propertyFormFooter}>
          <Button
            disabled={isPending}
            onClick={closeModal}
            size="sm"
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button disabled={!isDirty} size="sm" type="submit" variant="primary">
            Save
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export function useEditMediaGroupLabelModal() {
  const { openModal, closeModal } = useModalManager();

  return {
    open: ({ mediaGroup }: { mediaGroup: MediaGroupWithCategoryFields }) => {
      openModal({
        component: (
          <EditMediaGroupLabelModal closeModal={closeModal} mediaGroup={mediaGroup} />
        ),
      });
    },
    close: closeModal,
  };
}
