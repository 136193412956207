const ErrorDuck = ({
  className,
  height = 150,
  width = 156,
}: {
  className?: string;
  height?: number;
  width?: number;
}) => (
  <svg
    className={className}
    fill="none"
    height={height}
    viewBox="0 0 156 150"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.05"
      d="M1 66.3621C1 102.349 34.0124 131.724 75 131.724C115.988 131.724 149 102.349 149 66.3621C149 30.3751 115.988 1 75 1C34.0124 1 1 30.3751 1 66.3621Z"
      fill="url(#paint0_linear_638_97801)"
      stroke="white"
      strokeWidth="2"
    />
    <ellipse
      opacity="0.05"
      cx="62"
      cy="55.3849"
      rx="62"
      ry="55.3849"
      transform="matrix(-1 0 0 1 137 10.9775)"
      fill="url(#paint1_radial_638_97801)"
    />
    <path
      d="M37.1465 62.5455C37.1465 42.8549 53.142 26.8926 72.8735 26.8926C92.605 26.8926 108.601 42.8549 108.601 62.5455V140.537L37.1465 152.301V62.5455Z"
      fill="url(#paint2_radial_638_97801)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.746 141.503C111.746 142.482 111.037 143.317 110.071 143.477L36.3259 155.618C35.108 155.818 34.001 154.879 34.001 153.644V62.5457C34.001 41.1216 51.4047 23.7539 72.8734 23.7539C94.342 23.7539 111.746 41.1216 111.746 62.5457V141.503ZM37.1464 62.5457C37.1464 42.8551 53.1419 26.8928 72.8734 26.8928C92.6049 26.8928 108.6 42.8551 108.6 62.5457V138.84C108.6 139.819 107.891 140.654 106.925 140.813L39.4713 151.919C38.2534 152.119 37.1464 151.179 37.1464 149.945V62.5457Z"
      fill="#050614"
    />
    <path
      d="M93.5791 73.9141L75.3408 87.0034L87.9577 100.841L125.512 98.2668C129.969 97.9613 133.428 94.2569 133.428 89.7896C133.428 85.8257 130.688 82.3884 126.823 81.5059L93.5791 73.9141Z"
      fill="url(#paint3_radial_638_97801)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.6593 74.1107C93.0259 73.8476 93.4882 73.7545 93.9284 73.855L127.566 81.5367C131.916 82.53 135 86.3914 135 90.8439V91.7459C135 96.7651 131.105 100.927 126.088 101.271L88.0639 103.877C87.5845 103.91 87.1164 103.722 86.793 103.368L74.1761 89.5305C73.8743 89.1995 73.7284 88.7556 73.7752 88.3105C73.822 87.8654 74.057 87.4614 74.4211 87.2001L92.6593 74.1107ZM93.9191 77.0729L77.69 88.7203L88.6074 100.694L125.872 98.1397C129.24 97.9089 131.854 95.115 131.854 91.7459V90.8439C131.854 87.8552 129.784 85.2632 126.865 84.5965L93.9191 77.0729Z"
      fill="#050614"
    />
    <path
      d="M72.9296 56.9012C73.4474 54.9726 75.4338 53.8281 77.3664 54.3449C79.299 54.8616 80.4459 56.8439 79.928 58.7725L77.6002 67.4422C77.0823 69.3708 75.0959 70.5153 73.1633 69.9985C71.2307 69.4818 70.0839 67.4995 70.6017 65.5709L72.9296 56.9012Z"
      fill="#050614"
    />
    <path
      d="M87.8167 59.2289C87.4417 57.8323 88.2722 56.3968 89.6716 56.0226C91.0711 55.6484 92.5095 56.4772 92.8845 57.8738L94.6951 64.6169C95.0701 66.0134 94.2396 67.4489 92.8401 67.8231C91.4407 68.1973 90.0022 67.3685 89.6272 65.972L87.8167 59.2289Z"
      fill="#050614"
    />
    <defs>
      <linearGradient
        id="paint0_linear_638_97801"
        x1="146.698"
        y1="-15.8601"
        x2="88.6202"
        y2="137.87"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDEFF3" />
        <stop offset="1" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_638_97801"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(32.3648 48.7666) rotate(64.454) scale(68.7215 74.0396)"
      >
        <stop stopColor="#EDEFF3" />
        <stop offset="1" stopColor="#CED5DE" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_638_97801"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(47.5272 52.4521) rotate(33.8609) scale(89.1737 104.948)"
      >
        <stop offset="0.572917" stopColor="#F3F0ED" />
        <stop offset="0.741656" stopColor="#F0BDFF" />
        <stop offset="1" stopColor="#54D0FE" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_638_97801"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(83.7797 79.402) rotate(17.1925) scale(55.2953 24.1731)"
      >
        <stop offset="0.542145" stopColor="#FFD242" />
        <stop offset="0.828125" stopColor="#FDB740" />
        <stop offset="1" stopColor="#FF892B" />
      </radialGradient>
    </defs>
  </svg>
);

export default ErrorDuck;
