import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.6 16.8C2 15.2 1 13.2 1 11C1 6 5.9 2 12 2C18.1 2 23 6 23 11C23 16 18.1 20 12 20C10.9 20 9.9 19.9 8.9 19.6L3 22L3.6 16.8Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
