import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8475_5070)">
        <path
          d="M6 3C5.448 3 5 3.448 5 4V7H2V4C2 3.448 1.552 3 1 3C0.448 3 0 3.448 0 4V12C0 12.552 0.448 13 1 13C1.552 13 2 12.552 2 12V9H5V12C5 12.552 5.448 13 6 13C6.552 13 7 12.552 7 12V4C7 3.448 6.552 3 6 3Z"
          fill={color}
        />
        <path
          d="M12.9806 6.014C12.4406 5.927 11.8966 5.964 11.3816 6.114L11.4846 5H14.6916C15.2436 5 15.6916 4.552 15.6916 4C15.6916 3.448 15.2436 3 14.6916 3H10.5726C10.0556 3 9.62462 3.394 9.57662 3.908L9.19962 7.985C9.15862 8.427 9.41362 8.843 9.82662 9.007C10.2406 9.17 10.7106 9.042 10.9836 8.692C11.3996 8.158 12.0416 7.885 12.6596 7.989C13.3066 8.094 14.0056 8.64 14.0556 9.373C14.0806 9.749 13.9286 10.146 13.6376 10.461C13.3246 10.799 12.9056 10.996 12.4876 11.001C12.0686 11.023 11.5636 10.772 11.2416 10.373C10.8936 9.944 10.2646 9.877 9.83562 10.224C9.40562 10.571 9.33862 11.201 9.68662 11.63C10.3916 12.503 11.4056 13.002 12.4726 13.002H12.5096C13.4756 12.992 14.4226 12.561 15.1076 11.82C15.7706 11.104 16.1146 10.163 16.0516 9.239C15.9436 7.643 14.6516 6.287 12.9796 6.016L12.9806 6.014Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_8475_5070">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
