import { create } from 'zustand';

import type { ChatSessionAddedSource } from '@spaceduck/api';

export type SelectedSourceType = {
  id: string | null;
  type: ChatSessionAddedSource['type'];
  label: string;
};

type SourcesStore = {
  includedSources: ChatSessionAddedSource[];
  setIncludedSources: (includedSources: ChatSessionAddedSource[]) => void;
  addIncludedSource: (includedSource: ChatSessionAddedSource) => void;
  removeIncludedSource: (includedSource: SelectedSourceType) => void;
  files: Record<string, File | null>;
  takeFile: (fileKey: string) => File | null;
  addFile: (file: File, key: string) => void;
};

const useStore = create<SourcesStore>((set, get) => ({
  files: {},
  addFile: (file: File, key: string) => {
    set(({ files }) => {
      return {
        files: { ...files, [key]: file },
      };
    });
  },
  takeFile: (fileKey: string) => {
    const { files } = get();
    const file = files[fileKey];
    if (file) {
      set((files) => {
        return { ...files, [fileKey]: null };
      });
      return file;
    }
    return null;
  },
  includedSources: [],
  setIncludedSources: (includedSources: ChatSessionAddedSource[]) => {
    set({ includedSources });
  },
  addIncludedSource: (includedSource: ChatSessionAddedSource) => {
    set(({ includedSources }) => {
      return {
        includedSources: [
          ...includedSources.filter(
            (source) =>
              source.type !== includedSource.type || source.id !== includedSource.id
          ),
          includedSource,
        ],
      };
    });
  },
  removeIncludedSource: (loadingSource: SelectedSourceType) => {
    set(({ includedSources }) => {
      return {
        includedSources: includedSources.filter(
          (source) =>
            source.type !== loadingSource.type || source.id !== loadingSource.id
        ),
      };
    });
  },
}));

export const useSourcesStore = () => {
  const store = useStore();

  return {
    ...store,
  };
};
