import { useListWorkspaces } from '@/api/workspace';
import useWorkspaceId from './useWorkspaceId';

const useWorkspaces = () => {
  const workspaceId = useWorkspaceId();
  const query = useListWorkspaces();
  const workspaces = query.data?.workspaces ?? [];
  const workspace = workspaces.find((ws) => ws.id === workspaceId);
  return {
    workspaces,
    workspace,
  };
};

export default useWorkspaces;
