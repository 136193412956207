import { type Editor, EditorContent } from '@tiptap/react';
import clsx from 'clsx';
import { useRef } from 'react';
import '@fontsource/jetbrains-mono';

import SelectionMenu from './SelectionMenu';
import './content.scss';
import { NotesContextProvider } from './context/NotesContext';
import styles from './Editor.module.scss';

type TipTapEditor = {
  className?: string;
  editor?: Editor | null;
  id?: string;
  mediaGroupId: string;
};

export default function TipTapEditor({
  className,
  editor,
  id,
  mediaGroupId,
}: TipTapEditor) {
  const editorRef = useRef<HTMLDivElement | null>(null);

  if (!(mediaGroupId && editor)) return null;

  return (
    <NotesContextProvider containerRefId={id} mediaGroupId={mediaGroupId}>
      <div id={id}>
        <SelectionMenu editor={editor} />
        <EditorContent
          className={clsx(className, styles.editor)}
          editor={editor}
          ref={editorRef}
        />
      </div>
    </NotesContextProvider>
  );
}
