import { Extension } from '@tiptap/core';
import { type Editor, ReactRenderer } from '@tiptap/react';
import dayjs from 'dayjs';
import { Plugin, PluginKey } from 'prosemirror-state';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import tippy, { type Props as TProps } from 'tippy.js';

import type { AliveCommentDTO } from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';

import { useListComments } from '@api/comment';
import { useCommentContent } from '@components/detailsModal/comments/Comment';
import { linkNode, paragraphTextNode } from '@components/detailsModal/tiptap/utils';
import { useNavigateWithState } from '@hooks/useNavigateWithState';
import Menu from '@ui/Menu';
import UserAvatar from '@ui/UserAvatar';
import { copyTextToClipboard } from '@utils/copyToClipboard';
import { urlFor } from '@/urls';
import EditLinkForm from './link/EditLinkForm';
import styles from './HoverMenu.module.scss';

const {
  Copy,
  EditPencil,
  Link,
  LinkCard,
  Open,
  TableColumnAfter,
  TableColumnBefore,
  TableRemoveColumn,
  TableRemoveRow,
  TableRowAfter,
  TableRowBefore,
  TableToggleHeaderCell,
  TableToggleHeaderColumn,
  TableToggleHeaderRow,
  TrashDelete,
  Unlink,
} = Icon16;

type View = 'card' | 'inline';

const defaultTippySettings = (tippyRootId?: string) =>
  ({
    allowHTML: true,
    interactive: true,
    // Appending to body causes input focus issues
    appendTo: () => document.getElementById(tippyRootId ?? 'tippyHoverMenuRoot')!,
    maxWidth: 'none',
    hideOnClick: false,
    arrow: false,
    offset: [0, 0],
    popperOptions: {
      placement: 'top',
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top'],
          },
        },
      ],
    },
  }) as Partial<TProps>;

export default (tippyRootId: string | undefined = 'tippyHoverMenuRoot') =>
  Extension.create({
    name: 'hoverMenu',
    addProseMirrorPlugins() {
      const myWindow = window as any;

      return [
        new Plugin({
          key: new PluginKey('HoverMenu'),
          props: {
            handleDOMEvents: {
              mouseup(view) {
                const { from, to } = view.state.selection;
                if (from !== to) {
                  myWindow.tippyLinkQuickMenu?.destroy?.();
                  myWindow.tippyLinkQuickMenu = null;
                }
              },
              mouseover(view, event) {
                const { to, from } = view.state.selection;
                const eventTarget = event.target as HTMLElement;
                const myWindow = window as any;
                const editor = this.spec.editor;

                // Remove duplicate instances of tippy
                if (
                  eventTarget.tagName.toUpperCase() === 'A' ||
                  to !== from ||
                  (eventTarget.tagName.toUpperCase() === 'DIV' &&
                    eventTarget.getAttribute('data-media-card') &&
                    !eventTarget.classList.contains('ProseMirror-selectednode')) ||
                  eventTarget.getAttribute('data-comment')
                ) {
                  myWindow.tippyLinkQuickMenu?.destroy?.();
                  myWindow.tippyLinkQuickMenu = null;
                }

                if (to === from && eventTarget.tagName?.toUpperCase() === 'A') {
                  const targetPos = view.posAtDOM(event.target as HTMLElement, 0);
                  const targetNode = view.nodeDOM(targetPos)?.parentNode as HTMLElement;

                  const component = new ReactRenderer(MediaComponent, {
                    props: {
                      editor,
                      pos: targetPos,
                      targetNode,
                      type: 'inline',
                    },
                    editor,
                  });

                  if (targetNode && document.getElementById(tippyRootId)) {
                    const instance = tippy(targetNode as HTMLElement, {
                      ...defaultTippySettings(tippyRootId),
                      content: component.element,
                    });
                    myWindow.tippyLinkQuickMenu = instance;
                  }
                } else if (to === from && eventTarget.getAttribute('data-comment')) {
                  const id = eventTarget.getAttribute('data-comment-id');
                  if (!id) return true;

                  const targetPos = view.posAtDOM(event.target as HTMLElement, 0);
                  const targetNode = view.nodeDOM(targetPos) as HTMLElement;
                  const targetNodeAt = view.state.doc.nodeAt(targetPos);

                  if (!targetNodeAt?.marks?.length) return true;
                  const firstComment = targetNodeAt.marks.find(
                    (mark) => mark.type.name === 'comment'
                  );

                  if (!firstComment) return true;
                  const component = new ReactRenderer(CommentComponent, {
                    props: {
                      commentId: id,
                    },
                    editor,
                  });

                  if (targetNode && document.getElementById(tippyRootId)) {
                    const instance = tippy(targetNode.parentElement as HTMLElement, {
                      ...defaultTippySettings(tippyRootId),
                      content: component.element,
                    });
                    myWindow.tippyLinkQuickMenu = instance;
                  }
                } else if (
                  eventTarget.tagName?.toUpperCase() === 'DIV' &&
                  eventTarget.getAttribute('data-media-card')
                ) {
                  const targetPos = view.posAtDOM(eventTarget, 0);
                  const targetNodeDom = view.nodeDOM(targetPos) as HTMLElement;
                  const targetNode = targetNodeDom.querySelector(
                    '[data-media-card="true"]'
                  );

                  if (
                    to === from ||
                    targetNodeDom.classList.contains('ProseMirror-selectednode')
                  ) {
                    const component = new ReactRenderer(MediaComponent, {
                      props: {
                        editor,
                        pos: targetPos,
                        targetNode,
                        type: 'card',
                      },
                      editor,
                    });

                    if (targetNode && document.getElementById(tippyRootId)) {
                      myWindow.tippyLinkQuickMenu?.destroy();

                      const instance = tippy(targetNode as HTMLElement, {
                        ...defaultTippySettings(tippyRootId),
                        content: component.element,
                      });
                      myWindow.tippyLinkQuickMenu = instance;
                    }
                  }
                } else if (
                  eventTarget.classList.contains('.tiptapTableWrapper') ||
                  eventTarget.closest('.tiptapTableWrapper')
                ) {
                  const hoverPos = view.posAtDOM(eventTarget, 0);
                  const hoverNode = view.nodeDOM(hoverPos);

                  if (!hoverNode) return false;

                  const hoverElement =
                    hoverNode instanceof HTMLElement
                      ? hoverNode
                      : hoverNode.parentElement;

                  if (!hoverElement) return false;

                  const targetNode = hoverElement.classList.contains(
                    'tiptapTableWrapper'
                  )
                    ? hoverNode
                    : hoverElement.closest('.tiptapTableWrapper');

                  const targetPos = view.posAtDOM(targetNode as Node, 0);

                  if (!targetNode) return false;

                  const component = new ReactRenderer(TableComponent, {
                    props: {
                      editor,
                      pos: targetPos,
                    },
                    editor,
                  });

                  if (
                    myWindow.tippyLinkQuickMenu?.popper._tippy?.reference === targetNode
                  ) {
                    myWindow.tippyLinkQuickMenu?.show();
                    return false;
                  }

                  myWindow.tippyLinkQuickMenu?.destroy();

                  const instance = tippy(targetNode as HTMLElement, {
                    ...defaultTippySettings(tippyRootId),
                    content: component.element,
                  });

                  myWindow.tippyLinkQuickMenu = instance;
                }

                return false;
              },
            },
          },
          editor: this.editor,
          ...this.options.hoverMenu,
        }),
      ];
    },
  });

const MediaComponent = ({
  editor,
  pos,
  targetNode,
  type,
}: {
  editor: Editor;
  pos: number;
  targetNode?: HTMLElement;
  type: View;
}) => {
  const data = getLinkData(targetNode);

  const clearInstance = () => {
    (window as any).tippyLinkQuickMenu?.destroy?.();
    (window as any).tippyLinkQuickMenu = null;
  };

  const handleOpen = () => {
    let url = null;
    if (type === 'inline') {
      url = targetNode?.getAttribute('href');
    } else {
      url = targetNode?.getAttribute('data-href');
    }

    if (url) {
      window.open(url);
    }
  };

  const handleCopy = () => {
    let url = null;
    if (type === 'inline') {
      url = targetNode?.getAttribute('href');
    } else {
      url = targetNode?.getAttribute('data-href');
    }

    if (url) {
      copyTextToClipboard(url, {
        bodyText: 'Link copied to clipboard!',
      });
    }
  };

  const handleUnlink = () => {
    if (type === 'inline') {
      editor.chain().setNodeSelection(pos).extendMarkRange('link').unsetLink().run();
    } else {
      editor
        .chain()
        .setNodeSelection(pos)
        .deleteSelection()
        .insertContentAt(pos, paragraphTextNode(data.content))
        .run();
    }
    clearInstance();
  };

  const handleViewChange = (format: View) => {
    if (format === type) return true;

    switch (format) {
      case 'card':
        editor
          .chain()
          .setNodeSelection(pos)
          .extendMarkRange('link')
          .deleteSelection()
          .insertContentAt(pos, [
            {
              type: 'media-card',
              attrs: {
                'data-href': data.href,
                'data-target': data.target,
                'data-content': data.content,
              },
            },
            {
              type: 'paragraph',
            },
          ])
          .run();
        clearInstance();
        break;
      case 'inline':
        editor
          .chain()
          .setNodeSelection(pos)
          .deleteSelection()
          .insertContentAt(pos, {
            type: 'paragraph',
            content: [
              linkNode(data.content, { href: data.href!, target: data.target }),
            ],
          })
          .run();
        clearInstance();
        break;
    }
    return true;
  };

  const handleFormHide = () => {
    (window as any).tippyLinkQuickMenu?.hide();
  };

  if (!editor.isEditable) return null;

  return (
    <div>
      <Menu
        offset={[0, 0]}
        menuItems={[
          {
            icon: <Open />,
            onClick: handleOpen,
            tooltip: 'Open link',
          },
          {
            isSeparator: true,
          },
          {
            icon: <Copy />,
            onClick: handleCopy,
            tooltip: 'Copy link',
          },
          {
            icon: <EditPencil />,
            placement: 'top',
            removeMenuItemStyle: true,
            tooltip: 'Edit link',
            children: (
              <EditLinkForm
                hideForm={handleFormHide}
                initialTextValue={
                  (type === 'inline'
                    ? targetNode?.innerText
                    : targetNode?.getAttribute('data-content')) ?? undefined
                }
                initialValue={
                  (type === 'inline'
                    ? targetNode?.getAttribute('href')
                    : targetNode?.getAttribute('data-href')) ?? undefined
                }
                initialOpenInNewTab={
                  type === 'inline'
                    ? targetNode?.getAttribute('target') === '_blank'
                    : targetNode?.getAttribute('data-target') === '_blank'
                }
                onCreateLink={(url, openInNewTab, text) => {
                  if (type === 'inline') {
                    if (targetNode) {
                      editor
                        .chain()
                        .setNodeSelection(pos)
                        .deleteSelection()
                        .insertContentAt(pos, [
                          {
                            type: 'text',
                            text: text ?? '',
                            marks: [
                              {
                                type: 'link',
                                attrs: {
                                  href: url,
                                  target: openInNewTab ? '_blank' : 'self',
                                },
                              },
                            ],
                          },
                        ])
                        .run();
                    }
                  } else {
                    editor
                      .chain()
                      .setNodeSelection(pos)
                      .deleteSelection()
                      .insertContentAt(pos, [
                        {
                          type: 'media-card',
                          attrs: {
                            'data-href': url,
                            'data-target': openInNewTab ? '_blank' : 'self',
                            'data-content': text || url,
                          },
                        },
                      ])
                      .run();
                  }
                  handleFormHide();
                }}
                onDelete={() => {
                  if (type === 'inline') {
                    editor
                      .chain()
                      .setNodeSelection(pos)
                      .extendMarkRange('link')
                      .unsetLink()
                      .run();
                  } else {
                    editor
                      .chain()
                      .setNodeSelection(pos)
                      .deleteSelection()
                      .insertContentAt(pos, paragraphTextNode(data.content))
                      .run();
                  }
                  handleFormHide();
                }}
                showTextField
              />
            ),
          },
          {
            icon: <Unlink />,
            onClick: handleUnlink,
            tooltip: 'Remove link',
          },
          {
            isSeparator: true,
          },
          {
            icon: <Link />,
            title: 'View',
            menuItems: [
              {
                label: 'Inline view',
                icon: <Link />,
                disabled: type === 'inline',
                showCheck: type === 'inline',
                onClick: () => handleViewChange('inline'),
              },
              {
                label: 'Card view',
                icon: <LinkCard />,
                disabled: type === 'card',
                showCheck: type === 'card',
                onClick: () => handleViewChange('card'),
              },
            ],
          },
        ]}
      />
    </div>
  );
};

const CommentComponent = ({ commentId }: { commentId: string }) => {
  const { mediaGroupId } = useParams();

  if (!mediaGroupId) return null;

  return <CommentBox commentId={commentId} mediaGroupId={mediaGroupId} />;
};

const CommentBox = ({
  commentId,
  mediaGroupId,
}: {
  commentId: string;
  mediaGroupId: string;
}) => {
  const { data } = useListComments(mediaGroupId);
  if (!data?.comments) return null;
  const currentComment = data.comments.find((comment) => comment.id === commentId);

  if (currentComment?.kind !== 'alive') return null;

  return <AliveComment comment={currentComment} mediaGroupId={mediaGroupId} />;
};

const AliveComment = ({
  comment,
  mediaGroupId,
}: {
  comment: AliveCommentDTO;
  mediaGroupId: string;
}) => {
  const { component } = useCommentContent(comment, true, false);
  const navigateWithState = useNavigateWithState();
  const url = urlFor('mediaGroupComments', { mediaGroupId });
  const location = useLocation();

  const scrollSidebarToComment = () => {
    const commentInSidebar = document.querySelector(
      `.commentsSidebar [data-comment-id="${comment.id}"]`
    );
    if (commentInSidebar) {
      commentInSidebar.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  };

  const handleClick = () => {
    if (location.pathname !== url) {
      navigateWithState(url, {});
    } else {
      scrollSidebarToComment();
    }
  };

  return (
    <div className={styles.commentTextBox} onClick={handleClick}>
      <UserAvatar
        name={comment.createdBy.name}
        imageUrl={comment.createdBy.avatarUrl}
        size="xxs"
      />
      <div>
        <div className={styles.meta}>
          <span className={styles.createdInfo}>
            <span className={styles.createdByName} title={comment.createdBy.name}>
              {comment.createdBy.name}
            </span>
            <span className={styles.createdAt}>
              {dayjs(comment.createdAt).fromNow()}
            </span>
          </span>
        </div>
        <div className={styles.summary}>{component}</div>
      </div>
    </div>
  );
};

const TableComponent = ({
  editor,
  pos,
}: {
  editor: Editor;
  pos: number;
}) => {
  const getTableMenuState = (editor: Editor) => ({
    canAddColumnBefore: !editor.can().addColumnBefore(),
    canAddColumnAfter: !editor.can().addColumnAfter(),
    canRemoveColumn: !editor.can().deleteColumn(),
    canAddRowBefore: !editor.can().addRowBefore(),
    canAddRowAfter: !editor.can().addRowAfter(),
    canDeleteRow: !editor.can().deleteRow(),
    canToggleHeaderRow: !editor.can().toggleHeaderRow(),
    canToggleHeaderColumn: !editor.can().toggleHeaderColumn(),
    canToggleHeaderCell: !editor.can().toggleHeaderCell(),
  });

  const [menuState, setMenuState] = useState(() => getTableMenuState(editor));

  const handleAddColumnBefore = () => {
    editor.chain().focus().addColumnBefore().run();
    setMenuState(getTableMenuState(editor));
  };

  const handleAddColumnAfter = () => {
    editor.chain().focus().addColumnAfter().run();
    setMenuState(getTableMenuState(editor));
  };

  const handleRemoveColumn = () => {
    editor.chain().focus().deleteColumn().run();
    setMenuState(getTableMenuState(editor));
  };

  const handleAddRowBefore = () => {
    editor.chain().focus().addRowBefore().run();
    setMenuState(getTableMenuState(editor));
  };

  const handleAddRowAfter = () => {
    editor.chain().focus().addRowAfter().run();
    setMenuState(getTableMenuState(editor));
  };

  const handleRemoveRow = () => {
    editor.chain().focus().deleteRow().run();
    setMenuState(getTableMenuState(editor));
  };

  const handleToggleHeaderRow = () => {
    editor.chain().focus().toggleHeaderRow().run();
    setMenuState(getTableMenuState(editor));
  };

  const handleToggleHeaderColumn = () => {
    editor.chain().focus().toggleHeaderColumn().run();
    setMenuState(getTableMenuState(editor));
  };

  const handleToggleHeaderCell = () => {
    editor.chain().focus().toggleHeaderCell().run();
    setMenuState(getTableMenuState(editor));
  };

  const handleDeleteTable = () => {
    editor.commands.setNodeSelection(pos);
    if (editor.can().deleteTable()) {
      editor.chain().focus().deleteTable().run();
      setMenuState(getTableMenuState(editor));
    }
  };

  if (!editor.isEditable) return null;

  return (
    <div onMouseEnter={() => setMenuState(getTableMenuState(editor))}>
      <Menu
        offset={[0, 0]}
        menuItems={[
          {
            icon: <TableColumnBefore />,
            onClick: handleAddColumnBefore,
            tooltip: 'Add column before',
            disabled: menuState.canAddColumnBefore,
          },
          {
            icon: <TableColumnAfter />,
            onClick: handleAddColumnAfter,
            tooltip: 'Add column after',
            disabled: menuState.canAddColumnAfter,
          },
          {
            icon: <TableRemoveColumn />,
            onClick: handleRemoveColumn,
            tooltip: 'Delete column',
            disabled: menuState.canRemoveColumn,
          },
          {
            icon: <TableRowBefore />,
            onClick: handleAddRowBefore,
            tooltip: 'Add row before',
            disabled: menuState.canAddRowBefore,
          },
          {
            icon: <TableRowAfter />,
            onClick: handleAddRowAfter,
            tooltip: 'Add row after',
            disabled: menuState.canAddRowAfter,
          },
          {
            icon: <TableRemoveRow />,
            onClick: handleRemoveRow,
            tooltip: 'Delete row',
            disabled: menuState.canDeleteRow,
          },
          {
            icon: <TableToggleHeaderRow />,
            onClick: handleToggleHeaderRow,
            tooltip: 'Toggle header row',
            disabled: menuState.canToggleHeaderRow,
          },
          {
            icon: <TableToggleHeaderColumn />,
            onClick: handleToggleHeaderColumn,
            tooltip: 'Toggle header column',
            disabled: menuState.canToggleHeaderColumn,
          },
          {
            icon: <TableToggleHeaderCell />,
            onClick: handleToggleHeaderCell,
            tooltip: 'Toggle header cell',
            disabled: menuState.canToggleHeaderCell,
          },
          {
            isSeparator: true,
          },
          {
            icon: <TrashDelete />,
            onClick: handleDeleteTable,
            tooltip: 'Delete table',
          },
        ]}
      />
    </div>
  );
};

function getLinkData(node?: HTMLElement) {
  if (node instanceof HTMLAnchorElement) {
    return {
      href: node.href,
      target: node.target,
      content: node.innerHTML,
    };
  }
  if (node?.hasAttribute('data-media-card')) {
    return {
      href: node.getAttribute('data-href') ?? undefined,
      target: node.getAttribute('data-target') ?? undefined,
      content: node.getAttribute('data-content') ?? undefined,
    };
  }

  return {};
}
