import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1873_11176)">
        <path
          d="M8 4.17862V7.9968H11.8182"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.25114 14.9141L1.78723 11.2251L5.40941 12.2343"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.78718 11.225C2.57576 12.7442 3.8936 13.9218 5.49154 14.5351C7.08949 15.1485 8.85678 15.1551 10.4593 14.5538C12.0618 13.9524 13.3884 12.7848 14.1884 11.2716C14.9884 9.75844 15.2062 8.00461 14.8007 6.34172C14.3952 4.67882 13.3946 3.22212 11.9878 2.24702C10.5811 1.27192 8.86592 0.846011 7.16649 1.04982C5.46705 1.25362 3.90117 2.07302 2.76492 3.35308C1.62867 4.63315 1.00079 6.28516 1 7.99678"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1873_11176">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
