import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.776921 7C0.347832 7 0 7.35681 0 7.79688C0 8.23694 0.347832 8.59375 0.776921 8.59375H15.2086C15.6377 8.59375 15.9856 8.23694 15.9856 7.79688C15.9856 7.35681 15.6377 7 15.2086 7H0.776921Z"
        fill={color}
      />
    </svg>
  );
}
