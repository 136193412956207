import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 11.4994L13.0302 19.3313C10.766 21.5562 6.96226 21.5562 4.69811 19.3313C2.43396 17.1063 2.43396 13.3684 4.69811 11.1434L11.7623 4.20148C13.3925 2.59951 15.9283 2.59951 17.5585 4.20148C19.1887 5.80346 19.1887 8.29543 17.5585 9.8974L11.0377 16.2163C10.1321 17.1063 8.68302 17.1063 7.86792 16.2163C6.96226 15.3263 6.96226 13.9023 7.86792 13.1014L13.3019 7.76143"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
