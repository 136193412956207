import clsx from 'clsx';

import styles from './Sidebar.module.scss';

export default function Sidebar({
  children,
  open = false,
}: {
  children: React.ReactNode;
  open: boolean;
}) {
  return <div className={clsx(styles.sidebar, open && styles.active)}>{children}</div>;
}
