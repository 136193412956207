import { useNotesEditor } from '@/hooks/useNotesEditor';
import type { MediaGroupDetailDTO } from '@spaceduck/api';
import { useEffect, useState } from 'react';
import styles from './DocumentWrapper.module.scss';
import DetailsModalNotesContent from '@/components/detailsModal/DetailsModalNotesContent';
import BlockMenu from './components/BlockMenu';
import FormatMenu from './components/FormatMenu';
import LinkForm from './components/LinkForm';
import EmbedViewOptions from './components/EmbedViewOptions';
import MediaGroupMenu from './components/MediaGroupMenu';
import { MediaType } from '@spaceduck/utils';

type DocumentWrapperProps = {
  mediaGroup: MediaGroupDetailDTO;
};

export type MenuView =
  | 'blockMenu'
  | 'embedViewOptions'
  | 'formatMenu'
  | 'linkForm'
  | 'mediaGroupMenu';

export type ContentBlockNode = {
  attrs?: { id?: string; 'data-minimal-view'?: boolean };
  type?: { name: string };
};

export default function DocumentWrapper({ mediaGroup }: DocumentWrapperProps) {
  const [currentMenuView, setCurrentMenuView] = useState<MenuView>('blockMenu');
  const [focusedMediaGroupId, setFocusedMediaGroupId] = useState<string | null>(null);

  const { editor, handleImageUploadInputChange, imageUploadInputRef } = useNotesEditor(
    mediaGroup,
    {
      attributes: { 'data-media-group-id': mediaGroup?.id ?? '' },
    },
    undefined
  );

  useEffect(() => {
    const selection = editor?.view.state.selection;
    if (selection) {
      if ('node' in selection) {
        const node = selection.node as ContentBlockNode;
        if (
          node.attrs?.id &&
          node.type?.name &&
          /^(content-block)|(outgoing-link)/.test(node.type.name)
        ) {
          setFocusedMediaGroupId(node.attrs.id);
          return;
        }
      }
    }
    setFocusedMediaGroupId(null);
  }, [editor?.view.state.selection]);

  useEffect(() => {
    if (focusedMediaGroupId) {
      setCurrentMenuView('embedViewOptions');
      return;
    }

    setCurrentMenuView('blockMenu');
  }, [focusedMediaGroupId]);

  if (!mediaGroup?.id) return null;
  if (!editor) return null;

  return (
    <div className={styles.container}>
      <DetailsModalNotesContent editor={editor} mediaGroupId={mediaGroup.id} />
      {!!editor && (
        <div className={styles.menu}>
          {currentMenuView === 'blockMenu' && (
            <BlockMenu editor={editor} setView={setCurrentMenuView} />
          )}
          {currentMenuView === 'formatMenu' && (
            <FormatMenu editor={editor} setView={setCurrentMenuView} />
          )}
          {currentMenuView === 'linkForm' && (
            <LinkForm editor={editor} setView={setCurrentMenuView} />
          )}
          {currentMenuView === 'embedViewOptions' && (
            <EmbedViewOptions
              editor={editor}
              mediaGroupId={focusedMediaGroupId}
              setView={setCurrentMenuView}
            />
          )}
          {currentMenuView === 'mediaGroupMenu' && (
            <MediaGroupMenu editor={editor} setView={setCurrentMenuView} />
          )}
          <input
            accept={`${MediaType.PNG}, ${MediaType.GIF}, ${MediaType.JPEG}`}
            className={styles.hiddenInput}
            id="webViewImageUploadInput"
            multiple
            onChange={handleImageUploadInputChange}
            ref={imageUploadInputRef}
            type="file"
          />
        </div>
      )}
    </div>
  );
}
