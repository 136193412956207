import { useCallback, useContext, useEffect } from 'react';
import type { NodeProps } from '@xyflow/react';
import { useMediaGroupDetail } from '@api/mediaGroup';
import { EditorContent } from '@tiptap/react';

import { useNotesEditor } from '@hooks/useNotesEditor';
import { FlowCard } from '../components/FlowCard';
import { useCardUtils } from '../hooks/useCardUtils';
import { BoardStoreContext } from '../context/boardContext';
import type { DocumentNodeType } from '../types/board';
import SingleFieldEdit from '../../SingleFieldEdit';
import styles from './DocumentNode.module.scss';

export function DocumentNode(
  props: NodeProps<DocumentNodeType> & { children?: React.ReactNode }
) {
  const mediaGroupId = props.data.mediaGroupId;
  const { children, ...flowCardProps } = props;
  const {
    data: mediaGroupData,
    isError,
    isLoading,
    refetch,
  } = useMediaGroupDetail(mediaGroupId, { retry: false });

  const context = useContext(BoardStoreContext);
  const { openCardInSidebar } = useCardUtils();
  const handleDoubleClick = () => {
    openCardInSidebar({
      cardMediaGroupId: mediaGroupId,
      boardMediaGroupId: context?.mediaGroupId,
    });
  };

  const mediaGroup = mediaGroupData?.mediaGroup;

  const { editor } = useNotesEditor(
    mediaGroup,
    {
      attributes: { 'data-is-card-view': 'true' },
      editable: () => false,
    },
    true
  );

  useEffect(() => {
    if (mediaGroup?.document && editor) {
      queueMicrotask(() => editor.commands.setContent(mediaGroup.document));
    }
  }, [editor, mediaGroup?.document]);

  const handleRefetch = useCallback(() => refetch(), []);

  return (
    <FlowCard
      {...flowCardProps}
      isError={isError}
      isLoading={isLoading}
      mediaGroup={mediaGroupData?.mediaGroup}
      refetch={handleRefetch}
    >
      <div className={styles.scrollArea} onDoubleClick={handleDoubleClick}>
        <div className={styles.cardContent}>
          <SingleFieldEdit
            displayAs="h1"
            displayStyle={styles.h1}
            editable={false}
            enterToSubmit
            fallback="Untitled"
            fieldName="label"
            fieldLabel="title"
            mediaGroupId={mediaGroupId}
            onKeyDown={(ev) => {
              if (ev?.key === 'ArrowDown') {
                const { selectionStart, selectionEnd, value } = ev.currentTarget;

                if (selectionStart === selectionEnd && selectionEnd === value.length) {
                  ev.preventDefault();
                  ev.stopPropagation();
                  editor?.commands.focus();
                  return true;
                }
              }
              return false;
            }}
            required={true}
            switchFocus={() => editor?.commands.focus()}
            textareaStyle={styles.h1}
          />
          <EditorContent className={styles.editor} editor={editor} />
        </div>
      </div>
    </FlowCard>
  );
}
