import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3726_38306)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 5.12359C0 4.6768 0.362197 4.31461 0.808989 4.31461H15.191C15.6378 4.31461 16 4.6768 16 5.12359C16 5.57039 15.6378 5.93258 15.191 5.93258H0.808989C0.362197 5.93258 0 5.57039 0 5.12359Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10.1573C0 9.71052 0.362197 9.34832 0.808989 9.34832H15.191C15.6378 9.34832 16 9.71052 16 10.1573C16 10.6041 15.6378 10.9663 15.191 10.9663H0.808989C0.362197 10.9663 0 10.6041 0 10.1573Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.12356 0C5.57036 0 5.93255 0.362197 5.93255 0.808989V15.191C5.93255 15.6378 5.57036 16 5.12356 16C4.67677 16 4.31458 15.6378 4.31458 15.191V0.808989C4.31458 0.362197 4.67677 0 5.12356 0Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.24719 1.61798C2.08031 1.61798 1.92027 1.68427 1.80227 1.80227C1.68427 1.92027 1.61798 2.08031 1.61798 2.24719V13.7528C1.61798 13.9197 1.68427 14.0797 1.80227 14.1977C1.92027 14.3157 2.08031 14.382 2.24719 14.382H13.7528C13.9197 14.382 14.0797 14.3157 14.1977 14.1977C14.3157 14.0797 14.382 13.9197 14.382 13.7528V2.24719C14.382 2.08031 14.3157 1.92027 14.1977 1.80227C14.0797 1.68427 13.9197 1.61798 13.7528 1.61798H2.24719ZM0.658187 0.658187C1.07962 0.236757 1.6512 0 2.24719 0H13.7528C14.3488 0 14.9204 0.236757 15.3418 0.658187C15.7632 1.07962 16 1.6512 16 2.24719V13.7528C16 14.3488 15.7632 14.9204 15.3418 15.3418C14.9204 15.7632 14.3488 16 13.7528 16H2.24719C1.6512 16 1.07962 15.7632 0.658187 15.3418C0.236757 14.9204 0 14.3488 0 13.7528V2.24719C0 1.6512 0.236757 1.07962 0.658187 0.658187Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3726_38306">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
