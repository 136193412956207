import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 15.5L8 11.5L13.5 15.5V2C13.5 1.60218 13.342 1.22064 13.0607 0.93934C12.7794 0.658035 12.3978 0.5 12 0.5H4C3.60218 0.5 3.22064 0.658035 2.93934 0.93934C2.65804 1.22064 2.5 1.60218 2.5 2V15.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
