import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 64 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 64 64"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.749 28.999L56.9615 30.2635C58.305 31.0313 58.3049 32.9686 56.9614 33.7364L32.9923 47.433C32.3774 47.7844 31.6226 47.7844 31.0077 47.433L7.03885 33.7365C5.69522 32.9687 5.69522 31.0313 7.03885 30.2635L9.25 29"
        stroke="#7A7E9F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.745 41.9971L56.9613 43.2636C58.3049 44.0314 58.3048 45.9687 56.9612 46.7365L32.9923 60.4331C32.3774 60.7844 31.6226 60.7844 31.0077 60.4331L7.03885 46.7366C5.69522 45.9688 5.69522 44.0314 7.03885 43.2636L9.25 42.0001"
        stroke="#4B4E68"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.03885 20.7365C5.69522 19.9687 5.69522 18.0313 7.03885 17.2635L31.0077 3.56701C31.6226 3.21566 32.3774 3.21566 32.9923 3.56702L56.9612 17.2635C58.3048 18.0313 58.3048 19.9687 56.9611 20.7365L32.9923 34.433C32.3774 34.7843 31.6226 34.7843 31.0077 34.433L7.03885 20.7365Z"
        stroke="#B5B7CA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
