/**
 * Wrapper module for react-pdf. You must import from this file instead of
 * `react-pdf` directly in order to have the css and service worker configured
 * correctly.
 */
import { useCallback, useMemo, useState, type ComponentProps } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { useInView } from 'react-intersection-observer';

import styles from './PdfDocument.module.scss';
import { css } from '@/lib/css';

const pdfWorkerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url);
pdfjs.GlobalWorkerOptions.workerSrc = pdfWorkerSrc.toString();

export const PdfDocument = Document;

const pickSize = (known: number | null, guess: number | undefined) => {
  const value = known ?? guess ?? null;
  return value === null ? 'auto' : `${value}px`;
};

export const PdfPage = ({
  placeholderWidth,
  placeholderHeight,
  rootMargin = '200%',
  onLoadSuccess,
  containerClassName,
  ...props
}: ComponentProps<typeof Page> & {
  placeholderWidth?: number;
  placeholderHeight?: number;
  rootMargin?: string;
  containerClassName?: string;
}) => {
  const { ref, inView } = useInView({ rootMargin });
  const [height, setHeight] = useState<number | null>(null);
  const [width, setWidth] = useState<number | null>(null);
  const handleLoadSuccess = useCallback<NonNullable<typeof onLoadSuccess>>(
    (page) => {
      setWidth(page.width);
      setHeight(page.height);
      onLoadSuccess?.(page);
    },
    [onLoadSuccess]
  );
  const containerStyle = useMemo(() => {
    return css({
      width: pickSize(width, placeholderWidth),
      height: pickSize(height, placeholderHeight),
    });
  }, [width, height, placeholderWidth, placeholderHeight]);

  return (
    <div ref={ref} className={containerClassName} style={containerStyle}>
      {inView ? (
        <Page onLoadSuccess={handleLoadSuccess} {...props} />
      ) : (
        <div className={styles.pagePlaceholder}>loading...</div>
      )}
    </div>
  );
};
