import IFrameCheck from '@integrations/shared/IFrameCheck';
import App from './App';
import { AuthProvider } from './modules/auth/AuthContext';

export default function FigmaEntryPoint() {
  return (
    <IFrameCheck message="Download the Spaceduck figma plugin to use this page.">
      <AuthProvider>
        <App />
      </AuthProvider>
    </IFrameCheck>
  );
}
