import type { IconProps } from '../types/IconProps';

export default function Icon({ className, color = '#5957C2', size = 32 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6667 2.35352H5.33333C3.49238 2.35352 2 3.8459 2 5.68685V27.0202C2 28.8611 3.49238 30.3535 5.33333 30.3535H26.6667C28.5076 30.3535 30 28.8611 30 27.0202V5.68685C30 3.8459 28.5076 2.35352 26.6667 2.35352Z"
        fill={color}
      />
      <path
        d="M21.3333 1.6875H10.6667C10.4899 1.6875 10.3203 1.75774 10.1953 1.88276C10.0702 2.00779 10 2.17736 10 2.35417V15.6875C10 15.8113 10.0345 15.9327 10.0996 16.038C10.1647 16.1433 10.2578 16.2284 10.3685 16.2838C10.4793 16.3392 10.6032 16.3626 10.7265 16.3515C10.8498 16.3404 10.9676 16.2951 11.0667 16.2208L16 12.5208L20.9333 16.2208C21.0324 16.2951 21.1502 16.3404 21.2735 16.3515C21.3968 16.3626 21.5207 16.3392 21.6315 16.2838C21.7422 16.2284 21.8353 16.1433 21.9004 16.038C21.9655 15.9327 22 15.8113 22 15.6875V2.35417C22 2.17736 21.9298 2.00779 21.8047 1.88276C21.6797 1.75774 21.5101 1.6875 21.3333 1.6875Z"
        fill="#FFB202"
      />
    </svg>
  );
}
