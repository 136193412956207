import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 8.5L10.2071 5.79289C10.3946 5.60536 10.5 5.351 10.5 5.08579V3.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.06299 10.063L4.2071 11.2071C4.39463 11.3946 4.49999 11.649 4.49999 11.9142V15.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.936 10.061L10.7907 11.2071C10.6033 11.3947 10.498 11.6489 10.498 11.914V15.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 15.5V8.91421C7.5 8.649 7.39464 8.39464 7.20711 8.20711L4.79289 5.79289C4.60536 5.60536 4.5 5.351 4.5 5.08579V3.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 10.504C2.82843 10.504 3.5 9.83243 3.5 9.004C3.5 8.17557 2.82843 7.504 2 7.504C1.17157 7.504 0.5 8.17557 0.5 9.004C0.5 9.83243 1.17157 10.504 2 10.504Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.996 10.5C13.8244 10.5 14.496 9.82843 14.496 9C14.496 8.17157 13.8244 7.5 12.996 7.5C12.1675 7.5 11.496 8.17157 11.496 9C11.496 9.82843 12.1675 10.5 12.996 10.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 3.5C5.32843 3.5 6 2.82843 6 2C6 1.17157 5.32843 0.5 4.5 0.5C3.67157 0.5 3 1.17157 3 2C3 2.82843 3.67157 3.5 4.5 3.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.527 3.5C11.3554 3.5 12.027 2.82843 12.027 2C12.027 1.17157 11.3554 0.5 10.527 0.5C9.69855 0.5 9.02698 1.17157 9.02698 2C9.02698 2.82843 9.69855 3.5 10.527 3.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
