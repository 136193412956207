import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.24302 18.953L10.152 2.11101C10.33 1.77467 10.5963 1.49321 10.9222 1.2969C11.2482 1.10059 11.6215 0.996857 12.002 0.996857C12.3825 0.996857 12.7559 1.10059 13.0818 1.2969C13.4078 1.49321 13.6741 1.77467 13.852 2.11101L22.761 18.953C22.9282 19.2708 23.0103 19.6266 22.9991 19.9855C22.988 20.3445 22.8841 20.6944 22.6975 21.0013C22.5109 21.3081 22.2479 21.5614 21.9343 21.7364C21.6207 21.9115 21.2672 22.0023 20.908 22H3.09202C2.73323 22.0016 2.38014 21.9103 2.06709 21.735C1.75404 21.5597 1.49169 21.3063 1.30555 20.9996C1.11942 20.6928 1.01583 20.3431 1.00488 19.9845C0.993918 19.6259 1.07596 19.2705 1.24302 18.953Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8V14"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 19C12.8284 19 13.5 18.3284 13.5 17.5C13.5 16.6716 12.8284 16 12 16C11.1716 16 10.5 16.6716 10.5 17.5C10.5 18.3284 11.1716 19 12 19Z"
        fill={color}
      />
    </svg>
  );
}
