import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 24 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.5 1.5H1.5V11.5H11.5V1.5Z" fill="#E86C60" />
      <path d="M22.5 1.5H12.5V11.5H22.5V1.5Z" fill="#72C472" />
      <path d="M11.5 12.5H1.5V22.5H11.5V12.5Z" fill="#43A6DD" />
      <path d="M22.5 12.5H12.5V22.5H22.5V12.5Z" fill="#EFD358" />
    </svg>
  );
}
