import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.07692 5C1.37757 5 0 6.34315 0 8C0 9.65686 1.37757 11 3.07692 11C4.56546 11 5.80716 9.96934 6.09231 8.6H15.3846C15.7245 8.6 16 8.33139 16 8C16 7.66863 15.7245 7.4 15.3846 7.4H6.09231C5.80716 6.03066 4.56546 5 3.07692 5Z"
        fill={color}
      />
    </svg>
  );
}
