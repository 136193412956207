export const asSafeUrlRaw = (url: string | null): URL => {
  const DEFAULT_URL = '/';
  const parsed = new URL(url ?? DEFAULT_URL, window.location.origin);
  parsed.protocol = window.location.protocol;
  parsed.hostname = window.location.hostname;
  parsed.port = window.location.port;
  parsed.username = '';
  parsed.password = '';

  return parsed;
};

export const asSafeUrl = (url: string | null): string => {
  return asSafeUrlRaw(url).toString();
};
