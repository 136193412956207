import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2882_6483)">
        <path
          d="M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.00003 14.345C2.92792 13.6693 2.04451 12.733 1.43226 11.6234C0.820014 10.5139 0.498901 9.26727 0.498901 8C0.498901 6.73273 0.820014 5.48613 1.43226 4.37657C2.04451 3.26701 2.92792 2.33068 4.00003 1.655"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 1.655C13.0721 2.33068 13.9555 3.26701 14.5678 4.37657C15.18 5.48613 15.5011 6.73273 15.5011 8C15.5011 9.26727 15.18 10.5139 14.5678 11.6234C13.9555 12.733 13.0721 13.6693 12 14.345"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2882_6483">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
