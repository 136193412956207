import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9231 5C14.6224 5 16 6.34315 16 8C16 9.65686 14.6224 11 12.9231 11C11.4345 11 10.1928 9.96934 9.90769 8.6H0.615385C0.275515 8.6 0 8.33139 0 8C0 7.66863 0.275515 7.4 0.615385 7.4H9.90769C10.1928 6.03066 11.4345 5 12.9231 5Z"
        fill={color}
      />
    </svg>
  );
}
