import { Icon48 } from '@spaceduck/icons';
import { clsx } from 'clsx';
import { createContext, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useParams } from 'react-router-dom';

import { useFileUploadWrapper } from '@hooks/useFileUploadWrapper';
import useWorkspaceId from '@hooks/useWorkspaceId';
import styles from './FileUploadWrapper.module.scss';

const { CloudUpload } = Icon48;

export type FileInputContextProps = {
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
};

export const FileInputContext = createContext<FileInputContextProps>({
  inputRef: { current: null },
});

export default function FileUploadWrapper({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  const projectId = useParams<{ projectId: string }>().projectId!;
  const workspaceId = useWorkspaceId();
  const { dragActive, setDragActive, handleChange, handleDrag, handleDrop } =
    useFileUploadWrapper({ workspaceId, projectId });
  const inputRef = useRef<HTMLInputElement | null>(null);

  useHotkeys(
    'Escape',
    () => {
      setDragActive(false);
    },
    {
      enabled: dragActive,
    },
    [dragActive]
  );

  return (
    <>
      <div
        className={clsx(styles.overlay, dragActive && styles.highlight, className)}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
      >
        <div className={styles.slot}>
          <FileInputContext.Provider value={{ inputRef }}>
            {children}
          </FileInputContext.Provider>
          {!dragActive && (
            <div className={styles.hiddenInput}>
              <input
                type="file"
                multiple={true}
                onChange={handleChange}
                ref={inputRef}
              />
            </div>
          )}
        </div>
        <div className={styles.dragArea}>
          {dragActive && (
            <label onDrop={handleDrop}>
              <input type="file" multiple={true} onChange={handleChange} />
            </label>
          )}
        </div>
      </div>
      <div className={styles.stickyWrapper}>
        <div className={clsx('body6', styles.instructions)}>
          <CloudUpload />
          <div className={styles.text}>
            Drop files to upload them to your {projectId ? 'space' : 'repository'}
          </div>
        </div>
      </div>
    </>
  );
}
