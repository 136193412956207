import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.7 7.3L2.7 2.3C2.3 1.9 1.7 1.9 1.3 2.3C0.9 2.7 0.9 3.3 1.3 3.7L5.6 8L1.3 12.3C0.9 12.7 0.9 13.3 1.3 13.7C1.5 13.9 1.7 14 2 14C2.3 14 2.5 13.9 2.7 13.7L7.7 8.7C8.1 8.3 8.1 7.7 7.7 7.3Z"
        fill={color}
      />
      <path d="M15 12H8V14H15V12Z" fill={color} />
    </svg>
  );
}
