import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 14V20C19 21.105 18.105 22 17 22H4C2.895 22 2 21.105 2 20V7C2 5.895 2.895 5 4 5H10"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 9C21.2091 9 23 7.20914 23 5C23 2.79086 21.2091 1 19 1C16.7909 1 15 2.79086 15 5C15 7.20914 16.7909 9 19 9Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
