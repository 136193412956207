import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 3C6.447 3 6 3.448 6 4V7H3V4C3 3.448 2.553 3 2 3C1.447 3 1 3.448 1 4V12C1 12.552 1.447 13 2 13C2.553 13 3 12.552 3 12V9H6V12C6 12.552 6.447 13 7 13C7.553 13 8 12.552 8 12V4C8 3.448 7.553 3 7 3Z"
        fill={color}
      />
      <path
        d="M14.481 3.12409C14.162 2.94809 13.771 2.96009 13.463 3.15509L10.969 4.73509C10.502 5.03009 10.364 5.64909 10.659 6.11509C10.954 6.58109 11.573 6.72009 12.039 6.42509L12.998 5.81809V12.0011C12.998 12.5531 13.445 13.0011 13.998 13.0011C14.551 13.0011 14.998 12.5531 14.998 12.0011V4.00009C14.998 3.63509 14.8 3.30009 14.48 3.12409H14.481Z"
        fill={color}
      />
    </svg>
  );
}
