export const FALLBACK_VIDEO_WIDTH = 1200;
export const FALLBACK_VIDEO_HEIGHT = 720;
export const PORTRAIT_MAX_WIDTH = 390;
export const LANDSCAPE_MAX_WIDTH = 1440;

export type orientation = 'landscape' | 'portrait';

export const getOrientation = (width: number, height: number): orientation => {
  return width >= height ? 'landscape' : 'portrait';
};
