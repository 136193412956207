import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2281_5899)">
        <path
          d="M9 1.5C10.1073 1.49969 11.1889 1.83362 12.1033 2.45809C13.0177 3.08257 13.7223 3.96851 14.125 5"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 14.5C5.8927 14.5003 4.81109 14.1664 3.89669 13.5419C2.98228 12.9174 2.27765 12.0315 1.875 11"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 0.5H1.5C0.947715 0.5 0.5 0.947715 0.5 1.5V7.5C0.5 8.05228 0.947715 8.5 1.5 8.5H5.5C6.05228 8.5 6.5 8.05228 6.5 7.5V1.5C6.5 0.947715 6.05228 0.5 5.5 0.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 7.5H10.5C9.94772 7.5 9.5 7.94772 9.5 8.5V14.5C9.5 15.0523 9.94772 15.5 10.5 15.5H14.5C15.0523 15.5 15.5 15.0523 15.5 14.5V8.5C15.5 7.94772 15.0523 7.5 14.5 7.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2281_5899">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
