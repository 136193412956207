import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3772_7688)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.41474 9.58526C6.73067 9.90119 6.73067 10.4134 6.41474 10.7293L1.38103 15.7631C1.0651 16.079 0.552877 16.079 0.236947 15.7631C-0.0789824 15.4471 -0.0789824 14.9349 0.236947 14.619L5.27065 9.58526C5.58658 9.26933 6.09881 9.26933 6.41474 9.58526Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.763 0.236947C16.0789 0.552877 16.0789 1.0651 15.763 1.38103L10.7293 6.41474C10.4134 6.73067 9.90114 6.73067 9.58521 6.41474C9.26928 6.09881 9.26928 5.58658 9.58521 5.27065L14.6189 0.236947C14.9349 -0.0789824 15.4471 -0.0789824 15.763 0.236947Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.58521 9.58526C9.90114 9.26933 10.4134 9.26933 10.7293 9.58526L15.763 14.619C16.0789 14.9349 16.0789 15.4471 15.763 15.7631C15.4471 16.079 14.9349 16.079 14.6189 15.7631L9.58521 10.7293C9.26928 10.4134 9.26928 9.90119 9.58521 9.58526Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.236947 0.236947C0.552877 -0.0789824 1.0651 -0.0789824 1.38103 0.236947L6.41474 5.27065C6.73067 5.58658 6.73067 6.09881 6.41474 6.41474C6.09881 6.73067 5.58658 6.73067 5.27065 6.41474L0.236947 1.38103C-0.0789824 1.0651 -0.0789824 0.552877 0.236947 0.236947Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0675 0.808989C10.0675 0.362197 10.4297 0 10.8765 0H15.1911C15.6379 0 16.0001 0.362197 16.0001 0.808989V5.12359C16.0001 5.57039 15.6379 5.93258 15.1911 5.93258C14.7443 5.93258 14.3821 5.57039 14.3821 5.12359V1.61798H10.8765C10.4297 1.61798 10.0675 1.25578 10.0675 0.808989Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.808989 10.0674C1.25578 10.0674 1.61798 10.4296 1.61798 10.8764V14.382H5.12359C5.57039 14.382 5.93258 14.7442 5.93258 15.191C5.93258 15.6378 5.57039 16 5.12359 16H0.808989C0.362197 16 0 15.6378 0 15.191V10.8764C0 10.4296 0.362197 10.0674 0.808989 10.0674Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.808989C0 0.362197 0.362197 0 0.808989 0H5.12359C5.57039 0 5.93258 0.362197 5.93258 0.808989C5.93258 1.25578 5.57039 1.61798 5.12359 1.61798H1.61798V5.12359C1.61798 5.57039 1.25578 5.93258 0.808989 5.93258C0.362197 5.93258 0 5.57039 0 5.12359V0.808989Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1911 10.0674C15.6379 10.0674 16.0001 10.4296 16.0001 10.8764V15.191C16.0001 15.6378 15.6379 16 15.1911 16H10.8765C10.4297 16 10.0675 15.6378 10.0675 15.191C10.0675 14.7442 10.4297 14.382 10.8765 14.382H14.3821V10.8764C14.3821 10.4296 14.7443 10.0674 15.1911 10.0674Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3772_7688">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
