import { type Viewport, useReactFlow } from '@xyflow/react';
import { now } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

const MAX_DURATION = 90 * 24 * 60 * 60 * 1000;

export const useLocalStoragePersist = (mediaGroupId: string) => {
  const localStorageKey = `board-${mediaGroupId}`;
  const reactFlow = useReactFlow();
  const [viewportData, setViewportData] = useState<Viewport | null>(() => {
    const preExistingData = localStorage.getItem(localStorageKey);
    if (preExistingData) {
      const { lastUpdated, ...data } = JSON.parse(preExistingData);
      return data;
    }

    return null;
  });

  // Remove localstorage entry after MAX_DURATION
  useEffect(() => {
    for (const key in window.localStorage) {
      if (/^board-/.test(key)) {
        const entry = window.localStorage.getItem(key);
        if (entry) {
          const data = JSON.parse(entry);
          if (data.lastUpdated) {
            if (data.lastUpdated - MAX_DURATION < 0) {
              window.localStorage.removeItem(key);
            }
          }
        }
      }
    }
  }, []);

  const viewportWatcher = useCallback(
    (updatedViewportData: Viewport) => {
      const { x, y, zoom } = updatedViewportData;
      if (!viewportData) {
        setViewportData(updatedViewportData);
      } else if (
        x !== viewportData.x ||
        y !== viewportData.y ||
        zoom !== viewportData.zoom
      ) {
        setViewportData(updatedViewportData);
      }
    },
    [viewportData]
  );

  useEffect(() => {
    const preExistingData = localStorage.getItem(localStorageKey);
    if (preExistingData) {
      const data = JSON.parse(preExistingData);
      reactFlow.setViewport(data);
    }
  }, [localStorageKey]);

  useEffect(() => {
    if (!viewportData) {
      if (localStorage.getItem(localStorageKey)) {
        localStorage.removeItem(localStorageKey);
      }
      return;
    }

    localStorage.setItem(
      localStorageKey,
      JSON.stringify({ ...viewportData, lastUpdated: now() })
    );
  }, [viewportData]);

  return {
    localStorageKey,
    viewportWatcher,
  };
};
