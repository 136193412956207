import { NodeResizer, type NodeResizerProps } from '@xyflow/react';

import { useHistory } from '../hooks/useHistory';

export default function Resizer(props: NodeResizerProps) {
  const { pause, resume } = useHistory();

  return (
    <NodeResizer
      {...props}
      onResizeStart={() => pause()}
      onResizeEnd={() => resume()}
    />
  );
}
