import { useEffect, useRef, useState } from 'react';

export const useTypewriter = ({
  isComplete,
  text,
  timeout = text?.length ? text.length / 3000 : 15,
}: { isComplete?: boolean; text?: string; timeout?: number }) => {
  const [textToType, setTextToType] = useState<null | string>(null);
  const _textToType = useRef<null | string>(null);
  const [typedText, setTypedText] = useState('');
  const _typedText = useRef('');
  const [finishedTyping, setFinishedTyping] = useState(true);

  useEffect(() => {
    if (!text || text === _textToType.current) return;

    if (!textToType || text.includes(textToType)) {
      _textToType.current = text;
      setTextToType(text);
      return;
    }

    // Text is different to what has been displayed
    setTextToType(null);
    const intervalId = setTimeout(() => {
      setTextToType(text);
    }, 0);

    return () => clearInterval(intervalId);
  }, [text]);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval> | undefined = undefined;
    const clear = () => clearInterval(timer);

    if (!textToType?.length || isComplete) {
      return clear;
    }

    setFinishedTyping(false);
    timer = setInterval(() => {
      setTypedText((typedText) => textToType.substring(0, typedText.length + 1));
      if (
        !_textToType.current?.length ||
        _typedText.current.length >= _textToType.current?.length
      ) {
        clear();
      }
    }, timeout);

    if (_typedText.current.length >= textToType.length) {
      setFinishedTyping(true);
    }

    return clear;
  }, [isComplete, textToType, timeout]);

  useEffect(() => {
    _typedText.current = typedText;

    if (typedText === textToType) {
      setFinishedTyping(true);
    }
  }, [typedText, textToType]);

  useEffect(() => {
    if (isComplete && textToType) {
      setTypedText(textToType);
    }
  }, [isComplete, textToType]);

  return {
    finishedTyping,
    typedText,
  };
};
