import createToast from '@/utils/createToast';
import {
  ApiError,
  type CapabilityReason,
  type CapabilitySchema,
  type MinimalApiErrorPayload,
} from '@spaceduck/api';

export { safeUrl } from '@spaceduck/api';

export const toastApiError = <T extends MinimalApiErrorPayload>(error: ApiError<T>) => {
  const { title, body } = error.payload;
  createToast({ titleText: title, bodyText: body, iconVariant: 'warning' });
};

export const catchApiErrorIntoToast = <T, P extends unknown[]>(
  fn: (...args: P) => Promise<T>
) => {
  return async (...args: P): Promise<T> => {
    let result: T;
    try {
      result = await fn(...args);
    } catch (error) {
      if (error instanceof ApiError) {
        toastApiError(error);
      }
      throw error;
    }
    return result;
  };
};

export type IsCapable =
  | { capable: true; limitLeft?: number }
  | { capable: false; reason: CapabilityReason | null };

export const isCapable = <T>(
  wantedCapability: T,
  capabilities?: CapabilitySchema<T>[] | null
): IsCapable => {
  if (!capabilities) {
    return {
      capable: false,
      reason: null,
    };
  }

  const capable = capabilities?.find(
    (capability) => capability.capability === wantedCapability
  );

  if (capable?.capable) {
    return {
      capable: true,
      limitLeft: capable.limitLeft ?? undefined,
    };
  }

  return {
    capable: false,
    reason: capable?.reason ?? 'role',
  };
};
