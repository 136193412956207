import type { IconProps } from '../types/IconProps';

type EmptyIconProps = IconProps & {
  fill?: string;
};

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
  fill = 'none',
}: EmptyIconProps) {
  return (
    <svg
      className={className}
      fill={fill}
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}
