import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 32 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.3334 31.3333H4.66671C3.93004 31.3333 3.33337 30.7366 3.33337 30V1.99996C3.33337 1.26329 3.93004 0.666626 4.66671 0.666626H20.6667L28.6667 8.66663V30C28.6667 30.7366 28.07 31.3333 27.3334 31.3333Z"
        fill="#8680D8"
      />
      <path
        d="M20.6667 0.666626V7.33329C20.6667 8.06996 21.2634 8.66663 22.0001 8.66663H28.6667L20.6667 0.666626Z"
        fill="#B5B1E7"
      />
      <path
        d="M12 22.6666C11.892 22.6666 11.784 22.6406 11.6854 22.5879C11.4687 22.4719 11.3334 22.2459 11.3334 21.9999V11.3333C11.3334 11.0873 11.4687 10.8613 11.6854 10.7453C11.9007 10.6286 12.164 10.6413 12.3694 10.7786L20.3694 16.1119C20.5554 16.2359 20.6667 16.4439 20.6667 16.6666C20.6667 16.8893 20.5554 17.0973 20.37 17.2213L12.37 22.5546C12.258 22.6293 12.1294 22.6666 12 22.6666Z"
        fill="white"
      />
    </svg>
  );
}
