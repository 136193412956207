import type { ConnectionLineType, Edge, Node } from '@xyflow/react';

import type { ConnectionLineColor, NodeBorderColor } from './colors';

export type BaseNodeData = {
  mediaGroupId: string;
  color?: NodeBorderColor;
  copyOrCutNode?: (id: string, action: 'copy' | 'cut') => Promise<void> | void;
  deleteNode?: (id: string) => Promise<void> | void;
  duplicateNode?: (id: string) => Promise<void> | void;
  expanded: boolean;
  updateNode?: (id: string, node: Partial<Node>) => Promise<void> | void;
  height?: number | null;
  width?: number | null;
};

export type GroupNodeData = {
  label?: string | null;
  color?: NodeBorderColor;
  expanded?: boolean;
};

export type FileNodeData = {
  mediaGroupId: string;
};

export type FloatingTextNodeData = {
  content?: string | null;
  isInEditMode?: boolean;
  height?: number | null;
  width?: number | null;
};

export type MenuPlaceholderData = {
  id?: string;
};

export type TempFileUploadData = {
  id: string;
};

export type BaseNodeType = Node<BaseNodeData>;
export type ArticleNodeType = Node<BaseNodeData, 'articleNode'>;
export type AudioNodeType = Node<BaseNodeData, 'audioNode'>;
export type DocumentNodeType = Node<BaseNodeData, 'documentNode'>;
export type FileNodeType = Node<FileNodeData, 'fileNode'>;
export type FloatingTextNodeType = Node<FloatingTextNodeData, 'floatingTextNode'>;
export type GroupNodeType = Node<GroupNodeData, 'groupNode'>;
export type HighlightNodeType = Node<BaseNodeData, 'highlightNode'>;
export type MenuPlaceholder = Node<MenuPlaceholderData, 'menuPlaceholder'>;
export type ImageNodeType = Node<BaseNodeData, 'imageNode'>;
export type PdfNodeType = Node<BaseNodeData, 'pdfNode'>;
export type TempFileUploadType = Node<TempFileUploadData, 'tempFileUploadNode'>;
export type UnknownNodeType = Node<BaseNodeData, 'unknownNode'>;
export type VideoNodeType = Node<BaseNodeData, 'videoNode'>;

export type EdgeWidth = 'small' | 'medium' | 'large';

export const edgeWidthAsNumber: Record<EdgeWidth, number> = {
  small: 3,
  medium: 4,
  large: 5,
};

export type EdgeStyle = 'solid' | 'dashed';

export type BaseEdgeData = {
  type?: `${ConnectionLineType}`;
  color?: ConnectionLineColor;
  style?: EdgeStyle;
  width?: EdgeWidth;
};

export type BaseEdge = Edge<BaseEdgeData>;
