import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3841_2815)">
        <path
          opacity="0.4"
          d="M13.7528 0.80896H2.24716C1.45287 0.80896 0.80896 1.45287 0.80896 2.24716V13.7528C0.80896 14.5471 1.45287 15.191 2.24716 15.191H13.7528C14.5471 15.191 15.191 14.5471 15.191 13.7528V2.24716C15.191 1.45287 14.5471 0.80896 13.7528 0.80896Z"
          fill="#B5B7CA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.24719 1.61798C1.89969 1.61798 1.61798 1.89969 1.61798 2.24719V13.7528C1.61798 14.1003 1.89969 14.382 2.24719 14.382H13.7528C14.1003 14.382 14.382 14.1003 14.382 13.7528V2.24719C14.382 1.89969 14.1003 1.61798 13.7528 1.61798H2.24719ZM0 2.24719C0 1.0061 1.0061 0 2.24719 0H13.7528C14.9939 0 16 1.0061 16 2.24719V13.7528C16 14.9939 14.9939 16 13.7528 16H2.24719C1.0061 16 0 14.9939 0 13.7528V2.24719Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.05336 3.4275C7.16411 3.09821 7.47273 2.8764 7.82014 2.8764H8.17969C8.5271 2.8764 8.83573 3.09821 8.94648 3.4275L11.8488 12.0567C11.9912 12.4802 11.7634 12.939 11.3399 13.0814C10.9164 13.2238 10.4576 12.996 10.3152 12.5725L7.99992 5.6886L5.68463 12.5725C5.5422 12.996 5.08344 13.2238 4.65996 13.0814C4.23648 12.939 4.00864 12.4802 4.15107 12.0567L7.05336 3.4275Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.07684 9.4382C5.07684 8.99141 5.43904 8.62921 5.88583 8.62921H10.1141C10.5609 8.62921 10.9231 8.99141 10.9231 9.4382C10.9231 9.88499 10.5609 10.2472 10.1141 10.2472H5.88583C5.43904 10.2472 5.07684 9.88499 5.07684 9.4382Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3841_2815">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
