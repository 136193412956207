import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 12.8889V18.2222C2 18.6937 2.19156 19.1459 2.53253 19.4793C2.87351 19.8127 3.33597 20 3.81818 20H20.1818C20.664 20 21.1265 19.8127 21.4675 19.4793C21.8084 19.1459 22 18.6937 22 18.2222V12.8889"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 8.44444V5.77778C22 5.30628 21.8084 4.8541 21.4675 4.5207C21.1265 4.1873 20.664 4 20.1818 4H3.81818C3.33597 4 2.87351 4.1873 2.53253 4.5207C2.19156 4.8541 2 5.30628 2 5.77778V8.44444L12 13.7778L22 8.44444Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
