import { useCallback, useEffect, useMemo, useState } from 'react';
import { z } from 'zod';

import Button from '@/components/ui/Button';
import { safeParse } from '@/utils/json';
import { updateConsent } from './google';

import style from './AnalyticsConsentPopover.module.scss';
import { useMountEffect } from '@/hooks/useMountEffect';
import { claritySetConsent, useHasClarityCookies } from './clarity';

const grantSchema = z.union([z.literal('denied'), z.literal('granted')]);
const optionsSchema = z.object({
  ad_storage: grantSchema,
  ad_user_data: grantSchema,
  ad_personalization: grantSchema,
  analytics_storage: grantSchema,
  personalization_storage: grantSchema,
  functionality_storage: grantSchema,
  security_storage: grantSchema,
});
type Options = z.infer<typeof optionsSchema>;

const cookiePreConsentSchema = z.enum(['all', 'essential']);
type CookiePreConsentValue = z.infer<typeof cookiePreConsentSchema>;

const parseCookiePreConsent = (location: Location) => {
  const url = new URL(location.toString());
  const raw = url.searchParams.get('_cookiePreConsent');
  const parsed = cookiePreConsentSchema.safeParse(raw);
  return parsed.success ? parsed.data : null;
};

const usePreConsentValue = () => {
  const [value, setValue] = useState<CookiePreConsentValue | null>(() =>
    parseCookiePreConsent(window.location)
  );

  useEffect(() => {
    const handle = (event: PopStateEvent) => {
      setValue(parseCookiePreConsent(event.state.location));
    };
    window.addEventListener('popstate', handle);
    return () => window.removeEventListener('popstate', handle);
  }, []);

  return value;
};

const useLocalStorageItem = (key: string) => {
  const [_, setGeneration] = useState(0);

  useEffect(() => {
    const handler = (event: StorageEvent) => {
      if (event.key !== key) {
        return;
      }
      setGeneration((current) => current + 1);
    };
    window.addEventListener('storage', handler);
    return window.removeEventListener('storage', handler);
  }, [key]);

  const set = useCallback(
    (value: string | null) => {
      if (value === null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, value);
      }
      // events do not fire when changed within the same tab
      setGeneration((current) => current + 1);
    },
    [key]
  );

  const value = localStorage.getItem(key);
  return [value, set] as const;
};

const parseFromString = (value: string | null) => {
  if (value === null) {
    return null;
  }

  const loaded = safeParse(value);
  if (!loaded.success) {
    return null;
  }

  const result = optionsSchema.partial().safeParse(loaded.data);
  if (!result.success) {
    return null;
  }
  return result.data;
};

const useConsentMode = () => {
  const [value, setValue] = useLocalStorageItem('consentMode');

  const parsed = useMemo(
    (): Partial<Options> | null => parseFromString(value),
    [value]
  );

  const set = useCallback((newValue: Options | null) => {
    setValue(newValue === null ? null : JSON.stringify(newValue));
    if (newValue !== null) {
      updateConsent(newValue);
    }
  }, []);

  return [parsed, set] as const;
};

const hasAllKeys = (options: Partial<Options> | null): boolean => {
  if (options === null) {
    return false;
  }

  return (
    'ad_storage' in options &&
    'ad_user_data' in options &&
    'ad_personalization' in options &&
    'analytics_storage' in options &&
    'personalization_storage' in options &&
    'functionality_storage' in options &&
    'security_storage' in options
  );
};

export const AnalyticsConsentPopover = () => {
  const [consentMode, setConsentMode] = useConsentMode();
  const preConsent = usePreConsentValue();
  const hasClarityCookies = useHasClarityCookies();

  const handleGrantAll = () => {
    setConsentMode({
      ad_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      analytics_storage: 'granted',
      personalization_storage: 'granted',
      functionality_storage: 'granted',
      security_storage: 'granted',
    });
    void claritySetConsent(true);
  };

  const handleGrantEssentialOnly = () => {
    setConsentMode({
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
      personalization_storage: 'denied',
      functionality_storage: 'denied',
      security_storage: 'denied',
    });
    void claritySetConsent(false);
  };

  useMountEffect(() => {
    if (preConsent === 'all') {
      return handleGrantAll();
    }
    if (preConsent === 'essential') {
      return handleGrantEssentialOnly();
    }
    if (hasAllKeys(consentMode) && !hasClarityCookies) {
      void claritySetConsent(true);
    }
  });

  if (hasAllKeys(consentMode)) {
    // Don't need to prompt again
    return null;
  }

  return (
    <div className={style.analyticsConsentPopover}>
      <h3 className={style.heading}>
        Accept
        {/* spell-checker: disable-next-line */}
        <span className={style.strike}>Quackies</span>
        Cookies
      </h3>
      <p className={style.content}>
        This site uses cookies. Give us the green light to indulge our data cravings!
        Clicking "Allow all" helps us build a better experience for everyone. Check our{' '}
        <a
          href="https://www.spaceduck.com/legal/privacy"
          target="_blank"
          rel="noreferrer"
        >
          privacy policy
        </a>{' '}
        for more details.
      </p>
      <div className={style.choices}>
        <Button variant="primary" size="sm" onClick={handleGrantAll}>
          Allow All
        </Button>
        <Button variant="outlined" size="sm" onClick={handleGrantEssentialOnly}>
          Essential Only
        </Button>
      </div>
    </div>
  );
};
