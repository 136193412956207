import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 64 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 64 64"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.5086 6.62599C35.4288 6.84361 34.7282 7.89335 34.9416 8.97399L42.7284 48.4108L44.6282 58.0303C44.8428 59.1169 45.8996 59.8222 46.9854 59.6033L58.047 57.374C59.1268 57.1564 59.8274 56.1066 59.614 55.026L57.7112 45.3903L49.9274 5.96983C49.7128 4.88318 48.656 4.17785 47.5702 4.39668L36.5086 6.62599Z"
        stroke="#4B4E68"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6392 16.5876V28.1643"
        stroke="#B5B7CA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 43.5999H19.2778"
        stroke="#B5B7CA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.917 16.5876V28.1643"
        stroke="#7A7E9F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2778 43.5999H34.5557"
        stroke="#7A7E9F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.1016 15.9163L46.3436 27.27"
        stroke="#4B4E68"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.8413 43.9173L56.8241 40.8967"
        stroke="#4B4E68"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 5.97559C4.89543 5.97559 4 6.87102 4 7.97559V48.182V57.9998C4 59.1044 4.89543 59.9998 6 59.9998H17.2779C18.3824 59.9998 19.2778 59.1044 19.2778 57.9998V48.182V7.97559C19.2778 6.87102 18.3824 5.97559 17.2778 5.97559H6Z"
        stroke="#B5B7CA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2778 5.97559C20.1733 5.97559 19.2778 6.87102 19.2778 7.97559V48.182V57.9998C19.2778 59.1044 20.1733 59.9998 21.2778 59.9998H32.5557C33.6603 59.9998 34.5557 59.1044 34.5557 57.9998V48.182V7.97559C34.5557 6.87102 33.6603 5.97559 32.5557 5.97559H21.2778Z"
        stroke="#7A7E9F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
