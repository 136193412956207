import { clsx } from 'clsx';
import {
  type MouseEventHandler,
  type PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';

import type { BillingInterval, BillingPlan } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';

import { useGetSubscriptionInfo, useUpgradeSubscriptionLink } from '@api/billing';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Button, { ButtonLink } from '@ui/Button';
import Spinner from '@ui/Spinner';
import createToast from '@utils/createToast';
import { getMailToLink } from '@utils/openMailTo';

import { Container, Header, TopNav } from '../common';
import RadioGroup, { type RadioGroupOption } from '@/components/ui/RadioGroup';
import { Capitalized } from '@/components/Capitalized';
import { useManageSubscription } from './useManageSubscription';
import {
  getFeatures,
  getFeaturesTitle,
  getPlanDisplayName,
  getPricing,
  type Feature,
} from './billingData';
import { FeaturesSummary } from './FeaturesSummary';

import styles from './BillingAndPlanPage.module.scss';

const { Open2 } = Icon16;
const { Open, PricingPro, PricingTeam } = Icon24;

export function BillingAndPlanPage() {
  const workspaceId = useWorkspaceId();
  const { data: subscriptionInfo } = useGetSubscriptionInfo(workspaceId);
  const { mutateAsync: manageSubscription, isAnyPending } = useManageSubscription();

  return (
    <>
      <TopNav
        title="Manage Billing and Plan"
        currentBreadcrumb="Billing & Plan"
        owner="workspace"
      >
        {['pro', 'team'].includes(subscriptionInfo?.plan || 'starter') && (
          <Button
            size="sm"
            type="button"
            variant="outlined"
            onClick={async () => {
              if (!workspaceId) {
                return;
              }
              await manageSubscription(workspaceId);
            }}
            disabled={isAnyPending}
          >
            Manage subscription
            {isAnyPending ? <Spinner size={16} /> : <Open size={16} />}
          </Button>
        )}
      </TopNav>
      {workspaceId && (
        <Container className={styles.container}>
          <Header>
            <h1>Billing & Plan</h1>
            <p>Manage your organization billing & plan.</p>
          </Header>
          <StandardPlans workspaceId={workspaceId} />
        </Container>
      )}
    </>
  );
}

type PlanProps = { workspaceId: string };

const BILLING_PERIOD: RadioGroupOption<BillingInterval>[] = [
  { label: <span className={styles.intervalPill}>Monthly</span>, value: 'month' },
  {
    label: (
      <span className={styles.intervalPill}>
        Yearly <span className={styles.intervalDiscount}>Save 50%</span>
      </span>
    ),
    value: 'year',
  },
];

// TODO: Update link
const helpLink = getMailToLink({
  subject: 'I need help with a billing issue',
}).toString();

const StandardPlans = ({ workspaceId }: PlanProps) => {
  const [interval, setInterval] = useState<BillingInterval>('year');

  const { mutateAsync: manageSubscription, isAnyPending } = useManageSubscription();

  const handleManage = useCallback<MouseEventHandler>(
    async (event) => {
      event.preventDefault();
      await manageSubscription(workspaceId);
    },
    [manageSubscription, workspaceId]
  );

  if (!workspaceId) return null;

  return (
    <div className={styles.plansWrapper}>
      <div className={styles.plansHeader}>
        <h2>Upgrade or manage your plan</h2>
        <div className={styles.billingPeriod}>
          <RadioGroup
            layout="pill"
            defaultValue="year"
            options={BILLING_PERIOD}
            onValueChange={setInterval}
          />
        </div>
      </div>
      <div className={styles.launchBadge}>
        🚀 Launch offer - 25% off all plans for the first 12-months.
      </div>
      <div className={styles.plansTable}>
        <Plan plan="starter" interval={interval} />
        <div className={styles.divider} />
        <Plan plan="pro" interval={interval} />
      </div>
      <div className={styles.actions}>
        <div>Want to see all features?</div>
        <ButtonLink
          className={styles.iconButton}
          iconAfter={<Open2 />}
          size="sm"
          type="button"
          variant="secondary"
          to="https://www.spaceduck.com/pricing"
          target="_blank"
        >
          Compare plans
        </ButtonLink>
      </div>
      <div className={styles.footer}>
        <ul>
          <li>
            {isAnyPending ? (
              <span>Manage my subscription</span>
            ) : (
              <Link to="#" onClick={handleManage}>
                Manage my subscription
              </Link>
            )}
          </li>
          <li>
            <a href={helpLink} target="_blank" rel="noreferrer">
              I need help with a billing issue
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

const PlanCta = ({
  plan,
  interval,
}: { plan: BillingPlan; interval: BillingInterval }) => {
  const workspaceId = useWorkspaceId();
  const { data, status, refetch } = useGetSubscriptionInfo(workspaceId);
  const { mutateAsync: manageSubscription, isAnyPending } = useManageSubscription();

  const handleRetry = useCallback(() => {
    refetch();
  }, [refetch]);

  const { mutateAsync: upgradeSubscriptionLink } = useUpgradeSubscriptionLink();

  const handleUpgrade = useCallback(async () => {
    if (!workspaceId) {
      console.error('Failed to get upgrade link due to missing workspaceId');
      createToast({
        titleText: 'Upgrade error',
        bodyText: 'Failed to prepare for upgrade. Please try again later.',
        iconVariant: 'warning',
      });
      return;
    }
    const { redirectUrl } = await upgradeSubscriptionLink({
      workspaceId,
      plan,
      interval,
    });
    window.location.href = redirectUrl;
  }, [workspaceId, plan, interval]);

  const handleManage = useCallback(() => {
    if (!workspaceId) {
      return;
    }
    manageSubscription(workspaceId);
  }, [workspaceId]);

  const cta = useMemo(() => {
    if (status === 'pending') {
      return (
        <Button disabled size="md" type="button" variant="secondary">
          <Spinner />
        </Button>
      );
    }
    if (status === 'error') {
      return (
        <>
          <div>
            Failed to fetch current subscription information. Please try again later
          </div>
          <Button onClick={handleRetry} size="md" type="button" variant="outlined">
            Retry
          </Button>
        </>
      );
    }
    if (plan === data.plan) {
      return (
        <Button disabled={true} size="md" type="button" variant="outlined">
          Current Plan
        </Button>
      );
    }
    if (plan === 'starter') {
      return (
        <Button
          onClick={handleManage}
          size="md"
          type="button"
          variant="secondary"
          disabled={isAnyPending}
        >
          Downgrade
        </Button>
      );
    }
    return (
      <Button onClick={handleUpgrade} size="md" type="button" variant="primary">
        Upgrade
      </Button>
    );
  }, [plan, status, data, handleRetry, handleManage, handleUpgrade]);

  return (
    <>
      <PlanHeader plan={plan} />
      <PricingInfo plan={plan} interval={interval} />
      <div className={styles.upgradeCTA}>{cta}</div>
    </>
  );
};

const PlanIcon = ({ plan }: { plan: BillingPlan }) => {
  if (plan === 'starter') {
    return <PricingPro color="#0267CA" />;
  }
  return <PricingTeam color="#804CF5" />;
};

const PlanHeader = ({ plan }: { plan: BillingPlan }) => {
  return (
    <div className={styles.upgrade}>
      <PlanIcon plan={plan} />
      <Capitalized>{getPlanDisplayName(plan)}</Capitalized>
    </div>
  );
};

const PricingInfo = ({
  plan,
  interval,
}: { plan: BillingPlan; interval: BillingInterval }) => {
  const pricingData = getPricing(plan);

  if (pricingData === null) {
    return (
      <div className={clsx('body6', styles.pricing)}>
        <span className={clsx(styles.highlight)}>Free</span>
      </div>
    );
  }

  const pricing = interval === 'month' ? pricingData.monthly : pricingData.yearly;

  return (
    <div className={clsx('body6', styles.pricing)}>
      <span
        className={clsx(
          styles.highlight,
          pricing.launchDiscounted ? styles.strike : null
        )}
      >
        ${pricing.normal}
      </span>
      {pricing.launchDiscounted && (
        <span className={clsx(styles.highlight)}>${pricing.launchDiscounted}</span>
      )}
      <span>
        per user / per month
        <br />
        billed {interval === 'month' ? 'monthly' : 'yearly'}
      </span>
    </div>
  );
};

const Plan = ({ plan, interval }: { plan: BillingPlan; interval: BillingInterval }) => {
  const features = getFeatures(plan);
  const title = getFeaturesTitle(plan);

  return (
    <PlanDetails title={title} features={features}>
      <PlanCta plan={plan} interval={interval} />
    </PlanDetails>
  );
};

const PlanDetails = ({
  children,
  ...props
}: PropsWithChildren<{ title: string; features: Feature[] | null }>) => {
  return (
    <article className={styles.planDetails}>
      {children}
      <FeaturesSummary {...props} />
    </article>
  );
};
