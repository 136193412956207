import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.45455 20.1818H3.81818C3.33597 20.1818 2.87351 19.9903 2.53253 19.6493C2.19156 19.3083 2 18.8458 2 18.3636V2H9.27273L12 5.63636H22V18.3636C22 18.8458 21.8084 19.3083 21.4675 19.6493C21.1265 19.9903 20.664 20.1818 20.1818 20.1818H16.5455"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9999 21.0909C12.502 21.0909 12.909 20.6839 12.909 20.1818C12.909 19.6797 12.502 19.2727 11.9999 19.2727C11.4978 19.2727 11.0908 19.6797 11.0908 20.1818C11.0908 20.6839 11.4978 21.0909 11.9999 21.0909Z"
        fill={color}
      />
      <path
        d="M10.0483 9.78182C11.8483 8.98637 14.1774 9.08091 14.9729 10.3682C15.7683 11.6555 15.2192 13.1527 13.8556 14.3082C12.492 15.4636 12.0002 16.0909 12.0002 17"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
