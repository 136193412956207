import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 64 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 64 64"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_6101_3019" fill="white">
        <path d="M21 43V53H51L61 40L51 27H49" />
      </mask>
      <path
        d="M21 53H19V55H21V53ZM51 53V55H51.9848L52.5852 54.2194L51 53ZM61 40L62.5852 41.2194L63.5233 40L62.5852 38.7806L61 40ZM51 27L52.5852 25.7806L51.9848 25H51V27ZM19 43V53H23V43H19ZM21 55H51V51H21V55ZM52.5852 54.2194L62.5852 41.2194L59.4148 38.7806L49.4148 51.7806L52.5852 54.2194ZM62.5852 38.7806L52.5852 25.7806L49.4148 28.2194L59.4148 41.2194L62.5852 38.7806ZM51 25H49V29H51V25Z"
        fill="#4B4E68"
        mask="url(#path-1-inside-1_6101_3019)"
      />
      <path
        d="M13.4924 36L4.26163 24L13.4924 12H42V36H13.4924Z"
        stroke="#7A7E9F"
        strokeWidth="2"
      />
    </svg>
  );
}
