import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 7.99961H2.75C2.76488 5.72289 3.55325 4.29909 5.16062 3.64847C5.60775 3.46761 5.8195 2.96614 5.63487 2.52897C5.45025 2.09009 4.93837 1.88607 4.49213 2.06437C2.89962 2.70899 1 4.27251 1 8.06133V13.1428C1 13.616 1.39113 14 1.875 14H6.25C6.73387 14 7.125 13.616 7.125 13.1428V8.85681C7.125 8.38364 6.73387 7.99961 6.25 7.99961Z"
        fill={color}
      />
      <path
        d="M14.125 7.99961H10.625C10.6399 5.72289 11.4283 4.29909 13.0356 3.64847C13.4827 3.46761 13.6945 2.96614 13.5099 2.52897C13.3253 2.09009 12.8134 1.88607 12.3671 2.06437C10.7746 2.70899 8.875 4.27337 8.875 8.06133V13.1428C8.875 13.616 9.26613 14 9.75 14H14.125C14.6089 14 15 13.616 15 13.1428V8.85681C15 8.38364 14.6089 7.99961 14.125 7.99961Z"
        fill={color}
      />
    </svg>
  );
}
