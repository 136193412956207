import clsx from 'clsx';
import { useState } from 'react';

import type { NamedPrompt } from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';

import Button from '@ui/Button';
import Menu, { MenuItem, RecursiveDropdownMenuItem, Separator } from '@ui/DropdownMenu';
import Spinner from '@ui/Spinner';
import styles from './AIMenuInitial.module.scss';

const { AI } = Icon16;

export default function AIMenuInitial({
  container,
  isLoading = false,
  handleChoose,
  setFocus,
}: {
  container?: HTMLElement | null;
  isLoading?: boolean;
  handleChoose: (namedPrompt: NamedPrompt) => void;
  setFocus?: () => void;
}) {
  const [open, setOpen] = useState(true);

  if (isLoading) {
    return <Spinner size={24} />;
  }

  return (
    <Menu
      alignOffset={-13}
      className={clsx(isLoading && styles.hidden, styles.menu)}
      container={container}
      isPadded
      open={open}
      setOpen={setOpen}
      sideOffset={16}
      triggerContent={
        <Button className={styles.trigger} variant="icon">
          <AI />
        </Button>
      }
      width={260}
    >
      <MenuItem
        onSelect={(ev) => {
          ev.preventDefault();
          handleChoose('clarity_and_fluency');
        }}
      >
        Enhance clarity and fluency
      </MenuItem>
      <MenuItem
        onSelect={(ev) => {
          ev.preventDefault();
          handleChoose('final_polish');
        }}
      >
        Add final polish
      </MenuItem>
      <MenuItem
        onSelect={(ev) => {
          ev.preventDefault();
          handleChoose('simplify');
        }}
      >
        Simplify
      </MenuItem>
      <Separator />
      <RecursiveDropdownMenuItem
        container={container}
        className={clsx(!open && styles.hidden)}
        isPadded
        item={{
          content: 'Length',
          subMenu: [
            {
              content: 'Make longer',
              onClick: (ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                container?.querySelector('button')?.focus();
                handleChoose('length_more');
                setOpen(false);
              },
            },
            {
              content: 'Make shorter',
              onClick: (ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                container?.querySelector('button')?.focus();
                handleChoose('length_less');
                setOpen(false);
              },
            },
          ],
        }}
      />
      <Separator />
      <MenuItem
        onSelect={(ev) => {
          ev.preventDefault();
          handleChoose('summarize');
        }}
      >
        Summarize
      </MenuItem>
      <MenuItem
        onSelect={(ev) => {
          ev.preventDefault();
          handleChoose('action_items');
        }}
      >
        Create action items
      </MenuItem>
      <Separator />
      <MenuItem
        onSelect={(ev) => {
          ev.preventDefault();
          handleChoose('outline');
        }}
      >
        Outline...
      </MenuItem>
      <MenuItem
        onSelect={(ev) => {
          ev.preventDefault();
          handleChoose('brainstorm');
        }}
      >
        Brainstorm ideas...
      </MenuItem>
      <MenuItem
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          setFocus?.();
          setOpen(false);
        }}
      >
        Custom...
      </MenuItem>
    </Menu>
  );
}
