import Head from '@components/Head';
import Breadcrumb, { type BreadcrumbItem } from '@ui/Breadcrumb';
import Button from '@ui/Button';
import styles from './TopNav.module.scss';

export type TopNavProps = {
  breadcrumb: BreadcrumbItem[];
  children?: React.ReactNode;
  buttonIsDisabled?: boolean;
  buttonOnClick?: () => void;
  buttonText?: string;
  iconBefore?: React.ReactNode;
  title: string;
};

export default function TopNav({
  breadcrumb,
  buttonIsDisabled,
  buttonOnClick,
  buttonText,
  iconBefore,
  children,
  title,
}: TopNavProps) {
  return (
    <>
      <Head title={title} />
      <div className={styles.topNav}>
        <div>
          <Breadcrumb breadcrumb={breadcrumb} />
        </div>
        <div>
          {children}
          {buttonOnClick && (
            <Button
              disabled={buttonIsDisabled}
              iconBefore={iconBefore}
              onClick={() => buttonOnClick()}
              size="sm"
              variant="outlined"
            >
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
