import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 32 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 31.6667H4.33333C3.59667 31.6667 3 31.07 3 30.3333V2.33333C3 1.59667 3.59667 1 4.33333 1H20.3333L28.3333 9V30.3333C28.3333 31.07 27.7367 31.6667 27 31.6667Z"
        fill="#EA4C3A"
      />
      <path
        d="M20.3334 1V7.66667C20.3334 8.40333 20.93 9 21.6667 9H28.3334L20.3334 1Z"
        fill="#F49F95"
      />
      <path
        d="M20.3372 27.6668V19.9092H25.6326V21.4319H22.2122V23.0228H25.2955V24.5493H22.2122V27.6668H20.3372Z"
        fill="#F0F1F4"
      />
      <path
        d="M15.413 27.6668H12.5455V19.9092H15.4092C16.1996 19.9092 16.8801 20.0645 17.4508 20.3751C18.0241 20.6832 18.466 21.1276 18.7766 21.7084C19.0872 22.2867 19.2425 22.9786 19.2425 23.7842C19.2425 24.5923 19.0872 25.2867 18.7766 25.8675C18.4685 26.4483 18.0279 26.894 17.4546 27.2046C16.8814 27.5127 16.2008 27.6668 15.413 27.6668ZM14.4205 26.0683H15.341C15.7753 26.0683 16.1428 25.995 16.4433 25.8486C16.7463 25.6996 16.9748 25.4584 17.1289 25.1251C17.2854 24.7892 17.3637 24.3423 17.3637 23.7842C17.3637 23.2261 17.2854 22.7817 17.1289 22.4508C16.9723 22.1175 16.7412 21.8776 16.4357 21.7311C16.1327 21.5822 15.7589 21.5077 15.3145 21.5077H14.4205V26.0683Z"
        fill="#F0F1F4"
      />
      <path
        d="M5.58716 27.6668V19.9092H8.7917C9.37251 19.9092 9.87377 20.0228 10.2955 20.2501C10.7197 20.4748 11.0468 20.7892 11.2766 21.1933C11.5063 21.5948 11.6212 22.062 11.6212 22.5948C11.6212 23.1301 11.5038 23.5986 11.269 24.0001C11.0367 24.3991 10.7046 24.7084 10.2728 24.9281C9.84095 25.1478 9.32832 25.2577 8.73489 25.2577H6.75761V23.7804H8.3864C8.66923 23.7804 8.90534 23.7311 9.09473 23.6327C9.28665 23.5342 9.43185 23.3966 9.53034 23.2198C9.62882 23.0405 9.67807 22.8322 9.67807 22.5948C9.67807 22.3549 9.62882 22.1478 9.53034 21.9736C9.43185 21.7968 9.28665 21.6604 9.09473 21.5645C8.90281 21.4685 8.6667 21.4205 8.3864 21.4205H7.46216V27.6668H5.58716Z"
        fill="#F0F1F4"
      />
    </svg>
  );
}
