import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1449_2747)">
        <path
          d="M13.6 7.20001L3.6 0.200008C2.9 -0.299992 2 0.200008 2 1.00001V15C2 15.8 2.9 16.3 3.6 15.8L13.6 8.80001C14.1 8.40001 14.1 7.60001 13.6 7.20001Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1449_2747">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
