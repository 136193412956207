import clsx from 'clsx';

import Button from '@ui/Button';
import styles from './SubNav.module.scss';

type SubNavProps = {
  actions?: React.ReactNode;
  buttonIsDisabled?: boolean;
  buttonOnClick?: () => void;
  buttonText?: string;
  children?: React.ReactNode;
  className?: string;
};

export default function SubNav({
  actions,
  buttonIsDisabled,
  buttonOnClick,
  buttonText,
  children,
  className,
}: SubNavProps) {
  if (!(actions || buttonOnClick || children)) return null;

  return (
    <div className={clsx(styles.container, className)}>
      <div>{children}</div>
      <div>
        {actions}
        {buttonOnClick && (
          <Button
            disabled={buttonIsDisabled}
            onClick={() => buttonOnClick()}
            size="sm"
            variant="outlined"
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
}
