import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.25 6.25H21.25C21.7804 6.25 22.2891 6.46071 22.6642 6.83579C23.0393 7.21086 23.25 7.71957 23.25 8.25V16.25C23.25 16.7804 23.0393 17.2891 22.6642 17.6642C22.2891 18.0393 21.7804 18.25 21.25 18.25H7.25"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 14.25L7.25 18.25L11.25 22.25"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.25 18.25H3.25C2.71957 18.25 2.21086 18.0393 1.83579 17.6642C1.46071 17.2891 1.25 16.7804 1.25 16.25V8.25C1.25 7.71957 1.46071 7.21086 1.83579 6.83579C2.21086 6.46071 2.71957 6.25 3.25 6.25H17.25"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.25 2.25L17.25 6.25L13.25 10.25"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
