import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3762_12471)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.29895 4.04123C3.29895 3.63129 3.63127 3.29897 4.04122 3.29897H11.9587C12.3687 3.29897 12.701 3.63129 12.701 4.04123V11.9588C12.701 12.3687 12.3687 12.701 11.9587 12.701H4.04122C3.63127 12.701 3.29895 12.3687 3.29895 11.9588V4.04123ZM4.78349 4.7835V11.2165H11.2165V4.7835H4.78349Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4.04123C0 3.63129 0.332325 3.29897 0.742268 3.29897H1.40206C1.81201 3.29897 2.14433 3.63129 2.14433 4.04123C2.14433 4.45118 1.81201 4.7835 1.40206 4.7835H0.742268C0.332325 4.7835 0 4.45118 0 4.04123Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 11.9588C0 11.5488 0.332325 11.2165 0.742268 11.2165H1.40206C1.81201 11.2165 2.14433 11.5488 2.14433 11.9588C2.14433 12.3687 1.81201 12.701 1.40206 12.701H0.742268C0.332325 12.701 0 12.3687 0 11.9588Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8557 4.04123C13.8557 3.63129 14.188 3.29897 14.598 3.29897H15.2578C15.6677 3.29897 16 3.63129 16 4.04123C16 4.45118 15.6677 4.7835 15.2578 4.7835H14.598C14.188 4.7835 13.8557 4.45118 13.8557 4.04123Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8557 11.9588C13.8557 11.5488 14.188 11.2165 14.598 11.2165H15.2578C15.6677 11.2165 16 11.5488 16 11.9588C16 12.3687 15.6677 12.701 15.2578 12.701H14.598C14.188 12.701 13.8557 12.3687 13.8557 11.9588Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.04122 0C4.45116 0 4.78349 0.332325 4.78349 0.742268V1.40206C4.78349 1.81201 4.45116 2.14433 4.04122 2.14433C3.63127 2.14433 3.29895 1.81201 3.29895 1.40206V0.742268C3.29895 0.332325 3.63127 0 4.04122 0Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9588 0C12.3688 0 12.7011 0.332325 12.7011 0.742268V1.40206C12.7011 1.81201 12.3688 2.14433 11.9588 2.14433C11.5489 2.14433 11.2166 1.81201 11.2166 1.40206V0.742268C11.2166 0.332325 11.5489 0 11.9588 0Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.04122 13.8557C4.45116 13.8557 4.78349 14.188 4.78349 14.5979V15.2577C4.78349 15.6677 4.45116 16 4.04122 16C3.63127 16 3.29895 15.6677 3.29895 15.2577V14.5979C3.29895 14.188 3.63127 13.8557 4.04122 13.8557Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9588 13.8557C12.3688 13.8557 12.7011 14.188 12.7011 14.5979V15.2577C12.7011 15.6677 12.3688 16 11.9588 16C11.5489 16 11.2166 15.6677 11.2166 15.2577V14.5979C11.2166 14.188 11.5489 13.8557 11.9588 13.8557Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3762_12471">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
