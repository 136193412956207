import { useListMediaGroups } from '@/api/mediaGroup';
import useWorkspaceId from '@/hooks/useWorkspaceId';
import { css } from '@/lib/css';
import type { MediaGroupDTO, MediaGroupQuerySchema } from '@spaceduck/api';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { ContentType } from './icons';
import Card from './ui/Card';
import styles from './MediaGroupPreviewList.module.scss';
import ScrollArea from './ui/ScrollArea';
import Spinner from './ui/Spinner';

type KeyDownHandler = (event: KeyboardEvent) => boolean;

export type MediaGroupPreviewListProps = {
  searchTitle: string;
  handleSelectItem: (item: MediaGroupDTO) => void;
  setOnKeyDown: (setHandler: () => KeyDownHandler) => void;
  defaultFilters?: Omit<MediaGroupQuerySchema, 'workspace'>;
  excludeMediaGroupIds?: string[];
};

const MediaGroupPreviewList = (props: MediaGroupPreviewListProps) => {
  const { searchTitle, handleSelectItem, setOnKeyDown, defaultFilters } = props;

  const [searchValue, setSearchValue] = useState('');
  const [debounceIsLoading, setDebounceIsLoading] = useState(false);
  const debouncedSearch = useCallback(
    debounce(async (value: string) => {
      setDebounceIsLoading(false);
      setSearchValue(value);
    }, 150),
    []
  );

  useEffect(() => {
    setDebounceIsLoading(true);
    debouncedSearch(searchTitle);
  }, [searchTitle]);

  const workspaceId = useWorkspaceId();
  const {
    data: mediaGroupData,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    enabled,
  } = useListMediaGroups(workspaceId, {
    ...defaultFilters,
    title: [searchValue],
  });
  const mediaGroups = mediaGroupData?.pages
    .flatMap((page) => page.mediaGroups)
    .filter((mediaGroup) =>
      props.excludeMediaGroupIds?.length
        ? !props.excludeMediaGroupIds.includes(mediaGroup.id)
        : true
    );

  const containerRef = useRef<HTMLDivElement>(null);
  const mediaGroupListListRef = useRef<HTMLDivElement>(null);
  const { ref: loadMoreRef, inView } = useInView();
  const [activeEmbeddable, setActiveEmbeddable] = useState<string | null>(null);

  useEffect(() => {
    const activeElement = mediaGroupListListRef.current?.querySelector('.active');
    activeElement?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
  }, [activeEmbeddable]);

  useEffect(() => {
    const firstEmbeddable =
      mediaGroups?.length && mediaGroups.length > 0 ? mediaGroups[0] : undefined;
    if (!firstEmbeddable) {
      setActiveEmbeddable(null);
    } else if (!activeEmbeddable && firstEmbeddable) {
      setActiveEmbeddable(firstEmbeddable.id);
    } else if (activeEmbeddable) {
      const found = mediaGroups?.find(
        (embeddable) => embeddable.id === activeEmbeddable
      );
      if (!found) {
        setActiveEmbeddable(firstEmbeddable.id);
      }
    }
  }, [mediaGroupData?.pages]);

  useEffect(() => {
    if (enabled && inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [enabled, inView, isFetchingNextPage, hasNextPage]);

  const handleListItemClick = (embeddableId: string | null) => {
    setActiveEmbeddable(embeddableId || null);
  };

  const handleCardClick = (embeddableId: string) => {
    const mediaGroup = mediaGroups?.find(
      (mediaGroup) => mediaGroup.id === embeddableId
    );
    if (mediaGroup) {
      handleSelectItem(mediaGroup);
    }
  };
  const upHandler = () => {
    if (mediaGroups && mediaGroups.length > 0 && activeEmbeddable) {
      const activeIndex = mediaGroups.findIndex(
        (embeddable) => embeddable.id === activeEmbeddable
      );

      if (activeIndex > 0 && activeEmbeddable?.[activeIndex - 1]) {
        setActiveEmbeddable(mediaGroups[activeIndex - 1]?.id ?? null);
      }
    }
  };

  const downHandler = () => {
    if (mediaGroups && mediaGroups.length > 0) {
      if (activeEmbeddable) {
        const activeIndex = mediaGroups.findIndex(
          (mediaGroup) => mediaGroup.id === activeEmbeddable
        );

        const LOAD_MORE_ITEMS_THRESHOLD = 3;

        if (
          mediaGroups.length - activeIndex < LOAD_MORE_ITEMS_THRESHOLD &&
          enabled &&
          hasNextPage &&
          !isFetchingNextPage
        ) {
          fetchNextPage();
        }

        if (activeIndex < 0) return;

        if (activeIndex + 1 < mediaGroups.length) {
          const nextActiveMediaGroup = mediaGroups[activeIndex + 1];
          if (nextActiveMediaGroup) {
            setActiveEmbeddable(nextActiveMediaGroup.id);
          }
        }
      }
    }
  };

  const enterHandler = () => {
    if (activeEmbeddable) {
      const mediaGroup = mediaGroups?.find(
        (mediaGroup) => mediaGroup.id === activeEmbeddable
      );
      if (mediaGroup) {
        handleSelectItem(mediaGroup);
      }
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (containerRef.current?.checkVisibility?.()) {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    setOnKeyDown(() => onKeyDown);
  }, [activeEmbeddable]);

  if (isError) return null;

  if (isLoading || debounceIsLoading)
    return (
      <div className={clsx(styles.container, styles.isLoading)}>
        <Spinner />
      </div>
    );
  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.mediaGroupList} ref={mediaGroupListListRef}>
        <ScrollArea
          className={styles.scrollArea}
          orientation="vertical"
          style={css({
            '--width': '100%',
            '--maxHeight': '100%',
          })}
        >
          {mediaGroups?.map((mediaGroup) => {
            return (
              <div
                className={clsx(
                  styles.mediaGroupListItem,
                  activeEmbeddable === mediaGroup.id && `${styles.active} active`
                )}
                key={mediaGroup.id}
                onClick={() => handleListItemClick(mediaGroup.id)}
                onDoubleClick={() => {
                  handleListItemClick(mediaGroup.id);
                  handleCardClick(mediaGroup.id);
                }}
              >
                <ContentType contentType={mediaGroup.contentType} />
                <div className={styles.mediaGroupInfo}>
                  <div className={styles.label}>
                    {mediaGroup.label || mediaGroup.linkUrlSource}
                  </div>
                  <div className={styles.bucket}>{getBucketLabel(mediaGroup)}</div>
                </div>
              </div>
            );
          })}
          {hasNextPage && (
            <div ref={loadMoreRef} style={{ width: '100%', height: '10px' }}>
              {/* Triggers page fetch when in view */}
            </div>
          )}
        </ScrollArea>
      </div>
      <div className={styles.focusedItem}>
        {activeEmbeddable &&
          mediaGroups?.find((mediaGroup) => mediaGroup.id === activeEmbeddable) && (
            <div onClick={() => handleCardClick(activeEmbeddable)}>
              <Card
                mediaGroup={
                  mediaGroups.find((mediaGroup) => mediaGroup.id === activeEmbeddable)!
                }
                hideFooter
                className={styles.card}
              />
            </div>
          )}
      </div>
    </div>
  );
};

const getBucketLabel = (mediaGroup: MediaGroupDTO) => {
  if (mediaGroup.project) return mediaGroup.project.label;
  return 'Repository';
};

export default MediaGroupPreviewList;
