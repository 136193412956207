import { Icon16, Icon24 } from '@spaceduck/icons';

import Tooltip from '@/components/ui/Tooltip';
import type { Feature } from './billingData';

import styles from './FeatureSummary.module.scss';

const { Info } = Icon16;
const { Check } = Icon24;

export const FeaturesSummary = ({
  title,
  features,
}: { title: string; features: Feature[] | null }) => {
  if (!features?.length) {
    return null;
  }

  return (
    <div className={styles.featuresSummary}>
      <h4>{title}</h4>
      <FeatureList features={features} />
    </div>
  );
};

const FeatureList = ({ features }: { features: Feature[] }) => {
  return (
    <>
      {features.map((feature, idx) => (
        <FeatureInfo key={idx} feature={feature} />
      ))}
    </>
  );
};

const FeatureInfo = ({ feature: { title, tooltip } }: { feature: Feature }) => {
  return (
    <div className={styles.feature}>
      <span className={styles.title}>
        <Check className={styles.check} />
        {title}
      </span>
      {!!tooltip && (
        <Tooltip content={tooltip}>
          <span className={styles.tooltip}>
            <Info />
          </span>
        </Tooltip>
      )}
    </div>
  );
};
