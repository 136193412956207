import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33259 4.18499C4.57293 4.43163 4.57293 4.83153 4.33259 5.07817L2.10105 7.36841H15.3846C15.7245 7.36841 16 7.65117 16 7.99998C16 8.34881 15.7245 8.63156 15.3846 8.63156H2.10105L4.33259 10.9218C4.57293 11.1685 4.57293 11.5683 4.33259 11.815C4.09224 12.0617 3.70263 12.0617 3.46228 11.815L0.180259 8.44653C-0.0600853 8.19996 -0.0600853 7.80003 0.180259 7.55339L3.46228 4.18499C3.70263 3.93834 4.09224 3.93834 4.33259 4.18499Z"
        fill={color}
      />
    </svg>
  );
}
