import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1633_31933)">
        <path
          d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 6C5.5 6.27614 5.27614 6.5 5 6.5C4.72386 6.5 4.5 6.27614 4.5 6C4.5 5.72386 4.72386 5.5 5 5.5C5.27614 5.5 5.5 5.72386 5.5 6Z"
          stroke={color}
        />
        <path
          d="M11.5 6C11.5 6.27614 11.2761 6.5 11 6.5C10.7239 6.5 10.5 6.27614 10.5 6C10.5 5.72386 10.7239 5.5 11 5.5C11.2761 5.5 11.5 5.72386 11.5 6Z"
          stroke={color}
        />
        <path
          d="M11 9.79999C10.6889 10.3177 10.2491 10.7461 9.72341 11.0435C9.19771 11.3409 8.604 11.4972 8 11.4972C7.396 11.4972 6.80229 11.3409 6.27659 11.0435C5.75089 10.7461 5.31111 10.3177 5 9.79999"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1633_31933">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
