import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 64 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 64 64"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.334 21.6667V56.3334C52.334 57.7478 51.7721 59.1044 50.7719 60.1046C49.7717 61.1048 48.4151 61.6667 47.0007 61.6667H17.6673C16.2528 61.6667 14.8963 61.1048 13.8961 60.1046C12.8959 59.1044 12.334 57.7478 12.334 56.3334V21.6667"
        stroke={'#4B4E68'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.33398 13.6667H60.334"
        stroke={'#4B4E68'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.334 29.6667V49.6667"
        stroke={'#B5B7CA'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 29.6667V49.6667"
        stroke={'#7A7E9F'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.668 29.6667V49.6667"
        stroke={'#7A7E9F'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 13.6667V3H41.6667V13.6667"
        stroke={'#4B4E68'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
