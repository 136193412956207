import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1643_43426)">
        <path
          d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.00305 13C8.55534 13 9.00305 12.5523 9.00305 12C9.00305 11.4477 8.55534 11 8.00305 11C7.45077 11 7.00305 11.4477 7.00305 12C7.00305 12.5523 7.45077 13 8.00305 13Z"
          fill={color}
        />
        <path
          d="M6.5 3.57701C7.453 2.71701 9.518 2.73201 9.923 4.21201C10.453 6.13401 8 6.14201 8 9.00001"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1643_43426">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
