import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2196_9999)">
        <path
          d="M10 0.5V4.5H14"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 6.5V4.5L10 0.5H2V6.5"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 13.5V15.5H14V13.5"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.89998 11.5L7.74998 10.95H6.79998L6.69998 11.5H5.84998L6.79998 8.64999H7.84998L8.79998 11.5H7.89998ZM7.59998 10.35L7.49998 9.84999C7.44998 9.74999 7.44998 9.59999 7.39998 9.44999C7.34998 9.29999 7.34998 9.14999 7.29998 9.09999C7.29998 9.14999 7.24998 9.29999 7.24998 9.44999C7.24998 9.59999 7.14998 9.89999 6.99998 10.35H7.59998Z"
          fill={color}
        />
        <path d="M9.05005 11.5V8.64999H9.85005V11.5H9.05005Z" fill={color} />
        <path
          d="M15.5 6.5H0.5V13.5H15.5V6.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2196_9999">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
