import { Icon16 } from '@spaceduck/icons';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'sonner';

import { useWorkspace } from '@/api/workspace';
import { css } from '@/lib/css';
import { useCreateMediaGroup } from '@api/mediaGroup';
import type { CreateMediaGroupSchema } from '@spaceduck/api';
import Toast from '@ui/Toast';
import createToast, { warningToast } from '@utils/createToast';
import { type ProcessingResult, useProcessAssets } from './useProcessAssets';

const { Image, Video, File } = Icon16;

const getIcon = (file: File) => {
  if (file.type.startsWith('video/')) {
    return <Video />;
  }
  if (file.type.startsWith('image/')) {
    return <Image />;
  }
  return <File />;
};

const UPLOAD_PROGRESS_TOAST_ID = 'progressToast';

const collapsibleHeaderExpandedHeight = 49;
const collapsibleHeaderCollapsedHeight = 50;
const collapsibleItemHeight = 48;
const collapsibleContentGap = 4;
const collapsibleContentBox = 18;
const maxItemsDisplayed = 5;

const calcHeight = (numberOfItems: number) =>
  collapsibleHeaderExpandedHeight +
  numberOfItems * collapsibleItemHeight +
  (numberOfItems - 1) * collapsibleContentGap +
  collapsibleContentBox;

const handleFail = (item: ProcessingResult) => {
  warningToast({
    message: `"${item.file.name}" could not be uploaded.`,
  });
};
const handleReject = (item: ProcessingResult) => {
  warningToast({
    message: `"${item.file.name}" could not be uploaded.`,
  });
};
const handleError = () => {
  warningToast({
    message: 'Something went wrong. Please try again or contact support.',
  });
};

export const useFileUploadWrapper = (
  mediaGroupAttributes: Omit<CreateMediaGroupSchema, 'kind'>,
  options?: {
    onSuccess?: (mediaGroupId: string) => void | Promise<void>;
    onError?: () => void;
  }
) => {
  const { workspaceId, projectId, ...attributesRest } = mediaGroupAttributes;
  const workspace = useWorkspace(workspaceId || null);
  const verifiedWorkspaceId =
    workspace.isPending || workspace.isError ? null : workspace.data.workspace.id;
  const { mutateAsync: createMediaGroup } = useCreateMediaGroup();
  const [dragActive, setDragActive] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const onSuccess = options?.onSuccess;
  const onError = options?.onError;

  const handleSuccess = useCallback(
    async (item: ProcessingResult) => {
      if (!item.request.result) {
        return;
      }
      if (!workspaceId) {
        console.error('Processed asset abandoned due to missing workspaceId');
        createToast({
          titleText: 'Error adding content',
          bodyText: 'Please try again later',
          iconVariant: 'warning',
        });
        return;
      }
      try {
        const res = await createMediaGroup({
          kind: 'gallery',
          ...attributesRest,
          assets: [item.request.result.id],
          workspaceId: projectId ? undefined : workspaceId,
          projectId,
        });

        createToast({
          bodyText: item.file?.name ? `Uploaded ${item.file.name}` : 'Uploaded!',
          iconVariant: 'success',
        });
        onSuccess?.(res.mediaGroupId);
      } catch (err) {
        warningToast({
          message: `"${item.file.name}" could not be uploaded.`,
        });
        onError?.();
      }
    },
    [workspaceId, projectId, onSuccess, onError]
  );

  const { insert, abort, pending } = useProcessAssets({
    pollInterval: 1500,
    onSuccess: handleSuccess,
    onFail: handleFail,
    onReject: handleReject,
    onError: handleError,
  });

  const handleDrag = useCallback((ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    const { type } = ev;

    if (type === 'dragenter' || type === 'dragover') {
      setDragActive(true);
    } else if (type === 'dragleave') {
      setDragActive(false);
    }
  }, []);

  const processIncomingFiles = useCallback(
    (incomingFileList: FileList | null) => {
      if (!incomingFileList || !incomingFileList[0]) return;
      if (!verifiedWorkspaceId) {
        console.error('Dropped file discarded due to missing workspaceId');
        createToast({
          titleText: 'Error uploading content',
          bodyText: 'Please try again later',
          iconVariant: 'warning',
        });
        return;
      }
      setDragActive(false);
      insert({ workspaceId: verifiedWorkspaceId, files: incomingFileList });
    },
    [verifiedWorkspaceId, insert]
  );

  const handleDrop = useCallback(
    (ev: React.DragEvent<HTMLLabelElement>) => {
      const incomingFileList = ev.dataTransfer.files;
      ev.preventDefault();
      ev.stopPropagation();
      processIncomingFiles(incomingFileList);
    },
    [processIncomingFiles]
  );

  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const incomingFileList = ev.target.files;
      setDragActive(false);
      processIncomingFiles(incomingFileList);
    },
    [processIncomingFiles]
  );

  useEffect(() => {
    if (pending.length === 0) {
      toast.dismiss(UPLOAD_PROGRESS_TOAST_ID);
      return;
    }
    toast.custom(
      () => (
        <Toast
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          iconVariant="loading"
          processList={pending.map((x) => ({
            id: x.key,
            icon: getIcon(x.file),
            text: x.file.name,
            status: 'processing',
            // We currently don't support the cancellation of processing requests
            onCancel: x.kind === 'waiting' ? undefined : () => abort(x.key),
          }))}
        >
          Saving {pending.length} new item{pending.length > 1 ? 's' : ''} to your{' '}
          {projectId ? 'space' : 'repository'}...
        </Toast>
      ),
      {
        duration: Number.POSITIVE_INFINITY,
        id: UPLOAD_PROGRESS_TOAST_ID,
        style: css({
          '--max-width': '25rem',
          '--initial-height': isOpen
            ? `${Math.min(calcHeight(pending.length), calcHeight(maxItemsDisplayed))}px`
            : `${collapsibleHeaderCollapsedHeight}px`,
        }),
      }
    );
  }, [pending, isOpen]);

  return { dragActive, setDragActive, handleChange, handleDrag, handleDrop };
};
