import { mediaGroupSearchFilterPropertySchema } from '@spaceduck/api';
import { Icon16, Icon64 } from '@spaceduck/icons';
import clsx from 'clsx';
import { Suspense, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { BROWSER_EXT_URL, knownErrors } from '@/const';
import {
  useBulkDeleteMediaGroups,
  useBulkTagMediaGroups,
  useCopyMediaGroup,
  useListMediaGroups,
} from '@api/mediaGroup';
import { useWorkspace } from '@api/workspace';
import { BulkSelectionToolbar } from '@components/BulkSelectionToolbar';
import FileUploadWrapper from '@components/FileUploadWrapper';
import Head from '@components/Head';
import LoadingPlaceholder from '@components/LoadingPlaceholder';
import MediaGroupGrid from '@components/MediaGroupGrid';
import { NewMediaGroupDropdown } from '@components/NewMediaGroupDropdown';
import NoEntries from '@components/NoEntries';
import SearchIncludeFilters from '@components/SearchIncludeFilters';
import Searchbar from '@components/Searchbar';
import SidebarMenuToggle from '@components/SidebarMenuToggle';
import EmptyCollection from '@components/projects/EmptyCollection';
import { useBatchUpdate } from '@hooks/useBatchUpdate';
import useDeleteMediaGroupConfirmModal from '@hooks/useDeleteMediaGroupConformModal';
import useMoveMediaGroupConfirmModal from '@hooks/useMoveMediaGroupConfirmModal';
import { useSearch } from '@hooks/useSearch';
import { useSelectedMediaGroups } from '@hooks/useSelectedMediaGroups';
import useWorkspaceId from '@hooks/useWorkspaceId';
import sharedProjectStyles from '@pages/projects/Shared.module.scss';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import Breadcrumb from '@ui/Breadcrumb';
import breadcrumbStyles from '@ui/Breadcrumb.module.scss';
import Button from '@ui/Button';
import { useForceUpgradeModal } from '@ui/ForceUpgradeModal';
import Spinner from '@ui/Spinner';
import styles from './Collections.module.scss';

const { RepositoryBrowse } = Icon16;
const { Library } = Icon64;

// TODO: Either re-enable or remove once the fate of the library has need decided
const ENABLE_INCLUDE_FILTERS = false;

export default function CollectionsPage() {
  useForceUpgradeModal();
  const workspaceId = useWorkspaceId();
  const { selectedMediaGroupIds, selectedMediaGroups, onSelected, clearSelected } =
    useSelectedMediaGroups();
  const { setCurrentMediaGroupPageCount } = useDetailsModalStore(
    ({ setCurrentMediaGroupPageCount }) => ({ setCurrentMediaGroupPageCount })
  );

  const { error: workspaceError } = useWorkspace(workspaceId);

  const {
    debouncedSearchValue,
    excludeProjectLibraries,
    handleSearchClick,
    mediaGroupFilters,
    searchInputValue,
    searchQueries,
    setIsLoading,
  } = useSearch();

  const { handleCategoryChange, handleStatusChange } = useBatchUpdate();

  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    enabled,
  } = useListMediaGroups(workspaceId, {
    query: debouncedSearchValue,
    excludeProjectLibraries,
    ...mediaGroupFilters,
  });

  const { mutateAsync: bulkCopyMediaGroups } = useCopyMediaGroup();
  const { mutateAsync: bulkDeleteMediaGroups } = useBulkDeleteMediaGroups();
  const { mutateAsync: bulkTagMediaGroups } = useBulkTagMediaGroups();

  const openDeleteMediaGroupConfirmModal = useDeleteMediaGroupConfirmModal({
    onConfirm: async (ids: Set<string>) => {
      bulkDeleteMediaGroups(Array.from(ids));
    },
    onSuccess: () => {
      clearSelected();
    },
  });

  const moveMediaGroupsConfirmModal = useMoveMediaGroupConfirmModal({
    onConfirm: clearSelected,
  });

  const { ref, inView } = useInView();
  const mediaGroups = data?.pages.flatMap((page) => page.mediaGroups) || [];

  useEffect(() => {
    if (enabled && inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [enabled, inView, isFetchingNextPage, hasNextPage]);

  useEffect(() => {
    if (data?.pages?.length) {
      setCurrentMediaGroupPageCount(data.pages.length);
    }
  }, [data?.pages]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  const noEntries =
    mediaGroups.length === 0 &&
    searchQueries.length === 0 &&
    !searchInputValue &&
    !debouncedSearchValue &&
    !excludeProjectLibraries;

  if (workspaceError) {
    throw new Error(knownErrors.workspaceError, { cause: workspaceError });
  }

  return (
    <>
      <Head title="Browse" />
      <header className={breadcrumbStyles.headerBreadcrumbs}>
        <div className={sharedProjectStyles.sidebarMenuToggleWrapper}>
          <SidebarMenuToggle />
          <div className={sharedProjectStyles.divider} />
          <Breadcrumb
            breadcrumb={[
              {
                text: 'Browse',
                icon: <RepositoryBrowse />,
              },
              {
                text: 'Repository',
              },
            ]}
            truncate={true}
          />
        </div>
        <NewMediaGroupDropdown />
      </header>
      {!noEntries && (
        <div
          className={clsx(
            sharedProjectStyles.searchMenu,
            selectedMediaGroupIds.size > 0 && sharedProjectStyles.bulkSelectionEnabled
          )}
        >
          {selectedMediaGroupIds.size > 0 ? (
            <BulkSelectionToolbar
              count={selectedMediaGroupIds.size}
              onCopyMediaGroup={(projectId) => {
                bulkCopyMediaGroups({
                  mediaGroupIds: Array.from(selectedMediaGroupIds),
                  projectId,
                  mode: 'copy',
                });
                clearSelected();
              }}
              onMoveMediaGroup={(projectId) => {
                moveMediaGroupsConfirmModal.open({
                  mediaGroupIds: Array.from(selectedMediaGroupIds),
                  projectId,
                });
              }}
              onAddTag={(tag) => {
                bulkTagMediaGroups({
                  mediaGroupIds: Array.from(selectedMediaGroupIds),
                  tags: [tag],
                });
              }}
              onDelete={() => openDeleteMediaGroupConfirmModal(selectedMediaGroupIds)}
              onCancel={clearSelected}
              selectedMediaGroups={selectedMediaGroups}
              onUpdateStatus={(status) =>
                handleStatusChange(selectedMediaGroups, status)
              }
              onUpdateCategory={(category) =>
                handleCategoryChange(selectedMediaGroups, category)
              }
            />
          ) : (
            <>
              <Searchbar
                availableFilters={(
                  [...mediaGroupSearchFilterPropertySchema.options, 'date'] as const
                ).filter((f) => f !== 'status')}
              />
              {ENABLE_INCLUDE_FILTERS && <SearchIncludeFilters />}
            </>
          )}
        </div>
      )}
      {isLoading && (
        <div className={sharedProjectStyles.spinnerContainer}>
          <LoadingPlaceholder />
        </div>
      )}
      {!isLoading && (
        <FileUploadWrapper className={styles.fileUploadWrapper}>
          {noEntries ? (
            <Suspense fallback={<Spinner />}>
              <EmptyCollection />
            </Suspense>
          ) : (
            <>
              <MediaGroupGrid
                handleSearchClick={handleSearchClick}
                isError={isError}
                isFetchingNextPage={isFetchingNextPage}
                isLoading={isLoading}
                mediaGroups={mediaGroups}
                noResults={<NoResults />}
                selected={selectedMediaGroupIds}
                showCTACard={true}
                onSelected={onSelected}
              />
              <div ref={ref} style={{ width: '100%', height: '10px' }}>
                {/* Triggers page fetch when in view */}
              </div>
            </>
          )}
        </FileUploadWrapper>
      )}
    </>
  );
}

const NoResults = () => {
  return (
    <div className={styles.noResultsContainer}>
      <NoEntries className={styles.noResults} icon={<Library />}>
        <h1>Hello? Anyone there?</h1>
        <p>
          Bring more content or try a different search. You can also download our
          browser extension to capture content from anywhere on the web.
        </p>
        <div className={styles.buttons}>
          <NewMediaGroupDropdown
            align="start"
            triggerText="New item"
            variant="primary"
          />
          <Button
            onClick={() => window.open(BROWSER_EXT_URL)}
            size="sm"
            variant="secondary"
          >
            Get the browser extension
          </Button>
        </div>
      </NoEntries>
    </div>
  );
};
