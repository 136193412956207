import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 12,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 12 12"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.94343 5.17935C9.90121 5.12532 9.83759 5.08004 9.75948 5.04842C9.68137 5.0168 9.59176 5.00005 9.50038 5H2.49963C2.40816 5.00005 2.31847 5.01683 2.24031 5.0485C2.16215 5.08017 2.09852 5.12553 2.05634 5.17964C2.01416 5.23374 1.99505 5.29453 2.00109 5.35537C2.00713 5.41621 2.03808 5.47479 2.09058 5.52472L5.59096 8.85839C5.6371 8.90214 5.69836 8.93784 5.76955 8.96249C5.84074 8.98713 5.91978 9 6 9C6.08022 9 6.15927 8.98713 6.23046 8.96249C6.30165 8.93784 6.3629 8.90214 6.40905 8.85839L9.90943 5.52472C9.96197 5.47474 9.99293 5.41611 9.99893 5.35521C10.0049 5.29431 9.98573 5.23348 9.94343 5.17935Z"
        fill={color}
      />
    </svg>
  );
}
