import type { IconProps } from '../types/IconProps';

export default function Icon({ className, color = '#B5B1E7', size = 32 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5796_68030)">
        <path
          d="M3.33329 7.33325H1.33329C0.965103 7.33325 0.666626 7.63173 0.666626 7.99992C0.666626 8.36811 0.965102 8.66659 1.33329 8.66659H3.33329C3.70148 8.66659 3.99996 8.36811 3.99996 7.99992C3.99996 7.63173 3.70148 7.33325 3.33329 7.33325Z"
          fill={color}
        />
        <path
          d="M3.33329 23.3333H1.33329C0.965103 23.3333 0.666626 23.6317 0.666626 23.9999C0.666626 24.3681 0.965102 24.6666 1.33329 24.6666H3.33329C3.70148 24.6666 3.99996 24.3681 3.99996 23.9999C3.99996 23.6317 3.70148 23.3333 3.33329 23.3333Z"
          fill={color}
        />
        <path
          d="M30.6667 7.33325H28.6667C28.2985 7.33325 28 7.63173 28 7.99992C28 8.36811 28.2985 8.66659 28.6667 8.66659H30.6667C31.0349 8.66659 31.3333 8.36811 31.3333 7.99992C31.3333 7.63173 31.0349 7.33325 30.6667 7.33325Z"
          fill={color}
        />
        <path
          d="M30.6667 23.3333H28.6667C28.2985 23.3333 28 23.6317 28 23.9999C28 24.3681 28.2985 24.6666 28.6667 24.6666H30.6667C31.0349 24.6666 31.3333 24.3681 31.3333 23.9999C31.3333 23.6317 31.0349 23.3333 30.6667 23.3333Z"
          fill={color}
        />
        <path
          d="M8.66659 3.33329V1.33329C8.66659 0.965103 8.36811 0.666626 7.99992 0.666626C7.63173 0.666626 7.33325 0.965102 7.33325 1.33329V3.33329C7.33325 3.70148 7.63173 3.99996 7.99992 3.99996C8.36811 3.99996 8.66659 3.70148 8.66659 3.33329Z"
          fill={color}
        />
        <path
          d="M24.6666 3.33329V1.33329C24.6666 0.965103 24.3681 0.666626 23.9999 0.666626C23.6317 0.666626 23.3333 0.965102 23.3333 1.33329V3.33329C23.3333 3.70148 23.6317 3.99996 23.9999 3.99996C24.3681 3.99996 24.6666 3.70148 24.6666 3.33329Z"
          fill={color}
        />
        <path
          d="M8.66659 30.6667V28.6667C8.66659 28.2985 8.36811 28 7.99992 28C7.63173 28 7.33325 28.2985 7.33325 28.6667V30.6667C7.33325 31.0349 7.63173 31.3333 7.99992 31.3333C8.36811 31.3333 8.66659 31.0349 8.66659 30.6667Z"
          fill={color}
        />
        <path
          d="M24.6666 30.6667V28.6667C24.6666 28.2985 24.3681 28 23.9999 28C23.6317 28 23.3333 28.2985 23.3333 28.6667V30.6667C23.3333 31.0349 23.6317 31.3333 23.9999 31.3333C24.3681 31.3333 24.6666 31.0349 24.6666 30.6667Z"
          fill={color}
        />
        <path
          d="M24.6666 25.3333H7.33329C6.96463 25.3333 6.66663 25.0346 6.66663 24.6666V7.33329C6.66663 6.96529 6.96463 6.66663 7.33329 6.66663H24.6666C25.0353 6.66663 25.3333 6.96529 25.3333 7.33329V24.6666C25.3333 25.0346 25.0353 25.3333 24.6666 25.3333ZM7.99996 24H24V7.99996H7.99996V24Z"
          fill="#5957C2"
        />
        <path d="M12 8H8V12H12V8Z" fill="white" />
        <path d="M16 8H12V12H16V8Z" fill="#8680D8" />
        <path d="M20 8H16V12H20V8Z" fill="white" />
        <path d="M24 8H20V12H24V8Z" fill="#8680D8" />
        <path d="M12 12H8V16H12V12Z" fill="#8680D8" />
        <path d="M16 12H12V16H16V12Z" fill="white" />
        <path d="M20 12H16V16H20V12Z" fill="#8680D8" />
        <path d="M24 12H20V16H24V12Z" fill="white" />
        <path d="M12 16H8V20H12V16Z" fill="white" />
        <path d="M16 16H12V20H16V16Z" fill="#8680D8" />
        <path d="M20 16H16V20H20V16Z" fill="white" />
        <path d="M24 16H20V20H24V16Z" fill="#8680D8" />
        <path d="M12 20H8V24H12V20Z" fill="#8680D8" />
        <path d="M16 20H12V24H16V20Z" fill="white" />
        <path d="M20 20H16V24H20V20Z" fill="#8680D8" />
        <path d="M24 20H20V24H24V20Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_5796_68030">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
