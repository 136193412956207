import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 4.5C6.60457 4.5 7.5 3.60457 7.5 2.5C7.5 1.39543 6.60457 0.5 5.5 0.5C4.39543 0.5 3.5 1.39543 3.5 2.5C3.5 3.60457 4.39543 4.5 5.5 4.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 14.5L4.5 8.5L6.5 10.5L10.5 5.5L15.5 14.5H0.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
