import createToast from '@utils/createToast';
import type { CreateToastProps } from '@utils/createToast';
import { markdownToHtml } from './markdown';

const pushToClipboard = async (
  getBlobs: () => Promise<Blob[]>,
  toast?: Partial<CreateToastProps>
) => {
  if (!navigator.clipboard) {
    return createToast({
      bodyText: 'Unable to access clipboard',
      iconVariant: 'warning',
    });
  }

  const blobs = await getBlobs();
  const data = Object.fromEntries(blobs.map((blob) => [blob.type, blob]));
  const item = new ClipboardItem(data);

  try {
    await navigator.clipboard.write([item]);
  } catch (error) {
    console.error('Failed to copy to clipboard', error);
    createToast({
      bodyText: 'Unable to copy to clipboard',
      iconVariant: 'danger',
    });
    return;
  }

  createToast({
    titleText: 'Copied!',
    bodyText: 'Copied to clipboard!',
    iconVariant: 'success',
    ...toast,
  });
};

export const copyMarkdownToClipboard = async (
  markdown: string,
  toast?: Partial<CreateToastProps>
) => {
  return pushToClipboard(async () => {
    const html = await markdownToHtml(markdown);

    return [
      new Blob([html], { type: 'text/html' }),
      new Blob([markdown], { type: 'text/plain' }),
    ];
  }, toast);
};

export const copyTextToClipboard = (
  text: string,
  toast?: Partial<CreateToastProps>
) => {
  return pushToClipboard(
    () => Promise.resolve([new Blob([text], { type: 'text/plain' })]),
    toast
  );
};

export const copyUrlToClipboard = () => {
  return copyTextToClipboard(window.location.toString(), {
    bodyText: 'Share link copied to clipboard!',
  });
};
