import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4 10.6C15.4 12.6 15.4 15.7 13.4 17.7L10.6 20.5C8.6 22.5 5.5 22.5 3.5 20.5C1.5 18.5 1.5 15.4 3.5 13.4L6 11"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6 13.4C8.59998 11.4 8.59998 8.3 10.6 6.3L13.4 3.5C15.4 1.5 18.5 1.5 20.5 3.5C22.5 5.5 22.5 8.6 20.5 10.6L18 13"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
