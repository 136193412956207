import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5238 17.1765L7 4H6.52381L2 17.1765"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.61523 12.4706H9.90857"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3334 18.6334C15.3334 18.6334 16.5439 20.1563 18.6658 19.9869C20.5248 19.8382 22 18.705 22 16.8923V6.82355"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1905 15.2941C20.2944 15.2941 22 13.3979 22 11.0588C22 8.71976 20.2944 6.82355 18.1905 6.82355C16.0866 6.82355 14.381 8.71976 14.381 11.0588C14.381 13.3979 16.0866 15.2941 18.1905 15.2941Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
