import HorizontalRule from '@tiptap/extension-horizontal-rule';

export default HorizontalRule.extend({
  addNodeView() {
    return () => {
      const hr = document.createElement('div');
      hr.className = 'hr';

      return {
        dom: hr,
      };
    };
  },
});
