import type { IconProps } from '../types/IconProps';

export default function Icon({ className, color = '#F0F1F4', size = 56 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 56 56"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 28C0 12.536 12.536 0 28 0V0C43.464 0 56 12.536 56 28V28C56 43.464 43.464 56 28 56V56C12.536 56 0 43.464 0 28V28Z"
        fill="#6F53F9"
      />
      <path
        d="M42 28C41.9714 27.6927 41.8287 27.4072 41.6 27.2L23.6 14.2C23.443 14.0852 23.2576 14.0152 23.0639 13.9976C22.8701 13.98 22.6752 14.0154 22.5 14.1C22.3464 14.1944 22.2197 14.3269 22.1321 14.4845C22.0446 14.6421 21.9991 14.8197 22 15V41C21.9991 41.1803 22.0446 41.3578 22.1321 41.5155C22.2197 41.6731 22.3464 41.8055 22.5 41.9C22.6536 41.9808 22.8272 42.0155 23 42C23.2125 41.9792 23.4176 41.9109 23.6 41.8L41.6 28.8C41.8287 28.5927 41.9714 28.3073 42 28Z"
        fill={color}
      />
    </svg>
  );
}
