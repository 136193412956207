import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5977_31353)">
        <path
          d="M9.5 15L9.5 1L6.5 1L6.5 15L9.5 15Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2 2"
        />
        <path
          d="M15.5 15L15.5 1L12.5 1L12.5 15L15.5 15Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.5 15L3.5 1L0.5 1L0.499999 15L3.5 15Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5977_31353">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
