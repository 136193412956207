import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.63647 22L11.091 15.3333L15.6365 18.1905"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6364 10.5714H2V22H15.6364V10.5714Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.63639 15.3333C6.13846 15.3333 6.54548 14.9069 6.54548 14.381C6.54548 13.855 6.13846 13.4286 5.63639 13.4286C5.13431 13.4286 4.72729 13.855 4.72729 14.381C4.72729 14.9069 5.13431 15.3333 5.63639 15.3333Z"
        fill={color}
      />
      <path
        d="M14.7273 3.90475H17.4546C19.9646 3.90475 22 6.03714 22 8.66666V11.5238"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7273 5.80952L12 3.90476L14.7273 2V5.80952Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M14.7273 5.80952L12 3.90476L14.7273 2V5.80952Z" fill={color} />
    </svg>
  );
}
