import { Icon24 } from '@spaceduck/icons';
import type { Editor } from '@tiptap/react';
import { useState } from 'react';

import Button from './Button';
import styles from './LinkForm.module.scss';
import { linkNode } from '@/components/detailsModal/tiptap/utils';
import type { MenuView } from '../DocumentWrapper';

const { AlertSuccess, LeftArrow } = Icon24;

export default function LinkForm({
  editor,
  setView,
}: {
  editor: Editor;
  setView: React.Dispatch<React.SetStateAction<MenuView>>;
}) {
  const [linkHref, setLinkHref] = useState<string>(
    () => editor.getAttributes('link').href ?? ''
  );

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const { view } = editor;
    const { from, to } = view.state.selection;

    const data = new FormData(ev.currentTarget);
    const dataUrl = data.get('url') as string;
    const url = /^https?:\/\//.test(dataUrl) ? dataUrl : `https://${dataUrl}`;
    const existingUrl = editor.getAttributes('link').href;

    if (!url || !dataUrl) {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      setView('blockMenu');
      return;
    }

    if (from === to) {
      if (existingUrl) {
        editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url, target: '_blank' })
          .run();
      } else {
        editor
          .chain()
          .focus()
          .insertContent(
            linkNode(url, {
              href: url,
              target: '_blank',
            })
          )
          .run();
      }

      setView('blockMenu');
      return;
    }

    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url, target: '_blank' })
      .run();

    setView('blockMenu');
  };

  return (
    <form className={styles.linkForm} onSubmit={handleSubmit}>
      <div className={styles.back}>
        <Button onClick={() => setView('blockMenu')}>
          <LeftArrow size={20} />
        </Button>
      </div>
      <div className={styles.controls}>
        <input
          className={styles.input}
          onChange={(ev) => setLinkHref(ev.currentTarget.value)}
          name="url"
          placeholder="eg.spaceduck.com"
          type="text"
          autoCapitalize="off"
          inputMode="url"
          spellCheck={false}
          value={linkHref}
        />
        <Button type="submit">
          <AlertSuccess />
        </Button>
      </div>
    </form>
  );
}
