import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps & { active?: boolean }) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 3H5"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7408 8.20953C21.8742 7.58702 21.3997 7 20.763 7H3.23699C2.60034 7 2.12579 7.58702 2.25918 8.20953L4.83061 20.2095C4.92941 20.6706 5.33688 21 5.80842 21H18.1916C18.6631 21 19.0706 20.6706 19.1694 20.2095L21.7408 8.20953Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
