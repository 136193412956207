import { useCallback, useContext, useEffect, useState } from 'react';
import { useReactFlow, useUpdateNodeInternals } from '@xyflow/react';
import { debounce } from 'lodash';

import { BoardStoreContext } from '../context/boardContext';

export const useUpdateNodeInternalsOnZoom = () => {
  const boardContext = useContext(BoardStoreContext);
  const { getEdges, getViewport } = useReactFlow();
  const { zoom } = getViewport();
  const [debouncedZoom, setDebouncedZoom] = useState(zoom);

  // Add --flow-zoom CSS variable
  // Used for relative zoom styles
  useEffect(() => {
    if (boardContext?.containerRef.current) {
      boardContext.containerRef.current.style.setProperty('--flow-zoom', String(zoom));
    }
  }, [zoom]);

  // Redraw edges on zoom change
  const updateNodeInternals = useUpdateNodeInternals();
  const zoomWatcher = useCallback(
    debounce((zoom: number) => {
      setDebouncedZoom(zoom);
    }, 300),
    []
  );

  useEffect(() => {
    getEdges()
      .reduce((acc, curr) => {
        const { source, target } = curr;
        if (!acc.includes(source)) {
          acc.push(source);
        }
        if (!acc.includes(target)) {
          acc.push(target);
        }
        return acc;
      }, [] as string[])
      .forEach(updateNodeInternals);
  }, [debouncedZoom, updateNodeInternals]);

  return {
    zoomWatcher,
  };
};
