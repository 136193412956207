import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 18L5 21L2 20L6 16" fill="#4B4E68" />
      <path
        d="M8 18L5 21L2 20L6 16"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2931 2.70691L4.7071 13.2929C4.31658 13.6834 4.31658 14.3166 4.7071 14.7071L9.29264 19.2926C9.68316 19.6832 10.3163 19.6832 10.7068 19.2926L21.2928 8.70665C21.6834 8.31613 21.6834 7.68297 21.2928 7.29245L16.7073 2.70691C16.3168 2.31639 15.6836 2.31639 15.2931 2.70691Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
