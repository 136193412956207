import clsx from 'clsx';

import styles from './FieldRow.module.scss';

type FieldRowProps = {
  children?: React.ReactNode;
  className?: string;
  columns?: 1 | 2;
  label?: React.ReactNode;
};

export default function FieldRow({
  children,
  className,
  columns = 1,
  label,
}: FieldRowProps) {
  return (
    <div className={clsx(styles.row, styles[`columns-${columns}`])}>
      {!!label && <div className={styles.label}>{label}</div>}
      <div className={clsx(styles.field, className)}>{children}</div>
    </div>
  );
}
