import clsx from 'clsx';

import type { MediaGroupDetailDTO } from '@spaceduck/api';

import { BoardSummary } from '@components/BoardSummary';
import { ExtractBlock } from '@components/ExtractBlock';
import NotesCard from '@detailsModal/sidebar/NotesCard';
import type { ContentViewTab } from '@/types/MediaGroup';
import { EmbedView } from './EmbedView';
import { FirstMediaView } from './FirstMediaView';
import { IframeView } from './IFrameView';
import { PdfView } from './PdfView';
import { ReadingModeView } from './ReadingModeView';
import { SummaryModeView } from './SummaryModeView';
import styles from './ContentView.module.scss';

type ContentViewProps = {
  className?: string;
  mediaGroup: MediaGroupDetailDTO;
  view?: ContentViewTab | null;
};

export default function ContentView(props: ContentViewProps) {
  const { view, ...viewProps } = props;

  if (!props.mediaGroup) return null;

  if (props.mediaGroup.contentType === 'document') {
    return (
      <div className={props.className}>
        <NotesCard mediaGroup={props.mediaGroup} />
      </div>
    );
  }

  if (props.mediaGroup.contentType === 'pdf') {
    return <PdfView {...viewProps} />;
  }

  switch (view) {
    case 'iframe':
      return (
        <IframeView
          {...viewProps}
          containerClass={styles.iframeContainer}
          className={clsx(props.className, styles.frame)}
        />
      );
    case 'reading mode':
      return <ReadingModeView {...viewProps} />;
    case 'summary':
      return <SummaryModeView {...viewProps} />;
    default:
      return <GeneralView {...viewProps} />;
  }
}

const GeneralView = (viewProps: Omit<ContentViewProps, 'view'>) => {
  const { mediaGroup } = viewProps;
  const { kind, contentType } = mediaGroup;

  if (kind === 'extract') {
    return <ExtractBlock mediaGroup={mediaGroup} palette="surface3" />;
  }

  if (kind === 'bookmark') {
    if (contentType === 'audio' || contentType === 'social') {
      return <EmbedView {...viewProps} />;
    }
  }

  if (contentType === 'board') {
    return <BoardSummary {...viewProps} className={styles.board} />;
  }

  return <FirstMediaView {...viewProps} />;
};
