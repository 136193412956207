import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 8C4 8.553 4.447 9 5 9H11C11.553 9 12 8.553 12 8C12 7.447 11.553 7 11 7H5C4.447 7 4 7.447 4 8Z"
        fill={color}
      />
      <path
        d="M6 11H2V5H6C6.552 5 7 4.552 7 4C7 3.448 6.552 3 6 3H2C0.897 3 0 3.897 0 5V11C0 12.103 0.897 13 2 13H6C6.552 13 7 12.552 7 12C7 11.448 6.552 11 6 11Z"
        fill={color}
      />
      <path
        d="M14 3H10C9.448 3 9 3.448 9 4C9 4.552 9.448 5 10 5H14V11H10C9.448 11 9 11.448 9 12C9 12.552 9.448 13 10 13H14C15.103 13 16 12.103 16 11V5C16 3.897 15.103 3 14 3Z"
        fill={color}
      />
    </svg>
  );
}
