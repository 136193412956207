import type {
  AiModel,
  Chat,
  ChatMediaGroupSource,
  ChatSpaceSource,
  ChatTemporaryFileSource,
  ChatTemporaryLinkSource,
  MediaGroupDTO,
} from '@spaceduck/api';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './ResearchChat.module.scss';
import { ChatContent, SourcesPanel } from '@/pages/ai/ResearchAssistant';
import ChatMessageInput from '@/components/ai/ChatMessageInput';
import useWorkspaceId from '@/hooks/useWorkspaceId';
import { useCreateChatSession, useGetChatSession, useQueryChatSession } from '@/api/ai';
import { catchApiErrorIntoToast } from '@/api/util';
import { exists } from '@spaceduck/utils';
import { Icon24 } from '@spaceduck/icons';
import Button from '@/components/ui/Button';
import { ChatHistory } from '@/components/ai/ChatHistory';
import Spinner from '@/components/Spinner';

const { ResearchChatAI, ChatHistory: ChatHistoryIcon, Close } = Icon24;
export const ResearchChat = ({
  mediaGroup,
  addToCurrentDocument,
}: { mediaGroup: MediaGroupDTO; addToCurrentDocument: (text: string) => void }) => {
  const currentChatSessionRef = useRef<Chat>();
  const workspaceId = useWorkspaceId();
  const { mutateAsync: queryChatSession } = useQueryChatSession();
  const { mutateAsync, reset } = useCreateChatSession();
  const [chatId, setChatId] = useState<string | null>(null);
  const { data: chatSessionData, isLoading } = useGetChatSession(chatId);
  const createChatSession = catchApiErrorIntoToast(mutateAsync);
  const [showChatHistory, setShowChatHistory] = useState(false);
  const [showSources, setShowSources] = useState(false);

  useEffect(() => {
    if (chatSessionData) {
      currentChatSessionRef.current = chatSessionData.chat;
    }
  }, [chatSessionData]);
  const submitChatSession = async (
    query: string,
    mediaGroups: ChatMediaGroupSource[],
    projects: ChatSpaceSource[],
    links: ChatTemporaryLinkSource[],
    temporaryFiles: ChatTemporaryFileSource[],
    model: AiModel
  ) => {
    if (!workspaceId) {
      return;
    }
    currentChatSessionRef.current = {
      id: 'temp',
      label: query,
      mediaGroups,
      messages: [
        {
          id: '',
          pending: true,
          query,
          response: {
            parts: [],
          },
        },
      ],
      model,
    };
    try {
      const session = await createChatSession({
        workspaceId,
        model,
        projectIds: projects.map((project) => project.id),
        mediaGroupIds: mediaGroups.map((mediaGroup) => mediaGroup.id),
        links: links.map((link) => link.id).filter(exists),
        temporaryFiles: temporaryFiles.map((file) => file.id),
        initialQuery: query,
      });
      setChatId(session.chat.id);
    } catch (e) {
      currentChatSessionRef.current = undefined;
      reset();
    }
  };

  const queryChatSessionCallback = useCallback(
    (query: string) => {
      if (currentChatSessionRef.current) {
        currentChatSessionRef.current = {
          ...currentChatSessionRef.current,
          messages: [
            ...currentChatSessionRef.current.messages,
            {
              id: '',
              pending: true,
              query,
              response: {
                parts: [],
              },
            },
          ],
        };
        queryChatSession({
          chatId: currentChatSessionRef.current.id,
          query,
        });
      }
    },
    [currentChatSessionRef.current]
  );

  if (!workspaceId) {
    return;
  }

  if (isLoading) {
    return <Spinner />;
  }
  if (showChatHistory) {
    return (
      <div className={styles.chatHistory}>
        <div className={styles.header}>
          <span>Chat history</span>
          <Button
            size="sm"
            padX="2"
            variant="outlined"
            onClick={() => setShowChatHistory(false)}
          >
            <Close size={16} />
          </Button>
        </div>
        <ChatHistory
          workspaceId={workspaceId}
          onSelectChatSession={(chatId) => {
            setChatId(chatId);
            setShowChatHistory(false);
          }}
        />
      </div>
    );
  }

  if (showSources && currentChatSessionRef.current) {
    return (
      <SourcesPanel
        onClose={() => setShowSources(false)}
        sources={currentChatSessionRef.current.mediaGroups || []}
        title={currentChatSessionRef.current.label}
      />
    );
  }

  return (
    <div className={styles.researchChat}>
      <div className={styles.header}>
        <span>Research assistant</span>
        <div className={styles.actions}>
          <Button
            variant="outlined"
            size="sm"
            onClick={() => {
              currentChatSessionRef.current = undefined;
              setChatId(null);
            }}
          >
            New chat
          </Button>
          <Button
            variant="outlined"
            padX="2"
            size="sm"
            onClick={() => setShowChatHistory(true)}
          >
            <ChatHistoryIcon size={20} />
          </Button>
        </div>
      </div>
      <div className={styles.chatContent}>
        {currentChatSessionRef.current && (
          <ChatContent
            addResponseAction={addToCurrentDocument}
            expandSources={() => setShowSources(true)}
            messages={currentChatSessionRef.current.messages}
            sources={currentChatSessionRef.current?.mediaGroups}
            chatId={currentChatSessionRef.current.id}
            showSourcesCount={2}
          />
        )}
        {!currentChatSessionRef.current && (
          <div className={styles.emptyChat}>
            <ResearchChatAI />
            <p>
              Hello! I am your AI research assistant. I have access to your library and
              the web. Try asking me some questions...
            </p>
          </div>
        )}
      </div>
      <div className={styles.chatMessageInput}>
        <ChatMessageInput
          readOnly={!!currentChatSessionRef?.current}
          onSubmit={(query, ...rest) => {
            currentChatSessionRef?.current
              ? queryChatSessionCallback(query)
              : submitChatSession(query, ...rest);
          }}
          showTools={!currentChatSessionRef?.current}
          initialSources={
            !currentChatSessionRef.current
              ? [
                  {
                    id: mediaGroup.id,
                    label: mediaGroup.label,
                    type: 'library',
                  },
                ]
              : []
          }
        />
      </div>
    </div>
  );
};
