import { useMediaGroupDetail } from '@api/mediaGroup';
import { useContext } from 'react';

import { Icon24 } from '@spaceduck/icons';

import { isCapable } from '@api/util';
import { NotesContext } from '@components/detailsModal/tiptap/context/NotesContext';
import MediaGroupPreview from '@components/MediaGroupPreview';
import { getUrl } from '@detailsModal/DetailsModalSidebar';
import { useSidebarModal } from '@detailsModal/sidebar/SidebarModal';
import { useNavigateWithState } from '@hooks/useNavigateWithState';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import Button from '@ui/Button';
import DropdownMenu, { MenuItem } from '@ui/DropdownMenu';
import Tooltip from '@ui/Tooltip';
import { copyTextToClipboard } from '@utils/copyToClipboard';
import { urlFor } from '@/urls';
import styles from './CardView.module.scss';

type CardViewProps = {
  containerClassName?: string;
  mediaGroupId?: string;
  deleteNode?: () => void;
};

const { AlertInfo, Open, DrawerRight, Link, Menu, Remove } = Icon24;

export default function CardView(props: CardViewProps) {
  const { data } = useMediaGroupDetail(props.mediaGroupId ?? null);
  const { open: openSidebarModal } = useSidebarModal();
  const navigateWithState = useNavigateWithState();
  const { openDrawer, setQuickViewMediaGroupId } = useDetailsModalStore((store) => ({
    openDrawer: store.openDrawer,
    setQuickViewMediaGroupId: store.setQuickViewMediaGroupId,
  }));
  const editorContext = useContext(NotesContext);

  if (!data?.mediaGroup) return null;
  const userCanEdit = isCapable('edit', data.mediaGroup?.userCapabilities).capable;

  const mediaGroup = data.mediaGroup;
  const mediaGroupId = mediaGroup.id;

  const openInNewTab = () => {
    window.open(
      urlFor('mediaGroup', {
        mediaGroupId: mediaGroup.id,
      })
    );
  };

  const openInfoPopup = () => {
    openSidebarModal({ mediaGroupId });
  };

  const openInSidebar = () => {
    if (editorContext?.mediaGroupId) {
      const url = getUrl('browseRepository', editorContext.mediaGroupId);
      navigateWithState(url);
      openDrawer();
      setQuickViewMediaGroupId(mediaGroupId);
    }
  };

  const copyToClipboard = () => {
    copyTextToClipboard(
      new URL(
        urlFor('mediaGroup', {
          mediaGroupId,
        }),
        window.location.origin
      ).toString(),
      {
        titleText: undefined,
        bodyText: 'Content Block link copied',
      }
    );
  };

  return (
    <MediaGroupPreview
      containerClassName={styles.noPadding}
      className={styles.preview}
      mediaGroup={mediaGroup}
      menu={
        <>
          <Tooltip content="Open in new tab" align="end">
            <Button onClick={openInNewTab} variant="icon">
              <Open size={20} />
            </Button>
          </Tooltip>
          <Tooltip content="Info" align="end">
            <Button onClick={openInfoPopup} variant="icon">
              <AlertInfo size={20} />
            </Button>
          </Tooltip>
          {editorContext?.mediaGroupId && (
            <Tooltip content="Open in sidebar" align="end">
              <Button onClick={openInSidebar} variant="icon">
                <DrawerRight size={20} />
              </Button>
            </Tooltip>
          )}
          <Tooltip content="Show menu" align="end">
            <span>
              <DropdownMenu
                align="end"
                isPadded
                width={260}
                triggerContent={
                  <Button variant="icon">
                    <Menu size={20} />
                  </Button>
                }
              >
                <MenuItem iconBefore={<Link />} onSelect={copyToClipboard}>
                  Copy link
                </MenuItem>
                {userCanEdit && props.deleteNode && (
                  <MenuItem
                    iconBefore={<Remove size={20} />}
                    onSelect={() => props.deleteNode?.()}
                  >
                    Remove from document
                  </MenuItem>
                )}
              </DropdownMenu>
            </span>
          </Tooltip>
        </>
      }
    />
  );
}
