import { useMutation, useMutationState } from '@tanstack/react-query';

import { getManageSubscriptionLink } from '@spaceduck/api';
import { sleep } from '@spaceduck/utils';

import { catchApiErrorIntoToast } from '@/api/util';
import { identity } from '@/utils/functional';

const navigateToManageSubscriptionLink = async (workspaceId: string) => {
  try {
    await catchApiErrorIntoToast(async () => {
      const subscriptionLink = await getManageSubscriptionLink(workspaceId);
      window.location.href = subscriptionLink.redirectUrl;
    })();
  } catch (e) {
    console.error(e);
  }
  // Give some buffer for the redirect to actually happen
  await sleep(1000);
};

export const useManageSubscription = () => {
  const mutationKey = ['navigateToManageSubscriptionLink'];
  const isPending = useMutationState({
    filters: { mutationKey },
    select: (mutation) => mutation.state.status === 'pending',
  });
  const isAnyPending = isPending.find(identity) ?? false;
  const mutation = useMutation({
    mutationKey,
    mutationFn: async (workspaceId: string) => {
      await navigateToManageSubscriptionLink(workspaceId);
    },
  });
  return { isAnyPending, ...mutation };
};
