import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4884_44309)">
        <path
          d="M10.514 9.764L9.83284 10.6157C9.52237 11.0039 8.97128 11.1086 8.56057 10.8286C7.22894 9.92069 6.07927 8.77102 5.1714 7.43939C4.89139 7.02867 4.99607 6.47759 5.38428 6.16712L6.23596 5.486C6.41508 5.34266 6.54166 5.14403 6.59594 4.92113C6.65022 4.69824 6.62912 4.46364 6.53596 4.254L5.14296 1.117C5.04306 0.892205 4.86657 0.710194 4.64496 0.603418C4.42335 0.496642 4.17102 0.47204 3.93296 0.534L1.28096 1.221C1.0336 1.28586 0.818564 1.43908 0.676502 1.65171C0.534441 1.86435 0.475193 2.12165 0.509958 2.375C0.97525 5.68874 2.50674 8.76091 4.87289 11.1271C7.23905 13.4932 10.3112 15.0247 13.625 15.49C13.8782 15.5249 14.1355 15.4658 14.348 15.3237C14.5605 15.1815 14.7135 14.9664 14.778 14.719L15.466 12.068C15.5279 11.8299 15.5033 11.5776 15.3965 11.356C15.2898 11.1344 15.1078 10.9579 14.883 10.858L11.746 9.465C11.5364 9.37207 11.3019 9.351 11.0791 9.40508C10.8563 9.45915 10.6576 9.58533 10.514 9.764Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4884_44309">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
