export default function Logo() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1261_77429)">
        <path
          d="M11.2555 6.18025C8.77185 6.9354 6.81137 8.93158 6.06972 11.4605C5.97198 11.7941 5.52355 11.7941 5.43156 11.4605C4.68991 8.93158 2.72943 6.9354 0.245778 6.18025C-0.0819261 6.08073 -0.0819261 5.62413 0.245778 5.53047C2.72943 4.77531 4.68991 2.77914 5.43156 0.250254C5.5293 -0.083418 5.97773 -0.083418 6.06972 0.250254C6.81137 2.77914 8.77185 4.77531 11.2555 5.53047C11.5832 5.62998 11.5832 6.08659 11.2555 6.18025Z"
          fill="#F6F8FA"
        />
        <path
          d="M47.9999 29.7188C47.9999 30.6645 47.2585 31.425 46.3365 31.425H38.9512C38.5424 40.4827 30.5583 48 21.1484 48C11.7385 48 3.3266 40.0732 3.3266 30.6938V29.7968L0.218483 24.2685C-0.237755 23.4495 0.037889 22.4063 0.8268 21.9383C1.62522 21.4703 2.64225 21.7432 3.10799 22.5623L6.17809 28.0125H24.2375V18.5063C24.2375 13.2607 28.3912 9 33.5048 9C38.6185 9 42.7722 13.2607 42.7722 18.5063V21.9188C42.7722 22.8645 42.0308 23.625 41.1088 23.625C40.1868 23.625 39.4454 22.8645 39.4454 21.9188V18.5063C39.4454 15.1425 36.784 12.4125 33.5048 12.4125C30.2256 12.4125 27.5642 15.1425 27.5642 18.5063V28.0125H29.2276C30.1496 28.0125 30.891 28.773 30.891 29.7188V30.45C30.891 35.6077 26.4331 39.9563 21.1484 39.9563C17.9642 39.9563 14.8371 38.2793 12.9931 35.5688C12.4704 34.7985 12.6605 33.7358 13.4114 33.1898C14.1622 32.6535 15.1983 32.8485 15.7211 33.6188C16.9567 35.4225 19.0288 36.5438 21.1484 36.5438C24.2185 36.5438 26.9464 34.2428 27.4787 31.425H6.67235C7.10007 38.5133 13.687 44.5875 21.1484 44.5875C28.6098 44.5875 35.6434 38.094 35.6434 30.6938V29.7188C35.6434 28.773 36.3848 28.0125 37.3068 28.0125H46.3365C47.2585 28.0125 47.9999 28.773 47.9999 29.7188Z"
          fill="#F6F8FA"
        />
      </g>
      <defs>
        <clipPath id="clip0_1261_77429">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
