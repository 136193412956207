import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 5.5L3.365 14.149C3.40194 14.5191 3.57507 14.8623 3.85076 15.1119C4.12646 15.3615 4.48507 15.4999 4.857 15.5H11.143C11.5149 15.4999 11.8735 15.3615 12.1492 15.1119C12.4249 14.8623 12.5981 14.5191 12.635 14.149L13.5 5.5"
          stroke={color}
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.5 3.5H15.5" stroke={color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 3.5V0.5H10.5V3.5"
          stroke={color}
        />
      </g>
    </svg>
  );
}
