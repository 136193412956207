export function choice<T>(items: T[]): T;
export function choice<T, U>(items: T[], fallback: U): T | U;
export function choice<T, U>(items: T[], fallback?: U) {
  if (items.length === 0) {
    if (arguments.length >= 2) {
      return fallback;
    }
    throw new Error('Cannot choose from empty list without fallback');
  }
  return items[Math.floor(Math.random() * items.length)];
}
