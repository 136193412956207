import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_353_15942)">
        <path
          d="M12 23V10"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 14L12 10L16 14"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 18.777C17.6145 18.4092 19.0962 17.603 20.2821 16.4473C21.468 15.2916 22.312 13.8311 22.7214 12.2266C23.1307 10.6221 23.0894 8.93579 22.6021 7.35323C22.1148 5.77067 21.2003 4.35324 19.9593 3.25692C18.7183 2.1606 17.1989 1.4279 15.5684 1.13947C13.9378 0.851032 12.2592 1.01804 10.7175 1.62211C9.17569 2.22618 7.83045 3.2439 6.82981 4.56325C5.82917 5.88261 5.21191 7.45245 5.04598 9.1C3.83454 9.34579 2.75709 10.0316 2.02158 11.0251C1.28608 12.0186 0.944658 13.2493 1.06323 14.4798C1.18179 15.7102 1.75194 16.8531 2.66363 17.6878C3.57531 18.5226 4.7639 18.9901 5.99998 19H7.99998"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_353_15942">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
