import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 17.25C15.011 17.25 17.25 15.011 17.25 12.25C17.25 15.011 19.489 17.25 22.25 17.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.25 17.25C15.011 17.25 17.25 19.489 17.25 22.25C17.25 19.489 19.489 17.25 22.25 17.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 7.25C5.011 7.25 7.25 5.011 7.25 2.25C7.25 5.011 9.489 7.25 12.25 7.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 7.25C5.011 7.25 7.25 9.489 7.25 12.25C7.25 9.489 9.489 7.25 12.25 7.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.25 8.25C18.836 8.25 18.5 7.914 18.5 7.5C18.5 6.672 17.828 6 17 6C16.586 6 16.25 5.664 16.25 5.25C16.25 4.836 16.586 4.5 17 4.5C17.828 4.5 18.5 3.828 18.5 3C18.5 2.586 18.836 2.25 19.25 2.25C19.664 2.25 20 2.586 20 3C20 3.828 20.672 4.5 21.5 4.5C21.914 4.5 22.25 4.836 22.25 5.25C22.25 5.664 21.914 6 21.5 6C20.672 6 20 6.672 20 7.5C20 7.914 19.664 8.25 19.25 8.25Z"
        fill={color}
      />
      <path
        d="M5.25 22.25C4.836 22.25 4.5 21.914 4.5 21.5C4.5 20.672 3.828 20 3 20C2.586 20 2.25 19.664 2.25 19.25C2.25 18.836 2.586 18.5 3 18.5C3.828 18.5 4.5 17.828 4.5 17C4.5 16.586 4.836 16.25 5.25 16.25C5.664 16.25 6 16.586 6 17C6 17.828 6.672 18.5 7.5 18.5C7.914 18.5 8.25 18.836 8.25 19.25C8.25 19.664 7.914 20 7.5 20C6.672 20 6 20.672 6 21.5C6 21.914 5.664 22.25 5.25 22.25Z"
        fill={color}
      />
    </svg>
  );
}
