import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3729_42676)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.57576 4.84849C5.97742 4.84849 6.30303 5.1741 6.30303 5.57576C6.30303 6.66877 6.73723 7.71702 7.51011 8.4899C8.28298 9.26277 9.33123 9.69697 10.4242 9.69697C10.8259 9.69697 11.1515 10.0226 11.1515 10.4242C11.1515 10.8259 10.8259 11.1515 10.4242 11.1515C9.33123 11.1515 8.28298 11.5857 7.51011 12.3586C6.73723 13.1315 6.30303 14.1797 6.30303 15.2727C6.30303 15.6744 5.97742 16 5.57576 16C5.1741 16 4.84848 15.6744 4.84848 15.2727C4.84848 14.7315 4.74189 14.1956 4.53478 13.6956C4.32767 13.1956 4.0241 12.7413 3.64141 12.3586C3.25872 11.9759 2.8044 11.6723 2.30439 11.4652C1.80438 11.2581 1.26848 11.1515 0.727273 11.1515C0.325611 11.1515 0 10.8259 0 10.4242C0 10.0226 0.325611 9.69697 0.727273 9.69697C1.26848 9.69697 1.80438 9.59037 2.30439 9.38326C2.8044 9.17615 3.25872 8.87259 3.64141 8.4899C4.0241 8.10721 4.32767 7.65289 4.53478 7.15288C4.74189 6.65287 4.84848 6.11697 4.84848 5.57576C4.84848 5.1741 5.1741 4.84849 5.57576 4.84849ZM5.57575 8.32918C5.32907 8.76355 5.02462 9.16372 4.66993 9.51842C4.31523 9.87311 3.91506 10.1776 3.48068 10.4242C3.91506 10.6709 4.31523 10.9754 4.66993 11.3301C5.02462 11.6848 5.32907 12.0849 5.57575 12.5193C5.82082 12.0877 6.12448 11.6872 6.48159 11.3301C6.83868 10.973 7.23923 10.6693 7.67081 10.4242C7.23923 10.1792 6.83868 9.87551 6.48159 9.51842C6.12448 9.16131 5.82082 8.76076 5.57575 8.32918Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3637 0C12.7653 0 13.0909 0.325611 13.0909 0.727273C13.0909 1.30593 13.3208 1.86088 13.73 2.27005C14.1391 2.67922 14.6941 2.90909 15.2727 2.90909C15.6744 2.90909 16 3.2347 16 3.63636C16 4.03803 15.6744 4.36364 15.2727 4.36364C14.6941 4.36364 14.1391 4.59351 13.73 5.00268C13.3208 5.41185 13.0909 5.9668 13.0909 6.54545C13.0909 6.94712 12.7653 7.27273 12.3637 7.27273C11.962 7.27273 11.6364 6.94712 11.6364 6.54545C11.6364 5.9668 11.4065 5.41185 10.9973 5.00268C10.5882 4.59351 10.0332 4.36364 9.45457 4.36364C9.05291 4.36364 8.72729 4.03803 8.72729 3.63636C8.72729 3.2347 9.05291 2.90909 9.45457 2.90909C10.0332 2.90909 10.5882 2.67922 10.9973 2.27005C11.4065 1.86088 11.6364 1.30593 11.6364 0.727273C11.6364 0.325611 11.962 0 12.3637 0ZM12.3637 2.90912C12.261 3.04597 12.1483 3.17619 12.0259 3.29857C11.9035 3.42096 11.7733 3.53372 11.6364 3.63636C11.7733 3.73901 11.9035 3.85177 12.0259 3.97416C12.1483 4.09654 12.261 4.22675 12.3637 4.36361C12.4663 4.22675 12.5791 4.09654 12.7015 3.97416C12.8238 3.85177 12.954 3.73901 13.0909 3.63636C12.954 3.53372 12.8238 3.42096 12.7015 3.29857C12.5791 3.17619 12.4663 3.04597 12.3637 2.90912Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3729_42676">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
