import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.63647 12H19.5456"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9092 15.6364L19.5455 12L15.9092 8.36364"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7273 4.72727V3.81818C17.7273 3.33597 17.5357 2.87351 17.1947 2.53253C16.8538 2.19156 16.3913 2 15.9091 2H6.81818C6.33597 2 5.87351 2.19156 5.53253 2.53253C5.19156 2.87351 5 3.33597 5 3.81818V20.1818C5 20.664 5.19156 21.1265 5.53253 21.4675C5.87351 21.8084 6.33597 22 6.81818 22H15.9091C16.3913 22 16.8538 21.8084 17.1947 21.4675C17.5357 21.1265 17.7273 20.664 17.7273 20.1818V19.2727"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
