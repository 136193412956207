import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'var(--color-on-primary)',
  size = 48,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 48 48"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="var(--color-primary)"
        d="M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24"
      />
      <path
        fill={color}
        d="M33 24.001a.93.93 0 0 0-.3-.6l-13.5-9.75a.8.8 0 0 0-.825-.075.79.79 0 0 0-.375.675v19.5a.79.79 0 0 0 .375.675c.115.06.245.087.375.075.16-.015.313-.067.45-.15l13.5-9.75a.93.93 0 0 0 .3-.6"
      />
    </svg>
  );
}
