import type { MediaGroupDTO } from '@spaceduck/api';
import PromisePool from '@supercharge/promise-pool';

import { usePatchMediaGroup } from '@api/mediaGroup';
import createToast from '@utils/createToast';
import { pluralize } from '@spaceduck/utils';

export const useBatchUpdate = (batchItemsCount = 10) => {
  const { mutateAsync: patchMediaGroup } = usePatchMediaGroup();

  const handleStatusChange = async (
    selectedMediaGroups: Set<MediaGroupDTO>,
    status: string | null
  ) => {
    const { results, errors } = await PromisePool.for(selectedMediaGroups)
      .withConcurrency(batchItemsCount)
      .process(
        async ({ id }) =>
          await patchMediaGroup({
            mediaGroupId: id,
            patch: { status },
          })
      );

    if (results?.length > 0) {
      createToast({
        titleText: 'Status updated',
        bodyText: `Status was updated for ${results.length} ${pluralize(results.length > 1, 'entries', 'entry')}`,
        iconVariant: 'success',
      });
    }

    if (errors?.length > 0) {
      console.error('Error processing batch status update', errors);
      createToast({
        titleText: 'Failed to update status',
        bodyText: `Status was not updated for ${errors.length} ${pluralize(errors.length > 1, 'entries', 'entry')}`,
        iconVariant: 'warning',
      });
    }
  };

  const handleCategoryChange = async (
    selectedMediaGroups: Set<MediaGroupDTO> | string[],
    categoryId: string | null
  ) => {
    const entries = Array.isArray(selectedMediaGroups)
      ? new Set(selectedMediaGroups.map((id) => ({ id })))
      : selectedMediaGroups;

    const { results, errors } = await PromisePool.for(entries)
      .withConcurrency(batchItemsCount)
      .process(
        async ({ id }) =>
          await patchMediaGroup({
            mediaGroupId: id,
            patch: { categoryId },
          })
      );

    if (results?.length > 0) {
      createToast({
        titleText: 'Category updated',
        bodyText: `Category was updated for ${results.length} ${pluralize(results.length > 1, 'entries', 'entry')}`,
        iconVariant: 'success',
      });
    }

    if (errors?.length > 0) {
      console.error('Error processing batch category update', errors);
      createToast({
        titleText: 'Failed to update category',
        bodyText: `Category was not updated for ${errors.length} ${pluralize(errors.length > 1, 'entries', 'entry')}`,
        iconVariant: 'warning',
      });
    }
  };

  return {
    handleCategoryChange,
    handleStatusChange,
  };
};
