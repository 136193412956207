import clsx from 'clsx';
import type { ReactNode } from 'react';

import styles from './NoEntries.module.scss';

type NoEntriesProps = {
  children?: ReactNode;
  className?: string;
  collapsed?: boolean;
  icon?: ReactNode;
  pageType?: 'settings';
};

export default function NoEntries({
  children,
  className,
  collapsed,
  icon,
  pageType,
}: NoEntriesProps) {
  return (
    <div
      className={clsx(
        styles.container,
        pageType && styles[pageType],
        collapsed && styles.collapsed,
        className
      )}
    >
      <div className={clsx(styles.center, icon && styles.hasIcon)}>
        {!!icon && <div className={styles.icon}>{icon}</div>}
        <div className={styles.text}>{children}</div>
      </div>
    </div>
  );
}
