import type { Author } from '@spaceduck/api';
import clsx from 'clsx';

import duckSprites from '@assets/img/duck-sprite.png';
import ducklasAvatar from '@assets/img/ducklas-avatar.webp';
import SpriteSheet from '@ui/Sprite';
import styles from './UserAvatar.module.scss';

const PALETTE = [
  '#14B8A6',
  '#7375F2',
  '#0381FC',
  '#07D6F8',
  '#10B981',
  '#19E6D0',
  '#1B9754',
  '#27D878',
  '#2A75EF',
  '#4EA7FC',
  '#6366F1',
  '#8066FF',
  '#84CC16',
  '#8B5CF6',
  '#95E619',
  '#BFF075',
  '#D97706',
  '#DD5BF1',
  '#EE5DA5',
  '#F174B2',
  '#F55671',
  '#F57256',
  '#F7866E',
  '#EAB308',
  '#FFDD4D',
];

const hashString = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 6) - hash);
  }
  return hash;
};

const stringToColor = (str: string): string => {
  const color = PALETTE[Math.abs(hashString(str)) % PALETTE.length];
  return color!;
};

type AvatarSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export type UserAvatarProps = {
  className?: string;
  hasDashedBorder?: boolean;
  imageUrl?: string | null;
  isMuted?: boolean;
  name: string;
  size: AvatarSize;
};

export default function UserAvatar({
  className,
  hasDashedBorder = false,
  imageUrl,
  isMuted = false,
  name,
  size = 'md',
}: UserAvatarProps) {
  const initials = name
    .split(' ')
    .map((word) => word[0])
    .join('');

  return (
    <div
      className={clsx(
        styles.avatar,
        styles[size],
        hasDashedBorder && styles.dashedBorder,
        isMuted && styles.muted,
        className
      )}
      style={{
        color: stringToColor(name),
      }}
      title={name}
    >
      {imageUrl ? (
        <img src={imageUrl} alt={name} />
      ) : (
        <div
          className={clsx(
            {
              title6: size === 'xxs' || size === 'xs',
              title5: size === 'sm' || size === 'md',
              title4: size === 'lg' || size === 'xl' || size === 'xxl',
            },
            styles.initials
          )}
        >
          {initials}
        </div>
      )}
    </div>
  );
}

const DuckSprite = ({
  index,
  className,
}: {
  index: number;
  className?: string;
}) => (
  // SpriteSheet likely overcomplicated for our usage. Feel free to remove
  <SpriteSheet
    imgHeight={128}
    imgWidth={896}
    img={duckSprites}
    index={index}
    spriteSize={128}
    className={className}
  />
);

export function WorkspaceAvatar({
  className,
  size = 'xs',
  workspaceAvatar,
  workspaceName,
}: {
  className?: string;
  workspaceAvatar?: string | null;
  size?: AvatarSize;
  workspaceName?: string;
}) {
  if (workspaceAvatar) {
    return (
      <div className={clsx(styles.workspaceLogo, styles[size], className)}>
        <img src={workspaceAvatar} alt={workspaceName} />
      </div>
    );
  }

  return (
    <DuckSprite
      index={hashString(workspaceName ?? '🦆')}
      className={clsx(styles.workspaceLogo, styles[size], className)}
    />
  );
}

export function WorkspaceAndUserAvatar({
  userName,
  avatarUrl,
  workspaceName,
  className,
  workspaceAvatar,
}: {
  userName?: string;
  avatarUrl?: string | null;
  workspaceName?: string;
  className?: string;
  workspaceAvatar?: string | null;
}) {
  return (
    <div className={clsx(styles.companyLogo, className)}>
      <WorkspaceAvatar
        workspaceName={workspaceName}
        className={styles.textLogo}
        workspaceAvatar={workspaceAvatar}
      />
      <div className={styles.userAvatar}>
        <UserAvatar name={userName ?? '?'} size="xxs" imageUrl={avatarUrl} />
      </div>
    </div>
  );
}

export type AuthorAvatarProps = {
  author: Pick<Author, 'name' | 'avatarUrl'>;
} & Omit<UserAvatarProps, 'imageUrl' | 'name'>;

export const AuthorAvatar = ({ author, ...rest }: AuthorAvatarProps) => {
  const { avatarUrl, name } = author;
  return <UserAvatar imageUrl={avatarUrl} name={name} {...rest} />;
};

export const DucklasAvatar = (props: Omit<UserAvatarProps, 'imageUrl' | 'name'>) => {
  return <UserAvatar imageUrl={ducklasAvatar} name="Ducklas" {...props} />;
};
