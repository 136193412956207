import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" fill="#1E1E1E" />
      <g id="Icon / 16x16 / Tag">
        <rect
          width="960"
          height="2727"
          transform="translate(-648 -501)"
          fill="#010318"
        />
        <g id="Icon / 16x16 / Hand" clipPath="url(#clip0_4_34)">
          <path
            id="Vector"
            d="M13.25 2.5C13.1252 2.5 13.0028 2.51019 12.8834 2.52985C12.5839 1.63891 11.7406 0.995159 10.75 0.995159C10.5482 0.995159 10.3525 1.02189 10.1663 1.07194C9.76875 0.429063 9.05937 0 8.25 0C7.44063 0 6.72813 0.430937 6.33125 1.07625C6.14688 1.02656 5.95312 1 5.75313 1C4.5125 1 3.50031 2.00938 3.50031 3.25V7.05C3.33656 7.01875 3.16906 7 3.00031 7C3.00023 7 3.00039 7 3.00031 7C1.63844 7 0.5 8.1 0.5 9.50313C0.5 10.1372 0.736187 10.7381 1.17062 11.2047L3.70969 13.9309C4.93437 15.2469 6.66875 16 8.46562 16H9.49938C12.8094 16 15.5 13.3094 15.5 10V4.75C15.5 3.50938 14.4906 2.5 13.25 2.5ZM14 10C14 12.4816 11.9816 14.5 9.52812 14.5H8.46562C7.07781 14.5 5.75219 13.9231 4.80625 12.9075L2.31375 10.2316C2.11994 10.0235 2.01981 9.75031 2.01981 9.50969C2.01981 8.81719 2.59888 8.52844 2.9995 8.52844C3.267 8.52844 3.53387 8.63519 3.73137 8.84656L4.56637 9.74312C4.61875 9.77188 4.68437 9.79375 4.74687 9.79375C4.87519 9.79375 4.99888 9.69462 4.99888 9.54325V3.25C4.99888 2.83594 5.33481 2.5 5.72075 2.5C6.13481 2.5 6.44263 2.83594 6.44263 3.25V8C6.5 8.275 6.725 8.5 7 8.5C7.275 8.5 7.47188 8.27616 7.47188 8.02812L7.5 2.25C7.5 1.83594 7.83594 1.5 8.22188 1.5C8.63594 1.5 8.94375 1.83594 8.94375 2.25V8C9 8.275 9.225 8.5 9.5 8.5C9.775 8.5 9.99969 8.27612 9.99969 8.02812L9.99993 3.27187C9.99993 2.85781 10.3359 2.52187 10.7218 2.52187C11.1359 2.52187 11.4437 2.85781 11.4437 3.27187V8.02812C11.5 8.275 11.725 8.5 12 8.5C12.275 8.5 12.4719 8.27616 12.4719 8.02812V4.75C12.4719 4.33594 12.8078 4 13.1938 4C13.6078 4 13.9156 4.33594 13.9156 4.75V10H14Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4_34">
          <rect width="16" height="16" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
