import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3C5.448 3 5 3.448 5 4V7H2V4C2 3.448 1.552 3 1 3C0.448 3 0 3.448 0 4V12C0 12.552 0.448 13 1 13C1.552 13 2 12.552 2 12V9H5V12C5 12.552 5.448 13 6 13C6.552 13 7 12.552 7 12V4C7 3.448 6.552 3 6 3Z"
        fill={color}
      />
      <path
        d="M15.0003 9H14.0003V4C14.0003 3.56 13.7113 3.17 13.2903 3.043C12.8703 2.914 12.4133 3.079 12.1683 3.445L8.1683 9.445C7.9633 9.752 7.9443 10.146 8.1183 10.471C8.2923 10.796 8.6313 10.999 9.0003 10.999H12.0003V11.999C12.0003 12.551 12.4483 12.999 13.0003 12.999C13.5523 12.999 14.0003 12.551 14.0003 11.999V10.999H15.0003C15.5523 10.999 16.0003 10.551 16.0003 9.999C16.0003 9.447 15.5523 8.999 15.0003 8.999V9ZM12.0003 9H10.8693L12.0003 7.303V9Z"
        fill={color}
      />
    </svg>
  );
}
