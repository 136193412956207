import clsx from 'clsx';

import { Icon24 } from '@spaceduck/icons';

import Tooltip from '@ui/Tooltip';
import styles from './Row.module.scss';

type RowProps = {
  children?: React.ReactNode;
  info?: React.ReactNode;
  title: string;
};

const { AlertInfo } = Icon24;

export default function Row({ children, info, title }: RowProps) {
  return (
    <div className={styles.container}>
      <div className={clsx(styles.header, info && styles.hasInfo)}>
        {<h3>{title}</h3>}
        {info && (
          <Tooltip
            align="end"
            content={<div className={styles.infoContainer}>{info}</div>}
            variant="tertiary"
          >
            <span className={styles.info}>
              <AlertInfo size={20} />
            </span>
          </Tooltip>
        )}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
