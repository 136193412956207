import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7273 11.1111H18.3637"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7273 14.6667H18.3637"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3636 4C18.3636 4.98222 17.55 5.77778 16.5455 5.77778C15.5409 5.77778 14.7273 4.98222 14.7273 4H9.27273C9.27273 4.98222 8.45909 5.77778 7.45455 5.77778C6.45 5.77778 5.63636 4.98222 5.63636 4H3.81818C2.81364 4 2 4.79556 2 5.77778V18.2222C2 19.2044 2.81364 20 3.81818 20H20.1818C21.1864 20 22 19.2044 22 18.2222V5.77778C22 4.79556 21.1864 4 20.1818 4H18.3636Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.091 10.2222H5.63647V15.5556H11.091V10.2222Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
