import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 10.3946C21.6924 8.57187 20.8891 6.86892 19.6779 5.4724C18.4668 4.07587 16.8946 3.03966 15.1337 2.4773C13.3728 1.91494 11.491 1.84813 9.69467 2.2842C7.8983 2.72026 6.25661 3.64237 4.94949 4.94949C3.64237 6.25661 2.72026 7.8983 2.2842 9.69467C1.84813 11.491 1.91494 13.3728 2.4773 15.1337C3.03966 16.8946 4.07587 18.4668 5.4724 19.6779C6.86892 20.8891 8.57187 21.6924 10.3946 22L22 10.3946Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 10.3946C20.4141 10.1159 18.7844 10.2233 17.2488 10.7079C15.7133 11.1925 14.3171 12.04 13.1786 13.1786C12.04 14.3172 11.1925 15.7133 10.7079 17.2489C10.2233 18.7844 10.1159 20.4141 10.3946 22"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
