import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3800_26435)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.370171 1.20312C0.370171 0.814803 0.677082 0.5 1.05569 0.5H3.79776C4.17636 0.5 4.48328 0.814803 4.48328 1.20312C4.48328 1.59145 4.17636 1.90625 3.79776 1.90625H1.05569C0.677082 1.90625 0.370171 1.59145 0.370171 1.20312ZM5.94572 1.20312C5.94572 0.814803 6.25266 0.5 6.63125 0.5H9.37332C9.75191 0.5 10.0588 0.814803 10.0588 1.20312C10.0588 1.59145 9.75191 1.90625 9.37332 1.90625H6.63125C6.25266 1.90625 5.94572 1.59145 5.94572 1.20312ZM12.3896 0.5C12.011 0.5 11.7041 0.814803 11.7041 1.20312C11.7041 1.59145 12.011 1.90625 12.3896 1.90625H15.1317C15.5103 1.90625 15.8172 1.59145 15.8172 1.20312C15.8172 0.814803 15.5103 0.5 15.1317 0.5H12.3896ZM0 7.76562C0 7.32556 0.347832 6.96875 0.776921 6.96875H15.2086C15.6377 6.96875 15.9856 7.32556 15.9856 7.76562C15.9856 8.20569 15.6377 8.5625 15.2086 8.5625H0.776921C0.347832 8.5625 0 8.20569 0 7.76562ZM1.14709 13.1562C0.516095 13.1562 0.00456107 13.6809 0.00456107 14.3281C0.00456107 14.9754 0.516095 15.5 1.14709 15.5H14.8575C15.4884 15.5 16 14.9754 16 14.3281C16 13.6809 15.4884 13.1562 14.8575 13.1562H1.14709Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3800_26435">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
