import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.25 1.25V7.25H21.25L15.25 1.25Z" fill={color} />
      <path
        d="M15.25 1.25V7.25H21.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.25 1.25H3.25V23.25H21.25V7.25L15.25 1.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5657 20.393V14.9763H18.9999V16.0396H16.7817V17.1504H18.7813V18.2163H16.7817V20.393H15.5657Z"
        fill={color}
      />
      <path
        d="M12.3722 20.393H10.5126V14.9763H12.3697C12.8823 14.9763 13.3237 15.0848 13.6938 15.3016C14.0655 15.5168 14.3521 15.8271 14.5536 16.2326C14.755 16.6364 14.8557 17.1195 14.8557 17.682C14.8557 18.2462 14.755 18.7311 14.5536 19.1367C14.3538 19.5422 14.068 19.8534 13.6962 20.0703C13.3245 20.2854 12.8831 20.393 12.3722 20.393ZM11.7286 19.2769H12.3255C12.6072 19.2769 12.8455 19.2257 13.0404 19.1235C13.2369 19.0194 13.3851 18.851 13.485 18.6183C13.5865 18.3838 13.6373 18.0717 13.6373 17.682C13.6373 17.2923 13.5865 16.982 13.485 16.751C13.3835 16.5183 13.2336 16.3508 13.0354 16.2485C12.8389 16.1445 12.5965 16.0924 12.3083 16.0924H11.7286V19.2769Z"
        fill={color}
      />
      <path
        d="M6 20.393V14.9763H8.07823C8.4549 14.9763 8.77998 15.0557 9.05348 15.2144C9.32861 15.3713 9.54069 15.5908 9.68972 15.8729C9.83875 16.1533 9.91327 16.4795 9.91327 16.8515C9.91327 17.2253 9.83712 17.5524 9.68481 17.8328C9.53414 18.1114 9.31879 18.3274 9.03874 18.4808C8.75869 18.6342 8.42624 18.7109 8.04138 18.7109H6.75907V17.6794H7.81538C7.9988 17.6794 8.15193 17.645 8.27476 17.5762C8.39922 17.5074 8.49339 17.4113 8.55726 17.2879C8.62113 17.1627 8.65306 17.0173 8.65306 16.8515C8.65306 16.684 8.62113 16.5394 8.55726 16.4178C8.49339 16.2943 8.39922 16.1991 8.27476 16.1321C8.15029 16.0651 7.99717 16.0316 7.81538 16.0316H7.21599V20.393H6Z"
        fill={color}
      />
    </svg>
  );
}
