import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 1.5H2C1.17157 1.5 0.5 2.17157 0.5 3V13C0.5 13.8284 1.17157 14.5 2 14.5H14C14.8284 14.5 15.5 13.8284 15.5 13V3C15.5 2.17157 14.8284 1.5 14 1.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 1.5V14.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11 8H13" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 5H13" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 11H13" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
