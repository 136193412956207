import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4855 1.8087C7.80219 1.61869 8.19781 1.61869 8.5145 1.8087L14.0708 5.14251C14.7182 5.53091 14.7182 6.46909 14.0708 6.85749L8.5145 10.1913C8.19781 10.3813 7.80219 10.3813 7.4855 10.1913L1.92915 6.85749C1.28182 6.46909 1.28182 5.53091 1.92915 5.14251L7.4855 1.8087Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 9.10001L14.0708 9.14251C14.7182 9.53092 14.7182 10.4691 14.0708 10.8575L8.5145 14.1913C8.19781 14.3813 7.80219 14.3813 7.4855 14.1913L1.92916 10.8575C1.28182 10.4691 1.28182 9.53092 1.92915 9.14251L2 9.10001"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
