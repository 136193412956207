import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_669_34884)">
        <path
          d="M24 12.072C23.9997 9.7788 23.3424 7.53369 22.1058 5.60247C20.8692 3.67125 19.1051 2.13479 17.0225 1.175C14.9398 0.215205 12.6257 -0.127732 10.3542 0.186789C8.08263 0.501309 5.94878 1.46011 4.20524 2.94969C2.4617 4.43927 1.18149 6.39725 0.516158 8.59181C-0.149172 10.7864 -0.171753 13.1256 0.451088 15.3326C1.07393 17.5396 2.31611 19.5219 4.03057 21.0449C5.74503 22.5679 7.85996 23.5677 10.125 23.926V15.541H7.078V12.072H10.125V9.428C10.125 6.421 11.917 4.759 14.657 4.759C15.5571 4.77191 16.4552 4.85011 17.344 4.993V7.947H15.83C15.3742 7.8865 14.9131 8.00947 14.548 8.28888C14.1829 8.56829 13.9437 8.98127 13.883 9.437C13.8658 9.56462 13.8632 9.69377 13.875 9.822V12.072H17.2L16.668 15.541H13.868V23.926C16.692 23.481 19.2644 22.0423 21.1219 19.8691C22.9795 17.6959 24.0001 14.9309 24 12.072Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_669_34884">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
