import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2186_3115)">
        <path
          d="M8 9.5C9.38071 9.5 10.5 8.38071 10.5 7C10.5 5.61929 9.38071 4.5 8 4.5C6.61929 4.5 5.5 5.61929 5.5 7C5.5 8.38071 6.61929 9.5 8 9.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.69094 10.75L0.529941 14.35C0.4548 14.4748 0.417281 14.6187 0.421887 14.7643C0.426494 14.91 0.473031 15.0512 0.555912 15.171C0.638793 15.2908 0.754496 15.3842 0.889134 15.4399C1.02377 15.4956 1.17162 15.5113 1.31494 15.485L7.99994 14.271L14.6859 15.487C14.8293 15.5133 14.9771 15.4976 15.1117 15.4419C15.2464 15.3862 15.3621 15.2928 15.445 15.173C15.5279 15.0532 15.5744 14.912 15.579 14.7663C15.5836 14.6207 15.5461 14.4768 15.4709 14.352L13.3099 10.752"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 13.5C11.5899 13.5 14.5 10.5899 14.5 7C14.5 3.41015 11.5899 0.5 8 0.5C4.41015 0.5 1.5 3.41015 1.5 7C1.5 10.5899 4.41015 13.5 8 13.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2186_3115">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
