import type { MediaGroupDTO } from '@spaceduck/api';
import { Icon24 } from '@spaceduck/icons';
import clsx from 'clsx';
import styles from './ExtractBlock.module.scss';

const { Highlight, QuoteBottom, QuoteTop } = Icon24;

type ExtractBlockPalette = 'surface3' | 'surface2';

export type ExtractBlockComponentProps = {
  className?: string;
  isQuote: boolean;
  isVerticallyCentered?: boolean;
  onDoubleClick?: () => void;
  palette: ExtractBlockPalette;
  text: string;
};

export const ExtractBlockComponent = ({
  className,
  isQuote,
  isVerticallyCentered,
  onDoubleClick,
  palette,
  text,
}: ExtractBlockComponentProps) => {
  return (
    <div
      className={clsx(
        styles.extractBlock,
        styles[palette],
        isVerticallyCentered && styles.verticallyCentered,
        isQuote && styles.quote,
        className
      )}
      onDoubleClick={onDoubleClick}
    >
      <div className={clsx(styles.icon, 'icon')}>
        {isQuote ? <QuoteTop /> : <Highlight />}
      </div>
      <div className={clsx(styles.text, 'text')}>{text}</div>
      {isQuote && (
        <div className={clsx(styles.icon, 'icon')}>
          <QuoteBottom />
        </div>
      )}
    </div>
  );
};

export type ExtractBlockProps = Omit<ExtractBlockComponentProps, 'isQuote' | 'text'> & {
  mediaGroup: MediaGroupDTO;
};

export const ExtractBlock = ({ mediaGroup, ...props }: ExtractBlockProps) => {
  const { contentType, text } = mediaGroup;
  return (
    <ExtractBlockComponent isQuote={contentType === 'quote'} text={text} {...props} />
  );
};
