import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps & { active?: boolean }) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3758_30976)">
        <path
          d="M4.71763 2.92768C3.93185 3.34488 3.28794 3.9978 2.87532 4.79252C2.4627 3.9978 1.81879 3.34488 1.03301 2.92768C1.81879 2.51047 2.4627 1.85756 2.87532 1.06283C3.28794 1.85756 3.93185 2.51047 4.71763 2.92768Z"
          fill={color}
          stroke={color}
        />
        <path
          d="M19.4756 15.2125H18.9976L18.9761 15.69C18.7843 19.9413 15.0224 23.5 10.5742 23.5C6.12688 23.5 2.1633 19.7423 2.1633 15.3469V14.8984V14.7675L2.09914 14.6533L0.54604 11.8909C0.545914 11.8907 0.545788 11.8905 0.545662 11.8902C0.446826 11.712 0.512682 11.4925 0.667374 11.3998C0.822678 11.3096 1.02216 11.3582 1.11895 11.5276C1.11908 11.5278 1.11922 11.5281 1.11936 11.5283L2.6534 14.2516L2.79682 14.5063H3.08904H12.1187H12.6187V14.0063V9.25313C12.6187 6.89444 14.4836 5 16.7524 5C19.0212 5 20.8861 6.89444 20.8861 9.25313V10.9594C20.8861 11.1682 20.7273 11.3125 20.5544 11.3125C20.3815 11.3125 20.2227 11.1682 20.2227 10.9594V9.25313C20.2227 7.30718 18.6801 5.70625 16.7524 5.70625C14.8247 5.70625 13.2821 7.30718 13.2821 9.25313V14.0063V14.5063H13.7821H14.6138C14.7867 14.5063 14.9455 14.6506 14.9455 14.8594V15.225C14.9455 17.5162 12.9521 19.4781 10.5742 19.4781C9.15268 19.4781 7.74198 18.7258 6.91028 17.5036C6.80189 17.3439 6.8407 17.1164 6.99796 17.0006C7.13922 16.9011 7.33968 16.9323 7.44681 17.0902L7.44804 17.092C8.15491 18.1238 9.34221 18.7719 10.5742 18.7719C12.3446 18.7719 13.919 17.4552 14.2307 15.8053L14.3426 15.2125H13.7393H3.33617H2.80509L2.83708 15.7426C3.0677 19.5644 6.59304 22.7938 10.5742 22.7938C14.555 22.7938 18.3217 19.3485 18.3217 15.3469V14.8594C18.3217 14.6506 18.4805 14.5063 18.6534 14.5063H23.1683C23.3412 14.5063 23.4999 14.6506 23.4999 14.8594C23.4999 15.0682 23.3412 15.2125 23.1683 15.2125H19.4756Z"
          fill={color}
          stroke={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3758_30976">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
