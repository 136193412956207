import { useContext } from 'react';
import { useReactFlow } from '@xyflow/react';

import { useBoardStore } from './useBoardStore';
import { useHistory } from './useHistory';
import { useNodes } from './useNodes';
import { BoardStoreContext } from '../context/boardContext';
import {
  useCopyHotkey,
  useCutHotkey,
  useDuplicateHotkey,
  usePasteHotkey,
  useRedoHotkey,
  useSelectAllHotkey,
  useUndoHotkey,
} from '@/hooks/useShortcuts';

export const useBoardShortKeys = () => {
  const boardContext = useContext(BoardStoreContext);
  const clipboardItems = useBoardStore(({ clipboardItems }) => clipboardItems);
  const selectedNodes = useBoardStore(({ selectedNodes }) => selectedNodes);
  const setSelectedEdges = useBoardStore(({ setSelectedEdges }) => setSelectedEdges);
  const setSelectedNodes = useBoardStore(({ setSelectedNodes }) => setSelectedNodes);

  const { cutNodes, copyNodes, duplicateNode, pasteNodes } = useNodes();
  const { getEdges, getNodes, setEdges, setNodes } = useReactFlow();
  const { pause, resume, redo, undo } = useHistory();

  useUndoHotkey(
    (ev) => {
      if (ev.shiftKey) return;
      undo();
    },
    {
      preventDefault: true,
    },
    [boardContext]
  );

  useRedoHotkey(
    () => {
      redo();
    },
    {
      preventDefault: true,
    },
    [boardContext]
  );

  usePasteHotkey(
    () => {
      pasteNodes?.();
    },
    {
      enabled: !!clipboardItems,
    },
    {
      preventDefault: true,
    }
  );

  useCopyHotkey(
    () => {
      if (selectedNodes.length) {
        copyNodes();
      }
    },
    {
      enabled: selectedNodes.length > 0,
      preventDefault: true,
    }
  );

  useCutHotkey(
    () => {
      if (selectedNodes.length) {
        cutNodes();
      }
    },
    {
      enabled: selectedNodes.length > 0,
      preventDefault: true,
    }
  );

  useDuplicateHotkey(
    () => {
      if (selectedNodes.length === 1) {
        const id = selectedNodes[0];
        if (id) {
          duplicateNode?.(id);
        }
      }
    },
    {
      enabled: selectedNodes.length === 1,
      preventDefault: true,
    }
  );

  useSelectAllHotkey(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();

      pause();
      setNodes((nodes) => nodes.map((node) => ({ ...node, selected: true })));
      setEdges((edges) => edges.map((edge) => ({ ...edge, selected: true })));
      setSelectedEdges(getEdges().map((edge) => edge.id));
      setSelectedNodes(getNodes().map((node) => node.id));

      Promise.resolve().then(() => {
        resume();
      });
    },
    {
      preventDefault: true,
    },
    []
  );

  return {};
};
