import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6674 4.18499C11.4271 4.43163 11.4271 4.83153 11.6674 5.07817L13.899 7.36841H0.615386C0.275515 7.36841 0 7.65117 0 7.99998C0 8.34881 0.275515 8.63156 0.615386 8.63156H13.899L11.6674 10.9218C11.4271 11.1685 11.4271 11.5683 11.6674 11.815C11.9078 12.0617 12.2974 12.0617 12.5377 11.815L15.8197 8.44653C16.0601 8.19996 16.0601 7.80003 15.8197 7.55339L12.5377 4.18499C12.2974 3.93834 11.9078 3.93834 11.6674 4.18499Z"
        fill={color}
      />
    </svg>
  );
}
