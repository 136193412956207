export const addClass = (newClassName: string, existingClassName?: string | null) => {
  if (!existingClassName) return newClassName;

  const classNameArr = existingClassName.split(' ').map((name) => name.trim());
  if (!existingClassName.includes(newClassName)) {
    classNameArr.push(newClassName);
  }

  return classNameArr.join(' ');
};
