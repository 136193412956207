import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.02645 10.3367C4.2877 10.5999 4.28487 11.0238 4.02012 11.2835L1.93148 13.3324L2.64839 13.5668L4.5108 11.7398C4.77555 11.4801 5.20197 11.4829 5.46323 11.7461C5.72449 12.0093 5.72165 12.4332 5.4569 12.6929L3.30172 14.8071C3.12198 14.9834 2.85812 15.0449 2.61824 14.9665L0.46306 14.2618C0.240124 14.1889 0.0714374 14.0059 0.0178235 13.7787C-0.0357904 13.5516 0.0334005 13.3131 0.200448 13.1493L3.07402 10.3304C3.33877 10.0707 3.76519 10.0735 4.02645 10.3367Z"
        fill={color}
      />
      <path
        d="M12.1673 2.35089L7.85718 6.57898L9.29373 7.98818L13.6039 3.76009L12.1673 2.35089Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.63086 9.86746C1.36611 9.60775 1.36611 9.18668 1.63086 8.92697L10.2517 0.470297C10.5165 0.210588 10.9457 0.210587 11.2105 0.470297L15.5206 4.69838C15.7854 4.95809 15.7854 5.37917 15.5206 5.63888L6.89978 14.0956C6.63503 14.3553 6.20578 14.3553 5.94103 14.0956L1.63086 9.86746ZM3.06899 9.39722L6.42041 12.6848L14.0825 5.16863L10.7311 1.88104L3.06899 9.39722Z"
        fill={color}
      />
    </svg>
  );
}
