import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 7C19.8011 7 19.6103 6.92098 19.4697 6.78033C19.329 6.63968 19.25 6.44891 19.25 6.25C19.25 5.85218 19.092 5.47064 18.8107 5.18934C18.5294 4.90804 18.1478 4.75 17.75 4.75C17.5511 4.75 17.3603 4.67098 17.2197 4.53033C17.079 4.38968 17 4.19891 17 4C17 3.80109 17.079 3.61032 17.2197 3.46967C17.3603 3.32902 17.5511 3.25 17.75 3.25C18.1478 3.25 18.5294 3.09196 18.8107 2.81066C19.092 2.52936 19.25 2.14782 19.25 1.75C19.25 1.55109 19.329 1.36032 19.4697 1.21967C19.6103 1.07902 19.8011 1 20 1C20.1989 1 20.3897 1.07902 20.5303 1.21967C20.671 1.36032 20.75 1.55109 20.75 1.75C20.75 2.14782 20.908 2.52936 21.1893 2.81066C21.4706 3.09196 21.8522 3.25 22.25 3.25C22.4489 3.25 22.6397 3.32902 22.7803 3.46967C22.921 3.61032 23 3.80109 23 4C23 4.19891 22.921 4.38968 22.7803 4.53033C22.6397 4.67098 22.4489 4.75 22.25 4.75C21.8522 4.75 21.4706 4.90804 21.1893 5.18934C20.908 5.47064 20.75 5.85218 20.75 6.25C20.75 6.44891 20.671 6.63968 20.5303 6.78033C20.3897 6.92098 20.1989 7 20 7Z"
        fill={color}
      />
      <path
        d="M17 23C16.7348 23 16.4804 22.8946 16.2929 22.7071C16.1054 22.5196 16 22.2652 16 22C16 20.9391 15.5786 19.9217 14.8284 19.1716C14.0783 18.4214 13.0609 18 12 18C11.7348 18 11.4804 17.8946 11.2929 17.7071C11.1054 17.5196 11 17.2652 11 17C11 16.7348 11.1054 16.4804 11.2929 16.2929C11.4804 16.1054 11.7348 16 12 16C13.0609 16 14.0783 15.5786 14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12C16 11.7348 16.1054 11.4804 16.2929 11.2929C16.4804 11.1054 16.7348 11 17 11C17.2652 11 17.5196 11.1054 17.7071 11.2929C17.8946 11.4804 18 11.7348 18 12C18 13.0609 18.4214 14.0783 19.1716 14.8284C19.9217 15.5786 20.9391 16 22 16C22.2652 16 22.5196 16.1054 22.7071 16.2929C22.8946 16.4804 23 16.7348 23 17C23 17.2652 22.8946 17.5196 22.7071 17.7071C22.5196 17.8946 22.2652 18 22 18C20.9391 18 19.9217 18.4214 19.1716 19.1716C18.4214 19.9217 18 20.9391 18 22C18 22.2652 17.8946 22.5196 17.7071 22.7071C17.5196 22.8946 17.2652 23 17 23Z"
        fill={color}
      />
      <path
        d="M7 13C6.73478 13 6.48043 12.8946 6.29289 12.7071C6.10536 12.5196 6 12.2652 6 12C6 10.9391 5.57857 9.92172 4.82843 9.17157C4.07828 8.42143 3.06087 8 2 8C1.73478 8 1.48043 7.89464 1.29289 7.70711C1.10536 7.51957 1 7.26522 1 7C1 6.73478 1.10536 6.48043 1.29289 6.29289C1.48043 6.10536 1.73478 6 2 6C3.06087 6 4.07828 5.57857 4.82843 4.82843C5.57857 4.07828 6 3.06087 6 2C6 1.73478 6.10536 1.48043 6.29289 1.29289C6.48043 1.10536 6.73478 1 7 1C7.26522 1 7.51957 1.10536 7.70711 1.29289C7.89464 1.48043 8 1.73478 8 2C8 3.06087 8.42143 4.07828 9.17157 4.82843C9.92172 5.57857 10.9391 6 12 6C12.2652 6 12.5196 6.10536 12.7071 6.29289C12.8946 6.48043 13 6.73478 13 7C13 7.26522 12.8946 7.51957 12.7071 7.70711C12.5196 7.89464 12.2652 8 12 8C10.9391 8 9.92172 8.42143 9.17157 9.17157C8.42143 9.92172 8 10.9391 8 12C8 12.2652 7.89464 12.5196 7.70711 12.7071C7.51957 12.8946 7.26522 13 7 13Z"
        fill={color}
      />
    </svg>
  );
}
