import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3816_1969)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.70104 2.40939L7.85303 4.2574C7.53709 4.57333 7.02487 4.57333 6.70893 4.2574C6.393 3.94147 6.393 3.42924 6.70893 3.11331L8.56759 1.25465C9.39179 0.450243 10.4979 0 11.6496 0C12.8012 0 13.9073 0.450286 14.7315 1.2547L14.7454 1.2686C15.5498 2.0928 16.0001 3.19884 16.0001 4.35053C16.0001 5.50221 15.5498 6.60826 14.7454 7.43246L14.7385 7.43949L12.8868 9.29115C12.5708 9.60709 12.0586 9.60709 11.7427 9.29115C11.4268 8.97522 11.4268 8.46299 11.7427 8.14706L13.5907 6.29906C14.0981 5.77743 14.3821 5.07837 14.3821 4.35053C14.3821 3.62443 14.0995 2.92697 13.5943 2.40575C13.0731 1.90061 12.3757 1.61799 11.6496 1.61799C10.9217 1.61799 10.2227 1.90196 9.70104 2.40939Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.2574 6.70887C4.57333 7.02481 4.57333 7.53703 4.2574 7.85297L2.40939 9.70097C1.90196 10.2226 1.61799 10.9217 1.61799 11.6495C1.61799 12.3756 1.90061 13.073 2.40575 13.5943C2.92697 14.0994 3.62443 14.382 4.35053 14.382C5.07837 14.382 5.77743 14.0981 6.29906 13.5906L8.14706 11.7426C8.46299 11.4267 8.97522 11.4267 9.29115 11.7426C9.60709 12.0586 9.60709 12.5708 9.29115 12.8867L7.4325 14.7454C6.6083 15.5498 5.50221 16 4.35053 16C3.19884 16 2.0928 15.5497 1.2686 14.7453L1.2547 14.7314C0.450286 13.9072 0 12.8012 0 11.6495C0 10.4978 0.450286 9.39177 1.2547 8.56757L1.26156 8.56053L3.11331 6.70887C3.42924 6.39294 3.94147 6.39294 4.2574 6.70887Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4485 4.55152C11.7645 4.86746 11.7645 5.37969 11.4485 5.69562L5.69568 11.4485C5.37975 11.7644 4.86752 11.7644 4.55159 11.4485C4.23565 11.1325 4.23565 10.6203 4.55159 10.3044L10.3044 4.55152C10.6204 4.23559 11.1326 4.23559 11.4485 4.55152Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3816_1969">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
