import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 64 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 64 64"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.9999 33.8C25.0411 33.8 19.3999 28.1588 19.3999 21.2V17.6C19.3999 10.6412 25.0411 5 31.9999 5C38.9587 5 44.5999 10.6412 44.5999 17.6V21.2C44.5999 28.1588 38.9587 33.8 31.9999 33.8Z"
        stroke="#7A7E9F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.6 42.8H21.2C12.2531 42.8 5 50.0532 5 59.0001C5 59.0001 57.1496 59.0001 57.2 59.0001"
        stroke="#4B4E68"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.7998 35.6001V50.0001"
        stroke="#B5B7CA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.6001 42.8H59.0001"
        stroke="#B5B7CA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
