import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3772_7687)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7631 0.236947C16.079 0.552877 16.079 1.0651 15.7631 1.38103L10.0103 7.13384C9.69437 7.44977 9.18215 7.44977 8.86622 7.13384C8.55029 6.81791 8.55029 6.30569 8.86622 5.98976L14.619 0.236947C14.935 -0.0789824 15.4472 -0.0789824 15.7631 0.236947Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.62927 0.808989C8.62927 0.362197 8.99147 0 9.43826 0H15.1911C15.6379 0 16.0001 0.362197 16.0001 0.808989V6.5618C16.0001 7.00859 15.6379 7.37079 15.1911 7.37079C14.7443 7.37079 14.3821 7.00859 14.3821 6.5618V1.61798H9.43826C8.99147 1.61798 8.62927 1.25578 8.62927 0.808989Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.13384 8.86616C7.44977 9.18209 7.44977 9.69431 7.13384 10.0102L1.38103 15.7631C1.0651 16.079 0.552877 16.079 0.236947 15.7631C-0.0789824 15.4471 -0.0789824 14.9349 0.236947 14.619L5.98976 8.86616C6.30569 8.55023 6.81791 8.55023 7.13384 8.86616Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.808989 8.62921C1.25578 8.62921 1.61798 8.99141 1.61798 9.4382V14.382H6.5618C7.00859 14.382 7.37079 14.7442 7.37079 15.191C7.37079 15.6378 7.00859 16 6.5618 16H0.808989C0.362197 16 0 15.6378 0 15.191V9.4382C0 8.99141 0.362197 8.62921 0.808989 8.62921Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3772_7687">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
