import { Icon16, Icon24 } from '@spaceduck/icons';
import type { Editor } from '@tiptap/react';

import { css } from '@lib/css';
import ScrollArea from '@ui/ScrollArea';
import Button from './Button';
import styles from './FormatMenu.module.scss';
import type { MenuView } from '../DocumentWrapper';

const { Bold, Italics, Heading1, Heading2, Heading3, Underline, Strikethrough, Quote } =
  Icon16;

const { LeftArrow } = Icon24;

export default function FormatMenu({
  editor,
  setView,
}: {
  editor: Editor;
  setView: React.Dispatch<React.SetStateAction<MenuView>>;
}) {
  return (
    <div className={styles.formatMenu}>
      <div className={styles.back}>
        <Button
          onClick={() => {
            editor.chain().focus();
            setView('blockMenu');
          }}
        >
          <LeftArrow size={20} />
        </Button>
      </div>
      <ScrollArea
        orientation="horizontal"
        style={css({
          height: '100%',
          maxWidth: 'calc(100% - var(--size-14))',
        })}
      >
        <div className={styles.scrollable}>
          <Button
            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
            isActive={editor.isActive('heading', { level: 1 })}
          >
            <Heading1 size={20} />
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            isActive={editor.isActive('heading', { level: 2 })}
          >
            <Heading2 size={20} />
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
            isActive={editor.isActive('heading', { level: 3 })}
          >
            <Heading3 size={20} />
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleBold().run()}
            isActive={editor.isActive('bold')}
          >
            <Bold size={20} />
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            isActive={editor.isActive('italic')}
          >
            <Italics size={20} />
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            isActive={editor.isActive('underline')}
          >
            <Underline size={20} />
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleStrike().run()}
            isActive={editor.isActive('strike')}
          >
            <Strikethrough size={20} />
          </Button>
          <Button
            onClick={() => {
              editor.chain().focus().toggleBlockquote().run();
            }}
            isActive={editor.isActive('blockquote')}
          >
            <Quote size={20} />
          </Button>
        </div>
      </ScrollArea>
    </div>
  );
}
