import { getUrl } from '@detailsModal/DetailsModalSidebar';
import { useNavigateWithState } from '@hooks/useNavigateWithState';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';

export const useCardUtils = () => {
  const navigateWithState = useNavigateWithState();
  const { openDrawer, setQuickViewMediaGroupId } = useDetailsModalStore((store) => ({
    openDrawer: store.openDrawer,
    setQuickViewMediaGroupId: store.setQuickViewMediaGroupId,
  }));

  const openCardInSidebar = ({
    cardMediaGroupId,
    boardMediaGroupId,
  }: {
    cardMediaGroupId?: string;
    boardMediaGroupId?: string;
  }) => {
    if (!(cardMediaGroupId && boardMediaGroupId)) return;

    const url = getUrl('browseRepository', boardMediaGroupId);
    navigateWithState(url);
    openDrawer();
    setQuickViewMediaGroupId(cardMediaGroupId);
  };

  return {
    openCardInSidebar,
  };
};
