import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25 3.25V15.25C1.25 15.7804 1.46071 16.2891 1.83579 16.6642C2.21086 17.0393 2.71957 17.25 3.25 17.25H8.25L12.25 22.25L16.25 17.25H21.25C21.7804 17.25 22.2891 17.0393 22.6642 16.6642C23.0393 16.2891 23.25 15.7804 23.25 15.25V3.25C23.25 2.71957 23.0393 2.21086 22.6642 1.83579C22.2891 1.46071 21.7804 1.25 21.25 1.25H3.25C2.71957 1.25 2.21086 1.46071 1.83579 1.83579C1.46071 2.21086 1.25 2.71957 1.25 3.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.25 7.25V11.25"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.25 5.25V13.25"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 8.25V10.25"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.25 6.25V12.25"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
