import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1987_42090)">
        <path
          d="M8 0.5V4.5"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 9.5V12.5"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 11H5.5"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 10.5C11 10.7761 11.2239 11 11.5 11C11.7761 11 12 10.7761 12 10.5C12 10.2239 11.7761 10 11.5 10C11.2239 10 11 10.2239 11 10.5Z"
          fill={color}
        />
        <path
          d="M13 11.5C13 11.7761 13.2239 12 13.5 12C13.7761 12 14 11.7761 14 11.5C14 11.2239 13.7761 11 13.5 11C13.2239 11 13 11.2239 13 11.5Z"
          fill={color}
        />
        <path
          d="M12 7.50001H4.00001C3.37252 7.49513 2.75526 7.65904 2.21286 7.97457C1.67046 8.2901 1.22286 8.74565 0.916926 9.29352C0.610993 9.84139 0.45798 10.4614 0.473906 11.0887C0.489832 11.716 0.674113 12.3275 1.00745 12.8592C1.34079 13.3908 1.81093 13.8231 2.36865 14.1107C2.92636 14.3983 3.55114 14.5306 4.17757 14.4939C4.804 14.4572 5.40904 14.2527 5.92934 13.902C6.44963 13.5512 6.86606 13.067 7.13501 12.5H8.86501C9.13527 13.0625 9.55147 13.5422 10.0702 13.8892C10.5889 14.2361 11.1912 14.4375 11.8143 14.4725C12.4374 14.5075 13.0585 14.3747 13.6128 14.088C14.1671 13.8013 14.6343 13.3711 14.9658 12.8424C15.2974 12.3137 15.481 11.7057 15.4976 11.0819C15.5142 10.458 15.3632 9.84116 15.0603 9.29554C14.7574 8.74991 14.3137 8.29552 13.7754 7.97972C13.2372 7.66391 12.6241 7.49826 12 7.50001Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1987_42090">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
