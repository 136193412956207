import clsx from 'clsx';

import type { MediaGroupDetailDTO } from '@spaceduck/api';

import styles from './IFrameView.module.scss';

const NotAvailable = () => (
  <div className={styles.pane}>
    <div className={styles.content}>
      <div>No URL is associated with this media group, so no iframe can be shown.</div>
    </div>
  </div>
);

export const IframeView = ({
  className,
  containerClass,
  mediaGroup,
}: {
  className?: string;
  containerClass?: string;
  mediaGroup: MediaGroupDetailDTO;
}) => {
  if (!mediaGroup.linkUrl) {
    return <NotAvailable />;
  }

  return (
    <div className={clsx(styles.container, containerClass)}>
      <iframe
        className={clsx(styles.frame, className)}
        src={mediaGroup.linkUrl}
        referrerPolicy="no-referrer"
      />
    </div>
  );
};
