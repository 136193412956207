import type { IconProps } from '../types/IconProps';

export default function Icon({ className, color = '#5957C2', size = 32 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M14 2.667a.667.667 0 0 0-.667.666v24a.667.667 0 1 0 1.334 0v-24A.666.666 0 0 0 14 2.667M10 8.667a.667.667 0 0 0-.667.666v13.334a.667.667 0 1 0 1.334 0V9.333A.666.666 0 0 0 10 8.667M6 12.667a.667.667 0 0 0-.667.666v5.334a.667.667 0 1 0 1.334 0v-5.334A.667.667 0 0 0 6 12.667M2 14a.666.666 0 0 0-.667.667v2.666a.667.667 0 1 0 1.334 0v-2.666A.667.667 0 0 0 2 14M18 4a.667.667 0 0 0-.667.667v24a.667.667 0 0 0 1.334 0v-24A.666.666 0 0 0 18 4M22 8.667a.667.667 0 0 0-.667.666v13.334a.667.667 0 1 0 1.334 0V9.333A.666.666 0 0 0 22 8.667M26 12.667a.667.667 0 0 0-.667.666v5.334a.667.667 0 1 0 1.334 0v-5.334a.666.666 0 0 0-.667-.666M30 14a.667.667 0 0 0-.667.667v2.666a.667.667 0 1 0 1.334 0v-2.666A.666.666 0 0 0 30 14"
      />
    </svg>
  );
}
