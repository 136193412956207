import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3726_38325)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.97937 0.742268C1.97937 0.332325 2.31169 0 2.72164 0H13.2783C13.6883 0 14.0206 0.332325 14.0206 0.742268V5.36082C14.0206 5.77077 13.6883 6.10309 13.2783 6.10309H2.72164C2.31169 6.10309 1.97937 5.77077 1.97937 5.36082V0.742268ZM3.46391 1.48454V4.61856H12.5361V1.48454H3.46391Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.97937 10.6392C1.97937 10.2292 2.31169 9.8969 2.72164 9.8969H13.2783C13.6883 9.8969 14.0206 10.2292 14.0206 10.6392V15.2577C14.0206 15.6677 13.6883 16 13.2783 16H2.72164C2.31169 16 1.97937 15.6677 1.97937 15.2577V10.6392ZM3.46391 11.3814V14.5155H12.5361V11.3814H3.46391Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8C0 7.59005 0.332325 7.25773 0.742268 7.25773H15.2577C15.6677 7.25773 16 7.59005 16 8C16 8.40994 15.6677 8.74226 15.2577 8.74226H0.742268C0.332325 8.74226 0 8.40994 0 8Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3726_38325">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
