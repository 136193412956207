import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9093 3.23388L13.0522 1.48387C12.9074 1.18725 12.61 1 12.2852 1H3.7148C3.38998 1 3.09345 1.18725 2.94775 1.48387L2.09071 3.23388C1.87902 3.66613 2.05043 4.192 2.47381 4.40812C2.8989 4.62512 3.41227 4.44837 3.62396 4.017L4.24445 2.75087H7.14296V13.25H5.42888C4.9558 13.25 4.57184 13.642 4.57184 14.125C4.57184 14.608 4.9558 15 5.42888 15H10.5711C11.0442 15 11.4282 14.608 11.4282 14.125C11.4282 13.642 11.0442 13.25 10.5711 13.25H8.85704V2.75H11.7555L12.376 4.01612C12.526 4.32325 12.8286 4.5 13.144 4.5C13.2725 4.5 13.4036 4.47025 13.5262 4.40812C13.9496 4.192 14.121 3.66613 13.9093 3.23388Z"
        fill={color}
      />
    </svg>
  );
}
