import styles from './Card.module.scss';

export default function Card({
  button,
  children,
}: {
  button?: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <div className={styles.card}>
      <div className={styles.content}>{children}</div>
      {!!button && <div className={styles.button}>{button}</div>}
    </div>
  );
}

export const CardGrid = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.cardGrid}>{children}</div>;
};
