import type { MediaGroupContentType } from '@spaceduck/api';

export type DetailsModelTab =
  | 'aiSettings'
  | 'comment'
  | 'info'
  | 'browseRepository'
  | 'references'
  | 'tableOfContent'
  | 'researchChat'
  | 'activity';

export type ContentViewTab =
  | 'embed'
  | 'iframe'
  | 'pdf'
  | 'reading mode'
  | 'screenshot'
  | 'summary';

export const articleContentViews = [
  'screenshot',
  'reading mode',
  'summary',
] as const satisfies ReadonlyArray<ContentViewTab>;
export const bookmarkContentViews = [
  'screenshot',
  'summary',
] as const satisfies ReadonlyArray<ContentViewTab>;
export const socialContentViews = [
  'screenshot',
  'embed',
  'summary',
] as const satisfies ReadonlyArray<ContentViewTab>;
export const wikiContentViews = [
  'screenshot',
  'iframe',
  'summary',
] as const satisfies ReadonlyArray<ContentViewTab>;

export type ArticleContentView = (typeof articleContentViews)[number];
export type BookmarkContentView = (typeof bookmarkContentViews)[number];
export type SocialContentView = (typeof socialContentViews)[number];
export type WikiContentView = (typeof wikiContentViews)[number];

export const getContentView = (contentType: MediaGroupContentType) => {
  switch (contentType) {
    case 'article':
      return articleContentViews;
    case 'bookmark':
      return bookmarkContentViews;
    case 'wiki':
      return wikiContentViews;
    default:
      return null;
  }
};
