import { type Connection, type Edge, MarkerType, useReactFlow } from '@xyflow/react';
import { upperFirst } from 'lodash';

import { useBoardStore } from './useBoardStore';
import { connectionLineColorsAsHex } from '../types/colors';
import { edgeWidthAsNumber } from '../types/board';

export const useEdges = () => {
  const defaultConnectionLineColor = useBoardStore(
    ({ defaultConnectionLineColor }) => defaultConnectionLineColor
  );
  const defaultConnectionLineStyle = useBoardStore(
    ({ defaultConnectionLineStyle }) => defaultConnectionLineStyle
  );
  const defaultConnectionLineType = useBoardStore(
    ({ defaultConnectionLineType }) => defaultConnectionLineType
  );
  const defaultConnectionLineWidth = useBoardStore(
    ({ defaultConnectionLineWidth }) => defaultConnectionLineWidth
  );
  const defaultMarkerStartType = useBoardStore(
    ({ defaultMarkerStartType }) => defaultMarkerStartType
  );
  const defaultMarkerEndType = useBoardStore(
    ({ defaultMarkerEndType }) => defaultMarkerEndType
  );
  const onEdgesChange = useBoardStore(({ onEdgesChange }) => onEdgesChange);
  const selectedEdges = useBoardStore(({ selectedEdges }) => selectedEdges);
  const setSelectedEdges = useBoardStore(({ setSelectedEdges }) => setSelectedEdges);

  const { addEdges } = useReactFlow();

  const onConnect = async (params: Connection | Edge) => {
    if (params.source === params.target) return;

    const newEdge = {
      ...params,
      id: `${params.sourceHandle}->${params.targetHandle}`,
      type: 'baseEdge',
      data: {
        color: defaultConnectionLineColor,
        style: defaultConnectionLineStyle,
        type: defaultConnectionLineType,
        width: defaultConnectionLineWidth,
      },
      label: null,
      markerStart:
        defaultMarkerStartType === 'arrow'
          ? {
              type: MarkerType.Arrow,
              color: connectionLineColorsAsHex[defaultConnectionLineColor],
              strokeWidth: edgeWidthAsNumber[defaultConnectionLineWidth],
            }
          : defaultMarkerStartType
            ? `${defaultMarkerStartType}${upperFirst(defaultConnectionLineColor)}`
            : undefined,
      markerEnd:
        defaultMarkerEndType === 'arrow'
          ? {
              type: MarkerType.Arrow,
              color: connectionLineColorsAsHex[defaultConnectionLineColor],
              strokeWidth: edgeWidthAsNumber[defaultConnectionLineWidth],
            }
          : defaultMarkerEndType
            ? `${defaultMarkerEndType}${upperFirst(defaultConnectionLineColor)}`
            : undefined,
    };

    addEdges([newEdge]);
  };

  return {
    onConnect,
    onEdgesChange,
    selectedEdges,
    setSelectedEdges,
  };
};
