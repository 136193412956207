import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 20C19.6935 20 20.8381 19.5538 21.682 18.7595C22.5259 17.9652 23 16.888 23 15.7647C23 14.6414 22.5259 13.5642 21.682 12.7699C20.8381 11.9756 19.6935 11.5294 18.5 11.5294C18.479 11.5294 18.46 11.5351 18.44 11.5351C18.9248 9.39819 19.7395 7.33966 20.858 5.42494C20.9834 5.22793 21.028 4.99456 20.9833 4.76878C20.9386 4.54301 20.8077 4.3404 20.6153 4.19913C20.4229 4.05786 20.1823 3.98767 19.9387 4.00178C19.6952 4.0159 19.4655 4.11334 19.293 4.27576C19.077 4.47905 14 9.32611 14 15.2941C14.0063 15.3474 14.0177 15.4 14.034 15.4513C14.025 15.5567 14 15.6574 14 15.7647C14.0013 16.8876 14.4759 17.9641 15.3195 18.7581C16.1631 19.5521 17.3069 19.9988 18.5 20Z"
        fill={color}
      />
      <path
        d="M0.999997 15.7647C0.999997 16.6024 1.26392 17.4212 1.75838 18.1177C2.25285 18.8142 2.95566 19.357 3.77792 19.6776C4.60019 19.9982 5.50499 20.082 6.3779 19.9186C7.25082 19.7552 8.05264 19.3518 8.68198 18.7595C9.31131 18.1672 9.7399 17.4125 9.91353 16.591C10.0872 15.7694 9.99805 14.9178 9.65746 14.1439C9.31686 13.37 8.74008 12.7086 8.00006 12.2432C7.26004 11.7778 6.39001 11.5294 5.5 11.5294C5.479 11.5294 5.46 11.5351 5.44 11.5351C5.92483 9.39819 6.73947 7.33965 7.858 5.42493C7.98342 5.22793 8.02798 4.99456 7.98328 4.76878C7.93859 4.54301 7.80772 4.3404 7.61533 4.19913C7.42294 4.05786 7.1823 3.98767 6.93874 4.00178C6.69519 4.0159 6.46552 4.11334 6.293 4.27576C6.077 4.47905 0.999996 9.32611 0.999997 15.2941C1.00631 15.3474 1.01769 15.4 1.034 15.4513C1.025 15.5567 0.999997 15.6574 0.999997 15.7647Z"
        fill={color}
      />
    </svg>
  );
}
