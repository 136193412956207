import { useContext } from 'react';

import styles from './MenuPlaceholder.module.scss';
import { BoardStoreContext } from '../context/boardContext';

export function MenuPlaceholder() {
  const boardContext = useContext(BoardStoreContext);

  if (!boardContext?.floatingUIEdgeMenu) return;

  return (
    <div
      className={styles.bound}
      ref={boardContext.floatingUIEdgeMenu.refs.setReference}
    />
  );
}
