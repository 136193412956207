import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3749_15373)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 1.18774C0 0.807912 0.332325 0.5 0.742268 0.5H15.2577C15.6677 0.5 16 0.807912 16 1.18774V8.66472C16 9.04455 15.6677 9.35246 15.2577 9.35246H0.742268C0.332325 9.35246 0 9.04455 0 8.66472V1.18774ZM1.48454 1.87548V7.97698H14.5155V1.87548H1.48454Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 14.7623C0 14.3549 0.166162 14.0246 0.371134 14.0246H7.62887C7.83384 14.0246 8 14.3549 8 14.7623C8 15.1697 7.83384 15.5 7.62887 15.5H0.371134C0.166162 15.5 0 15.1697 0 14.7623Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 11.8115C0 11.4041 0.332325 11.0738 0.742268 11.0738H15.2577C15.6677 11.0738 16 11.4041 16 11.8115C16 12.2189 15.6677 12.5492 15.2577 12.5492H0.742268C0.332325 12.5492 0 12.2189 0 11.8115Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3749_15373">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
