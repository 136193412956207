import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

const WorkspaceLayout = () => {
  const { workspaceId } = useParams();
  const { setSessionWorkspaceId } = useWorkspaceStore();

  useEffect(() => {
    setSessionWorkspaceId(workspaceId ?? null);
  }, [workspaceId]);

  return <Outlet />;
};

export default WorkspaceLayout;
