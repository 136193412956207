import type { MediaGroupDetailDTO } from '@spaceduck/api';

type ViewName = 'media' | 'reading' | 'iframe' | 'embed' | 'summary';

export const getSupportedViews = ({
  kind,
  contentType,
}: Pick<MediaGroupDetailDTO, 'kind' | 'contentType'>) => {
  const views = new Set<ViewName>();

  if (kind === 'bookmark' && contentType !== 'video') {
    if (contentType !== 'audio') {
      views.add('media');
    }
    if (contentType === 'article') {
      views.add('reading');
    }
    if (contentType === 'wiki') {
      views.add('iframe');
    }
    if (contentType === 'social' || contentType === 'audio') {
      views.add('embed');
    }
    if (contentType !== 'audio') {
      views.add('summary');
    }
  } else {
    views.add('media');
  }

  return views;
};

export const getDefaultView = (
  mediaGroup: Pick<MediaGroupDetailDTO, 'kind' | 'contentType'>
) => {
  const views = getSupportedViews(mediaGroup);
  if (views.has('embed')) {
    return 'embed';
  }
  return null;
};
