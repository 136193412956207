import { useState } from 'react';
import { upperFirst } from 'lodash';

import type { MediaDTO, MediaGroupDetailDTO } from '@spaceduck/api';

import { ContentView } from '@components/icons';
import { IframeView } from '@detailsModal/contentView/IFrameView';
import { SummaryModeView } from '@detailsModal/contentView/SummaryModeView';
import { ReadingModeView } from '@detailsModal/contentView/ReadingModeView';
import { EmbedView } from '@detailsModal/contentView/EmbedView';
import TabButton, { TabButtonWrapper } from '@ui/TabButton';
import {
  articleContentViews,
  type ArticleContentView,
  bookmarkContentViews,
  type BookmarkContentView,
  wikiContentViews,
  type WikiContentView,
} from '@/types/MediaGroup';
import styles from './PreviewCard.module.scss';
import clsx from 'clsx';

type PreviewCardProps = {
  firstMedia: MediaDTO;
  mediaGroup: MediaGroupDetailDTO;
  onDoubleClick?: () => void;
};

export default function PreviewCard(props: PreviewCardProps) {
  switch (props.mediaGroup.contentType) {
    case 'bookmark':
      return <Bookmark {...props} />;
    case 'social':
      return <Embedded {...props} />;
    case 'audio':
      return <Embedded {...props} allowPointerEvents />;
    case 'wiki':
      return <Wiki {...props} />;
    default:
      return <Article {...props} />;
  }
}

const Article = ({
  activeTab = 'screenshot',
  firstMedia,
  mediaGroup,
  onDoubleClick,
}: PreviewCardProps & {
  activeTab?: ArticleContentView;
}) => {
  const [view, setView] = useState<ArticleContentView>(activeTab);

  return (
    <div className={styles.container}>
      <div className={styles.viewSelection}>
        <TabButtonWrapper className={styles.tabButtonWrapper} variant="dark">
          {articleContentViews.map((contentView) => {
            return (
              <TabButton
                isActive={view === contentView}
                key={`article${upperFirst(contentView)}`}
                onClick={() => setView(contentView)}
                variant="dark"
              >
                <ContentView view={contentView} />
                {upperFirst(contentView)}
              </TabButton>
            );
          })}
        </TabButtonWrapper>
      </div>
      <div className={styles.scrollArea}>
        <ArticleView
          media={firstMedia}
          mediaGroup={mediaGroup}
          onDoubleClick={onDoubleClick}
          view={view}
        />
      </div>
    </div>
  );
};

const ArticleView = ({
  media,
  mediaGroup,
  onDoubleClick,
  view,
}: {
  media: MediaDTO;
  mediaGroup: MediaGroupDetailDTO;
  onDoubleClick?: () => void;
  view: ArticleContentView;
}) => {
  const { assetName, assetUrl } = media;

  if (view === 'reading mode') {
    return <ReadingModeView className={styles.readingMode} mediaGroup={mediaGroup} />;
  }

  if (view === 'summary') {
    return <SummaryModeView isPreviewCardView mediaGroup={mediaGroup} />;
  }

  return (
    <img
      className={styles.image}
      src={assetUrl}
      alt={assetName}
      onDoubleClick={onDoubleClick}
    />
  );
};

const Bookmark = ({
  activeTab = 'screenshot',
  firstMedia,
  mediaGroup,
  onDoubleClick,
}: PreviewCardProps & {
  activeTab?: BookmarkContentView;
}) => {
  const [view, setView] = useState<BookmarkContentView>(activeTab);

  return (
    <div className={styles.container}>
      <div className={styles.viewSelection}>
        <TabButtonWrapper className={styles.tabButtonWrapper} variant="dark">
          {bookmarkContentViews.map((contentView) => {
            return (
              <TabButton
                isActive={view === contentView}
                key={`bookmark${upperFirst(contentView)}`}
                onClick={() => setView(contentView)}
                variant="dark"
              >
                <ContentView view={contentView} />
                {upperFirst(contentView)}
              </TabButton>
            );
          })}
        </TabButtonWrapper>
      </div>
      <div className={styles.scrollArea}>
        <BookmarkView
          media={firstMedia}
          mediaGroup={mediaGroup}
          onDoubleClick={onDoubleClick}
          view={view}
        />
      </div>
    </div>
  );
};

const BookmarkView = ({
  media,
  mediaGroup,
  onDoubleClick,
  view,
}: {
  media: MediaDTO;
  mediaGroup: MediaGroupDetailDTO;
  onDoubleClick?: () => void;
  view: ArticleContentView;
}) => {
  const { assetName, assetUrl } = media;

  if (view === 'summary') {
    return <SummaryModeView isPreviewCardView mediaGroup={mediaGroup} />;
  }

  return (
    <img
      className={styles.image}
      src={assetUrl}
      alt={assetName}
      onDoubleClick={onDoubleClick}
    />
  );
};

const Embedded = ({
  mediaGroup,
  onDoubleClick,
  allowPointerEvents = false,
}: PreviewCardProps & {
  allowPointerEvents?: boolean;
}) => {
  return (
    <div className={styles.container} onDoubleClick={onDoubleClick}>
      <div className={styles.scrollArea}>
        <EmbedView
          className={styles.noPadding}
          containerClassName={clsx(
            styles.embed,
            !allowPointerEvents && styles.disallowPointerEvents
          )}
          mediaGroup={mediaGroup}
        />
      </div>
    </div>
  );
};

const Wiki = ({
  activeTab = 'screenshot',
  firstMedia,
  mediaGroup,
  onDoubleClick,
}: PreviewCardProps & {
  activeTab?: WikiContentView;
}) => {
  const [view, setView] = useState<WikiContentView>(activeTab);

  return (
    <div className={styles.container}>
      <div className={styles.viewSelection}>
        <TabButtonWrapper className={styles.tabButtonWrapper} variant="dark">
          {wikiContentViews.map((contentView) => {
            return (
              <TabButton
                isActive={view === contentView}
                key={`wiki${upperFirst(contentView)}`}
                onClick={() => setView(contentView)}
                variant="dark"
              >
                <ContentView view={contentView} />
                {upperFirst(contentView)}
              </TabButton>
            );
          })}
        </TabButtonWrapper>
      </div>
      <div className={styles.scrollArea}>
        <WikiView
          media={firstMedia}
          mediaGroup={mediaGroup}
          onDoubleClick={onDoubleClick}
          view={view}
        />
      </div>
    </div>
  );
};

const WikiView = ({
  media,
  mediaGroup,
  onDoubleClick,
  view,
}: {
  media: MediaDTO;
  mediaGroup: MediaGroupDetailDTO;
  onDoubleClick?: () => void;
  view: WikiContentView;
}) => {
  const { assetName, assetUrl } = media;

  if (view === 'iframe') {
    return <IframeView className={styles.iframe} mediaGroup={mediaGroup} />;
  }

  if (view === 'summary') {
    return <SummaryModeView isPreviewCardView mediaGroup={mediaGroup} />;
  }

  return (
    <img
      className={styles.image}
      src={assetUrl}
      alt={assetName}
      onDoubleClick={onDoubleClick}
    />
  );
};
