import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3895_7012)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.00012207 0.888931C0.00012207 0.398011 0.332447 4.19617e-05 0.74239 4.19617e-05H15.2579C15.6678 4.19617e-05 16.0001 0.398011 16.0001 0.888931V15.1112C16.0001 15.6021 15.6678 16 15.2579 16H0.74239C0.332447 16 0.00012207 15.6021 0.00012207 15.1112V0.888931ZM1.48466 1.77782V14.2223H14.5156V1.77782H1.48466Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.29895 5.23465C3.29895 4.8051 3.63127 4.45687 4.04122 4.45687H11.9587C12.3687 4.45687 12.701 4.8051 12.701 5.23465C12.701 5.66421 12.3687 6.01243 11.9587 6.01243H4.04122C3.63127 6.01243 3.29895 5.66421 3.29895 5.23465Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.29895 8.00004C3.29895 7.57049 3.63127 7.22226 4.04122 7.22226H11.9587C12.3687 7.22226 12.701 7.57049 12.701 8.00004C12.701 8.4296 12.3687 8.77782 11.9587 8.77782H4.04122C3.63127 8.77782 3.29895 8.4296 3.29895 8.00004Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.29895 10.7654C3.29895 10.3359 3.63127 9.98766 4.04122 9.98766H7.34019C7.75013 9.98766 8.08245 10.3359 8.08245 10.7654C8.08245 11.195 7.75013 11.5432 7.34019 11.5432H4.04122C3.63127 11.5432 3.29895 11.195 3.29895 10.7654Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3895_7012">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
