import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1902_18390)">
        <path
          d="M19 9H5V18H19V9Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 14.75C12.6904 14.75 13.25 14.1904 13.25 13.5C13.25 12.8096 12.6904 12.25 12 12.25C11.3096 12.25 10.75 12.8096 10.75 13.5C10.75 14.1904 11.3096 14.75 12 14.75Z"
          fill={color}
        />
        <path
          d="M16 9V5C16 3.93913 15.5786 2.92172 14.8284 2.17157C14.0783 1.42143 13.0609 1 12 1C10.9391 1 9.92172 1.42143 9.17157 2.17157C8.42143 2.92172 8 3.93913 8 5V9"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 24C7.82843 24 8.5 23.3284 8.5 22.5C8.5 21.6716 7.82843 21 7 21C6.17157 21 5.5 21.6716 5.5 22.5C5.5 23.3284 6.17157 24 7 24Z"
          fill={color}
        />
        <path
          d="M12 24C12.8284 24 13.5 23.3284 13.5 22.5C13.5 21.6716 12.8284 21 12 21C11.1716 21 10.5 21.6716 10.5 22.5C10.5 23.3284 11.1716 24 12 24Z"
          fill={color}
        />
        <path
          d="M17 24C17.8284 24 18.5 23.3284 18.5 22.5C18.5 21.6716 17.8284 21 17 21C16.1716 21 15.5 21.6716 15.5 22.5C15.5 23.3284 16.1716 24 17 24Z"
          fill={color}
        />
        <path
          d="M22 24C22.8284 24 23.5 23.3284 23.5 22.5C23.5 21.6716 22.8284 21 22 21C21.1716 21 20.5 21.6716 20.5 22.5C20.5 23.3284 21.1716 24 22 24Z"
          fill={color}
        />
        <path
          d="M2 24C2.82843 24 3.5 23.3284 3.5 22.5C3.5 21.6716 2.82843 21 2 21C1.17157 21 0.5 21.6716 0.5 22.5C0.5 23.3284 1.17157 24 2 24Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1902_18390">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
