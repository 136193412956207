import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.37303 13.183C1.13046 12.8362 1.00037 12.4232 1.00037 12C1.00037 11.5768 1.13046 11.1638 1.37303 10.817C2.94603 8.59 6.81903 4 12 4C17.181 4 21.054 8.59 22.627 10.817C22.8696 11.1638 22.9997 11.5768 22.9997 12C22.9997 12.4232 22.8696 12.8362 22.627 13.183C21.054 15.41 17.181 20 12 20C6.81903 20 2.94603 15.41 1.37303 13.183Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 22L22 2"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
