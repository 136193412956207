import type { MediaGroupDetailDTO } from '@spaceduck/api';

import { isCapable } from '@api/util';
import DetailsModalNotesContent from '@components/detailsModal/DetailsModalNotesContent';
import { useNotesEditor } from '@hooks/useNotesEditor';
import styles from './NotesCard.module.scss';

export default function NotesCard({
  editable = true,
  mediaGroup,
}: {
  editable?: boolean;
  mediaGroup?: MediaGroupDetailDTO;
}) {
  const userCanEdit = isCapable('edit', mediaGroup?.userCapabilities).capable;
  const { editor, handleImageUploadInputChange, imageUploadInputRef } = useNotesEditor(
    mediaGroup,
    {
      attributes: { 'data-media-group-id': mediaGroup?.id ?? '' },
      editable: () => editable && userCanEdit,
    },
    true
  );

  if (!mediaGroup) return null;

  return (
    <DetailsModalNotesContent
      asQuickView={true}
      className={styles.notesContent}
      editable={editable}
      editor={editor}
      handleImageUploadInputChange={handleImageUploadInputChange}
      imageUploadInputRef={imageUploadInputRef}
      mediaGroupId={mediaGroup.id}
    />
  );
}
