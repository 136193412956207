import { useCallback, useEffect, useRef, useState } from 'react';

const DEFAULT_DELAY = 250;

export const useThrottledAction = (
  action: () => void | Promise<void>,
  delay?: number
) => {
  const [isThrottled, setIsThrottled] = useState(false);
  const ref = useRef({ isThrottled: false, action, delay });

  useEffect(() => {
    ref.current = { ...ref.current, action };
  }, [action]);
  useEffect(() => {
    ref.current = { ...ref.current, delay };
  }, [delay]);
  useEffect(() => {
    ref.current = { ...ref.current, isThrottled };
  }, [isThrottled]);

  const throttledAction = useCallback(() => {
    const { isThrottled, delay, action } = ref.current;
    if (isThrottled) {
      return false;
    }
    setIsThrottled(true);
    setTimeout(() => setIsThrottled(false), delay ?? DEFAULT_DELAY);
    action();
    return true;
  }, []);

  return { action: throttledAction, isThrottled };
};
