import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
  active,
  activeColor = '#EAB308',
}: IconProps & { active?: boolean; activeColor?: string }) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.48901L15.09 8.75101L22 9.75501L17 14.629L18.18 21.511L12 18.261L5.82 21.511L7 14.629L2 9.75501L8.91 8.75101L12 2.48901Z"
        fill={active ? activeColor : undefined}
        stroke={active ? activeColor : color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
