import type { BillingPlan, WorkspaceUsage } from '@spaceduck/api';
import clsx from 'clsx';
import upperFirst from 'lodash/upperFirst';

import { useGetSubscriptionInfo } from '@api/billing';
import { useUsageModal } from '@components/UsageModal';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Button from '@ui/Button';
import styles from './PlanUsage.module.scss';
import { ProgressBar } from './ui/ProgressBar';

const UNPAID_PLANS = new Set(['starter']);

const calcLimit = (usageList: WorkspaceUsage[] | undefined) => {
  if (usageList === undefined) {
    return null;
  }

  const used = usageList
    .filter(
      (
        usage
      ): usage is Pick<typeof usage, 'currentUsage' | 'limitName'> & {
        planLimit: number;
      } => !!usage.planLimit
    )
    .map((usage) => usage.currentUsage / usage.planLimit);

  if (used.length === 0) {
    return null;
  }
  return Math.max(...used);
};

const getPlanDisplayName = (plan: BillingPlan) => {
  switch (plan) {
    case 'starter':
      return 'free';
  }
  return plan;
};

const getLabel = (plan: BillingPlan, limit: number) => {
  const planLabel = upperFirst(getPlanDisplayName(plan));

  if (limit < 1) {
    return `${planLabel} account limits`;
  }

  return `${planLabel} account limits reached`;
};

export default function PlanUsage(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) {
  const { className, onClick, ...buttonProps } = props;
  const workspaceId = useWorkspaceId();
  const { data, status } = useGetSubscriptionInfo(workspaceId);
  const { open } = useUsageModal();

  const limit = calcLimit(data?.usage);

  if (
    status === 'error' ||
    // Don't want to flash in/out for paid users, so no loading indicator
    status === 'pending' ||
    limit === null ||
    // Never show on paid plans
    !UNPAID_PLANS.has(data.plan)
  ) {
    return null;
  }

  return (
    <div className={styles.planUsage}>
      <span className={styles.label}>{getLabel(data.plan, limit)}</span>
      <ProgressBar value={limit} />
      <Button
        className={clsx(
          styles.button,
          styles[`plan${upperFirst(data.plan)}`],
          className
        )}
        onClick={(ev) => {
          onClick ? onClick(ev) : open();
        }}
        variant={limit === 1 ? 'primary' : 'outlined'}
        size="xs"
        {...buttonProps}
      >
        Upgrade now
      </Button>
    </div>
  );
}
