import type { IconProps } from '../types/IconProps';

export default function Icon({ className, color = '#157EFB', size = 32 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.3333 31.3333H4.66659C3.92992 31.3333 3.33325 30.7366 3.33325 30V1.99996C3.33325 1.26329 3.92992 0.666626 4.66659 0.666626H27.3333C28.0699 0.666626 28.6666 1.26329 28.6666 1.99996V30C28.6666 30.7366 28.0699 31.3333 27.3333 31.3333Z"
        fill={color}
      />
      <path
        d="M19.3332 0.666626H12.6665V13.3333C12.6665 14.4379 13.5619 15.3333 14.6665 15.3333H17.3332C18.4377 15.3333 19.3332 14.4379 19.3332 13.3333V0.666626Z"
        fill="#010318"
      />
      <path d="M18 0.666626H16V1.99996H18V0.666626Z" fill="#B5B7CA" />
      <path d="M16 2H14V3.33333H16V2Z" fill="#B5B7CA" />
      <path d="M18 3.33325H16V4.66659H18V3.33325Z" fill="#B5B7CA" />
      <path d="M16 4.66663H14V5.99996H16V4.66663Z" fill="#B5B7CA" />
      <path d="M18 6H16V7.33333H18V6Z" fill="#B5B7CA" />
      <path d="M16 7.33325H14V8.66659H16V7.33325Z" fill="#B5B7CA" />
      <path d="M18 8.66663H16V9.99996H18V8.66663Z" fill="#B5B7CA" />
      <path d="M16 10H14V11.3333H16V10Z" fill="#B5B7CA" />
      <path d="M18 11.3333H16V12.6666H18V11.3333Z" fill="#B5B7CA" />
      <path d="M16 12.6666H14V14H16V12.6666Z" fill="#B5B7CA" />
      <path
        d="M14 15.3333V18.6666C14 19.7713 14.8953 20.6666 16 20.6666C17.1047 20.6666 18 19.7713 18 18.6666V15.3333H14ZM16.6667 18.6666H15.3333V17.3333H16.6667V18.6666Z"
        fill="#F0F1F4"
      />
    </svg>
  );
}
