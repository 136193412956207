import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.982C14.937 2.982 15.285 2.993 16.445 3.046C17.1424 3.05412 17.8332 3.18233 18.487 3.425C18.965 3.60111 19.3973 3.88237 19.752 4.248C20.1176 4.60269 20.3989 5.035 20.575 5.513C20.8177 6.16685 20.9459 6.85762 20.954 7.555C21.007 8.715 21.018 9.063 21.018 12C21.018 14.937 21.007 15.285 20.954 16.445C20.9459 17.1424 20.8177 17.8332 20.575 18.487C20.3919 18.9615 20.1116 19.3924 19.752 19.752C19.3924 20.1116 18.9615 20.3919 18.487 20.575C17.8332 20.8177 17.1424 20.9459 16.445 20.954C15.285 21.007 14.937 21.018 12 21.018C9.063 21.018 8.715 21.007 7.555 20.954C6.85762 20.9459 6.16685 20.8177 5.513 20.575C5.035 20.3989 4.60269 20.1176 4.248 19.752C3.88237 19.3973 3.60111 18.965 3.425 18.487C3.18233 17.8332 3.05412 17.1424 3.046 16.445C2.993 15.285 2.982 14.937 2.982 12C2.982 9.063 2.993 8.715 3.046 7.555C3.05412 6.85762 3.18233 6.16685 3.425 5.513C3.60111 5.035 3.88237 4.60269 4.248 4.248C4.60269 3.88237 5.035 3.60111 5.513 3.425C6.16685 3.18233 6.85762 3.05412 7.555 3.046C8.715 2.993 9.063 2.982 12 2.982ZM12 1C9.013 1 8.638 1.013 7.465 1.066C6.55258 1.08486 5.6499 1.25762 4.795 1.577C4.06355 1.86017 3.3994 2.29319 2.84521 2.84824C2.29102 3.40329 1.85904 4.06811 1.577 4.8C1.25762 5.6549 1.08486 6.55758 1.066 7.47C1.013 8.638 1 9.013 1 12C1 14.987 1.013 15.362 1.066 16.535C1.08486 17.4474 1.25762 18.3501 1.577 19.205C1.86017 19.9365 2.29319 20.6006 2.84824 21.1548C3.40329 21.709 4.06811 22.141 4.8 22.423C5.6549 22.7424 6.55758 22.9151 7.47 22.934C8.638 22.987 9.013 23 12 23C14.987 23 15.362 22.987 16.535 22.934C17.4474 22.9151 18.3501 22.7424 19.205 22.423C19.9365 22.1398 20.6006 21.7068 21.1548 21.1518C21.709 20.5967 22.141 19.9319 22.423 19.2C22.7424 18.3451 22.9151 17.4424 22.934 16.53C22.987 15.362 23 14.987 23 12C23 9.013 22.987 8.638 22.934 7.465C22.9151 6.55258 22.7424 5.6499 22.423 4.795C22.1398 4.06355 21.7068 3.3994 21.1518 2.84521C20.5967 2.29102 19.9319 1.85904 19.2 1.577C18.3451 1.25762 17.4424 1.08486 16.53 1.066C15.362 1.013 14.987 1 12 1Z"
        fill={color}
      />
      <path
        d="M12 6.351C10.8827 6.351 9.79051 6.68231 8.86154 7.30303C7.93256 7.92375 7.20852 8.806 6.78096 9.83822C6.3534 10.8704 6.24153 12.0063 6.4595 13.1021C6.67747 14.1979 7.21548 15.2044 8.00551 15.9944C8.79554 16.7845 9.80209 17.3225 10.8979 17.5405C11.9937 17.7584 13.1295 17.6466 14.1617 17.219C15.194 16.7914 16.0762 16.0674 16.6969 15.1384C17.3176 14.2094 17.649 13.1173 17.649 12C17.649 10.5018 17.0538 9.06495 15.9944 8.00555C14.935 6.94616 13.4982 6.351 12 6.351ZM12 15.667C11.2747 15.667 10.5657 15.4519 9.96268 15.049C9.35964 14.6461 8.88964 14.0734 8.61209 13.4033C8.33454 12.7332 8.26192 11.9959 8.40341 11.2846C8.54491 10.5733 8.89416 9.91988 9.40699 9.40704C9.91983 8.8942 10.5732 8.54495 11.2846 8.40346C11.9959 8.26197 12.7332 8.33459 13.4033 8.61213C14.0733 8.88968 14.646 9.35969 15.049 9.96272C15.4519 10.5658 15.667 11.2747 15.667 12C15.667 12.9725 15.2806 13.9053 14.5929 14.593C13.9052 15.2807 12.9725 15.667 12 15.667Z"
        fill={color}
      />
      <path
        d="M17.872 7.448C18.601 7.448 19.192 6.85701 19.192 6.128C19.192 5.39898 18.601 4.808 17.872 4.808C17.143 4.808 16.552 5.39898 16.552 6.128C16.552 6.85701 17.143 7.448 17.872 7.448Z"
        fill={color}
      />
    </svg>
  );
}
