import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1818 21.1818H3.81818C3.33597 21.1818 2.87351 20.9903 2.53253 20.6493C2.19156 20.3083 2 19.8458 2 19.3636V3H9.27273L12 6.63636H22V19.3636C22 19.8458 21.8084 20.3083 21.4675 20.6493C21.1265 20.9903 20.664 21.1818 20.1818 21.1818Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
