import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9483 21.4675L21.4684 14.9474C22.1784 14.2374 22.1784 13.0865 21.4684 12.3765L11.091 2H2V11.091L12.3765 21.4675C13.0865 22.1775 14.2374 22.1775 14.9474 21.4675H14.9483Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.45468 9.2728C8.45884 9.2728 9.27288 8.45877 9.27288 7.4546C9.27288 6.45044 8.45884 5.6364 7.45468 5.6364C6.45051 5.6364 5.63647 6.45044 5.63647 7.4546C5.63647 8.45877 6.45051 9.2728 7.45468 9.2728Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9093 10.1819L16.5457 13.8183"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.182 12.9092L13.8184 16.5456"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
