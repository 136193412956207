import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3368 1.66319C14.5544 1.88077 14.5544 2.23353 14.3368 2.45111L2.45111 14.3369C2.23353 14.5544 1.88077 14.5544 1.66319 14.3369C1.4456 14.1193 1.4456 13.7665 1.66319 13.5489L13.549 1.66319C13.7665 1.4456 14.1193 1.4456 14.3368 1.66319Z"
        fill={color}
      />
    </svg>
  );
}
