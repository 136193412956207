import { useState } from 'react';
import clsx from 'clsx';
import type { Editor } from '@tiptap/core';
import { Icon16, Icon24 } from '@spaceduck/icons';

import Button from '@ui/Button';
import Menu, { MenuItem } from '@ui/DropdownMenu';
import Spinner from '@ui/Spinner';
import styles from './AIMenuFollowUp.module.scss';

const { AI } = Icon16;
const { Checkbox, New, Repeat, TrashCan } = Icon24;

export default function AIMenuFollowUp({
  container,
  discard,
  editor,
  isLoading = false,
  resultsEditor,
  retry,
}: {
  container?: HTMLElement | null;
  discard?: () => void;
  editor: Editor;
  isLoading?: boolean;
  resultsEditor: Editor | null;
  retry?: () => void;
}) {
  const [open, setOpen] = useState(true);
  const handleReplaceSelection = () => {
    if (!resultsEditor) return;

    const { selection } = editor.state;
    const resultsContent = resultsEditor.getJSON();
    if (!resultsContent.content?.length) return;

    if (resultsContent.content.length === 1 && resultsContent.content[0]) {
      // Merge content and keep marks within results
      const resultsFirstElementChild = resultsEditor.view.dom.firstElementChild;
      if (resultsFirstElementChild) {
        editor.commands.insertContentAt(selection, resultsFirstElementChild.innerHTML);
        while (editor.can().liftListItem('listItem')) {
          editor.commands.liftListItem('listItem');
        }
      }
      return;
    }

    // Inject content as is splitting existing block
    editor.commands.insertContentAt(selection, resultsContent);
    while (editor.can().liftListItem('listItem')) {
      editor.commands.liftListItem('listItem');
    }
  };

  const handleInsertBelow = () => {
    if (!resultsEditor) return;

    /* cspell:disable-next-line */
    editor.commands.selectTextblockEnd();
    editor.commands.insertContent(resultsEditor.getJSON());
  };

  if (isLoading) {
    return <Spinner size={24} />;
  }

  return (
    <Menu
      alignOffset={-13}
      className={clsx(isLoading && styles.hidden, styles.menu)}
      container={container}
      isPadded
      open={open && !isLoading}
      setOpen={setOpen}
      width={230}
      triggerContent={
        <Button className={styles.trigger} variant="icon">
          <AI />
        </Button>
      }
    >
      <MenuItem
        disabled={!resultsEditor}
        iconBefore={<Checkbox size={20} />}
        onClick={handleReplaceSelection}
      >
        Replace selection
      </MenuItem>
      <MenuItem
        disabled={!resultsEditor}
        iconBefore={<New size={20} />}
        onClick={handleInsertBelow}
      >
        Insert below
      </MenuItem>
      {!!retry && (
        <MenuItem
          onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            retry();
          }}
          iconBefore={<Repeat size={20} />}
        >
          Try again
        </MenuItem>
      )}
      {!!discard && (
        <MenuItem onClick={discard} iconBefore={<TrashCan size={20} />}>
          Discard
        </MenuItem>
      )}
    </Menu>
  );
}
