import { getOsFromUserAgent } from '@/utils/os';
import { useMemo } from 'react';

export const useOs = () => {
  const userAgent = window.navigator.userAgent;
  return useMemo(() => getOsFromUserAgent(userAgent), [userAgent]);
};

export const useIsMac = () => {
  return useOs().isMac;
};
