import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 64 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 64 64"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58 8H6C4.89543 8 4 8.89543 4 10V22C4 23.1046 4.89543 24 6 24H58C59.1046 24 60 23.1046 60 22V10C60 8.89543 59.1046 8 58 8Z"
        stroke="#B5B7CA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 40H60"
        stroke="#7A7E9F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 56H60"
        stroke="#4B4E68"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
