import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 16H2C1.447 16 1 15.552 1 15C1 14.448 1.447 14 2 14H14C14.553 14 15 14.448 15 15C15 15.552 14.553 16 14 16Z"
        fill={color}
      />
      <path
        d="M8 12C5.243 12 3 9.757 3 7V1C3 0.448 3.447 0 4 0C4.553 0 5 0.448 5 1V7C5 8.654 6.346 10 8 10C9.654 10 11 8.654 11 7V1C11 0.448 11.447 0 12 0C12.553 0 13 0.448 13 1V7C13 9.757 10.757 12 8 12Z"
        fill={color}
      />
    </svg>
  );
}
