import clsx from 'clsx';

import type { MediaGroupDTO } from '@spaceduck/api';
import { Icon32 } from '@spaceduck/icons';

import styles from './BoardSummary.module.scss';

const { Board } = Icon32;

export function BoardSummary({
  className,
  mediaGroup,
}: { className?: string; mediaGroup: MediaGroupDTO }) {
  const { source } = mediaGroup.thumbnail;
  if (source.kind !== 'board') return null;

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.header}>
        <Board />
        <div className={styles.info}>
          <div className={styles.label}>
            <span>{mediaGroup.label}</span>
          </div>
          <div>Board</div>
        </div>
      </div>
      {(source.images > 0 || source.documents > 0) && (
        <div className={styles.footer}>
          {source.images > 0 && (
            <div>
              <span className={styles.count}>{source.images}</span>
              <div>Images</div>
            </div>
          )}
          {source.documents > 0 && (
            <div>
              <span>{source.documents}</span>
              <div>Docs</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
