import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 32,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M22.04 22.542a1 1 0 0 1-1.6.8l-4.8-3.6a1 1 0 0 0-.6-.2H9.707a1 1 0 0 1-1-1v-4.667a1 1 0 0 1 1-1h5.333a1 1 0 0 0 .6-.2l4.8-3.6a1 1 0 0 1 1.6.8z"
      />
    </svg>
  );
}
