import Table from '@tiptap/extension-table';

import { TableView } from './table/TableView';

export default Table.extend({
  // From TipTap repo
  // @ts-ignore
  addOptions() {
    return {
      HTMLAttributes: {},
      resizable: false,
      handleWidth: 5,
      cellMinWidth: 25,
      width: '100%',
      minWidth: '100%',
      lastColumnResizable: false,
      allowTableNodeSelection: false,
      View: TableView,
    };
  },
});
