import { useState } from 'react';
import { usePoll } from './usePoll';

export const usePollTimeout = <T, K>(
  interval: number,
  done: (response: K) => boolean,
  fn: (data: T) => Promise<K> | K,
  timeout: number
): ((data: T) => Promise<K> | K) => {
  const [pollEnabled, setPollEnabled] = useState(true);
  const poll = usePoll(
    interval,
    pollEnabled,
    async (data?: { data?: T; resolve: (data: K) => void }) => {
      if (!data?.data) {
        return;
      }
      const response = await fn(data.data);
      if (done(response)) {
        setPollEnabled(false);
        data.resolve(response);
      }
    }
  );
  return (data?: T) => {
    return new Promise((resolve: (data: K) => void, reject) => {
      setPollEnabled(true);
      poll({
        data,
        resolve,
      });
      setTimeout(() => {
        reject(null);
        setPollEnabled(false);
      }, timeout);
    });
  };
};
