import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.73279 1.28916C8.33716 0.863097 7.66284 0.863097 7.26721 1.28916L1.76721 7.21224C1.59545 7.3972 1.5 7.64028 1.5 7.89269V14.5C1.5 15.0523 1.94772 15.5 2.5 15.5H5.5C6.05228 15.5 6.5 15.0523 6.5 14.5V12.5C6.5 11.9477 6.94772 11.5 7.5 11.5H8.5C9.05228 11.5 9.5 11.9477 9.5 12.5V14.5C9.5 15.0523 9.94772 15.5 10.5 15.5H13.5C14.0523 15.5 14.5 15.0523 14.5 14.5V7.89269C14.5 7.64028 14.4045 7.3972 14.2328 7.21224L8.73279 1.28916Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
