import Head from '@components/Head';
import { useSession } from '@hooks/useAuth';

export default function UserProfilePage() {
  const session = useSession();

  if (session?.kind !== 'authenticated') throw new Error('Login required');

  return (
    <>
      <Head title="User profile" />
      <div>Welcome {session.user?.preferredName}</div>
    </>
  );
}
