import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 12,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 12 12"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.05657 7.82065C2.09879 7.87468 2.16241 7.91996 2.24052 7.95158C2.31863 7.9832 2.40824 7.99995 2.49962 8L9.50037 8C9.59184 7.99995 9.68153 7.98317 9.75969 7.9515C9.83785 7.91983 9.90148 7.87447 9.94366 7.82036C9.98584 7.76626 10.0049 7.70547 9.99891 7.64463C9.99287 7.58379 9.96192 7.52521 9.90942 7.47528L6.40904 4.14161C6.3629 4.09786 6.30164 4.06216 6.23045 4.03751C6.15926 4.01287 6.08022 4 6 4C5.91978 4 5.84073 4.01287 5.76954 4.03751C5.69835 4.06216 5.6371 4.09786 5.59095 4.14161L2.09057 7.47528C2.03803 7.52526 2.00707 7.58389 2.00107 7.64479C1.99508 7.70569 2.01427 7.76652 2.05657 7.82065Z"
        fill={color}
      />
    </svg>
  );
}
