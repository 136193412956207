import { clsx } from 'clsx';

import ErrorDuck from '@assets/img/ErrorDuck';
import Head from '@components/Head';
import Button from '@ui/Button';
import styles from './ErrorPage.module.scss';

export default function ClientErrorPage({
  buttonAction,
  children,
  message,
  title,
}: {
  buttonAction?: () => void;
  children?: React.ReactNode;
  message?: string;
  title?: string;
}) {
  return (
    <>
      <Head title={title ?? 'Bad Request'} />
      <div className={clsx('body5', styles.container)}>
        <ErrorDuck />
        {!!title && <h1 className="h6">{title}</h1>}
        {!!message && <p>{message}</p>}
        {children}
        <Button
          onClick={() => {
            buttonAction?.();
            // Force reload
            window.location.href = '/';
          }}
        >
          Go to the Spaceduck Homepage
        </Button>
      </div>
    </>
  );
}
