import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 8L8 2L14 8" stroke={color} />
      <path d="M2 14L8 8L14 14" stroke={color} />
    </svg>
  );
}
