import type {
  AiModel,
  ChatTemporaryLinkSource,
  ChatMediaGroupSource,
  ChatSpaceSource,
  ChatTemporaryFileSource,
  MediaGroupDTO,
  SearchSuggestionDTO,
} from '@spaceduck/api';
import styles from './ChatMessageInput.module.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ResearchSourcesDropdown,
  type SourceOption,
} from '@/components/ai/ResearchSourcesDropdown';
import MediaGroupSourcePopup from '@/components/ai/MediaGroupSourcePopover';
import SearchSuggestions from '../SearchSuggestions';
import Button from '../ui/Button';
import { Icon16, Icon24 } from '@spaceduck/icons';
import { useCreateTemporaryLink, type SourceType } from '@/api/ai';

import clsx from 'clsx';
import { EditorContent } from '@tiptap/react';
import { useResearchAssistantEditor } from './Tiptap';

import { type SelectedSourceType, useSourcesStore } from './useSourcesStore';
import { textNode } from '../detailsModal/tiptap/utils';
import { exists } from '@spaceduck/utils';
import { eqSet } from '@/utils/set';
import ScrollArea from '@ui/ScrollArea';
import { css } from '@/lib/css';
import { v4 } from 'uuid';
import { useBookmarkModal } from '../CreateBookmarkModal';
import { catchApiErrorIntoToast } from '@/api/util';

const { At, SendComment, Attach } = Icon24;

const { AI, StatusInfo } = Icon16;
type FilterContext =
  | {
      exclude: boolean;
      sourceType: SourceType;
      suggestionQuery: string;
      context: 'suggestion';
    }
  | {
      exclude: boolean;
      filterQuery: string;
      context: 'filter';
    }
  | {
      context: undefined;
    };

const extractFilterAndSuggestionQuery = (value: string): FilterContext => {
  let context: 'filter' | 'suggestion' | undefined;
  if (value.includes(':')) {
    context = 'suggestion';
  } else if (value.startsWith('@')) {
    context = 'filter';
  }

  const [filter, suggestionQuery] = value
    .replace('@', '')
    .split(':')
    .map((f) => f.trim());
  const filterParsed = (['library', 'space', 'url'] as const).filter(
    (property) => property === filter?.replace('-', '')
  );

  if (filterParsed.length && filterParsed[0] && context === 'suggestion') {
    return {
      exclude: filter?.startsWith('-') ?? false,
      sourceType: filterParsed[0],
      suggestionQuery: suggestionQuery ?? '',
      context,
    };
  }
  if (context === 'filter') {
    return {
      exclude: filter?.startsWith('-') ?? false,
      filterQuery: filter?.replace('-', '') ?? '',
      context,
    };
  }

  return {
    context: undefined,
  };
};

export default function ChatMessageInput({
  readOnly,
  onSubmit,
  placeholder,
  showTools = false,
  initialSources,
}: {
  readOnly: boolean;
  onSubmit: (
    query: string,
    mediaGroups: ChatMediaGroupSource[],
    projects: ChatSpaceSource[],
    links: ChatTemporaryLinkSource[],
    temporaryFiles: ChatTemporaryFileSource[],
    model: AiModel
  ) => void;
  placeholder?: string;
  showTools?: boolean;
  initialSources?: SelectedSourceType[];
}) {
  const { includedSources, addFile } = useSourcesStore();
  const [filterContext, setFilterContext] = useState<FilterContext>();
  const inputField = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const sendButtonRef = useRef<HTMLButtonElement>(null);
  const [mentionCommand, setMentionCommand] =
    useState<(props: SelectedSourceType) => void>();
  const [editorSources, setEditorSources] = useState<SelectedSourceType[]>([]);

  const { mutateAsync: createLink } = useCreateTemporaryLink();
  const { open: openCreateBookmarkModal } = useBookmarkModal();
  const selectSourceLookup = useCallback(
    async (sourceLookup: SourceOption['label']) => {
      if (!inputField.current) {
        return;
      }
      if (sourceLookup === 'library' || sourceLookup === 'space') {
        const toInsert = sourceLookup.replace(inputValue.replace('@', ''), '');
        editor?.commands.insertContent(textNode(`${toInsert}:`));
        return;
      }
      if (sourceLookup === 'link') {
        setInputValue('');
        openCreateBookmarkModal({
          onSubmit: async (data) => {
            const tempLink = await catchApiErrorIntoToast(createLink)({
              url: data.url,
            });
            mentionCommand?.({
              label: data.url,
              id: tempLink.link.id,
              type: 'link',
            });
          },
        });
      }
      return;
    },
    [inputValue]
  );

  const selectMediaGroup = (mediaGroup: MediaGroupDTO) => {
    mentionCommand?.({ id: mediaGroup.id, type: 'library', label: mediaGroup.label });
  };

  const selectSpace = (suggestion: SearchSuggestionDTO) => {
    if (suggestion.filter === 'project' && suggestion.id) {
      mentionCommand?.({ id: suggestion.id, type: 'space', label: suggestion.label });
    }
  };

  useEffect(() => {
    if (!readOnly) {
      setFilterContext(extractFilterAndSuggestionQuery(inputValue));
    }
  }, [inputValue, readOnly]);

  const pendingSources = useMemo(() => {
    const includedSourcesIds = new Set(includedSources.map((source) => source.id));
    const editorSourcesIds = new Set(editorSources.map((source) => source.id));

    return !eqSet(includedSourcesIds, editorSourcesIds);
  }, [includedSources, editorSources]);

  const editor = useResearchAssistantEditor({
    onMentionUpdate: (value) => setInputValue(value),
    onSend: () => {
      sendButtonRef.current?.click();
      return true;
    },
    setCommand: (command) => setMentionCommand(() => command),
    placeholder:
      placeholder ??
      'Ask Ducklas the research duck a question or chat to your repository...',
    initialSources,
  });

  const handleSubmit = useCallback(() => {
    const text = editor?.getText()?.trim() || '';
    if (text.length === 0 || pendingSources) {
      return false;
    }
    onSubmit(
      text,
      includedSources.filter((source) => source.type === 'library'),
      includedSources.filter((source) => source.type === 'space'),
      includedSources.filter((source) => source.type === 'link'),
      includedSources.filter((source) => source.type === 'file'),
      'gpt-4o'
    );
    editor?.commands.clearContent(true);
    return true;
  }, [includedSources, pendingSources]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (editor) {
      const div = document.createElement('div');
      div.innerHTML = editor.getHTML();
      const mentionElements = div.querySelectorAll('span[data-type=mention]');
      const sources: SelectedSourceType[] = Array.from(mentionElements.values())
        .map((mentionElement) => {
          return {
            id: mentionElement.getAttribute('id'),
            label: mentionElement.getAttribute('label'),
            type: mentionElement.getAttribute('type'),
          };
        })
        .filter(
          (source): source is SelectedSourceType =>
            source.label !== null && source.type !== null
        );

      setEditorSources(sources);
    }
  }, [editor?.getHTML()]);

  return (
    <>
      <div className={styles.widget}>
        <div className={styles.inputBar}>
          <div className={styles.inputWrapper}>
            <div className={styles.content}>
              <ScrollArea
                orientation="vertical"
                style={css({
                  '--width': '100%',
                  '--maxHeight': '30vh',
                })}
              >
                <EditorContent
                  editor={editor}
                  className={styles.input}
                  innerRef={inputField}
                  onKeyDown={() => editor?.commands.scrollIntoView()}
                />
              </ScrollArea>
              {!showTools && (
                <Button
                  ref={sendButtonRef}
                  className={styles.submitButton}
                  disabled={editor?.getText()?.trim()?.length === 0 || pendingSources}
                  onClick={handleSubmit}
                  size="sm"
                  variant="light"
                  isSquare={true}
                >
                  <SendComment />
                </Button>
              )}
            </div>
            {filterContext?.context === 'filter' && (
              <ResearchSourcesDropdown
                filter={filterContext.filterQuery}
                inputRef={inputField}
                setSelectedSource={selectSourceLookup}
                onDismiss={() => {
                  setInputValue('');
                }}
              />
            )}
            {filterContext?.context === 'suggestion' &&
              filterContext.sourceType === 'library' && (
                <MediaGroupSourcePopup
                  inputRef={inputField}
                  handleSelectItem={selectMediaGroup}
                  searchTitle={filterContext.suggestionQuery}
                  onEscapeKeyDown={() => {
                    editor?.commands.deleteCurrentNode();
                  }}
                  excludeMediaGroupIds={editorSources
                    .filter((source) => source.type === 'library')
                    .map((mediaGroup) => mediaGroup.id)
                    .filter(exists)}
                />
              )}
            {filterContext?.context === 'suggestion' &&
              filterContext.sourceType === 'space' && (
                <SearchSuggestions
                  inputRef={inputField}
                  filter="project"
                  defaultFilters={{
                    projectNot: includedSources
                      .filter((source) => source.type === 'space')
                      .map((project) => project.id),
                  }}
                  active
                  onPreview={() => {}}
                  onSelect={selectSpace}
                  query={filterContext.suggestionQuery}
                  onEscapeKeyDown={() => {
                    editor?.commands.clearContent();
                  }}
                />
              )}
          </div>
        </div>
        {showTools && (
          <div className={styles.tools}>
            <div className={styles.sources}>
              <Button
                className={styles.sourceButton}
                disabled={readOnly}
                onClick={() => {
                  if (inputField.current) {
                    editor?.commands.focus();
                    editor?.commands.insertContent(textNode('@'));
                  }
                }}
                size="md"
                variant="ghost"
                iconBefore={<At size={20} />}
              >
                Source
              </Button>
              <Button
                className={styles.sourceButton}
                disabled={readOnly}
                size="md"
                variant="ghost"
                iconBefore={<Attach size={20} />}
                onClick={() => {
                  fileInputRef.current?.click();
                }}
              >
                Attach
              </Button>
              <Button
                className={styles.sourceButton}
                disabled={readOnly}
                size="md"
                variant="ghost"
                iconBefore={<AI size={20} />}
              >
                {/* TODO: Support multiple models */}
                {'GPT-4o'}
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={(ev) => {
                  if (ev.target.files) {
                    const keys = [];
                    for (let i = 0; i < ev.target.files.length; i++) {
                      const file = ev.target.files[i];
                      const key = v4();
                      if (file) {
                        keys.push(key);
                        editor?.commands.insertContent?.({
                          type: 'mention',
                          attrs: {
                            type: 'file',
                            fileKey: key,
                            label: file?.name || '',
                          },
                        });
                        addFile(file, key);
                      }
                    }
                  }
                }}
                style={{ display: 'none' }}
              />
            </div>
            <Button
              ref={sendButtonRef}
              className={styles.submitButton}
              disabled={(editor?.getText()?.trim()?.length ?? 0) < 3 || pendingSources}
              onClick={handleSubmit}
              size="sm"
              variant="light"
              isSquare={true}
            >
              <SendComment />
            </Button>
          </div>
        )}
      </div>
      {!readOnly && (
        <span
          className={clsx(
            styles.pendingNotice,
            pendingSources ? styles.active : undefined
          )}
        >
          <AlertBanner />
        </span>
      )}
    </>
  );
}

const AlertBanner = () => {
  return (
    <div className={styles.alertBanner}>
      <StatusInfo />
      <p>Working on your content. This may take a moment...</p>
    </div>
  );
};
