import { useEffect, useState } from 'react';
import { useReactFlow } from '@xyflow/react';

import { exists } from '@spaceduck/utils';

import { useBoardStore } from './useBoardStore';
import type { MenuPlaceholder } from '../types/board';

export const useEdgeFloatingMenu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const selectedEdges = useBoardStore(({ selectedEdges }) => selectedEdges);
  const { addNodes, deleteElements, getEdge, getNode, getNodesBounds } = useReactFlow();

  useEffect(() => {
    const menu = getNode('menuPlaceholder');

    if (menu) {
      deleteElements({ nodes: [menu] });
    }

    if (selectedEdges.length) {
      const relatedNodes = Array.from(
        selectedEdges.reduce<Set<string>>((acc, curr) => {
          const edge = getEdge(curr);
          if (!edge) return acc;

          const { source, target } = edge;
          acc.add(source);
          acc.add(target);
          return acc;
        }, new Set())
      )
        .map((nodeId) => getNode(nodeId))
        .filter(exists);

      const { x, y, height, width } = getNodesBounds(relatedNodes);
      const node: MenuPlaceholder = {
        id: 'menuPlaceholder',
        data: {},
        position: { x, y },
        height: height,
        width: width,
        type: 'menuPlaceholder',
      };
      addNodes([node]);
    }
  }, [selectedEdges]);

  return {
    selectedEdges,
    setShowMenu,
    showMenu,
  };
};
