export type JsonParseSuccess<T = unknown> = { success: true; data: T };
export type JsonParseFail = { success: false; error: unknown };
export type JsonParseResult<T = unknown> = JsonParseFail | JsonParseSuccess<T>;

export const safeParse = (serialized: string): JsonParseResult => {
  let data: unknown;
  try {
    data = JSON.parse(serialized);
  } catch (error) {
    return { success: false, error };
  }
  return { success: true, data };
};
