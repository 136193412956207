import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.70101 2.40945L8.21257 3.89789C7.89664 4.21382 7.38442 4.21382 7.06849 3.89789C6.75256 3.58196 6.75256 3.06973 7.06849 2.75381L8.56758 1.25472C9.39177 0.450316 10.4978 7.62939e-05 11.6495 7.62939e-05C12.8012 7.62939e-05 13.9072 0.450358 14.7314 1.25476L14.7453 1.26866C15.5497 2.09285 16 3.19889 16 4.35057C16 5.50224 15.5497 6.60828 14.7453 7.43247L14.7385 7.4395L13.2463 8.9316C12.9304 9.24753 12.4181 9.24753 12.1022 8.9316C11.7863 8.61567 11.7863 8.10344 12.1022 7.78751L13.5906 6.29908C14.0981 5.77746 14.382 5.0784 14.382 4.35057C14.382 3.62447 14.0994 2.92703 13.5943 2.40581C13.0731 1.90067 12.3756 1.61805 11.6495 1.61805C10.9217 1.61805 10.2226 1.90202 9.70101 2.40945Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.89793 7.06841C4.21386 7.38434 4.21386 7.89656 3.89793 8.21249L2.40949 9.70093C1.90207 10.2226 1.6181 10.9216 1.6181 11.6494C1.6181 12.3755 1.90071 13.073 2.40586 13.5942C2.92708 14.0993 3.62452 14.3819 4.35061 14.3819C5.07845 14.3819 5.7775 14.098 6.29912 13.5906L7.78756 12.1021C8.10349 11.7862 8.61571 11.7862 8.93164 12.1021C9.24757 12.418 9.24757 12.9303 8.93164 13.2462L7.43256 14.7453C6.60836 15.5497 5.50229 15.9999 4.35061 15.9999C3.19894 15.9999 2.0929 15.5496 1.26871 14.7452L1.25481 14.7313C0.450404 13.9071 0.00012207 12.8011 0.00012207 11.6494C0.00012207 10.4978 0.450404 9.39173 1.25481 8.56753L1.26167 8.5605L2.75385 7.06841C3.06978 6.75248 3.582 6.75248 3.89793 7.06841Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41471 9.58526C6.73064 9.90119 6.73064 10.4134 6.41471 10.7293L5.69561 11.4484C5.37968 11.7644 4.86745 11.7644 4.55152 11.4484C4.23559 11.1325 4.23559 10.6203 4.55152 10.3044L5.27062 9.58526C5.58655 9.26933 6.09878 9.26933 6.41471 9.58526Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4484 4.55155C11.7643 4.86748 11.7643 5.37971 11.4484 5.69564L10.7293 6.41474C10.4134 6.73067 9.90114 6.73067 9.58521 6.41474C9.26928 6.09881 9.26928 5.58658 9.58521 5.27065L10.3043 4.55155C10.6202 4.23562 11.1325 4.23562 11.4484 4.55155Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.236947 0.236947C0.552877 -0.0789824 1.0651 -0.0789824 1.38103 0.236947L15.7631 14.619C16.079 14.9349 16.079 15.4471 15.7631 15.7631C15.4471 16.079 14.9349 16.079 14.619 15.7631L0.236947 1.38103C-0.0789824 1.0651 -0.0789824 0.552877 0.236947 0.236947Z"
        fill={color}
      />
    </svg>
  );
}
