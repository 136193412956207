import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.25 2.25C20.7804 2.25 21.2891 2.46071 21.6642 2.83579C22.0393 3.21086 22.25 3.71957 22.25 4.25V20.25C22.25 20.7804 22.0393 21.2891 21.6642 21.6642C21.2891 22.0393 20.7804 22.25 20.25 22.25H4.25C3.71957 22.25 3.21086 22.0393 2.83579 21.6642C2.46071 21.2891 2.25 20.7804 2.25 20.25V4.25C2.25 3.71957 2.46071 3.21086 2.83579 2.83579C3.21086 2.46071 3.71957 2.25 4.25 2.25"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 10.25C16.25 11.074 15.3092 11.5444 14.65 11.05L12.85 9.7C12.4944 9.43333 12.0056 9.43333 11.65 9.7L9.85 11.05C9.19076 11.5444 8.25 11.074 8.25 10.25V3.25C8.25 2.69772 8.69772 2.25 9.25 2.25H15.25C15.8023 2.25 16.25 2.69772 16.25 3.25V10.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
