import { Icon16, type IconProps } from '@spaceduck/icons';

import type { ContentViewTab } from '@/types/MediaGroup';

const { Image, IFrame, PDF, ReadingMode, ShowEmbed, Summary } = Icon16;

export default function ContentView(props: { view: ContentViewTab } & IconProps) {
  const { view, ...iconPros } = props;

  switch (view) {
    case 'embed':
      return <ShowEmbed {...iconPros} />;
    case 'iframe':
      return <IFrame {...iconPros} />;
    case 'pdf':
      return <PDF {...iconPros} />;
    case 'reading mode':
      return <ReadingMode {...iconPros} />;
    case 'screenshot':
      return <Image {...iconPros} />;
    case 'summary':
      return <Summary {...iconPros} />;
    default:
      return null;
  }
}
