import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 'Just now',
    m: 'A minute ago',
    mm: '%d minutes ago',
    h: 'An hour ago',
    hh: '%d hours ago',
    d: 'A day ago',
    dd: '%d days ago',
    M: 'A month ago',
    MM: '%d months ago',
    y: 'A year ago',
    yy: '%d years ago',
  },
});

export default dayjs;
