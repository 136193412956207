import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.091 12H5.63647V17.4545H11.091V12Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5455 6.54546H20.1818C21.1864 6.54546 22 7.35909 22 8.36364V20.1818C22 21.1864 21.1864 22 20.1818 22H3.81818C2.81364 22 2 21.1864 2 20.1818V8.36364C2 7.35909 2.81364 6.54546 3.81818 6.54546H7.45455"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8182 8.36364H10.1819V3.81818C10.1819 2.81818 11.0001 2 12.0001 2C13.0001 2 13.8182 2.81818 13.8182 3.81818V8.36364Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7273 12.9091H18.3637"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7273 16.5455H18.3637"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
