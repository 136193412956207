import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useReactFlow, type NodeProps } from '@xyflow/react';

import { Icon16 } from '@spaceduck/icons';
import { exists } from '@spaceduck/utils';

import Button from '@ui/Button';
import ContextMenu, { type ContextMenuItemProps } from '@ui/ContextMenu';
import { RecursiveDropdownMenu } from '@ui/DropdownMenu';
import Tooltip from '@ui/Tooltip';
import { css } from '@/lib/css';
import ColorSelector from '../components/ColorSelector';
import Resizer from '../components/Resizer';
import { useFrames } from '../hooks/useFrames';
import type { GroupNodeType } from '../types/board';
import { type NodeBorderColor, nodeBorderColors } from '../types/colors';
import styles from './GroupNode.module.scss';
import { useMetaKey } from '@/hooks/useMetaKey';

const { OptionsThick } = Icon16;

export const GroupNode = (props: NodeProps<GroupNodeType>) => {
  const { getNode, getNodes, setNodes } = useReactFlow();
  const [expanded, _setExpanded] = useState(props.data.expanded ?? true);
  const [color, _setColor] = useState<NodeBorderColor>(
    (props.data.color as NodeBorderColor) ?? 'grey'
  );
  const [name, setName] = useState(props.data.label ?? 'Frame');
  const [showNameInput, setShowNameInput] = useState(false);

  const {
    cutFrame: _cutFrame,
    copyFrame: _copyFrame,
    deleteFrame: _deleteFrame,
    duplicateFrame: _duplicateFrame,
    updateFrame,
  } = useFrames();

  const setColor = useCallback(
    (color: NodeBorderColor) => {
      _setColor(color);
      updateFrame(props.id, {
        data: {
          color,
          expanded,
        },
        className: clsx(color, !expanded && 'collapsed'),
        style: css({
          backgroundColor: 'var(--background)',
        }),
      });
    },
    [props.id, updateFrame, _setColor]
  );

  const setExpanded = useCallback(
    (isExpanded: boolean) => {
      _setExpanded(isExpanded);
      const frame = getNode(props.id);
      if (frame) {
        setNodes((nodes) => {
          return nodes.map((node) => {
            if (node.id === props.id) {
              return {
                ...node,
                className: clsx(color, !isExpanded && 'collapsed'),
                data: { ...node.data, expanded: isExpanded },
              };
            }

            if (node.parentId === props.id) {
              return { ...node, hidden: !isExpanded, data: { ...node.data } };
            }

            return node;
          });
        });
      }
    },
    [_setExpanded, getNode, getNodes, props.id, setNodes]
  );

  useEffect(() => {
    const node = getNode(props.id);
    if (node) {
      setExpanded((node.data.expanded as boolean) ?? true);
    }
  }, []);

  const copyFrame = (id: string) => {
    _copyFrame(id);
  };

  const cutFrame = (id: string) => {
    _cutFrame(id);
  };

  const deleteFrame = (id: string) => {
    _deleteFrame(id);
  };

  const duplicateFrame = (id: string) => {
    _duplicateFrame(id);
  };

  const contextMenu = createContextMenu({
    color,
    copy: () => copyFrame(props.id),
    cut: () => cutFrame(props.id),
    deleteSelf: () => deleteFrame(props.id),
    duplicate: () => duplicateFrame(props.id),
    expanded,
    setColor,
    toggleExpanded: () => setExpanded(!expanded),
  });

  const handleNameChange = () => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === props.id) {
          return { ...node, data: { ...node.data, label: name } };
        }

        return node;
      })
    );
  };

  return (
    <ContextMenu contentClassName={styles.menu} items={contextMenu}>
      <div className={clsx('nowheel', styles.groupNode)}>
        {expanded && <Resizer minWidth={300} minHeight={300} />}
        <div className={styles.header}>
          {showNameInput ? (
            <input
              // biome-ignore lint/a11y/noAutofocus: non-disruptive and expected behavior
              autoFocus
              className={styles.label}
              onBlur={() => {
                setShowNameInput(false);
                handleNameChange();
              }}
              onChange={(ev) => {
                setName(ev.currentTarget.value);
              }}
              value={name}
              type="text"
            />
          ) : (
            <button
              className={styles.label}
              type="button"
              onClick={() => setShowNameInput(true)}
            >
              {name || 'Frame'}
            </button>
          )}
          <div>
            <Tooltip content="Options">
              <span>
                <RecursiveDropdownMenu
                  className={styles.menu}
                  dropdownMenuProps={{
                    isPadded: true,
                  }}
                  items={contextMenu}
                  isUnstyled
                >
                  <Button className={styles.noFocusOutline} variant="ghost">
                    <OptionsThick />
                  </Button>
                </RecursiveDropdownMenu>
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
    </ContextMenu>
  );
};

function createContextMenu({
  color,
  copy,
  cut,
  deleteSelf,
  duplicate,
  expanded,
  setColor,
  toggleExpanded,
}: {
  color: string | undefined;
  copy: () => void;
  cut: () => void;
  deleteSelf: () => void;
  duplicate: () => void;
  expanded: boolean;
  setColor: (color: NodeBorderColor) => void;
  toggleExpanded: () => void;
}) {
  const metaKey = useMetaKey();
  const selectedColor = color;

  const contextMenu: ContextMenuItemProps[] = [
    {
      content: 'Cut',
      onClick: cut,
      shortcut: `${metaKey} X`,
    },
    {
      content: 'Copy',
      onClick: copy,
      shortcut: `${metaKey} C`,
    },
    {
      content: 'Duplicate',
      onClick: duplicate,
      shortcut: `${metaKey} D`,
    },
    {
      content: 'Remove from board',
      onClick: deleteSelf,
      shortcut: 'Del',
    },
    {
      content: null,
      isSeparator: true,
    },
    {
      content: `${expanded ? 'Fold' : 'Expand'}`,
      onClick: toggleExpanded,
    },
    {
      className: styles.noHover,
      content: (
        <div className={styles.colorList}>
          {nodeBorderColors.map((color) => {
            return (
              <ColorSelector
                className={styles.colorSwatch}
                color={color}
                isActive={color === selectedColor}
                key={color}
                onChange={(color: NodeBorderColor) => setColor(color)}
              />
            );
          })}
        </div>
      ),
    },
  ].filter(exists);

  return contextMenu;
}
