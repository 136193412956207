import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1589_4797)">
        <path
          d="M10.579 8.36842V6.15789C10.579 5.75263 10.2475 5.42105 9.84219 5.42105H8.36851V9.10526H9.84219C10.2475 9.10526 10.579 8.77368 10.579 8.36842ZM9.10535 6.15789H9.84219V8.36842H9.10535V6.15789ZM12.0527 7.63158H12.7896V6.89474H12.0527V6.15789H12.7896V5.42105H11.3159V9.10526H12.0527V7.63158ZM6.15798 7.63158H6.89483C7.30009 7.63158 7.63167 7.3 7.63167 6.89474V6.15789C7.63167 5.75263 7.30009 5.42105 6.89483 5.42105H5.42114V9.10526H6.15798V7.63158ZM6.15798 6.15789H6.89483V6.89474H6.15798V6.15789Z"
          fill={color}
        />
        <rect
          x="3.21045"
          y="1"
          width="11.7895"
          height="11.7895"
          rx="2"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5789 15H3C1.89543 15 1 14.1046 1 13V4.68423"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1589_4797">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
