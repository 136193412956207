import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.328 4.672L19.328 9.672"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 21L2 22L3 16L16.414 2.586C16.7891 2.21106 17.2977 2.00043 17.828 2.00043C18.3583 2.00043 18.8669 2.21106 19.242 2.586L21.414 4.758C21.7889 5.13306 21.9996 5.64167 21.9996 6.172C21.9996 6.70233 21.7889 7.21095 21.414 7.586L8 21Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
