import type { TableOfContentDataItem } from '@tiptap-pro/extension-table-of-contents';
import type { Editor } from '@tiptap/core';
import { TextSelection } from '@tiptap/pm/state';
import clsx from 'clsx';

import { Icon24 } from '@spaceduck/icons';

import { css } from '@lib/css';
import Button from '@ui/Button';
import DropdownMenu from '@ui/DropdownMenu';
import ScrollArea from '@ui/ScrollArea';
import styles from './TableOfContents.module.scss';

const { TableOfContents: TableOfContentsIcon } = Icon24;

export const TableOfContentItem = ({
  item,
  onItemClick,
}: {
  item: TableOfContentDataItem;
  onItemClick: (ev: React.MouseEvent, id: string) => void;
}) => {
  return (
    <div className={clsx(styles.item, item.level && styles[`level${item.level}`])}>
      <a href={`#${item.id}`} onClick={(ev) => onItemClick(ev, item.id)}>
        {item.textContent}
      </a>
    </div>
  );
};

export default function TableOfContents({
  items = [],
  editor,
}: {
  items: TableOfContentDataItem[];
  editor: Editor;
}) {
  const onItemClick = (ev: React.MouseEvent, id: string) => {
    ev.preventDefault();
    if (editor) {
      const element = editor.view.dom.querySelector(`[data-toc-id="${id}"`);

      if (!element) return;
      const pos = editor.view.posAtDOM(element, 0);

      const tr = editor.view.state.tr;
      tr.setSelection(new TextSelection(tr.doc.resolve(pos)));
      editor.view.dispatch(tr);
      editor.view.focus();
      element.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.menu}>
      <DropdownMenu
        className={styles.dropdown}
        side="right"
        sideOffset={4}
        triggerContent={
          <Button className={styles.button} size="sm" variant="outlined">
            <TableOfContentsIcon size={20} />
          </Button>
        }
        width={220}
      >
        {items.length ? (
          <ScrollArea
            orientation="vertical"
            style={css({
              '--width': '100%',
              '--maxHeight': '100%',
            })}
          >
            <div className={styles.list}>
              {items.map((item) => (
                <TableOfContentItem
                  onItemClick={(ev) => onItemClick(ev, item.id)}
                  key={item.id}
                  item={item}
                />
              ))}
            </div>
          </ScrollArea>
        ) : (
          <div className={styles.empty}>No outline...</div>
        )}
      </DropdownMenu>
    </div>
  );
}
