import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 24 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.20195 14.6384C6.20195 15.7948 5.25733 16.7394 4.10098 16.7394C2.94463 16.7394 2 15.7948 2 14.6384C2 13.4821 2.94463 12.5375 4.10098 12.5375H6.20195V14.6384Z"
        fill="#E01E5A"
      />
      <path
        d="M7.26062 14.6384C7.26062 13.4821 8.20525 12.5375 9.3616 12.5375C10.5179 12.5375 11.4626 13.4821 11.4626 14.6384V19.899C11.4626 21.0554 10.5179 22 9.3616 22C8.20525 22 7.26062 21.0554 7.26062 19.899V14.6384Z"
        fill="#E01E5A"
      />
      <path
        d="M9.3616 6.20195C8.20525 6.20195 7.26062 5.25733 7.26062 4.10098C7.26062 2.94463 8.20525 2 9.3616 2C10.5179 2 11.4626 2.94463 11.4626 4.10098V6.20195H9.3616Z"
        fill="#36C5F0"
      />
      <path
        d="M9.36156 7.26059C10.5179 7.26059 11.4625 8.20522 11.4625 9.36157C11.4625 10.5179 10.5179 11.4625 9.36156 11.4625H4.10098C2.94463 11.4625 2 10.5179 2 9.36157C2 8.20522 2.94463 7.26059 4.10098 7.26059H9.36156Z"
        fill="#36C5F0"
      />
      <path
        d="M17.7981 9.36157C17.7981 8.20522 18.7427 7.26059 19.8991 7.26059C21.0554 7.26059 22 8.20522 22 9.36157C22 10.5179 21.0554 11.4625 19.8991 11.4625H17.7981V9.36157Z"
        fill="#2EB67D"
      />
      <path
        d="M16.7394 9.36156C16.7394 10.5179 15.7948 11.4625 14.6385 11.4625C13.4821 11.4625 12.5375 10.5179 12.5375 9.36156V4.10098C12.5375 2.94463 13.4821 2 14.6385 2C15.7948 2 16.7394 2.94463 16.7394 4.10098V9.36156Z"
        fill="#2EB67D"
      />
      <path
        d="M14.6385 17.798C15.7948 17.798 16.7394 18.7427 16.7394 19.899C16.7394 21.0554 15.7948 22 14.6385 22C13.4821 22 12.5375 21.0554 12.5375 19.899V17.798H14.6385Z"
        fill="#ECB22E"
      />
      <path
        d="M14.6385 16.7394C13.4821 16.7394 12.5375 15.7948 12.5375 14.6384C12.5375 13.4821 13.4821 12.5375 14.6385 12.5375H19.899C21.0554 12.5375 22 13.4821 22 14.6384C22 15.7948 21.0554 16.7394 19.899 16.7394H14.6385Z"
        fill="#ECB22E"
      />
    </svg>
  );
}
