import type { InviteRole, ProjectDuty, Role } from '@spaceduck/api';

export const ROLE_NAMES: Record<Role, string> = {
  V: 'Viewer',
  E: 'Editor',
  A: 'Admin',
  O: 'Owner',
};

export const PROJECT_DUTY_NAMES: Record<ProjectDuty, string> = {
  L: 'Lead',
  S: 'Stakeholder',
};

export const INVITE_ROLES: Record<InviteRole, string> = {
  V: 'Viewer',
  E: 'Editor',
};
