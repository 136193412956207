import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  size = 24,
}: IconProps & { active?: boolean }) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2308 1H7.76923C4.03069 1 1 4.03069 1 7.76923V16.2308C1 19.9693 4.03069 23 7.76923 23H16.2308C19.9693 23 23 19.9693 23 16.2308V7.76923C23 4.03069 19.9693 1 16.2308 1Z"
        fill="url(#paint0_linear_2788_6078)"
      />
      <path
        d="M17.85 12.3391C15.2106 13.1272 13.1272 15.2106 12.3391 17.8499C12.2353 18.1982 11.7587 18.1982 11.6609 17.8499C10.8728 15.2106 8.78945 13.1272 6.15009 12.3391C5.80186 12.2352 5.80186 11.7587 6.15009 11.6609C8.78945 10.8728 10.8728 8.78942 11.6609 6.15006C11.7648 5.80183 12.2414 5.80183 12.3391 6.15006C13.1272 8.78942 15.2106 10.8728 17.85 11.6609C18.1982 11.7648 18.1982 12.2413 17.85 12.3391Z"
        fill="url(#paint1_linear_2788_6078)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2788_6078"
          x1="21.125"
          y1="1.75"
          x2="2.375"
          y2="22.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#181386" />
          <stop offset="1" stopColor="#1A169C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2788_6078"
          x1="18.1111"
          y1="5.88889"
          x2="3.97937"
          y2="15.2177"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECEEF9" />
          <stop offset="1" stopColor="#ECEEF9" />
        </linearGradient>
      </defs>
    </svg>
  );
}
