import { useDeleteConfirmModal } from '@/components/ui/ConfirmModal';
import createToast from '@/utils/createToast';

export default function useDeleteMediaGroupConfirmModal<
  T extends Set<string> | string,
>({
  onConfirm,
  onSuccess,
}: {
  onConfirm?: (data: T) => Promise<void> | void;
  onSuccess?: () => void;
} = {}) {
  const { open: openDeleteConfirmationModal } = useDeleteConfirmModal<T>({
    onConfirm: async (data?: T) => {
      let plural = true;
      if (!data) return;
      if (typeof data === 'string' || data.size === 1) {
        plural = false;
      }
      await onConfirm?.(data);
      if (onSuccess) {
        onSuccess();
        return;
      }

      createToast({
        bodyText: `Item${plural ? 's' : ''} deleted`,
        iconVariant: 'success',
      });
    },
  });

  return (data: T) => {
    let plural = true;
    if (typeof data === 'string' || data.size === 1) {
      plural = false;
    }
    openDeleteConfirmationModal(data, {
      title: `Delete item${plural ? 's' : ''}`,
      subtitle: `This action will delete ${plural ? 'these items' : 'this item'} from all spaces and boards and move ${plural ? 'them' : 'it'} to the trash.`,
      confirmText: 'Yes, delete item',
    });
  };
}
