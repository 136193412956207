import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'var(--color-on-primary)',
  size = 48,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 48 48"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="var(--color-primary)"
        d="M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24"
      />
      <path
        fill={color}
        d="M19 13.5h-4.5a.5.5 0 0 0-.5.5v20a.5.5 0 0 0 .5.5H19a.5.5 0 0 0 .5-.5V14a.5.5 0 0 0-.5-.5"
      />
      <path
        fill={color}
        d="M33.5 13.5H29a.5.5 0 0 0-.5.5v20a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5V14a.5.5 0 0 0-.5-.5"
      />
    </svg>
  );
}
