import { useCallback } from 'react';
import BaseAudioPlayer from 'react-h5-audio-player';
import createToast from '@/utils/createToast';
import { Icon32, Icon48 } from '@spaceduck/icons';

import 'react-h5-audio-player/src/styles.scss';
import styles from './AudioPlayer.module.scss';

const { LoopPlayback, Volume } = Icon32;
const { Play, Pause } = Icon48;

const ICON_DISABLED_COLOR = 'var(--on-surface-4)';

const EmptyNode = () => <></>;

export const AudioPlayer = ({ src }: { src: string }) => {
  const handlePlayError = useCallback((error: unknown) => {
    console.error('Error playing back audio', error);
    createToast({
      iconVariant: 'warning',
      titleText: 'Playback error',
      bodyText: 'Unable to play audio. Please try again later.',
    });
  }, []);
  return (
    <div className={styles.audioPlayer}>
      <BaseAudioPlayer
        layout="stacked"
        src={src}
        showJumpControls={false}
        showSkipControls={false}
        onPlayError={handlePlayError}
        customIcons={{
          play: <Play />,
          pause: <Pause />,
          loop: <LoopPlayback />,
          loopOff: <LoopPlayback color={ICON_DISABLED_COLOR} />,
          volume: <Volume />,
          volumeMute: <Volume color={ICON_DISABLED_COLOR} />,
          // Default icons are download from remote server. We have these
          // features disabled, but also marking these as null to ensure the
          // client does not attempt to fetch these.
          rewind: <EmptyNode />,
          forward: <EmptyNode />,
          previous: <EmptyNode />,
          next: <EmptyNode />,
        }}
      />
    </div>
  );
};
