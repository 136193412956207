import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3C5.448 3 5 3.448 5 4V7H2V4C2 3.448 1.552 3 1 3C0.448 3 0 3.448 0 4V12C0 12.552 0.448 13 1 13C1.552 13 2 12.552 2 12V9H5V12C5 12.552 5.448 13 6 13C6.552 13 7 12.552 7 12V4C7 3.448 6.552 3 6 3Z"
        fill={color}
      />
      <path
        d="M15.462 5.792C15.425 4.253 13.977 3 12.233 3C11.046 3 10.001 3.52 9.36504 4.425C9.04804 4.877 9.15704 5.501 9.60904 5.818C10.061 6.135 10.686 6.026 11.002 5.574C11.353 5.074 11.924 4.999 12.233 4.999C12.982 4.999 13.454 5.488 13.462 5.838C13.464 5.937 13.397 6.114 13.201 6.315C12.878 6.646 12.221 6.999 11.343 6.999C10.791 6.999 10.343 7.447 10.343 7.999C10.343 8.551 10.791 8.999 11.343 8.999C12.221 8.999 12.879 9.352 13.201 9.683C13.397 9.884 13.464 10.061 13.462 10.16C13.453 10.511 12.982 10.999 12.233 10.999C11.924 10.999 11.353 10.924 11.002 10.424C10.686 9.972 10.061 9.863 9.60904 10.18C9.15704 10.497 9.04804 11.121 9.36504 11.573C10.001 12.479 11.046 12.998 12.233 12.998C13.976 12.998 15.425 11.746 15.462 10.206C15.479 9.531 15.184 8.848 14.633 8.284C14.535 8.183 14.429 8.088 14.318 7.997C14.429 7.907 14.535 7.811 14.633 7.71C15.184 7.146 15.478 6.463 15.462 5.788V5.792Z"
        fill={color}
      />
    </svg>
  );
}
