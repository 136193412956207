import { type PropsWithChildren, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';

import type { MediaGroupDetailDTO } from '@spaceduck/api';

import LoadingPlaceholder from '@components/LoadingPlaceholder';
import Button from '@ui/Button';
import styles from './EmbedView.module.scss';

const KEY = import.meta.env.VITE_IFRAMELY_HASHED_KEY;

type PaneActionsProps = {
  actions?: Array<{
    label: string;
    onClick: () => unknown;
  }>;
};

const PaneActions = ({ actions }: PaneActionsProps) => {
  if (!actions || actions.length === 0) {
    return null;
  }
  return (
    <div className={styles.actions}>
      {actions.map(({ onClick, label }, key) => (
        <Button key={key} onClick={onClick}>
          {label}
        </Button>
      ))}
    </div>
  );
};

type PaneProps = PropsWithChildren<PaneActionsProps>;

const Pane = ({ children, ...props }: PaneProps) => {
  return (
    <div className={styles.pane}>
      <div className={styles.content}>
        {children}
        <PaneActions {...props} />
      </div>
    </div>
  );
};

const NotAvailable = () => (
  <Pane>
    <div>No URL is associated with this media group, so no iframe can be shown.</div>
  </Pane>
);

const LoadingPartial = () => {
  return (
    <Pane>
      <LoadingPlaceholder message="Fetching embed data..." />
    </Pane>
  );
};

const ErrorPartial = ({ retry }: { retry: () => unknown }) => {
  const actions = useMemo(
    () => [
      {
        label: 'Retry',
        onClick: () => {
          retry();
        },
      },
    ],
    [retry]
  );

  return (
    <Pane actions={actions}>
      <div>Failed to fetch embed data. Please try again later.</div>
    </Pane>
  );
};

const EmptyPartial = () => {
  const actions = useMemo(
    () => [
      {
        label: 'Reload',
        onClick: () => {
          // This should only fail if we have a bad key, so we need to reload
          //  to potentially get the JS update.
          window.location.reload();
        },
      },
    ],
    []
  );

  return (
    <Pane actions={actions}>
      <div>Failed to fetch embed data. Please try again later.</div>
    </Pane>
  );
};

const useIframely = (url: string | null) => {
  const enabled = !!url;
  return useQuery({
    queryKey: ['iframely', url],
    queryFn: async () => {
      if (!enabled) {
        throw new Error('Query disabled');
      }
      const response = await fetch(
        `https://cdn.iframe.ly/api/iframely?url=${encodeURIComponent(url)}&key=${KEY}&iframe=1&omit_script=1`
      );
      return response.json();
    },
  });
};

export const EmbedView = ({
  className,
  containerClassName,
  mediaGroup,
}: {
  mediaGroup: Pick<MediaGroupDetailDTO, 'linkUrl'>;
  className?: string;
  containerClassName?: string;
}) => {
  const { data, status, error, refetch } = useIframely(mediaGroup.linkUrl);

  if (!mediaGroup.linkUrl) {
    return <NotAvailable />;
  }

  if (status === 'pending') {
    return <LoadingPartial />;
  }
  if (status === 'error') {
    console.error('Failed to fetch embedded view', error);
    return <ErrorPartial retry={refetch} />;
  }

  const { html } = data;

  if (!html) {
    return <EmptyPartial />;
  }

  return (
    <>
      <Helmet>
        <script async src="//cdn.iframe.ly/embed.js" />
      </Helmet>
      <div className={clsx(styles.container, containerClassName)}>
        <div
          className={clsx(styles.frame, className)}
          // biome-ignore lint/security/noDangerouslySetInnerHtml: only supported method
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </>
  );
};
