import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3 10.6968V8.08775C18.3 6.47318 17.6363 4.92474 16.4548 3.78306C15.2733 2.64139 13.6709 2 12 2C10.3291 2 8.72671 2.64139 7.54523 3.78306C6.36375 4.92474 5.7 6.47318 5.7 8.08775V10.6968C5.7 13.5667 3 14.2625 3 15.9149C3 17.3933 6.51 18.5239 12 18.5239C17.49 18.5239 21 17.3933 21 15.9149C21 14.2625 18.3 13.5667 18.3 10.6968Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20.2633C11.0901 20.2633 10.2351 20.2337 9.43054 20.1763C9.60208 20.705 9.94381 21.1671 10.406 21.4951C10.8681 21.8231 11.4266 22 12 22C12.5735 22 13.1319 21.8231 13.5941 21.4951C14.0563 21.1671 14.398 20.705 14.5695 20.1763C13.7649 20.2337 12.9099 20.2633 12 20.2633Z"
        fill={color}
      />
    </svg>
  );
}
