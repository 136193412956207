import { type Ref, forwardRef, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import type { CreateMediaGroupSchema } from '@spaceduck/api';

import { useCreateMediaGroup } from '@api/mediaGroup';
import { catchApiErrorIntoToast } from '@api/util';
import {
  type CreateBookmarkData,
  useBookmarkModal,
} from '@components/CreateBookmarkModal';
import { useFileUploadWrapper } from '@hooks/useFileUploadWrapper';
import useWorkspaceId from '@hooks/useWorkspaceId';
import DropdownMenu, { RecursiveDropdownMenuItem, Separator } from '@ui/DropdownMenu';
import { useFrames } from '../hooks/useFrames';
import { useNodes } from '../hooks/useNodes';
import styles from './BoardContextMenu.module.scss';
import { useMetaKey } from '@/hooks/useMetaKey';

type Coordinates = {
  x: number;
  y: number;
};

export type BoardContextMenuCoordinates = Coordinates | null;

export const BoardContextMenu = ({
  coordinates,
}: {
  coordinates: BoardContextMenuCoordinates;
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [tempCoordinates, setTempCoordinates] =
    useState<BoardContextMenuCoordinates>(null);

  const triggerFile = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      {!!coordinates && (
        <Menu
          openFileBrowser={triggerFile}
          x={coordinates.x}
          y={coordinates.y}
          setTempCoordinates={setTempCoordinates}
        />
      )}
      <FileUploader ref={fileInputRef} coordinates={tempCoordinates} />
    </>
  );
};

const Menu = ({
  openFileBrowser,
  setTempCoordinates,
  x,
  y,
}: {
  openFileBrowser: () => void;
  setTempCoordinates: React.Dispatch<React.SetStateAction<BoardContextMenuCoordinates>>;
  x: number;
  y: number;
}) => {
  useEffect(() => {
    setTempCoordinates({ x, y });
  }, []);

  const metaKey = useMetaKey();

  const {
    addFloatingTextNode,
    addMediaNode,
    clipboardItems,
    createDocumentNode,
    pasteNodes,
  } = useNodes();

  const { addFrame } = useFrames();

  const { mutateAsync: createMediaGroup } = useCreateMediaGroup();
  const createLink = catchApiErrorIntoToast(
    async (data: Omit<CreateMediaGroupSchema, 'kind'>) => {
      const { projectId, workspaceId, ...rest } = data;
      const { mediaGroupId } = await createMediaGroup({
        kind: 'bookmark',
        projectId: projectId,
        workspaceId: !projectId ? workspaceId : undefined,
        ...rest,
      });

      addMediaNode(mediaGroupId, x, y);
    }
  );

  const workspaceId = useWorkspaceId();
  const projectId = useParams<{ projectId?: string }>().projectId;

  const { open: openCreateBookmarkModal } = useBookmarkModal();
  const launchCreateBookmarkModal = () => {
    openCreateBookmarkModal({
      onSubmit: (data: CreateBookmarkData) => {
        createLink({
          workspaceId: workspaceId ? workspaceId : undefined,
          projectId: projectId,
          url: data.url,
        });
      },
    });
  };

  return (
    <DropdownMenu
      modal={false}
      isPadded
      open
      sideOffset={6}
      triggerContent={<div style={{ position: 'fixed', top: y, left: x }} />}
      width={262}
    >
      {!!clipboardItems && !!pasteNodes && (
        <>
          <RecursiveDropdownMenuItem
            item={{
              content: 'Paste',
              onClick: () => pasteNodes?.({ x, y }),
              shortcut: `${metaKey} V`,
            }}
          />
          <Separator className={styles.separator} color={1} />
        </>
      )}
      <RecursiveDropdownMenuItem
        item={{
          content: 'Document',
          onClick: () => createDocumentNode(x, y),
          shortcut: 'D',
        }}
      />
      <RecursiveDropdownMenuItem
        item={{
          content: 'Text',
          onClick: () => addFloatingTextNode(x, y),
          shortcut: 'T',
        }}
      />
      <RecursiveDropdownMenuItem
        item={{
          content: 'Frame',
          onClick: () => addFrame(x, y),
          shortcut: 'F',
        }}
      />
      <Separator className={styles.separator} color={1} />
      <RecursiveDropdownMenuItem
        item={{
          content: 'Link',
          onClick: () => launchCreateBookmarkModal(),
        }}
      />
      <RecursiveDropdownMenuItem
        item={{
          content: 'Upload',
          onClick: openFileBrowser,
        }}
      />
    </DropdownMenu>
  );
};

const FileUploader = forwardRef(
  (
    { coordinates }: { coordinates: BoardContextMenuCoordinates },
    ref: Ref<HTMLInputElement>
  ) => {
    const { addMediaNode } = useNodes();
    const workspaceId = useWorkspaceId();
    const projectId = useParams<{ projectId?: string }>().projectId;
    const { handleChange } = useFileUploadWrapper(
      {
        workspaceId,
        projectId,
      },
      {
        onSuccess: async (mediaGroupId: string) => {
          if (!coordinates) return;

          addMediaNode(mediaGroupId, coordinates.x, coordinates.y);
        },
      }
    );

    return (
      <input
        type="file"
        multiple
        ref={ref}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    );
  }
);
