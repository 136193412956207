import { useState } from 'react';
import clsx from 'clsx';

import type { CreateMediaGroupSchema } from '@spaceduck/api';
import { Icon24 } from '@spaceduck/icons';

import { NewMediaGroupDropdown } from '@components/NewMediaGroupDropdown';
import { useCreateMediaGroupActions } from '@hooks/useCreateMediaGroupActions';
import Button from '@ui/Button';
import Tooltip from '@ui/Tooltip';
import styles from './CollectionsCTACard.module.scss';

const { Add, Document, Frame, Image, Link, PDF } = Icon24;

export default function CollectionsCTACard({
  mediaGroupAttributes,
}: {
  mediaGroupAttributes?: Omit<CreateMediaGroupSchema, 'kind' | 'workspaceId'>;
}) {
  const {
    createBoard,
    createDocument,
    createLink,
    fileInputRef,
    handleChange,
    openCreateBookmarkModal,
  } = useCreateMediaGroupActions(mediaGroupAttributes);
  const [mouseOver, setMouseOver] = useState(false);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const quickActions: {
    icon: React.ReactNode;
    label: string;
    onClick?: () => void;
  }[] = [
    {
      icon: <Document size={20} />,
      label: 'Create a note',
      onClick: createDocument,
    },
    {
      icon: <PDF size={20} />,
      label: 'Upload a file',
      onClick: () => fileInputRef.current?.click(),
    },
    {
      icon: <Image size={20} />,
      label: 'Upload an image',
      onClick: () => fileInputRef.current?.click(),
    },
    {
      icon: <Link size={20} />,
      label: 'Add a bookmark',
      onClick: () =>
        openCreateBookmarkModal({
          onSubmit: (data) => createLink(data.url),
        }),
    },
    {
      icon: <Frame size={20} />,
      label: 'Create a board',
      onClick: createBoard,
    },
  ];

  return (
    <div
      className={clsx(
        styles.container,
        (mouseOver || dropdownIsOpen) && styles.isFocused
      )}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <NewMediaGroupDropdown
        open={dropdownIsOpen}
        setOpen={setDropdownIsOpen}
        className={styles.dropdown}
      >
        <Button
          className={styles.button}
          iconBefore={<Add />}
          size="md"
          type="button"
          variant="outlined"
        >
          Add a new item
        </Button>
      </NewMediaGroupDropdown>

      <div className={styles.action}>
        {quickActions.map(({ icon, label, onClick }, idx) => (
          <Tooltip key={idx} content={label}>
            <Button
              className={styles.quickAction}
              onClick={onClick}
              size="sm"
              variant="icon"
            >
              {icon}
            </Button>
          </Tooltip>
        ))}
      </div>
      <input
        type="file"
        multiple
        ref={fileInputRef}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </div>
  );
}
