import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 32 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3754_19399)">
        <path
          d="M27.6314 32.0002H4.36841C3.61236 32.0002 3 31.3776 3 30.6089V1.39131C3 0.622613 3.61236 0 4.36841 0H20.7893L28.9998 8.34789V30.6089C28.9998 31.3776 28.3874 32.0002 27.6314 32.0002Z"
          fill="#FFB202"
        />
        <path
          d="M20.7896 0V6.95657C20.7896 7.72528 21.4019 8.34789 22.158 8.34789H29L20.7896 0Z"
          fill="#FED89E"
        />
        <path
          d="M22.4581 27.9976C20.4412 27.9976 18.8586 26.351 18.8586 23.956C18.8586 21.6893 20.364 20 22.4002 20C24.1372 20 25.4206 21.112 25.9031 22.8334H23.7415C23.5678 22.2453 23.0564 21.8069 22.3519 21.8069C21.4545 21.8069 20.7307 22.5019 20.7307 24.0095C20.7307 25.2925 21.3001 26.2869 22.5546 26.2869C23.3459 26.2869 23.838 25.795 24.0407 25.1535H22.3712V23.5604H25.9899C26.1347 26.0944 24.7548 27.9976 22.4581 27.9976Z"
          fill="#010318"
        />
        <path
          d="M18.9183 20.1606L16.4479 27.8374H14.46L12.0089 20.1606H13.9871L15.5118 25.4745L17.0848 20.1606H18.9183Z"
          fill="#010318"
        />
        <path
          d="M12.0313 25.324C12.0313 26.9064 10.8926 27.9435 9.0881 27.9435C7.28356 27.9435 6.12557 26.885 6.00012 25.185H7.84326C7.86256 25.9121 8.29681 26.3718 9.0495 26.3718C9.67675 26.3718 10.0917 26.1045 10.0917 25.6234C10.0917 25.2919 9.79255 25.046 9.38725 24.9605L8.12311 24.6932C7.01337 24.458 6.27997 23.7416 6.27997 22.5014C6.27997 21.0686 7.41866 20.0636 8.9723 20.0636C10.6224 20.0636 11.8094 21.1114 11.9155 22.7687H10.0724C10.0241 22.0737 9.59955 21.6246 8.9916 21.6246C8.45121 21.6246 8.12311 21.9454 8.12311 22.3624C8.12311 22.7045 8.43191 22.9077 8.7986 22.9825L10.1399 23.2712C11.3751 23.5385 12.0313 24.2121 12.0313 25.324Z"
          fill="#010318"
        />
      </g>
      <defs>
        <clipPath id="clip0_3754_19399">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
