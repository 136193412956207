import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8475_5091)">
        <path
          d="M5.00025 12.9998C4.74425 12.9998 4.48825 12.9018 4.29325 12.7068L0.29325 8.70676C-0.09775 8.31576 -0.09775 7.68376 0.29325 7.29276L4.29325 3.29276C4.68425 2.90176 5.31625 2.90176 5.70725 3.29276C6.09825 3.68376 6.09825 4.31576 5.70725 4.70676L2.41425 7.99976L5.70725 11.2928C6.09825 11.6838 6.09825 12.3158 5.70725 12.7068C5.51225 12.9018 5.25625 12.9998 5.00025 12.9998Z"
          fill={color}
        />
        <path
          d="M11.0002 12.9998C10.7442 12.9998 10.4882 12.9018 10.2932 12.7068C9.90225 12.3158 9.90225 11.6838 10.2932 11.2928L13.5863 7.99976L10.2932 4.70676C9.90225 4.31576 9.90225 3.68376 10.2932 3.29276C10.6842 2.90176 11.3162 2.90176 11.7072 3.29276L15.7072 7.29276C16.0982 7.68376 16.0982 8.31576 15.7072 8.70676L11.7072 12.7068C11.5122 12.9018 11.2562 12.9998 11.0002 12.9998Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_8475_5091">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
