import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.132 7.24225C12.672 6.593 13 5.80025 13 4.9375C13 2.76663 10.981 1 8.5 1H3.5C2.671 1 2 1.58713 2 2.3125V13.6875C2 14.4129 2.671 15 3.5 15H9C11.757 15 14 13.0374 14 10.625C14 9.25475 13.262 8.0455 12.132 7.24225ZM5 3.625H8.5C9.327 3.625 10 4.21388 10 4.9375C10 5.66113 9.327 6.25 8.5 6.25H5V3.625ZM9 12.375H5V8.875H9C10.103 8.875 11 9.65987 11 10.625C11 11.5901 10.103 12.375 9 12.375Z"
        fill={color}
      />
    </svg>
  );
}
