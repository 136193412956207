import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.189 8.937V13.059C9.66984 12.9221 9.1266 12.9034 8.5993 13.0044C8.07199 13.1054 7.57407 13.3234 7.14222 13.6424C6.71038 13.9613 6.35562 14.3732 6.10411 14.8475C5.85259 15.3218 5.71073 15.8466 5.68898 16.383C5.65924 16.8449 5.72874 17.3078 5.89281 17.7405C6.05687 18.1733 6.3117 18.5659 6.64015 18.892C6.9686 19.2181 7.36306 19.4701 7.79701 19.631C8.23096 19.7919 8.69433 19.8581 9.15598 19.825C9.6223 19.8608 10.0908 19.7949 10.5292 19.6319C10.9675 19.4689 11.3653 19.2126 11.6949 18.8809C12.0246 18.5491 12.2783 18.1498 12.4385 17.7104C12.5988 17.271 12.6617 16.8021 12.623 16.336V0H16.7C17.393 4.315 19.551 5.316 22.44 5.778V9.913C20.4379 9.74881 18.5066 9.09645 16.815 8.013V16.18C16.815 19.88 14.625 24 9.18798 24C8.16794 23.9955 7.15905 23.7875 6.22046 23.3881C5.28187 22.9886 4.43245 22.4059 3.72201 21.6739C3.01156 20.942 2.45438 20.0755 2.08313 19.1254C1.71187 18.1754 1.53401 17.1607 1.55998 16.141C1.59228 15.0794 1.84923 14.0366 2.31389 13.0815C2.77854 12.1265 3.44035 11.2807 4.25568 10.6C5.07102 9.9193 6.02136 9.41915 7.04407 9.1325C8.06679 8.84586 9.13864 8.77923 10.189 8.937Z"
        fill={color}
      />
    </svg>
  );
}
