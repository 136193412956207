import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 22H22.0039"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.57143 16.7008C3.56669 15.6961 3.56669 14.0685 4.57143 13.0637L14.8816 2.75355C15.8863 1.74882 17.5139 1.74882 18.5187 2.75355C19.5234 3.75829 19.5234 5.38588 18.5187 6.39062L8.20849 16.7008C7.20466 17.7046 5.57617 17.7046 4.57143 16.7008Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.57144 16.7008L2.9093 18.3629"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1853 8.36099L21.0946 9.27026L16.5482 13.8166"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
