import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 32,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 32 32"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 16C26.9714 15.6927 26.8287 15.4073 26.6 15.2L8.60002 2.20001C8.44296 2.08518 8.25763 2.01522 8.06387 1.9976C7.87011 1.97999 7.67521 2.01539 7.50001 2.10001C7.3464 2.19447 7.21971 2.32689 7.13213 2.48452C7.04456 2.64216 6.99906 2.81969 7.00001 3.00001V29C6.99906 29.1803 7.04456 29.3579 7.13213 29.5155C7.21971 29.6731 7.3464 29.8056 7.50001 29.9C7.65356 29.9808 7.82719 30.0156 8.00001 30C8.2125 29.9793 8.41758 29.9109 8.60002 29.8L26.6 16.8C26.8287 16.5927 26.9714 16.3073 27 16Z"
        fill={color}
      />
    </svg>
  );
}
