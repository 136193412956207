import type { MediaGroupDTO, MediaGroupDetailDTO } from '@spaceduck/api';

import { urlFor } from '@/urls';

export function getContainerInfoFromMediaGroup(mediaGroup: MediaGroupDTO) {
  if (mediaGroup.linkUrl) {
    return {
      kind: 'domain',
      url: mediaGroup.linkUrl,
      urlSource: mediaGroup.linkUrlSource,
    } as const;
  }

  return { kind: 'workspace', ...mediaGroup.workspace } as const;
}

export const getCloseTo = (mediaGroup?: MediaGroupDTO | MediaGroupDetailDTO) => {
  if (!mediaGroup) {
    return '/';
  }

  if (mediaGroup.project) {
    return urlFor('spaceLibrary', { projectId: mediaGroup.project.id });
  }

  return urlFor('workspaceRepository', {
    workspaceId: mediaGroup.workspace.id,
  });
};

export const calcCardHeight = ({
  columnWidth,
  fixedHeight,
  isNotes = false,
  thumbNailHeight,
  thumbNailWidth,
}: {
  columnWidth: number;
  fixedHeight?: number;
  isNotes?: boolean;
  thumbNailHeight?: number | null;
  thumbNailWidth?: number | null;
}) => {
  if (fixedHeight !== undefined) return fixedHeight;

  const ITEM_BORDER = 1;
  const ITEM_PADDING = 16;
  const DETAIL_TOP_HEIGHT = 64;
  const DETAIL_BOTTOM_HEIGHT = 39;
  const imageWidth = columnWidth - 2 * (ITEM_PADDING + ITEM_BORDER);
  const imageHeight = isNotes
    ? 300
    : ((thumbNailHeight ?? 1) / (thumbNailWidth ?? 1)) * imageWidth;
  const cardHeight =
    imageHeight +
    DETAIL_TOP_HEIGHT +
    DETAIL_BOTTOM_HEIGHT +
    3 * ITEM_PADDING +
    2 * ITEM_BORDER;

  return cardHeight;
};
