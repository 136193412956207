export const connectionLineColors = [
  'yellow',
  'amber',
  'orange',
  'red',
  'pink',
  'purple',
  'green',
  'sky',
  'blue',
  'grey',
  'white',
  'default',
] as const;

export type ConnectionLineColor = (typeof connectionLineColors)[number];

export const connectionLineColorsAsHex: Record<ConnectionLineColor, string> = {
  yellow: '#FFDF6B',
  amber: '#FFC46B',
  orange: '#FBA179',
  red: '#F16F6F',
  pink: '#ED8CBD',
  purple: '#937EE7',
  green: '#9DD194',
  sky: '#B8E3FF',
  blue: '#7A95F6',
  grey: '#232323',
  white: '#FFFFFF',
  default: '#B5B7CA',
};

export const nodeBorderColors = [
  'grey',
  'lavender',
  'blue',
  'green',
  'perryWinkle',
  'pink',
  'red',
  'peach',
  'roastedSesame',
  'gold',
] as const;

export type NodeBorderColor = (typeof nodeBorderColors)[number];
