import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3750_24947)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7631 0.236947C16.079 0.552877 16.079 1.0651 15.7631 1.38103L7.85295 9.29114C7.53702 9.60707 7.0248 9.60707 6.70887 9.29114C6.39294 8.97521 6.39294 8.46299 6.70887 8.14706L14.619 0.236947C14.9349 -0.0789825 15.4471 -0.0789825 15.7631 0.236947Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.62927 0.808989C8.62927 0.362197 8.99147 0 9.43826 0H15.1911C15.6379 0 16.0001 0.362197 16.0001 0.808989V6.5618C16.0001 7.00859 15.6379 7.37079 15.1911 7.37079C14.7443 7.37079 14.3821 7.00859 14.3821 6.5618V1.61798H9.43826C8.99147 1.61798 8.62927 1.25578 8.62927 0.808989Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.808989 0C1.25578 0 1.61798 0.362197 1.61798 0.808989V13.7528C1.61798 13.9197 1.68427 14.0797 1.80227 14.1977C1.92027 14.3157 2.08031 14.382 2.24719 14.382H15.191C15.6378 14.382 16 14.7442 16 15.191C16 15.6378 15.6378 16 15.191 16H2.24719C1.6512 16 1.07962 15.7632 0.658187 15.3418C0.236757 14.9204 0 14.3488 0 13.7528V0.808989C0 0.362197 0.362197 0 0.808989 0Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3750_24947">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
