import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2725_66274)">
        <path
          d="M3.8 6.6C5.3464 6.6 6.6 5.3464 6.6 3.8C6.6 2.2536 5.3464 1 3.8 1C2.2536 1 1 2.2536 1 3.8C1 5.3464 2.2536 6.6 3.8 6.6Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.2 6.6C13.7464 6.6 15 5.3464 15 3.8C15 2.2536 13.7464 1 12.2 1C10.6536 1 9.40002 2.2536 9.40002 3.8C9.40002 5.3464 10.6536 6.6 12.2 6.6Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.8 15C5.3464 15 6.6 13.7464 6.6 12.2C6.6 10.6536 5.3464 9.39999 3.8 9.39999C2.2536 9.39999 1 10.6536 1 12.2C1 13.7464 2.2536 15 3.8 15Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.2 15C13.7464 15 15 13.7464 15 12.2C15 10.6536 13.7464 9.39999 12.2 9.39999C10.6536 9.39999 9.40002 10.6536 9.40002 12.2C9.40002 13.7464 10.6536 15 12.2 15Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2725_66274">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
