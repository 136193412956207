import type { IconProps } from '../types/IconProps';

export default function Icon({ className, color = '#1B9754', size = 16 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_862_61213)">
        <path
          d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM11.707 6.707L7.707 10.707C7.512 10.902 7.256 11 7 11C6.744 11 6.488 10.902 6.293 10.707L4.293 8.707C3.902 8.316 3.902 7.684 4.293 7.293C4.684 6.902 5.316 6.902 5.707 7.293L7 8.586L10.293 5.293C10.684 4.902 11.316 4.902 11.707 5.293C12.098 5.684 12.098 6.316 11.707 6.707Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_862_61213">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
