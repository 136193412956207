import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4884_44314)">
        <path
          d="M2.5 0.5H1.5C0.947715 0.5 0.5 0.947715 0.5 1.5V2.5C0.5 3.05228 0.947715 3.5 1.5 3.5H2.5C3.05228 3.5 3.5 3.05228 3.5 2.5V1.5C3.5 0.947715 3.05228 0.5 2.5 0.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 0.5H7.5C6.94772 0.5 6.5 0.947715 6.5 1.5V2.5C6.5 3.05228 6.94772 3.5 7.5 3.5H8.5C9.05228 3.5 9.5 3.05228 9.5 2.5V1.5C9.5 0.947715 9.05228 0.5 8.5 0.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 0.5H13.5C12.9477 0.5 12.5 0.947715 12.5 1.5V2.5C12.5 3.05228 12.9477 3.5 13.5 3.5H14.5C15.0523 3.5 15.5 3.05228 15.5 2.5V1.5C15.5 0.947715 15.0523 0.5 14.5 0.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 6.5H1.5C0.947715 6.5 0.5 6.94772 0.5 7.5V8.5C0.5 9.05228 0.947715 9.5 1.5 9.5H2.5C3.05228 9.5 3.5 9.05228 3.5 8.5V7.5C3.5 6.94772 3.05228 6.5 2.5 6.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 6.5H7.5C6.94772 6.5 6.5 6.94772 6.5 7.5V8.5C6.5 9.05228 6.94772 9.5 7.5 9.5H8.5C9.05228 9.5 9.5 9.05228 9.5 8.5V7.5C9.5 6.94772 9.05228 6.5 8.5 6.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 6.5H13.5C12.9477 6.5 12.5 6.94772 12.5 7.5V8.5C12.5 9.05228 12.9477 9.5 13.5 9.5H14.5C15.0523 9.5 15.5 9.05228 15.5 8.5V7.5C15.5 6.94772 15.0523 6.5 14.5 6.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 12.5H7.5C6.94772 12.5 6.5 12.9477 6.5 13.5V14.5C6.5 15.0523 6.94772 15.5 7.5 15.5H8.5C9.05228 15.5 9.5 15.0523 9.5 14.5V13.5C9.5 12.9477 9.05228 12.5 8.5 12.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4884_44314">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
