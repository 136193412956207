const SUPPORT_EMAIL = 'support@spaceduck.com';

export const getMailToLink = ({
  to = SUPPORT_EMAIL,
  subject,
  body,
}: { to?: string; subject: string; body?: string }) => {
  const url = new URL(`mailto:${to}`);
  url.searchParams.set('subject', subject);

  if (body) {
    url.searchParams.set('body', body);
  }
  return url;
};

export const openMailTo = (subject: string, body?: string) => {
  window.open(getMailToLink({ to: SUPPORT_EMAIL, subject, body }).toString());
};
