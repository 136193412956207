import type {
  MediaGroupSortOption,
  MediaGroupWithCategoryFields,
} from '@spaceduck/api';
import { pluralize } from '@spaceduck/utils';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import type {
  AvailableTypes,
  DeleteCellFn,
  Row,
  TableColumnMeta,
  TableRowReducerAction,
  UpdateCellFn,
} from '@/types/Category';
import { urlFor } from '@/urls';
import createToast from '@/utils/createToast';
import { useBulkDeleteMediaGroups, useMediaGroupCategoryItems } from '@api/mediaGroup';
import { useSetMediaGroupCategoryFieldInstances } from '@api/mediaGroupCategory';
import { catchApiErrorIntoToast } from '@api/util';
import { useEditMediaGroupLabelModal } from '@components/category/Forms';
import { useBatchUpdate } from '@hooks/useBatchUpdate';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { useDeleteConfirmModal } from '@ui/ConfirmModal';
import { copyTextToClipboard } from '@utils/copyToClipboard';

export const useCategoryRow = ({
  categoryProperties,
  categoryId,
  searchQuery,
  sortBy = 'name',
}: {
  categoryProperties: TableColumnMeta[];
  categoryId: string;
  searchQuery: string;
  sortBy: MediaGroupSortOption;
}) => {
  const {
    data: mediaGroupData,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetchingNextPage,
    isLoading,
    isRefetching,
  } = useMediaGroupCategoryItems(categoryId, sortBy);

  useEffect(() => {
    refetch();
  }, [sortBy]);

  const mediaGroups = mediaGroupData?.pages.flatMap((page) => page.mediaGroups);

  const convertMediaGroupToRow = (mg: MediaGroupWithCategoryFields): Row => {
    return {
      _id: mg.id,
      _mediaGroup: mg,
      _data: mg.fields.reduce<Record<string, AvailableTypes>>((prev, field) => {
        prev[field.fieldId] = field.instances;
        return prev;
      }, {}),
    };
  };

  const initialData: Row[] = mediaGroups?.map(convertMediaGroupToRow) || [];

  const { handleCategoryChange } = useBatchUpdate();
  const { mutateAsync: deleteMediaGroups } = useBulkDeleteMediaGroups({
    hideDefaultToasts: true,
  });

  const dataReducer = (state: Row[], action: TableRowReducerAction): Row[] => {
    switch (action.type) {
      case 'DELETE':
        if (action.payload) {
          return state.filter((row) => !(action.payload as string[]).includes(row._id));
        }
        return state;
      case 'REMOVE':
        if (action.payload) {
          return state.filter((row) => !(action.payload as string[]).includes(row._id));
        }
        return state;
      case 'SET':
        if (action.payload) {
          return [...action.payload];
        }
        return state;
      case 'UPDATE_CELL': {
        const { propertyId, rowIndex, value } = action.payload;
        return state.map((row, idx) => {
          if (idx !== rowIndex) return row;
          return { ...row, _data: { ...row._data, [propertyId]: value } };
        });
      }
      default:
        return state;
    }
  };

  const handleDeleteRows = async (mediaGroupIds: string[]) => {
    await deleteMediaGroups(mediaGroupIds);
    setData({ type: 'DELETE', payload: mediaGroupIds });
  };

  const handleRemoveRows = async (mediaGroupIds: string[]) => {
    await handleCategoryChange(mediaGroupIds, null);
    setData({ type: 'REMOVE', payload: mediaGroupIds });
  };

  const { mutateAsync: setFieldInstances } = useSetMediaGroupCategoryFieldInstances();

  const getMediaGroupIdByRowIndex = (index: number) => {
    if (filteredRows?.[index]) {
      return filteredRows[index]?._id;
    }

    return null;
  };

  const [data, setData] = useReducer(dataReducer, initialData);

  const handleCellUpdate: UpdateCellFn = async ({ rowIndex, propertyId, value }) => {
    const mediaGroupId = getMediaGroupIdByRowIndex(rowIndex);
    const dataIndex = data.findIndex((row) => row._mediaGroup?.id === mediaGroupId);

    if (!mediaGroupId) {
      createToast({
        titleText: 'Update failed',
        bodyText: 'Media group not found',
        iconVariant: 'warning',
      });
      return;
    }

    await setFieldInstances({
      fieldId: propertyId,
      mediaGroupId,
      instances: value,
    });

    // TODO: set to updated value from server
    setData({
      type: 'UPDATE_CELL',
      payload: {
        propertyId,
        rowIndex: dataIndex,
        value,
      },
    });
  };

  const handleCellDelete: DeleteCellFn = async ({ rowIndex, propertyId }) => {
    const mediaGroupId = getMediaGroupIdByRowIndex(rowIndex);

    if (!mediaGroupId) {
      createToast({
        titleText: 'Update failed',
        bodyText: 'Media group not found',
        iconVariant: 'warning',
      });
      return;
    }

    await setFieldInstances({
      fieldId: propertyId,
      mediaGroupId,
      instances: [],
    });

    // TODO: set to updated value from server
    setData({
      type: 'UPDATE_CELL',
      payload: {
        propertyId,
        rowIndex,
        value: [],
      },
    });
  };

  useEffect(() => {
    if (!(isRefetching || isLoading)) {
      const existingMediaGroupIds = data?.map(({ _id }) => _id).join();

      const updatedMediaGroupIds = mediaGroups?.map(({ id }) => id).join();

      if (existingMediaGroupIds !== updatedMediaGroupIds) {
        const updatedData =
          mediaGroups?.map((mediaGroup) => {
            return (
              data.find((row) => row._id === mediaGroup.id) ??
              convertMediaGroupToRow(mediaGroup)
            );
          }) ?? [];

        setData({
          type: 'SET',
          payload: updatedData,
        });
      }
    }
  }, [mediaGroups, sortBy]);

  const [selectAllRows, setSelectAllRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const toggleSelectedRow = (id: string) => {
    setSelectedRows((selectedRows: string[]) => {
      const exists = !!selectedRows.find((rowId) => rowId === id);

      if (exists) {
        setSelectAllRows(false);
        return selectedRows.filter((rowId) => rowId !== id);
      }
      return [...selectedRows, id];
    });
  };

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const getMediaGroupById = useCallback(
    (id: string) => {
      return mediaGroups?.find((mediaGroup) => mediaGroup.id === id) ?? null;
    },
    [mediaGroups]
  );

  useEffect(() => {
    if (!isLoading && !hasNextPage && !isFetchingNextPage) {
      const payload: Row[] = data
        .filter((item) => !!getMediaGroupById(item._id))
        .map((item) => ({
          ...item,
          _mediaGroup: getMediaGroupById(item._id) ?? null,
        }));
      setData({ type: 'SET', payload });
    }
  }, [isRefetching, hasNextPage, isFetchingNextPage, isLoading]);

  const filteredRows = useMemo(() => {
    const query = new RegExp(searchQuery, 'i');
    if (!data) return [];

    return data.filter((item) => {
      if (!searchQuery) return item;

      return query.test(item._mediaGroup?.label ?? '');
    });
  }, [data, searchQuery, sortBy]);

  const handleUnToggleAll = () => {
    setSelectedRows([]);
    setSelectAllRows(false);
  };

  const handleToggleAll = () => {
    if (selectAllRows) {
      handleUnToggleAll();
    } else {
      setSelectedRows(filteredRows.map((item) => item._id));
      setSelectAllRows(true);
    }
  };

  const sortedColumns = useMemo(
    () =>
      categoryProperties
        .filter((item) => item.id !== '_id')
        .sort((a, b) => a.order - b.order),
    [categoryProperties]
  );

  const getCurrentSelectedItem = () => {
    const item = selectedRows[0];
    if (!item) return null;

    const mediaGroup = mediaGroups?.find(({ id }) => item === id);
    return mediaGroup;
  };

  const workspaceId = useWorkspaceId();

  const { open: openEditMediaGroupLabelModal } = useEditMediaGroupLabelModal();
  const { open: openDeleteConfirmModal } = useDeleteConfirmModal({
    title: `Delete ${pluralize(selectedRows.length !== 1, 'items', 'item')}`,
    subtitle: pluralize(
      selectedRows.length !== 1,
      'This action will delete these item from all spaces and boards and move them to the trash.',
      'This action will delete this item from all spaces and boards and move it to the trash.'
    ),
    confirmText: `Yes, delete ${pluralize(selectedRows.length !== 1, 'items', 'item')}`,
    onConfirm: catchApiErrorIntoToast(async () => {
      try {
        await handleDeleteRows(selectedRows);
        setSelectedRows([]);
        if (selectAllRows) {
          setSelectAllRows(false);
        }

        createToast({
          titleText: `${pluralize(selectedRows.length !== 1, 'Items', 'Item')} deleted`,
          bodyText: pluralize(
            selectedRows.length !== 1,
            'These items have been successfully deleted and moved to the trash. They will be permanently deleted in 30 days.',
            'The item has been successfully deleted and moved to the trash. It will be permanently deleted in 30 days.'
          ),
          iconVariant: 'success',
          buttonText: workspaceId ? 'View trash' : undefined,
          onClick: workspaceId
            ? () => window.open(urlFor('workspaceTrashItems', { workspaceId }))
            : undefined,
        });
      } catch (error) {
        console.error('Failed to delete category items', error);
        createToast({
          iconVariant: 'danger',
          titleText: `Unable to delete ${pluralize(selectedRows.length !== 1, 'these items', 'item')}`,
          bodyText: 'Please try again later or contact support',
        });
      }
    }),
  });

  const handleRenameItem = () => {
    const mediaGroup = getCurrentSelectedItem();
    if (mediaGroup) {
      openEditMediaGroupLabelModal({ mediaGroup });
    }
  };

  const handleCopyLink = () => {
    const mediaGroup = getCurrentSelectedItem();
    if (!mediaGroup) return;

    copyTextToClipboard(
      new URL(
        urlFor('mediaGroup', { mediaGroupId: mediaGroup.id }),
        window.location.origin
      ).toString(),
      {
        titleText: 'Link copied',
        bodyText: 'The link has been successfully copied to your clipboard.',
      }
    );
  };

  const handleRemoveItem = async () => {
    await handleRemoveRows(selectedRows);
    setSelectedRows([]);
    if (selectAllRows) {
      setSelectAllRows(false);
    }
  };

  return {
    data,
    filteredRows,
    handleCellDelete,
    handleCellUpdate,
    handleCopyLink,
    handleRenameItem,
    handleRemoveItem,
    handleToggleAll,
    handleUnToggleAll,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isRefetching,
    mediaGroups,
    openDeleteConfirmModal,
    selectAllRows,
    selectedRows,
    setData,
    setSelectedRows,
    sortedColumns,
    toggleSelectedRow,
  };
};
