import Spinner from '@ui/Spinner';
import styles from './TempFileUploadNode.module.scss';

export const TempFileUploadNode = () => {
  return (
    <div className={styles.container}>
      <Spinner />
    </div>
  );
};
