import { css } from '@/lib/css';
import styles from './ProgressBar.module.scss';

export const ProgressBar = ({ value }: { value: number }) => {
  const percent = Math.max(0, Math.min(value, 1)) * 100;

  return (
    <div
      className={styles.progressBar}
      style={css({ '--progress-percent': `${percent}%` })}
    >
      <div className={styles.bar} />
    </div>
  );
};
