import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.5 1.5v12M8.5 2.5v12M4.5 4.5v7M2.469 6.5v4M.5 7.5v2M10.5 4.5v7M12.5 6.5v4M14.5 7.5v2"
      />
    </svg>
  );
}
