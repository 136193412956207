const Logo = ({
  className,
  height = 64,
  width = 64,
}: {
  className?: string;
  height?: number;
  width?: number;
}) => (
  <svg
    className={className}
    fill="none"
    height={height}
    viewBox="0 0 64 64"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_638_73779)">
      <path
        d="M23.2555 10.1802C20.7718 10.9354 18.8114 12.9316 18.0697 15.4605C17.972 15.7941 17.5235 15.7941 17.4316 15.4605C16.6899 12.9316 14.7294 10.9354 12.2458 10.1802C11.9181 10.0807 11.9181 9.62413 12.2458 9.53047C14.7294 8.77531 16.6899 6.77914 17.4316 4.25025C17.5293 3.91658 17.9777 3.91658 18.0697 4.25025C18.8114 6.77914 20.7718 8.77531 23.2555 9.53047C23.5832 9.62998 23.5832 10.0866 23.2555 10.1802Z"
        fill="url(#paint0_linear_638_73779)"
      />
      <path
        d="M59.9999 33.7188C59.9999 34.6645 59.2585 35.425 58.3365 35.425H50.9512C50.5424 44.4827 42.5583 52 33.1484 52C23.7385 52 15.3266 44.0732 15.3266 34.6938V33.7968L12.2185 28.2685C11.7622 27.4495 12.0379 26.4063 12.8268 25.9383C13.6252 25.4703 14.6422 25.7432 15.108 26.5623L18.1781 32.0125H36.2375V22.5063C36.2375 17.2607 40.3912 13 45.5048 13C50.6185 13 54.7722 17.2607 54.7722 22.5063V25.9188C54.7722 26.8645 54.0308 27.625 53.1088 27.625C52.1868 27.625 51.4454 26.8645 51.4454 25.9188V22.5063C51.4454 19.1425 48.784 16.4125 45.5048 16.4125C42.2256 16.4125 39.5642 19.1425 39.5642 22.5063V32.0125H41.2276C42.1496 32.0125 42.891 32.773 42.891 33.7188V34.45C42.891 39.6077 38.4331 43.9563 33.1484 43.9563C29.9642 43.9563 26.8371 42.2793 24.9931 39.5688C24.4704 38.7985 24.6605 37.7358 25.4114 37.1898C26.1622 36.6535 27.1983 36.8485 27.7211 37.6188C28.9567 39.4225 31.0288 40.5438 33.1484 40.5438C36.2185 40.5438 38.9464 38.2428 39.4787 35.425H18.6723C19.1001 42.5133 25.687 48.5875 33.1484 48.5875C40.6098 48.5875 47.6434 42.094 47.6434 34.6938V33.7188C47.6434 32.773 48.3848 32.0125 49.3068 32.0125H58.3365C59.2585 32.0125 59.9999 32.773 59.9999 33.7188Z"
        fill="url(#paint1_linear_638_73779)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_638_73779"
        x="-0.00195312"
        y="0"
        width="64.002"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-4" dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_638_73779"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_638_73779"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_638_73779"
        x1="17.7506"
        y1="4"
        x2="17.7506"
        y2="15.7107"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_638_73779"
        x1="35.999"
        y1="13"
        x2="35.999"
        y2="52"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.7" />
      </linearGradient>
    </defs>
  </svg>
);

export default Logo;
