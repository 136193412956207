import clsx from 'clsx';
import upperFirst from 'lodash/upperFirst';

import { Icon16 } from '@spaceduck/icons';
import styles from './Tag.module.scss';
const { Close } = Icon16;

type TagProps = {
  children?: React.ReactNode;
  className?: string;
  isRounded?: boolean;
  onClick?: () => void;
  onRemoveClick?: () => void;
  removeIconIsHidden?: boolean;
  removeIconPosition?: 'inline' | 'floating';
  size?: 'sm' | 'md' | 'lg';
  style?: React.CSSProperties;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
};

export default function Tag({
  children,
  className,
  onClick,
  isRounded = false,
  onRemoveClick,
  removeIconPosition = 'inline',
  removeIconIsHidden = false,
  size = 'md',
  style,
  variant = 'primary',
}: TagProps) {
  const handleRemoveClick = (ev: React.MouseEvent<HTMLSpanElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    onRemoveClick?.();
  };

  return (
    <button
      className={clsx(
        styles.tag,
        isRounded && styles.rounded,
        onClick && styles.hasAction,
        styles[size],
        styles[variant],
        removeIconIsHidden && styles.removeIconOnHover,
        styles[`removeIcon${upperFirst(removeIconPosition)}`],
        className
      )}
      onClick={onClick}
      style={style}
      type="button"
    >
      {children}
      {onRemoveClick && (
        <span className={styles.icon} onClick={handleRemoveClick} title="Remove">
          <Close size={size === 'lg' ? 16 : 12} />
        </span>
      )}
    </button>
  );
}
