import MediaGroupPreviewList from '@/components/MediaGroupPreviewList';
import type { MediaGroupDTO } from '@spaceduck/api';
import type { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion';
import { forwardRef, useImperativeHandle, useState } from 'react';

export type CommandListRef = {
  onKeyDown: NonNullable<
    ReturnType<NonNullable<SuggestionOptions['render']>>['onKeyDown']
  >;
};

const ContentBlockList = forwardRef<CommandListRef, SuggestionProps>((props, ref) => {
  const [onKeyDown, setOnKeyDown] = useState<(event: KeyboardEvent) => boolean>();
  const insertBackLink = (mediaGroup: MediaGroupDTO) => {
    const { id, label, contentType } = mediaGroup;
    props.editor
      .chain()
      .focus()
      .insertContentAt(props.range, [
        {
          type: 'outgoing-link',
          attrs: {
            id,
            label,
            contentType,
          },
        },
      ])
      .run();
  };

  const insertContentBlock = (mediaGroup: MediaGroupDTO) => {
    const { id, label, contentType } = mediaGroup;
    props.editor
      .chain()
      .focus()
      .insertContentAt(props.range, [
        {
          type: 'content-block',
          attrs: {
            id,
            label,
            contentType,
          },
        },
      ])
      .run();
  };

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      return onKeyDown?.(event) || false;
    },
  }));

  const handleEmbedItem = (mediaGroup: MediaGroupDTO) => {
    if (mediaGroup.contentType === 'document') {
      insertBackLink(mediaGroup);
    } else {
      insertContentBlock(mediaGroup);
    }
  };

  if (!props.editor.isEditable) return null;

  return (
    <MediaGroupPreviewList
      searchTitle={props.query}
      handleSelectItem={handleEmbedItem}
      setOnKeyDown={setOnKeyDown}
    />
  );
});

ContentBlockList.displayName = 'ContentBlockList';

export default ContentBlockList;
