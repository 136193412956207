import { useState } from 'react';
import clsx from 'clsx';

import type { MediaGroupDetailDTO } from '@spaceduck/api';
import { Icon56 } from '@spaceduck/icons';

import styles from './Video.module.scss';

type VideoProps = {
  className?: string;
  mediaGroup: MediaGroupDetailDTO;
  onDoubleClick?: () => void;
};

const { Play } = Icon56;

export default function Video(props: VideoProps) {
  if (props.mediaGroup.kind === 'bookmark') {
    return <YoutubeEmbed {...props} />;
  }

  return <VideoEmbed {...props} />;
}

const YoutubeEmbed = ({ className, mediaGroup }: VideoProps) => {
  if (!mediaGroup.embed?.videoId) return null;

  return (
    <div className={clsx(styles.preview, className)}>
      <div className={styles.videoPadding}>
        <iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className={clsx(styles.video, styles.youtube)}
          referrerPolicy="strict-origin-when-cross-origin"
          src={`https://www.youtube.com/embed/${encodeURIComponent(mediaGroup.embed.videoId)}`}
          title={`${mediaGroup.label}`}
        />
      </div>
    </div>
  );
};

const VideoEmbed = ({ className, mediaGroup, onDoubleClick }: VideoProps) => {
  const [showVideo, setShowVideo] = useState(false);
  const firstMedia = mediaGroup.media[0];

  if (!firstMedia) return null;

  return (
    <div className={clsx(styles.preview, className)}>
      <div
        className={clsx(styles.videoPadding, !showVideo && styles.noTouch)}
        onDoubleClick={() => {
          if (!showVideo) onDoubleClick?.();
        }}
      >
        <video
          autoPlay
          className={clsx(styles.video, showVideo && styles.visible)}
          controls
          onClick={() => setShowVideo(true)}
          onPause={() => setShowVideo(false)}
          poster={firstMedia.posterUrl ?? undefined}
          width="100%"
        >
          <source src={firstMedia.assetUrl} type={firstMedia.mediaType} />
        </video>
        {!!firstMedia.posterUrl && (
          <img className={styles.image} src={firstMedia.posterUrl} alt="" />
        )}
      </div>
      <button className={styles.play} onClick={() => setShowVideo(true)} type="button">
        <Play />
      </button>
    </div>
  );
};
