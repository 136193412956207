import { useCopyMediaGroup } from '@/api/mediaGroup';
import { useDeleteConfirmModal } from '@/components/ui/ConfirmModal';

export default function useMoveMediaGroupConfirmModal({
  onConfirm,
}: {
  onConfirm?: () => void;
} = {}) {
  const { mutateAsync: copyMediaGroup } = useCopyMediaGroup();
  const moveMediaGroupsConfirmModal = useDeleteConfirmModal({
    title: 'Moving space',
    subtitle:
      'Be aware that by transferring this asset to a different space, all associated comments will remain linked. Consequently, contributors may lose access to this asset if they are not granted permissions for the new space.',
    confirmText: 'Move',
    onConfirm: async ({
      mediaGroupIds,
      projectId,
    }: {
      mediaGroupIds: string[];
      projectId?: string;
    }) => {
      copyMediaGroup({
        mediaGroupIds,
        projectId,
        mode: 'move',
      });
      onConfirm?.();
    },
  });

  return moveMediaGroupsConfirmModal;
}
