import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 6.5L6.79289 8.79289C7.18342 9.18342 7.81658 9.18342 8.20711 8.79289L15.5 1.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 7.5V13C13.5 13.828 12.828 14.5 12 14.5H2C1.172 14.5 0.5 13.828 0.5 13V3C0.5 2.172 1.172 1.5 2 1.5H10.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
