import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3C5.447 3 5 3.448 5 4V7H2V4C2 3.448 1.553 3 1 3C0.447 3 0 3.448 0 4V12C0 12.552 0.447 13 1 13C1.553 13 2 12.552 2 12V9H5V12C5 12.552 5.447 13 6 13C6.553 13 7 12.552 7 12V4C7 3.448 6.553 3 6 3Z"
        fill={color}
      />
      <path
        d="M15.0006 10.9998H12.3686C12.7176 10.6258 13.0206 10.3058 13.2706 10.0438L13.4946 9.81082C14.7806 8.46882 15.7096 7.49982 15.7046 6.03682C15.7026 5.53682 15.5856 5.05682 15.3656 4.64582C14.8616 3.70282 13.8306 3.08982 12.6066 3.00682C10.9646 2.89582 9.50464 3.98082 9.12364 5.58682C8.99564 6.12482 9.32764 6.66382 9.86464 6.79082C10.4016 6.91882 10.9406 6.58782 11.0686 6.04982C11.2206 5.40882 11.8076 4.95682 12.4716 5.00282C12.8806 5.03082 13.3936 5.19982 13.6016 5.58882C13.6396 5.65982 13.7036 5.81282 13.7046 6.04382C13.7066 6.67182 13.1986 7.22882 12.0506 8.42682L11.8256 8.66182C11.2486 9.26582 10.3926 10.1738 9.35264 11.3298C9.08764 11.6238 9.02164 12.0448 9.18264 12.4058C9.34264 12.7668 9.70164 12.9988 10.0956 12.9988H14.9996C15.5526 12.9988 15.9996 12.5508 15.9996 11.9988C15.9996 11.4468 15.5526 10.9988 14.9996 10.9988L15.0006 10.9998Z"
        fill={color}
      />
    </svg>
  );
}
