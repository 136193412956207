import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11399_5802)">
        <path
          d="M5.41366 1.33398V1.33398C2.91131 2.59158 1.33203 5.15277 1.33203 7.95336V8.04794C1.33203 10.8485 2.91131 13.4097 5.41366 14.6673V14.6673"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5824 1.33398V1.33398C13.0848 2.59158 14.6641 5.15277 14.6641 7.95336V8.04794C14.6641 10.8485 13.0848 13.4097 10.5824 14.6673V14.6673"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11399_5802">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
