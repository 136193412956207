import { memo, useContext } from 'react';
import { type ControlProps, Panel, useReactFlow, useStore } from '@xyflow/react';
import clsx from 'clsx';
import { useHotkeys } from 'react-hotkeys-hook';

import { Icon12, Icon16, Icon24 } from '@spaceduck/icons';

import Button from '@ui/Button';
import Popover from '@ui/Popover';
import { BoardStoreContext } from '../context/boardContext';
import { useNodes } from '../hooks/useNodes';
import { useBoardStore } from '../hooks/useBoardStore';
import { useHistory } from '../hooks/useHistory';
import styles from './ZoomControls.module.scss';
import { useZoomInHotkey, useZoomOutHotkey } from '@/hooks/useShortcuts';

const { TinyDownArrow } = Icon12;
const { Add, Hand, MinusRemove, ZoomTo100, ZoomToFit, ZoomToSelection } = Icon16;
const { Redo, Undo } = Icon24;

function ControlsComponent({
  'aria-label': ariaLabel = 'React Flow controls',
  position = 'bottom-left',
  showZoom = true,
  style,
}: ControlProps) {
  const context = useContext(BoardStoreContext);
  const storeState = context?.store?.temporal.getState();
  const { redo, undo } = useHistory();

  const currentZoom = useStore(({ transform }) => transform[2]);
  const minZoomReached = useStore(({ transform, minZoom }) => transform[2] <= minZoom);
  const maxZoomReached = useStore(({ transform, maxZoom }) => transform[2] >= maxZoom);

  const { fitView, zoomTo } = useReactFlow();
  const { selectedNodes } = useNodes();
  const panOnDrag = useBoardStore(({ panOnDrag }) => panOnDrag);
  const setPanOnDrag = useBoardStore(({ setPanOnDrag }) => setPanOnDrag);
  const zoomLevels = [0, 0.05, 0.1, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 2];

  const zoomIn = () => {
    const level = zoomLevels.find((level) => {
      return currentZoom < level;
    });
    if (level) zoomTo(level);
  };

  const zoomOut = () => {
    const level = [...zoomLevels].reverse().find((level) => currentZoom > level);
    if (level) zoomTo(level);
  };

  const handleZoomIn = () => {
    zoomIn();
  };

  const handleZoomOut = () => {
    zoomOut();
  };

  const handleZoomTo100 = () => {
    zoomTo(1);
  };

  const handleZoomToFit = () => {
    fitView();
  };

  const handleZoomToSelection = () => {
    fitView({
      nodes: selectedNodes.map((nodeId) => ({ id: nodeId })),
    });
  };

  const handlePanSelection = () => {
    setPanOnDrag(!panOnDrag);
  };

  useHotkeys('Shift + 0', () => {
    handleZoomTo100();
  });

  useHotkeys('Shift + 1', () => {
    handleZoomToFit();
  });

  useHotkeys('Shift + 2', () => {
    handleZoomToSelection();
  });

  useZoomInHotkey(handleZoomIn, {
    preventDefault: true,
  });

  useZoomOutHotkey(handleZoomOut, {
    preventDefault: true,
  });

  const handleUndo = () => {
    undo();
  };

  const handleRedo = () => {
    redo();
  };

  return (
    <Panel
      aria-label={ariaLabel}
      className={clsx('react-flow__controls', styles.controls)}
      data-testid="rf__controls"
      position={position}
      style={style}
    >
      {showZoom && (
        <>
          <Button
            aria-label="zoom out"
            disabled={minZoomReached}
            onClick={handleZoomOut}
            size="xs"
            title="zoom out"
            variant="icon"
          >
            <MinusRemove />
          </Button>
          <Popover
            popoverContentProps={{
              align: 'start',
              alignOffset: -48,
              sideOffset: 8,
            }}
            showArrow={false}
            trigger={
              <Button
                className={styles.zoomDropdown}
                iconAfter={<TinyDownArrow />}
                variant="ghost"
              >{`${(currentZoom * 100).toFixed(0)}%`}</Button>
            }
          >
            <div className={styles.popoverContent}>
              <PopoverButton
                onClick={handlePanSelection}
                className={clsx(styles.paddedShortKey, panOnDrag && styles.isActive)}
                icon={<Hand />}
                label="Hand"
                shortKey={['Space']}
              />
              <PopoverButton
                onClick={handleZoomTo100}
                icon={<ZoomTo100 />}
                label="Zoom to 100%"
                shortKey={['⇧', '0']}
              />
              <PopoverButton
                onClick={handleZoomToFit}
                icon={<ZoomToFit />}
                label="Zoom to fit"
                shortKey={['⇧', '1']}
              />
              <PopoverButton
                onClick={handleZoomToSelection}
                icon={<ZoomToSelection />}
                label="Zoom to selection"
                shortKey={['⇧', '2']}
              />
              <div className={styles.divider} />
              <div className={styles.actions}>
                <div className={styles.zoomActions}>
                  <Button
                    aria-label="zoom out"
                    disabled={minZoomReached}
                    onClick={handleZoomOut}
                    size="xs"
                    title="zoom out"
                    variant="icon"
                  >
                    <MinusRemove />
                  </Button>
                  <Button
                    className={styles.zoomDropdown}
                    variant="ghost"
                  >{`${(currentZoom * 100).toFixed(0)}%`}</Button>
                  <Button
                    aria-label="zoom in"
                    disabled={maxZoomReached}
                    title="zoom in"
                    onClick={handleZoomIn}
                    size="xs"
                    variant="icon"
                  >
                    <Add />
                  </Button>
                </div>
                {storeState && (
                  <div className={styles.historyActions}>
                    <Button
                      aria-label="undo"
                      disabled={!storeState.pastStates.length}
                      onClick={handleUndo}
                      title="undo"
                      variant="icon"
                    >
                      <Undo size={20} />
                    </Button>
                    <Button
                      aria-label="redo"
                      disabled={!storeState.futureStates.length}
                      onClick={handleRedo}
                      title="redo"
                      variant="icon"
                    >
                      <Redo size={20} />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Popover>
          <Button
            aria-label="zoom in"
            disabled={maxZoomReached}
            title="zoom in"
            onClick={handleZoomIn}
            size="xs"
            variant="icon"
          >
            <Add />
          </Button>
        </>
      )}
    </Panel>
  );
}

const PopoverButton = ({
  className,
  icon,
  label,
  onClick,
  shortKey,
}: {
  className?: string;
  icon?: React.ReactNode;
  label: string;
  onClick?: () => void;
  shortKey?: string[];
}) => {
  return (
    <Button
      className={clsx(styles.popoverButton, className)}
      onClick={onClick}
      variant="ghost"
    >
      {!!icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.label}>{label}</span>
      {!!shortKey && (
        <span className={styles.shortKey}>
          {shortKey.map((char, idx) => {
            return (
              <span className={styles.shortKeyChar} key={idx}>
                {char}
              </span>
            );
          })}
        </span>
      )}
    </Button>
  );
};

ControlsComponent.displayName = 'Controls';

const ZoomControls = memo(ControlsComponent);
export default ZoomControls;
