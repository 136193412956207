import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.76051 3.25318C9.76083 1.84097 11.4817 0.999985 14.4001 0.999985C14.7315 0.999985 15.0001 1.26862 15.0001 1.59998C15.0001 1.93136 14.7315 2.19998 14.4001 2.19998C11.7185 2.19998 10.4394 2.959 9.73971 3.94679C8.9934 5.00045 8.80044 6.43494 8.59548 8.07437C8.59222 8.10057 8.58896 8.12678 8.58569 8.15309C8.39321 9.69407 8.1774 11.423 7.23973 12.7468C6.23942 14.1589 4.5185 15 1.60012 15C1.26875 15 1.00012 14.7314 1.00012 14.4C1.00012 14.0686 1.26875 13.8 1.60012 13.8C4.28173 13.8 5.56081 13.0409 6.2605 12.0532C7.00684 10.9995 7.19981 9.56505 7.40475 7.92556C7.40801 7.89938 7.41129 7.87315 7.41457 7.84686C7.60696 6.3059 7.82284 4.57693 8.76051 3.25318Z"
        fill={color}
      />
    </svg>
  );
}
