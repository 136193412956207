import {
  type ProjectDTO,
  type ProjectDetailDTO,
  type ProjectMode,
  projectModeSchema,
} from '@spaceduck/api';
import { clsx } from 'clsx';
import { useId } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { urlFor } from '@/urls';
import { useCreateProject, useDeleteProject, usePatchProject } from '@api/project';
import { type IsCapable, catchApiErrorIntoToast } from '@api/util';
import { useUpgradePlanModal } from '@components/UpgradePlanModal';
import { useModalManager } from '@context/ModalManagerContext';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { Icon16, Icon24 } from '@spaceduck/icons';
import Button from '@ui/Button';
import { useDeleteConfirmModal } from '@ui/ConfirmModal';
import Dialog from '@ui/Dialog';
import RadioGroup, { RadioGroupItem } from '@ui/RadioGroup';
import Spinner from '@ui/Spinner';
import createToast from '@utils/createToast';
import styles from './CreateProjectsModal.module.scss';

const { Project: ProjectIcon } = Icon16;
const { TrashCan } = Icon24;

type ManageProjectFormData = {
  label: string;
  description: string;
  mode: ProjectMode;
};

export const projectModes: Record<ProjectMode, { label: string; description: string }> =
  {
    open: {
      label: 'Public',
      description: 'Anyone from your workspace can see and join this space.',
    },
    private: {
      label: 'Private',
      description: 'Only people who are explicitly invited can see this space.',
    },
    default: {
      label: 'All-access',
      description: 'Everyone from your workspace will automatically join this space.',
    },
  };

function ManageProjectsModal({
  isOpen = true,
  closeModal,
  project,
  redirectOnCreate,
  redirectOnDelete = true,
  isStarred = false,
}: {
  isOpen?: boolean;
  closeModal?: () => void;
  project?: ProjectDetailDTO | ProjectDTO | null;
  redirectOnCreate?: boolean;
  redirectOnDelete?: boolean;
  isStarred?: boolean;
}) {
  const workspaceId = useWorkspaceId();
  const navigate = useNavigate();
  const canDelete =
    project?.capabilities?.find(({ capability }) => capability === 'delete')?.capable ??
    false;
  const { mutateAsync: deleteProject } = useDeleteProject();
  const { open: openDeleteConfirmModal } = useDeleteConfirmModal({
    title: 'Delete space',
    subtitle: 'This action will delete this Space and move it to the trash.',
    confirmText: 'Yes, delete space',
    onConfirm: async () => {
      if (!project) {
        return;
      }
      await deleteProject(project.id);
      createToast({
        bodyText: 'Space deleted',
        iconVariant: 'success',
      });
      closeModal?.();
      if (redirectOnDelete) {
        navigate(
          workspaceId ? urlFor('workspaceSpaces', { workspaceId }) : urlFor('home')
        );
      }
    },
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ManageProjectFormData>();
  const {
    mutateAsync: createProject,
    isPending: isPendingCreate,
    isError: isErrorCreate,
  } = useCreateProject();
  const {
    mutateAsync: patchProject,
    isPending: isPendingPatch,
    isError: isErrorPatch,
  } = usePatchProject();
  const id = useId();
  const isPending = isPendingCreate || isPendingPatch;
  const isError = isErrorCreate || isErrorPatch;

  const editCapability = project?.capabilities?.find(
    ({ capability }) => capability === 'edit'
  );
  const canEdit = editCapability?.capable ?? false;

  const onSubmit = catchApiErrorIntoToast(async (data: ManageProjectFormData) => {
    if (project) {
      if (!canEdit) {
        createToast({
          bodyText: 'You do not have permission to edit this space',
          iconVariant: 'danger',
        });
        return;
      }
      await patchProject({
        id: project.id,
        patch: {
          ...data,
        },
      });
      closeModal?.();
      createToast({
        bodyText: 'Space successfully updated',
        iconVariant: 'success',
      });
    } else {
      if (!workspaceId) {
        console.error('Failed to create space due to missing workspaceId');
        createToast({
          titleText: 'Space creation error',
          bodyText: 'Please try again later.',
          iconVariant: 'warning',
        });
        return;
      }
      const { id: projectId } = await createProject({
        workspaceId,
        isStarred,
        ...data,
      });
      reset();
      closeModal?.();
      createToast({
        bodyText: 'New space successfully created',
        iconVariant: 'success',
      });
      if (redirectOnCreate) {
        navigate(urlFor('space', { projectId }));
      }
    }
  });

  const errorMessage = project ? 'Could not update space.' : 'Error in space creation.';
  const buttonText = project ? 'Save' : 'Create space';
  const pendingText = project ? 'Saving' : 'Creating space...';

  return (
    <Dialog
      breadcrumb={[
        {
          icon: <ProjectIcon />,
          text: project ? 'Edit space' : 'New space',
        },
      ]}
      closeModal={closeModal}
      isOpen={isOpen}
      maxWidth="38.75rem"
    >
      <form
        className={clsx(isPending && styles.formDisabled)}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="formBody">
          <div className="formGroup">
            <label htmlFor={`${id}Label`}>Space name</label>
            <input
              {...register('label', { required: 'Name is required' })}
              autoComplete="off"
              className={errors?.label && 'hasError'}
              defaultValue={project ? project.label : ''}
              id={`${id}Label`}
              placeholder="e.g. Homepage redesign..."
              type="text"
            />
            {errors?.label?.message && (
              <p className="errorMessage">{errors.label.message}</p>
            )}
          </div>
          <div className="formGroup">
            <label htmlFor={`${id}Description`}>Description (optional)</label>
            <textarea
              {...register('description')}
              autoComplete="off"
              defaultValue={project ? project.description : ''}
              id={`${id}Description`}
              placeholder="e.g. Improve conversion..."
              style={{ resize: 'none' }}
            />
            {errors?.description?.message && (
              <p className="errorMessage">{errors.description.message}</p>
            )}
          </div>
          <div className="formGroup">
            <h3 className="subtitle5">Space type</h3>
            <div className="formGroup">
              <Controller
                control={control}
                defaultValue={project?.mode ?? 'open'}
                name="mode"
                render={({ field: { onChange, value: checkedValue } }) => {
                  return (
                    <RadioGroup
                      className={styles.threeColumnOptions}
                      defaultValue={checkedValue}
                      onValueChange={(value) => {
                        const result = projectModeSchema.safeParse(value);
                        if (!result.success) {
                          console.error('Failed to parse space mode', result.error);
                          return;
                        }
                        onChange(result.data);
                      }}
                    >
                      {Object.entries(projectModes).map(
                        ([key, { description, label }]) => {
                          return (
                            <div
                              key={key}
                              className={clsx(
                                'formSectionHighlight',
                                styles.radioGroupItem
                              )}
                            >
                              <RadioGroupItem
                                checked={key === checkedValue}
                                description={description}
                                label={label}
                                value={key}
                              />
                            </div>
                          );
                        }
                      )}
                    </RadioGroup>
                  );
                }}
              />
            </div>
          </div>
          {isError && <p className="errorMessage fieldError">{errorMessage}</p>}
        </div>
        <div
          className={clsx(
            styles.formFooter,
            project && canDelete && styles.hasDeleteButton
          )}
        >
          {!!project && canDelete && (
            <Button
              className={styles.deleteButton}
              disabled={isPending}
              iconBefore={<TrashCan />}
              onClick={() => openDeleteConfirmModal()}
              size="sm"
              type="button"
              variant="secondary"
            >
              Delete space
            </Button>
          )}
          <div className={styles.formActions}>
            <Button
              disabled={isPending}
              onClick={closeModal}
              size="sm"
              type="button"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isPending} variant="primary" size="sm">
              {isPending ? (
                <>
                  <Spinner size={16} />
                  {pendingText}
                </>
              ) : (
                buttonText
              )}
            </Button>
          </div>
        </div>
      </form>
      {isPending && <div className={styles.pendingOverlay} />}
    </Dialog>
  );
}

export function useCreateProjectModal({
  canCreateProject,
  redirectOnCreate,
  isStarred,
}: {
  canCreateProject: IsCapable;
  redirectOnCreate: boolean;
  isStarred?: boolean;
}) {
  const promptUpgradePlan =
    !canCreateProject.capable && canCreateProject.reason === 'plan';
  const { openModal, closeModal } = useModalManager();
  const { open: openUpgradeToPaidModal } = useUpgradePlanModal({
    header: 'Space management',
    title: 'Cannot create more spaces',
    message: 'Cannot create more spaces',
  });
  if (promptUpgradePlan) {
    return {
      open: () => {
        openUpgradeToPaidModal();
      },
    };
  }
  return {
    open: () => {
      openModal({
        component: (
          <ManageProjectsModal
            redirectOnCreate={redirectOnCreate}
            isStarred={isStarred}
          />
        ),
      });
    },
    close: closeModal,
  };
}

export function useEditProjectModal(
  project: ProjectDetailDTO | ProjectDTO | null,
  redirectOnDelete?: boolean
) {
  const { openModal, closeModal } = useModalManager();

  return {
    open: () => {
      openModal({
        component: (
          <ManageProjectsModal project={project} redirectOnDelete={redirectOnDelete} />
        ),
      });
    },
    close: closeModal,
  };
}
