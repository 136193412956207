export const getOS = () => getOsFromUserAgent(window.navigator.userAgent);

export const getOsFromUserAgent = (userAgent: string) => {
  return {
    isLinux: userAgent.indexOf('Linux') !== -1,
    isMac: userAgent.indexOf('Mac') !== -1,
    isUnix: userAgent.indexOf('X11') !== -1,
    isWindows: userAgent.indexOf('Win') !== -1,
  };
};
