import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3726_38184)">
        <path
          d="M1.51479 4C2.35139 4 3.02959 3.32843 3.02959 2.5C3.02959 1.67157 2.35139 1 1.51479 1C0.678196 1 0 1.67157 0 2.5C0 3.32843 0.678196 4 1.51479 4Z"
          fill={color}
        />
        <path
          d="M1.51479 9C2.35139 9 3.02959 8.32843 3.02959 7.5C3.02959 6.67157 2.35139 6 1.51479 6C0.678196 6 0 6.67157 0 7.5C0 8.32843 0.678196 9 1.51479 9Z"
          fill={color}
        />
        <path
          d="M1.51479 14C2.35139 14 3.02959 13.3284 3.02959 12.5C3.02959 11.6716 2.35139 11 1.51479 11C0.678196 11 0 11.6716 0 12.5C0 13.3284 0.678196 14 1.51479 14Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.20715 2.5C5.20715 2.03401 5.58864 1.65625 6.05922 1.65625H15.148C15.6186 1.65625 16.0001 2.03401 16.0001 2.5C16.0001 2.96599 15.6186 3.34375 15.148 3.34375H6.05922C5.58864 3.34375 5.20715 2.96599 5.20715 2.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.20715 7.5C5.20715 7.03401 5.58864 6.65625 6.05922 6.65625H15.148C15.6186 6.65625 16.0001 7.03401 16.0001 7.5C16.0001 7.96599 15.6186 8.34375 15.148 8.34375H6.05922C5.58864 8.34375 5.20715 7.96599 5.20715 7.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.20715 12.5C5.20715 12.034 5.58864 11.6562 6.05922 11.6562H15.148C15.6186 11.6562 16.0001 12.034 16.0001 12.5C16.0001 12.966 15.6186 13.3438 15.148 13.3438H6.05922C5.58864 13.3438 5.20715 12.966 5.20715 12.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3726_38184">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
