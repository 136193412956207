import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 4 24"
      width={(size / 24) * 4}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 2L2 22" stroke={color} strokeWidth="4" strokeLinecap="round" />
    </svg>
  );
}
