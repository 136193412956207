const LoadingDuck = ({
  className,
  height = 150,
  width = 157,
}: {
  className?: string;
  height?: number;
  width?: number;
}) => (
  <svg
    className={className}
    fill="none"
    height={height}
    viewBox="0 0 157 150"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.05"
      d="M1 66.5C1 102.565 34.0145 132 75 132C115.986 132 149 102.565 149 66.5C149 30.435 115.986 1 75 1C34.0145 1 1 30.435 1 66.5Z"
      fill="url(#paint0_linear_541_33465)"
      stroke="white"
      strokeWidth="2"
    />
    <ellipse
      opacity="0.05"
      cx="62"
      cy="55.5"
      rx="62"
      ry="55.5"
      transform="matrix(-1 0 0 1 137 11)"
      fill="url(#paint1_radial_541_33465)"
    />
    <path
      d="M37.1465 62.6753C37.1465 42.9438 53.142 26.9482 72.8735 26.9482C92.605 26.9482 108.601 42.9438 108.601 62.6753V140.829L37.1465 152.617V62.6753Z"
      fill="url(#paint2_radial_541_33465)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.746 141.801C111.746 142.78 111.037 143.615 110.071 143.774L36.3265 155.94C35.1085 156.141 34.001 155.202 34.001 153.967V62.6751C34.001 41.2065 51.4047 23.8027 72.8734 23.8027C94.342 23.8027 111.746 41.2065 111.746 62.6752V141.801ZM37.1464 62.6751C37.1464 42.9436 53.1419 26.9481 72.8734 26.9481C92.6049 26.9481 108.6 42.9437 108.6 62.6752V139.132C108.6 140.111 107.892 140.946 106.926 141.105L39.4719 152.234C38.2538 152.435 37.1464 151.495 37.1464 150.26V62.6751Z"
      fill="#050614"
    />
    <path
      d="M93.5791 74.0674L75.3408 87.1839L87.9577 101.05L125.495 98.4719C129.962 98.1651 133.428 94.4522 133.428 89.9748C133.428 86.0029 130.683 82.5583 126.811 81.6722L93.5791 74.0674Z"
      fill="url(#paint3_radial_541_33465)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.6593 74.2647C93.0259 74.001 93.4882 73.9077 93.9284 74.0084L127.566 81.7061C131.916 82.7014 135 86.5708 135 91.0326V91.9365C135 96.9661 131.105 101.137 126.088 101.482L88.0639 104.093C87.5845 104.126 87.1164 103.938 86.793 103.583L74.1761 89.7164C73.8743 89.3847 73.7284 88.94 73.7752 88.4939C73.822 88.0479 74.057 87.6431 74.4211 87.3812L92.6593 74.2647ZM93.9191 77.233L77.69 88.9046L88.6074 100.903L125.872 98.3436C129.24 98.1123 131.854 95.3126 131.854 91.9365V91.0326C131.854 88.0376 129.784 85.4403 126.865 84.7722L93.9191 77.233Z"
      fill="#050614"
    />
    <path
      d="M71.6426 57.8033C71.6426 55.8026 73.2645 54.1807 75.2652 54.1807C77.266 54.1807 78.8879 55.8026 78.8879 57.8033V66.7975C78.8879 68.7983 77.266 70.4202 75.2652 70.4202C73.2645 70.4202 71.6426 68.7983 71.6426 66.7975V57.8033Z"
      fill="#050614"
    />
    <path
      d="M88.6328 58.553C88.6328 57.1042 89.8073 55.9297 91.2561 55.9297C92.7049 55.9297 93.8794 57.1042 93.8794 58.553V65.5485C93.8794 66.9973 92.7049 68.1718 91.2561 68.1718C89.8073 68.1718 88.6328 66.9973 88.6328 65.5485V58.553Z"
      fill="#050614"
    />
    <defs>
      <linearGradient
        id="paint0_linear_541_33465"
        x1="146.698"
        y1="-15.8931"
        x2="88.4089"
        y2="138.076"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDEFF3" />
        <stop offset="1" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_541_33465"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(32.3648 48.8679) rotate(64.5002) scale(68.8378 74.0681)"
      >
        <stop stopColor="#EDEFF3" />
        <stop offset="1" stopColor="#CED5DE" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_541_33465"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(47.5272 52.5608) rotate(33.9159) scale(89.2313 105.099)"
      >
        <stop offset="0.572917" stopColor="#F3F0ED" />
        <stop offset="0.741656" stopColor="#F0BDFF" />
        <stop offset="1" stopColor="#54D0FE" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_541_33465"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(83.7797 79.5667) rotate(17.2261) scale(55.3053 24.2189)"
      >
        <stop offset="0.542145" stopColor="#FFD242" />
        <stop offset="0.828125" stopColor="#FDB740" />
        <stop offset="1" stopColor="#FF892B" />
      </radialGradient>
    </defs>
  </svg>
);

export default LoadingDuck;
