import { useEditor } from '@tiptap/react';

import { extensions } from '@components/detailsModal/tiptap/extensions';

export const useNotesViewer = (content: any) => {
  const editorConfig = {
    extensions: [...extensions],
    content,
    editable: false,
  };
  const editor = useEditor(editorConfig, [content]);

  return {
    editor,
  };
};
