import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 4C4.30653 4 3.16193 4.44622 2.31802 5.24049C1.47411 6.03476 1 7.11203 1 8.2353C1 9.35857 1.47411 10.4358 2.31802 11.2301C3.16193 12.0244 4.30653 12.4706 5.5 12.4706C5.521 12.4706 5.54 12.4649 5.56 12.4649C5.07517 14.6018 4.26053 16.6603 3.142 18.5751C3.01658 18.7721 2.97202 19.0054 3.01671 19.2312C3.06141 19.457 3.19228 19.6596 3.38467 19.8009C3.57706 19.9421 3.8177 20.0123 4.06125 19.9982C4.30481 19.9841 4.53448 19.8867 4.707 19.7242C4.923 19.5209 10 14.6739 10 8.70588C9.99369 8.65264 9.98231 8.60004 9.966 8.54871C9.975 8.4433 10 8.34259 10 8.2353C9.99868 7.11241 9.52415 6.03587 8.68052 5.24187C7.83689 4.44786 6.69307 4.00125 5.5 4Z"
        fill={color}
      />
      <path
        d="M23 8.2353C23 7.39763 22.7361 6.57878 22.2416 5.88229C21.7471 5.1858 21.0443 4.64295 20.2221 4.32239C19.3998 4.00184 18.495 3.91796 17.6221 4.08138C16.7492 4.2448 15.9474 4.64817 15.318 5.24049C14.6887 5.83281 14.2601 6.58747 14.0865 7.40903C13.9128 8.2306 14.0019 9.08218 14.3425 9.85607C14.6831 10.63 15.2599 11.2914 15.9999 11.7568C16.74 12.2222 17.61 12.4706 18.5 12.4706C18.521 12.4706 18.54 12.4649 18.56 12.4649C18.0752 14.6018 17.2605 16.6603 16.142 18.5751C16.0166 18.7721 15.972 19.0054 16.0167 19.2312C16.0614 19.457 16.1923 19.6596 16.3847 19.8009C16.5771 19.9421 16.8177 20.0123 17.0613 19.9982C17.3048 19.9841 17.5345 19.8867 17.707 19.7242C17.923 19.5209 23 14.6739 23 8.70589C22.9937 8.65264 22.9823 8.60004 22.966 8.54871C22.975 8.4433 23 8.34259 23 8.2353Z"
        fill={color}
      />
    </svg>
  );
}
