import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1627_15978)">
        <path
          d="M0.5 7C0.5 3.41 3.858 0.5 8 0.5C12.142 0.5 15.5 3.41 15.5 7C15.5 10.59 12.142 13.5 8 13.5C7.475 13.5 6.963 13.452 6.468 13.363L2.5 15.5V11.409C1.262 10.25 0.5 8.704 0.5 7Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1627_15978">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
