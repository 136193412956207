import { Icon32, type IconComponent, type IconProps } from '@spaceduck/icons';
import { MediaClass, MediaType } from '@spaceduck/utils';

const {
  AudioFile,
  Bookmark,
  GenericFile,
  ImageFile,
  PdfFile,
  SvgFile,
  VideoFile,
  ZipFile,
} = Icon32;

const MEDIA_TYPE_TO_COMPONENT = new Map<string, IconComponent>([
  [MediaType.ZIP, ZipFile],
  [MediaType.SVG, SvgFile],
  [MediaType.PDF, PdfFile],
  [MediaType.URI_LIST, Bookmark],
]);

const MEDIA_CLASS_TO_COMPONENT = new Map<string, IconComponent>([
  [MediaClass.AUDIO, AudioFile],
  [MediaClass.IMAGE, ImageFile],
  [MediaClass.VIDEO, VideoFile],
]);

export type FileIconProps = IconProps & { mediaType: string };

const getMediaTypeComponent = (mediaType: string) => {
  return MEDIA_TYPE_TO_COMPONENT.get(mediaType);
};

const getMediaClassComponent = (mediaType: string) => {
  return MEDIA_CLASS_TO_COMPONENT.get(mediaType.split('/')[0] ?? '');
};

export const FileIcon = ({ mediaType, ...props }: FileIconProps) => {
  const Component =
    getMediaTypeComponent(mediaType) ??
    getMediaClassComponent(mediaType) ??
    GenericFile;
  return <Component {...props} />;
};
