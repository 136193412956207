import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 11.5C9.933 11.5 11.5 9.933 11.5 8C11.5 6.067 9.933 4.5 8 4.5C6.067 4.5 4.5 6.067 4.5 8C4.5 9.933 6.067 11.5 8 11.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 14.876C9.51987 15.522 7.87076 15.6729 6.29795 15.3064C4.72513 14.9399 3.31262 14.0756 2.2705 12.8418C1.22838 11.6081 0.612313 10.071 0.513934 8.45902C0.415555 6.84706 0.840115 5.24641 1.72447 3.89511C2.60883 2.54382 3.90573 1.51407 5.42231 0.959021C6.93889 0.403972 8.59411 0.353273 10.1418 0.814464C11.6895 1.27565 13.047 2.2241 14.0124 3.51874C14.9778 4.81337 15.4995 6.38504 15.5 8V9.5C15.5 10.0304 15.2893 10.5391 14.9142 10.9142C14.5391 11.2893 14.0304 11.5 13.5 11.5C12.9696 11.5 12.4609 11.2893 12.0858 10.9142C11.7107 10.5391 11.5 10.0304 11.5 9.5V4.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
