import * as RadixPopover from '@radix-ui/react-popover';
import clsx from 'clsx';

import styles from './Popover.module.scss';

export default function NavigationMenu({
  children,
  className,
  closeIcon,
  popoverContentProps,
  showArrow = true,
  trigger,
}: {
  children: React.ReactNode;
  className?: string;
  closeIcon?: React.ReactNode;
  popoverContentProps?: RadixPopover.PopoverContentProps;
  showArrow?: boolean;
  trigger: React.ReactNode;
}) {
  return (
    <RadixPopover.Root>
      <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          className={clsx(styles.popoverContent, className)}
          {...popoverContentProps}
        >
          <div className={clsx(styles.container, className)}>{children}</div>
          {!!closeIcon && (
            <RadixPopover.Close aria-label="Close" asChild>
              {closeIcon}
            </RadixPopover.Close>
          )}
          {showArrow && <RadixPopover.Arrow className={styles.popoverArrow} />}
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
}
