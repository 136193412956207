import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5238 22L17.2381 15.3333L22 18.1905"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9999 10.5714H7.71423V22H21.9999V10.5714Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5238 15.3333C12.0498 15.3333 12.4762 14.9069 12.4762 14.381C12.4762 13.855 12.0498 13.4286 11.5238 13.4286C10.9978 13.4286 10.5714 13.855 10.5714 14.381C10.5714 14.9069 10.9978 15.3333 11.5238 15.3333Z"
        fill={color}
      />
      <path
        d="M9.61905 3.90475H6.7619C4.13238 3.90475 2 6.03714 2 8.66666V11.5238"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.61902 5.80952L12.4762 3.90476L9.61902 2V5.80952Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path d="M9.61902 5.80952L12.4762 3.90476L9.61902 2V5.80952Z" fill={color} />
    </svg>
  );
}
