export function Favicon({
  url,
  className,
  size = 128,
  alt = 'favicon',
}: {
  url: string;
  className?: string;
  size?: number;
  alt?: string;
}) {
  const parsedUrl = new URL(url).hostname;
  return (
    <img
      src={`https://s2.googleusercontent.com/s2/favicons?domain=${parsedUrl}&sz=${size}`}
      alt={alt}
      className={className}
    />
  );
}
