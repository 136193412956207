import type { MediaGroupDTO, ProjectDTO } from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';
import { exists } from '@spaceduck/utils';
import clsx from 'clsx';
import type { ReactNode } from 'react';

import MediaGroupCard from '@components/MediaGroupCard';
import NoResults from '@components/NoResults';
import ProductLoading from '@components/ProductLoading';
import { useBackgroundLocation } from '@hooks/useBackgroundLocation';
import { useCheckMediaGroupUpdated } from '@hooks/useCheckMediaGroupUpdated';
import MasonryGrid, { type Breakpoints } from '@ui/MasonryGrid';
import CollectionsCTACard from './CollectionsCTACard';
import DeletedMediaGroupCard from './DeletedMediaGroupCard';
import styles from './MediaGroupGrid.module.scss';

const { Project, RepositoryBrowse } = Icon16;

type MediaGroupGridProps = {
  breakpoints?: Breakpoints;
  isError: boolean;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  handleSearchClick: (id: string) => void;
  mediaGroups: MediaGroupDTO[];
  noResults?: ReactNode;
  selected: Set<string>;
  onSelected: (selected: MediaGroupDTO) => void;
  showCTACard?: boolean;
};

type CTACard = { card: JSX.Element; isCTACard: boolean };

// Magic numbers collected from the inspector
const fileIconWidth = 512;
const fileIconHeight = 128;

const MediaGroupGrid = ({
  breakpoints,
  isError,
  isFetchingNextPage,
  isLoading,
  handleSearchClick,
  mediaGroups,
  noResults,
  selected,
  onSelected,
  showCTACard = false,
}: MediaGroupGridProps) => {
  const backgroundLocation = useBackgroundLocation();
  const updatedMediaGroupMap = useCheckMediaGroupUpdated(
    backgroundLocation ? [] : mediaGroups
  );

  if (isLoading || isError) {
    return (
      <div className={styles.container}>
        <ProductLoading />
      </div>
    );
  }

  if (!mediaGroups?.length) {
    return noResults || <NoResults />;
  }

  const ctaCard = {
    card: <CollectionsCTACard />,
    isCTACard: true,
  };

  const items: (MediaGroupDTO | CTACard)[] = showCTACard
    ? [ctaCard, ...mediaGroups]
    : mediaGroups;

  return (
    <div className={styles.gridContainer}>
      <MasonryGrid
        breakpoints={breakpoints}
        items={items.map((item) => {
          if ('isCTACard' in item && item.isCTACard)
            return {
              card: item.card,
              thumbHeight: 0,
              thumbWidth: 0,
              isNotes: false,
              fixedHeight: 104,
            };

          const mediaGroup = item as MediaGroupDTO;

          const thumbHeight =
            mediaGroup.thumbnail.source?.kind === 'fileIcon'
              ? fileIconHeight
              : mediaGroup.thumbnail.height;
          const thumbWidth =
            mediaGroup.thumbnail.source?.kind === 'fileIcon'
              ? fileIconWidth
              : mediaGroup.thumbnail.width;
          return {
            card: !mediaGroup.isDeleted ? (
              <MediaGroupCard
                key={mediaGroup.id}
                mediaGroup={mediaGroup}
                updatedMediaGroupMap={updatedMediaGroupMap}
                handleSearchClick={handleSearchClick}
                selected={selected?.has(mediaGroup.id)}
                onSelected={() => {
                  onSelected(mediaGroup);
                }}
                inBulkSelectMode={(selected?.size ?? -1) > 0}
              />
            ) : (
              <DeletedMediaGroupCard
                key={mediaGroup.id}
                mediaGroup={mediaGroup}
                updatedMediaGroupMap={updatedMediaGroupMap}
              />
            ),
            isNotes: mediaGroup.kind === 'document',
            thumbHeight,
            thumbWidth,
          };
        })}
      />
      {isFetchingNextPage && (
        <div className={styles.container}>
          <ProductLoading />
        </div>
      )}
    </div>
  );
};

const AddToRepository = () => (
  <div className={styles.menuItem}>
    <RepositoryBrowse size={20} />
    <span className={styles.projectLabel} title="Repository">
      Repository
    </span>
  </div>
);

export const makeAddToProjectMenu = ({
  projects,
  onAddToProject,
  onAddToRepository,
}: {
  projects: ProjectDTO[];
  onAddToProject: (projectId: string) => void;
  onAddToRepository?: () => void;
}) => {
  const projectOptions = projects.map((project) => ({
    content: (
      <div className={styles.menuItem}>
        <Project size={20} />
        <span className={styles.projectLabel} title={project.label}>
          {project.label}
        </span>
      </div>
    ),
    onClick: () => {
      onAddToProject?.(project.id);
    },
  }));

  const addToRepositoryOption = [];
  if (onAddToRepository) {
    addToRepositoryOption.push({
      content: <AddToRepository />,
      onClick: () => {
        onAddToRepository();
      },
    });
  }

  if (projectOptions.length > 0) {
    return [...addToRepositoryOption, ...projectOptions];
  }

  return [
    ...addToRepositoryOption,
    addToRepositoryOption.length === 0 && projectOptions.length === 0
      ? {
          content: (
            <div className={clsx(styles.menuItem, styles.noResults)}>
              No spaces found
            </div>
          ),
          disabled: true,
          isMenuItem: false,
        }
      : null,
  ].filter(exists);
};

export default MediaGroupGrid;
