import { Icon12, Icon24 } from '@spaceduck/icons';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import { type ReactNode, forwardRef } from 'react';
import type { Placement } from 'tippy.js';

import styles from './Menu.module.scss';
import Tooltip from './Tooltip';

type MenuItem = {
  children?: ReactNode;
  icon?: ReactNode;
  label?: string;
  menuItems?: MenuItem[];
  onClick?: (ev: React.MouseEvent) => void;
  title?: string;
  tooltip?: string;
  disabled?: boolean;
  isSeparator?: boolean;
  placement?: Placement;
  removeMenuItemStyle?: boolean;
  showCheck?: boolean;
  ref?: React.MutableRefObject<HTMLButtonElement | null>;
};

type MenuProps = {
  className?: string;
  containerClassName?: string;
  menuItems?: MenuItem[];
  offset?: [number, number];
};

const { TinyDownArrow } = Icon12;
const { Check } = Icon24;

export default function Menu({
  className,
  containerClassName,
  menuItems,
  offset,
}: MenuProps) {
  if (!menuItems?.length) return null;

  return (
    <div className={clsx(styles.menu, containerClassName)}>
      {menuItems?.map((menuItem, idx) => {
        const {
          children,
          disabled,
          icon,
          label,
          menuItems: submenuItems,
          onClick,
          title,
          tooltip,
          isSeparator,
          ref,
          placement = 'bottom-start',
          removeMenuItemStyle,
        } = menuItem;
        return (
          <span className={styles.tippyWrapper} key={idx}>
            {(submenuItems && submenuItems.length > 0) || children ? (
              <Tippy
                allowHTML
                onShow={({ reference }) => {
                  reference.setAttribute('aria-expanded', 'true');
                }}
                onHide={({ reference }) => {
                  reference.setAttribute('aria-expanded', 'false');
                }}
                interactive={true}
                trigger="click"
                placement={placement}
                offset={offset}
                content={
                  <div
                    className={clsx(!removeMenuItemStyle && styles.content, className)}
                  >
                    {title && <div className={styles.title}>{title}</div>}
                    {submenuItems?.map((submenuItem, jdx) => {
                      return <MenuItem {...submenuItem} key={jdx} />;
                    })}
                    {children}
                  </div>
                }
              >
                <div className={styles.triggerWrapper}>
                  <Trigger
                    className={styles.level1}
                    hasSubmenu
                    icon={icon}
                    label={label}
                    tooltip={tooltip}
                  />
                </div>
              </Tippy>
            ) : (
              <MenuItem
                icon={icon}
                label={label}
                onClick={onClick}
                tooltip={tooltip}
                disabled={disabled}
                isSeparator={isSeparator}
                ref={ref}
              />
            )}
          </span>
        );
      })}
    </div>
  );
}

const Trigger = ({
  className,
  hasSubmenu,
  icon,
  label,
  showCheck,
  tooltip,
}: { className?: string; hasSubmenu?: boolean } & Pick<
  MenuItem,
  'icon' | 'label' | 'showCheck' | 'tooltip'
>) => {
  if (!(icon || label)) return null;

  const trigger = (
    <div
      className={clsx(
        styles.trigger,
        icon && styles.hasIcon,
        label && styles.hasLabel,
        hasSubmenu && styles.hasArrow,
        !hasSubmenu && showCheck && styles.hasActiveCheck,
        className
      )}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      {label && <span className={styles.text}>{label}</span>}
      {hasSubmenu && (
        <span className={styles.arrow}>
          <TinyDownArrow />
        </span>
      )}
      {!hasSubmenu && showCheck && (
        <span className={styles.activeCheck}>
          <Check />
        </span>
      )}
    </div>
  );

  if (!tooltip) {
    return trigger;
  }

  return (
    <Tooltip content={tooltip} align="start">
      <span className={styles.tooltip}>{trigger}</span>
    </Tooltip>
  );
};

type MenuItemProps = { className?: string } & Pick<
  MenuItem,
  'disabled' | 'icon' | 'label' | 'onClick' | 'tooltip' | 'isSeparator' | 'showCheck'
>;

const MenuItem = forwardRef<HTMLButtonElement, MenuItemProps>(
  (
    {
      className,
      disabled = false,
      icon,
      label,
      onClick,
      tooltip,
      isSeparator,
      showCheck,
    }: MenuItemProps,
    ref
  ) => {
    if (isSeparator) {
      return <div className={styles.separator} />;
    }

    return (
      <WrapWithTooltip tooltip={tooltip}>
        <button
          className={clsx(styles.menuItem, className)}
          disabled={disabled}
          onClick={onClick}
          ref={ref}
          type="button"
        >
          <Trigger icon={icon} label={label} showCheck={showCheck} />
        </button>
      </WrapWithTooltip>
    );
  }
);

const WrapWithTooltip = ({
  children,
  tooltip,
}: {
  children: ReactNode;
  tooltip?: string;
}) => {
  if (!tooltip) return children;

  return (
    <span>
      <Tooltip content={tooltip} align="start">
        {children}
      </Tooltip>
    </span>
  );
};
