import { useRef } from 'react';
import { useParams } from 'react-router-dom';

import type { CreateMediaGroupSchema } from '@spaceduck/api';

import { useCreateAndNavigateToMediaGroup, useCreateMediaGroup } from '@api/mediaGroup';
import { catchApiErrorIntoToast } from '@api/util';
import { useBookmarkModal } from '@components/CreateBookmarkModal';
import { useFileUploadWrapper } from '@hooks/useFileUploadWrapper';
import useWorkspaceId from '@hooks/useWorkspaceId';

export const useCreateMediaGroupActions = (
  mediaGroupAttributes?: Omit<CreateMediaGroupSchema, 'kind' | 'workspaceId'>
) => {
  const workspaceId = useWorkspaceId();
  const projectId = useParams<{ projectId?: string }>().projectId;

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { handleChange } = useFileUploadWrapper({
    ...mediaGroupAttributes,
    workspaceId,
    projectId,
  });

  const createBoard = useCreateAndNavigateToMediaGroup('board');
  const createDocument = useCreateAndNavigateToMediaGroup('document');
  const { open: openCreateBookmarkModal } = useBookmarkModal();
  const { mutateAsync: createMediaGroup } = useCreateMediaGroup();
  const createLink = catchApiErrorIntoToast(
    async (data: Omit<CreateMediaGroupSchema, 'kind'>) => {
      const { projectId, workspaceId, ...rest } = data;
      await createMediaGroup({
        kind: 'bookmark',
        projectId: projectId,
        workspaceId: !projectId ? workspaceId : undefined,
        ...rest,
      });
    }
  );

  return {
    createBoard: () =>
      createBoard({
        ...mediaGroupAttributes,
        workspaceId: workspaceId ? workspaceId : undefined,
        projectId: projectId,
      }),
    createDocument: () =>
      createDocument({
        ...mediaGroupAttributes,
        workspaceId: workspaceId ? workspaceId : undefined,
        projectId: projectId,
      }),
    createLink: (url: string) =>
      createLink({
        ...mediaGroupAttributes,
        workspaceId: workspaceId ? workspaceId : undefined,
        projectId: projectId,
        url,
      }),
    fileInputRef,
    handleChange,
    openCreateBookmarkModal,
  };
};
