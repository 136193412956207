import { useContext } from 'react';
import { type Edge, type Node, useReactFlow } from '@xyflow/react';
import { isEqual } from 'lodash';

import { BoardStoreContext } from '../context/boardContext';
import type { UnknownNodeType } from '../types/board';
import { usePersist } from './usePersist';

type HistoryState = {
  nodes?: Node[];
  edges?: Edge[];
};

export const useHistory = () => {
  const boardContext = useContext(BoardStoreContext);
  const temporal = boardContext?.store?.temporal;
  const { getEdges, getNodes } = useReactFlow();
  const { updateEntry } = usePersist({ mediaGroupId: boardContext?.mediaGroupId });

  const pause = () => {
    if (!temporal) return;

    const lastState = temporal.getState();
    temporal.setState({
      ...lastState,
      pastStates: [...lastState.pastStates, { nodes: getNodes(), edges: getEdges() }],
    });
    temporal.getState().pause();
  };

  const pauseWithoutSave = () => {
    temporal?.getState().pause();
  };

  const resume = () => {
    temporal?.getState().resume();
  };

  const clear = () => {
    temporal?.getState().clear();
  };

  const undo = () => {
    if (!temporal) return;

    const pastStates = temporal.getState().pastStates;

    if (!pastStates.length) return;

    const pastState = pastStates.at(-1);
    if (!pastState) return;

    const shouldUpdate = hasDifferentBaseState(
      { nodes: getNodes(), edges: getEdges() },
      pastState
    );

    temporal.getState().undo();
    temporal.getState().pause();

    const { edges, nodes } = pastState;

    if (shouldUpdate) {
      updateEntry({
        patch: {
          board: {
            nodes,
            edges,
          },
        },
        showToast: false,
      });
    }

    temporal.getState().resume();
  };

  const redo = () => {
    if (!temporal) return;

    const { futureStates } = temporal.getState();
    if (!futureStates.length) return;

    const futureState = futureStates.at(-1);
    if (!futureState) return;

    const shouldUpdate = hasDifferentBaseState(
      { nodes: getNodes(), edges: getEdges() },
      futureState
    );

    temporal.getState().redo();
    temporal.getState().pause();

    const { edges, nodes } = futureState;

    if (shouldUpdate) {
      updateEntry({
        patch: {
          board: {
            nodes,
            edges,
          },
        },
        showToast: false,
      });
    }

    temporal.getState().resume();
  };

  const replaceHistoryOfUnknownNode = (node: Node) => {
    if (!temporal) return;

    const mediaGroupId = node.data.mediaGroupId;

    if (!mediaGroupId) return;
    const { pastStates, futureStates } = temporal.getState();

    const updatedPastStates = pastStates.map((state) => {
      return {
        ...state,
        nodes: state.nodes?.map((existingNode) => {
          if (existingNode.data.mediaGroupId !== mediaGroupId) return existingNode;

          return node;
        }),
      };
    });

    const updatedFutureStates = futureStates.map((state) => {
      return {
        ...state,
        nodes: state.nodes?.map((existingNode) => {
          if (existingNode.data.mediaGroupId !== mediaGroupId) return existingNode;

          return node;
        }),
      };
    });

    temporal.getState().pause();

    temporal.setState({
      pastStates: updatedPastStates.slice(0, -1),
      futureStates: updatedFutureStates,
    });

    // Delay resume after setState to prevent additional of current state
    Promise.resolve(() => {
      temporal.getState().resume();
    });
  };

  const replaceHistoryOfPendingNodes = (
    replacedNodesWithKeys: { key: string; node: UnknownNodeType }[]
  ) => {
    if (!temporal || !replacedNodesWithKeys.length) return;

    const { pastStates, futureStates } = temporal.getState();
    const updatedPastStates = pastStates.map((state) => {
      return {
        ...state,
        nodes: state.nodes?.map((node) => {
          if (node.type !== 'tempFileUploadNode') return node;

          const relatedEntry = replacedNodesWithKeys.find(
            (entry) => entry.key === node.data.id
          );
          return relatedEntry?.node ?? node;
        }),
      };
    });

    const updatedFutureStates = futureStates.map((state) => {
      return {
        ...state,
        nodes: state.nodes?.map((node) => {
          if (node.type !== 'tempFileUploadNode') return node;

          const relatedEntry = replacedNodesWithKeys.find(
            (entry) => entry.key === node.data.id
          );
          return relatedEntry?.node ?? node;
        }),
      };
    });

    temporal.getState().pause();

    temporal.setState({
      pastStates: updatedPastStates,
      futureStates: updatedFutureStates,
    });

    temporal.getState().resume();
  };

  return {
    clear,
    pause,
    pauseWithoutSave,
    redo,
    replaceHistoryOfPendingNodes,
    replaceHistoryOfUnknownNode,
    resume,
    undo,
  };
};

function hasDifferentBaseState(currentState: HistoryState, changedState: HistoryState) {
  if (
    !currentState.edges ||
    !currentState.nodes ||
    !changedState.edges ||
    !changedState.nodes
  )
    return false;

  if (
    currentState.edges.length !== changedState.edges.length ||
    currentState.nodes.length !== changedState.nodes.length
  )
    return true;

  const normalizedCurrentEdges = currentState.edges.map(normalizeEdge);
  const normalizedChangeEdges = changedState.edges.map(normalizeEdge);

  if (!isEqual(normalizedCurrentEdges, normalizedChangeEdges)) {
    const mismatchDetails = [];
    for (let i = 0; i < normalizedCurrentEdges.length; i++) {
      if (!isEqual(normalizedCurrentEdges[i], normalizedChangeEdges[i])) {
        mismatchDetails.push([normalizedCurrentEdges[i], normalizedChangeEdges[i]]);
      }
    }
    console.log('Edges history diff: ', mismatchDetails);

    return true;
  }

  const normalizedCurrentNodes = currentState.nodes.map(normalizeNode);
  const normalizedChangeNodes = changedState.nodes.map(normalizeNode);
  if (!isEqual(normalizedCurrentNodes, normalizedChangeNodes)) {
    const mismatchDetails = [];
    for (let i = 0; i < normalizedCurrentNodes.length; i++) {
      if (!isEqual(normalizedCurrentNodes[i], normalizedChangeNodes[i])) {
        mismatchDetails.push([normalizedCurrentNodes[i], normalizedChangeNodes[i]]);
      }
    }
    console.log('Nodes history diff: ', mismatchDetails);

    return true;
  }

  return false;
}

function normalizeNode(node: Node) {
  const {
    dragging,
    hidden,
    measured,
    position,
    resizing,
    selected,
    style,
    ...baseNode
  } = node;

  const {
    content = null,
    isInEditMode = false,
    height = null,
    width = null,
    ...nodeData
  } = { ...node.data };

  baseNode.data = nodeData;

  return baseNode;
}

function normalizeEdge(edge: Edge) {
  const { hidden, selected, style, ...baseEdge } = edge;
  return baseEdge;
}
