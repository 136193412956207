import { useContext, useEffect, useMemo, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore, useViewport } from '@xyflow/react';

import type { CreateMediaGroupSchema, MediaGroupDetailDTO } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';

import { useCreateMediaGroup } from '@api/mediaGroup';
import { catchApiErrorIntoToast } from '@api/util';
import {
  type CreateBookmarkData,
  useBookmarkModal,
} from '@components/CreateBookmarkModal';
import { useBackgroundLocation } from '@hooks/useBackgroundLocation';
import { useFileUploadWrapper } from '@hooks/useFileUploadWrapper';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import { getCloseTo } from '@utils/mediaGroup';
import HorizontalMenu, { type MenuItemProps } from '../components/HorizontalMenu';
import { BoardStoreContext } from '../context/boardContext';
import { useBoardStore } from '../hooks/useBoardStore';
import { DEFAULT_CARD_HEIGHT, DEFAULT_CARD_WIDTH, useNodes } from '../hooks/useNodes';
import { useModalManager } from '@context/ModalManagerContext';
import styles from './ActionMenu.module.scss';

const { CardLarge, Cursor, Link, TextT, Upload } = Icon16;
const { Frame } = Icon24;

export default function ActionMenu({
  hasSelected,
  mediaGroup,
}: {
  hasSelected: boolean;
  mediaGroup: MediaGroupDetailDTO;
}) {
  const actionMenuMode = useBoardStore(({ actionMenuMode }) => actionMenuMode);
  const setActionMenuMode = useBoardStore(({ setActionMenuMode }) => setActionMenuMode);

  const boardContext = useContext(BoardStoreContext);
  const { addMediaNode } = useNodes();
  const { zoom } = useViewport();
  const getScreenCenter = () => {
    if (boardContext?.containerRef.current) {
      const { height, width } =
        boardContext.containerRef.current.getBoundingClientRect();
      const flowContainerCenterX = width / 2;
      const flowContainerCenterY = height / 2;
      const offsetX = (DEFAULT_CARD_WIDTH / 2) * zoom;
      const offsetY = (DEFAULT_CARD_HEIGHT / 2) * zoom;

      return { x: flowContainerCenterX - offsetX, y: flowContainerCenterY - offsetY };
    }

    return { x: 0, y: 0 };
  };

  const workspaceId = useWorkspaceId();
  const projectId = useParams<{ projectId?: string }>().projectId;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { handleChange } = useFileUploadWrapper(
    {
      workspaceId,
      projectId,
    },
    {
      onSuccess: async (mediaGroupId: string) => {
        const { x, y } = getScreenCenter();
        addMediaNode(mediaGroupId, x, y);
      },
    }
  );

  const { mutateAsync: createMediaGroup } = useCreateMediaGroup();
  const createLink = catchApiErrorIntoToast(
    async (data: Omit<CreateMediaGroupSchema, 'kind'>) => {
      const { projectId, workspaceId, ...rest } = data;
      const { mediaGroupId } = await createMediaGroup({
        kind: 'bookmark',
        projectId: projectId,
        workspaceId: !projectId ? workspaceId : undefined,
        ...rest,
      });

      const { x, y } = getScreenCenter();
      addMediaNode(mediaGroupId, x, y);
    }
  );
  const { open: openCreateBookmarkModal } = useBookmarkModal();
  const launchCreateBookmarkModal = () => {
    openCreateBookmarkModal({
      onSubmit: (data: CreateBookmarkData) => {
        createLink({
          workspaceId: workspaceId ? workspaceId : undefined,
          projectId: projectId,
          url: data.url,
        });
      },
    });
  };

  useHotkeys('V', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setActionMenuMode('select');
  });

  useHotkeys('D', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setActionMenuMode('document');
  });

  useHotkeys('T', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setActionMenuMode('text');
  });

  useHotkeys('F', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setActionMenuMode('frame');
  });

  const { isCommenting, setQuickViewMediaGroupId } = useDetailsModalStore((store) => ({
    isCommenting: store.isCommenting,
    setQuickViewMediaGroupId: store.setQuickViewMediaGroupId,
  }));

  const backgroundLocation = useBackgroundLocation();
  const navigate = useNavigate();
  const { modals } = useModalManager();

  const unselectNodesAndEdges = useStore(
    ({ unselectNodesAndEdges }) => unselectNodesAndEdges
  );

  const handleEscape = () => {
    if (hasSelected) {
      unselectNodesAndEdges();
      return;
    }

    if (actionMenuMode === 'select') {
      navigate(backgroundLocation || getCloseTo(mediaGroup));
      setQuickViewMediaGroupId(null);
    }

    if (actionMenuMode !== 'select') {
      setActionMenuMode('select');
    }
  };

  useHotkeys(
    'Escape',
    handleEscape,
    {
      enabled: !isCommenting && !modals.length,
    },
    [isCommenting, actionMenuMode, hasSelected]
  );

  useEffect(() => {
    if (boardContext?.containerRef?.current) {
      const supportedCursors = ['document', 'frame', 'text'];
      supportedCursors
        .filter((state) => state !== actionMenuMode)
        .forEach((state) => {
          boardContext.containerRef.current?.classList.remove(state);
        });

      if (supportedCursors.includes(actionMenuMode)) {
        boardContext.containerRef.current.classList.add(actionMenuMode);
      }
    }
  }, [actionMenuMode, boardContext]);

  const menu: MenuItemProps[] = useMemo(
    () => [
      {
        title: 'Select',
        shortKeys: ['V'],
        buttonIcon: <Cursor size={16} />,
        isActive: actionMenuMode === 'select',
        onClick: () => setActionMenuMode('select'),
      },
      {
        title: 'Document',
        shortKeys: ['D'],
        buttonIcon: <CardLarge size={16} />,
        isActive: actionMenuMode === 'document',
        onClick: () => setActionMenuMode('document'),
      },
      {
        title: 'Text',
        shortKeys: ['T'],
        buttonIcon: <TextT size={16} />,
        isActive: actionMenuMode === 'text',
        onClick: () => setActionMenuMode('text'),
      },
      {
        title: 'Frame',
        shortKeys: ['F'],
        buttonIcon: <Frame size={16} />,
        showSeparatorAfter: true,
        isActive: actionMenuMode === 'frame',
        onClick: () => setActionMenuMode('frame'),
      },
      {
        title: 'Link',
        buttonIcon: <Link size={16} />,
        onClick: () => launchCreateBookmarkModal(),
      },
      {
        title: 'Upload',
        buttonIcon: <Upload size={16} />,
        onClick: () => {
          fileInputRef?.current?.click();
        },
      },
    ],
    [actionMenuMode, setActionMenuMode, launchCreateBookmarkModal]
  );

  return (
    <div className={styles.container}>
      <HorizontalMenu menu={menu} />
      <input
        type="file"
        multiple
        ref={fileInputRef}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </div>
  );
}
