import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2196_7508)">
        <path
          d="M15 0.75V6.75H21"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 9.75V6.75L15 0.75H3V9.75"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 20.25V23.25H21V20.25"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.22502 15.975C9.22502 16.275 9.15002 16.5 9.00002 16.65C8.85002 16.8 8.70002 17.025 8.40002 17.1C8.10002 17.175 7.87502 17.25 7.50002 17.25C7.20002 17.25 6.97502 17.25 6.75002 17.175C6.52502 17.1 6.37502 17.1 6.15002 16.95V15.9C6.37502 16.05 6.60002 16.125 6.82502 16.2C7.05002 16.275 7.27502 16.275 7.50002 16.275C7.65002 16.275 7.80002 16.275 7.87502 16.2C7.95002 16.125 8.02502 16.05 8.02502 15.975C8.02502 15.9 8.02502 15.9 7.95002 15.825C7.87502 15.75 7.87502 15.75 7.80002 15.675C7.72502 15.6 7.57502 15.525 7.20002 15.375C6.90002 15.225 6.67502 15.15 6.60002 15C6.52502 14.85 6.37502 14.775 6.30002 14.625C6.22502 14.475 6.22502 14.325 6.22502 14.1C6.22502 13.725 6.37502 13.425 6.67502 13.2C6.97502 12.975 7.35002 12.9 7.87502 12.9C8.32502 12.9 8.77502 12.975 9.22502 13.2L8.85002 14.1C8.47502 13.95 8.10002 13.8 7.80002 13.8C7.65002 13.8 7.57502 13.8 7.50002 13.875C7.42502 13.95 7.42502 14.025 7.42502 14.1C7.42502 14.175 7.50002 14.25 7.57502 14.325C7.65002 14.4 7.87502 14.475 8.25002 14.7C8.62502 14.85 8.85002 15.075 9.00002 15.225C9.15002 15.375 9.22502 15.675 9.22502 15.975Z"
          fill={color}
        />
        <path
          d="M12.3 12.975H13.575L12.225 17.25H10.875L9.44995 12.975H10.725L11.325 15.15C11.475 15.6 11.475 15.975 11.55 16.125C11.55 15.975 11.625 15.825 11.625 15.6C11.625 15.375 11.7 15.225 11.7 15.15L12.3 12.975Z"
          fill={color}
        />
        <path
          d="M15.75 14.775H17.625V17.1C17.1 17.25 16.575 17.325 15.975 17.325C15.3 17.325 14.775 17.1 14.475 16.725C14.175 16.35 13.95 15.825 13.95 15.075C13.95 14.4 14.175 13.875 14.55 13.425C14.925 12.975 15.525 12.825 16.2 12.825C16.5 12.825 16.725 12.825 16.95 12.9C17.175 12.975 17.4 13.05 17.55 13.125L17.175 14.025C16.875 13.875 16.5 13.8 16.125 13.8C15.75 13.8 15.525 13.875 15.375 14.1C15.225 14.325 15.075 14.625 15.075 15.075C15.075 15.45 15.15 15.75 15.3 15.975C15.45 16.2 15.675 16.275 16.05 16.275C16.2 16.275 16.35 16.275 16.5 16.2V15.525H15.75V14.775Z"
          fill={color}
        />
        <path
          d="M23.25 9.75H0.75V20.25H23.25V9.75Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2196_7508">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
