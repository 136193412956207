import { z } from 'zod';
import { ApiClient } from '../client';
import { successfulResponseSchema } from '../schema';
import { apiUrl } from '../util';
import {
  contentTypeSchema,
  type MediaGroupContentType,
  mediaGroupDTO,
  mediaGroupKindSchema,
} from './schemas';

export const researchAssistantSessionCapability = z.enum([
  'researchAssistantSessionCreate',
]);
export type ResearchAssistantSessionCapability = z.infer<
  typeof researchAssistantSessionCapability
>;

export const models = ['gpt-4o', 'gemini'] as const;
const modelSchema = z.enum(models);
export type AiModel = z.infer<typeof modelSchema>;

const chatSessionSourceMediaGroup = z.object({
  id: z.string(),
  label: z.string(),
  type: z.literal('library').optional().default('library'),
  // TODO: remove default
  description: z.string().default(''),
  contentType: contentTypeSchema,
  container: z.string(),
  linkUrl: z.string().nullable(),
  linkUrlSource: z.string().nullable(),
  kind: mediaGroupKindSchema,
  pending: z.boolean(),
});

const contentTypes = z.enum([
  'essay',
  'literature review',
  'research paper',
  'personal statement',
  'article or blog',
  'speech',
  'grant proposal',
]);

export type AiSettingsContentType = z.infer<typeof contentTypes>;

const writingStyles = z.enum([
  'academic',
  'casual',
  'persuasive',
  'bold',
  'friendly',
  'business',
]);

export type AiSettingsWritingStyle = z.infer<typeof writingStyles>;
const languages = z.enum(['EN_US', 'EN_GB']);
export type AiSettingsLanguage = z.infer<typeof languages>;
const mediaGroupAiSettings = z.object({
  documentPrompt: z.string(),
  contentType: contentTypes,
  writingStyle: writingStyles,
  language: languages,
  autoComplete: z.boolean(),
  autoCite: z.boolean(),
  writingResources: mediaGroupDTO.array(),
  writingReference: mediaGroupDTO.nullable(),
});

export type MediaGroupAiSettings = z.infer<typeof mediaGroupAiSettings>;

export type MediaGroupAiSettingsPatch = Partial<{
  documentPrompt: MediaGroupAiSettings['documentPrompt'];
  contentType: MediaGroupAiSettings['contentType'];
  writingStyle: MediaGroupAiSettings['writingStyle'];
  language: MediaGroupAiSettings['language'];
  autoComplete: MediaGroupAiSettings['autoComplete'];
  autoCite: MediaGroupAiSettings['autoCite'];
  writingReference: string | null;
}>;

export type NamedPrompt =
  | 'clarity_and_fluency'
  | 'final_polish'
  | 'simplify'
  | 'length_more'
  | 'length_less'
  | 'summarize'
  | 'action_items'
  | 'outline'
  | 'brainstorm'
  | 'autocomplete';

export type ChatMediaGroupSource = z.infer<typeof chatSessionSourceMediaGroup>;
export type ChatSpaceSource = {
  id: string;
  type: 'space';
  label: string;
  isPrivate: boolean;
};

export type ChatSessionSource = z.infer<typeof chatSessionSourceMediaGroup>;

export type ChatTemporaryLinkSource = {
  favIcon?: string;
  domain?: string;
  title?: string;
  description?: string;
  pending: boolean;
  type: 'link';
  url: string;
  id?: string;
};

export type ChatTemporaryFileSource = {
  type: 'file';
  id: string;
  label: string;
  pending: boolean;
  contentType: MediaGroupContentType;
};

export type ChatSessionAddedSource =
  | ChatSessionSource
  | ChatTemporaryFileSource
  | ChatTemporaryLinkSource
  | ChatSpaceSource;

const responseParagraphSchema = z.object({
  text: z.string(),
  references: z.string().array().optional().nullable().default(null),
});

export type ResponseParagraph = z.infer<typeof responseParagraphSchema>;

const interactionSchema = z.object({
  id: z.string(),
  query: z.string(),
  pending: z.boolean().optional().default(true),
  response: z.object({
    parts: responseParagraphSchema.array(),
  }),
});

export type ChatInteraction = z.infer<typeof interactionSchema>;

const temporaryFileSchema = z.object({
  id: z.string(),
  filename: z.string(),
  pending: z.boolean(),
  state: z.enum(['W', 'C', 'E', 'F', 'R']),
  message: z.string(),
  contentType: contentTypeSchema,
});

const temporaryLinkSchema = z.object({
  id: z.string(),
  pending: z.boolean(),
  state: z.enum(['W', 'C', 'E', 'F', 'R']),
  message: z.string(),
});

export type TemporaryLink = z.infer<typeof temporaryLinkSchema>;
export type TemporaryFile = z.infer<typeof temporaryFileSchema>;

export const chatSchema = z.object({
  id: z.string(),
  label: z.string(),
  model: modelSchema,
  mediaGroups: chatSessionSourceMediaGroup.array(),
  messages: interactionSchema.array(),
});

export const chatSummarySchema = z.object({
  id: z.string(),
  label: z.string(),
});

export type Chat = z.infer<typeof chatSchema>;

export type WritingAssistanceSchema = { currentText: string; previousText: string } & (
  | { namedPrompt: NamedPrompt }
  | { prompt: string }
);
export const createChatSession = async (
  workspaceId: string,
  model: AiModel,
  projectIds: string[],
  mediaGroupIds: string[],
  links: string[],
  files: string[],
  initialQuery: string
) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/`,
    body: {
      workspaceId,
      model,
      projectIds,
      mediaGroupIds,
      files,
      initialQuery,
      links,
    },
    method: 'POST',
    responseSchema: successfulResponseSchema.extend({
      chat: chatSchema,
    }),
  });

export const queryChatSession = async (query: string, chatId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/${chatId}/query/`,
    body: { query },
    method: 'POST',
    responseSchema: successfulResponseSchema.extend({
      message: interactionSchema,
    }),
  });

export type RewriteChatMessageOptions = (
  | {
      part: 'query';
      query: string;
    }
  | {
      part: 'response';
    }
) & {
  id: string;
};

export const rewriteChatSessionMessage = async (
  chatId: string,
  body: RewriteChatMessageOptions
) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/${chatId}/rewrite/`,
    body,
    method: 'POST',
    responseSchema: successfulResponseSchema.extend({
      message: interactionSchema,
    }),
  });

export const getChatSession = async (id: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/${id}/`,
    method: 'GET',
    responseSchema: successfulResponseSchema.extend({
      chat: chatSchema,
    }),
  });

export const removeChatSession = async (id: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/${id}/`,
    method: 'DELETE',
    responseSchema: successfulResponseSchema.extend({
      workspaceId: z.string(),
    }),
  });

export const getChatSessionHistory = async (workspaceId: string | null) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/`,
    method: 'GET',
    params: { workspaceId },
    responseSchema: successfulResponseSchema.extend({
      chats: chatSummarySchema.array(),
    }),
  });

export const createTemporaryFile = async (assetId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/file/`,
    body: { assetId },
    method: 'POST',
    responseSchema: successfulResponseSchema.extend({
      file: temporaryFileSchema,
    }),
  });

export const createTemporaryLink = async (url: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/link/`,
    body: { url },
    method: 'POST',
    responseSchema: successfulResponseSchema.extend({
      link: temporaryLinkSchema,
    }),
  });

export const getChatTemporaryLink = async (id: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/link/detail/${id}/`,
    method: 'GET',
    responseSchema: successfulResponseSchema.extend({
      link: temporaryLinkSchema,
    }),
  });

export const getChatTemporaryFile = async (id: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/file/detail/${id}/`,
    method: 'GET',
    responseSchema: successfulResponseSchema.extend({
      file: temporaryFileSchema,
    }),
  });

export const triggerMediaGroupProcessing = async (mediaGroupId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/chat-session/trigger-media-group-processing/`,
    body: { mediaGroupId },
    method: 'POST',
    responseSchema: successfulResponseSchema,
  });

export const getMediaGroupAiSettings = async (mediaGroupId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${mediaGroupId}/ai-settings/`,
    method: 'GET',
    responseSchema: successfulResponseSchema.extend({
      settings: mediaGroupAiSettings,
    }),
  });

export const patchMediagroupAiSettings = async (
  mediaGroupId: string,
  patch: MediaGroupAiSettingsPatch
) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${mediaGroupId}/ai-settings/update/`,
    method: 'PATCH',
    body: { patch },
    responseSchema: successfulResponseSchema.extend({
      settings: mediaGroupAiSettings,
    }),
  });

export const addWritingResource = async (mediaGroupId: string, resourceId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${mediaGroupId}/ai-settings/add-resource/`,
    method: 'POST',
    body: { mediaGroupId: resourceId },
    responseSchema: successfulResponseSchema.extend({
      settings: mediaGroupAiSettings,
    }),
  });

export const removeWritingResource = async (mediaGroupId: string, resourceId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${mediaGroupId}/ai-settings/remove-resource/`,
    method: 'POST',
    body: { mediaGroupId: resourceId },
    responseSchema: successfulResponseSchema.extend({
      settings: mediaGroupAiSettings,
    }),
  });

export const getWritingAssistance = async (
  mediaGroupId: string,
  body: WritingAssistanceSchema,
  signal?: AbortSignal
) =>
  ApiClient.call({
    endpoint: apiUrl`/w/explore/media-group/${mediaGroupId}/ai-write-assist/`,
    method: 'POST',
    body,
    responseSchema: successfulResponseSchema.extend({
      response: z.string(),
    }),
    signal,
  });
