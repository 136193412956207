import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1841_14935)">
        <path
          d="M4.14937 19.6052C4.03681 19.4926 3.95778 19.3508 3.9211 19.1959C3.88443 19.0409 3.89156 18.8788 3.94169 18.7276L5.74203 13.33C5.79082 13.1842 5.87778 13.0541 5.99386 12.9533C6.10995 12.8525 6.25092 12.7846 6.40212 12.7567L13.2402 11.727L6.40328 10.696C6.25218 10.6683 6.1113 10.6005 5.9953 10.4998C5.8793 10.399 5.79244 10.269 5.74377 10.1233L3.94227 4.72458C3.89002 4.56615 3.88512 4.39593 3.92817 4.23476C3.97122 4.07359 4.06035 3.92848 4.18464 3.81721C4.30892 3.70594 4.46297 3.63334 4.6279 3.60832C4.79283 3.58329 4.96148 3.60691 5.11319 3.6763L20.8726 10.9477C21.0214 11.0162 21.1474 11.1259 21.2357 11.2638C21.324 11.4017 21.371 11.562 21.371 11.7258C21.371 11.8896 21.324 12.0499 21.2357 12.1878C21.1474 12.3257 21.0214 12.4354 20.8726 12.5039L5.11319 19.7765C4.9614 19.8465 4.79241 19.8706 4.6271 19.8457C4.46178 19.8209 4.30736 19.7481 4.18293 19.6365L4.14937 19.6052Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1841_14935">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
