import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.25387 12.9714H2.50624C2.52452 12.952 2.54281 12.9337 2.56008 12.9153L2.67385 12.795C3.34122 12.0955 3.86842 11.5428 3.86537 10.6943C3.86537 10.3986 3.79427 10.1121 3.66323 9.8663C3.36662 9.30749 2.76527 8.94548 2.05421 8.89551C1.10241 8.83433 0.244067 9.46758 0.0216077 10.4068C-0.0759086 10.8177 0.177024 11.2297 0.586389 11.3276C0.994739 11.4265 1.40614 11.1705 1.50365 10.7606C1.55343 10.5536 1.75557 10.4098 1.9506 10.421C2.12125 10.4333 2.27362 10.5006 2.31933 10.5862C2.32238 10.5934 2.34067 10.6301 2.34067 10.6984C2.34067 10.9187 2.07758 11.2052 1.57273 11.7355L1.45693 11.8568C1.16234 12.1658 0.726569 12.6308 0.195309 13.2233C-0.00581876 13.4476 -0.0566085 13.7699 0.0663026 14.0462C0.189214 14.3226 0.461447 14.5 0.762122 14.5H3.25387C3.6744 14.5 4.01571 14.1574 4.01571 13.7352C4.01571 13.313 3.6744 12.9704 3.25387 12.9704V12.9714Z"
        fill={color}
      />
      <path
        d="M1.17345 3.71625L1.27097 3.65405V6.3431C1.27097 6.76527 1.61227 7.1079 2.03281 7.1079C2.45335 7.1079 2.79466 6.76527 2.79466 6.3431V2.26414C2.79466 1.98575 2.6433 1.72878 2.40053 1.59417C2.15674 1.46058 1.86013 1.46976 1.62548 1.61762L0.358783 2.42322C0.00325461 2.6496 -0.102388 3.12174 0.123118 3.47865C0.348625 3.83658 0.820969 3.94059 1.17447 3.71523L1.17345 3.71625Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.84204 3.62379C5.84204 3.2014 6.20108 2.85899 6.64398 2.85899H15.198C15.6409 2.85899 16 3.2014 16 3.62379C16 4.04618 15.6409 4.3886 15.198 4.3886H6.64398C6.20108 4.3886 5.84204 4.04618 5.84204 3.62379Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.84204 8.38257C5.84204 7.96018 6.20108 7.61777 6.64398 7.61777H15.198C15.6409 7.61777 16 7.96018 16 8.38257C16 8.80496 15.6409 9.14738 15.198 9.14738H6.64398C6.20108 9.14738 5.84204 8.80496 5.84204 8.38257Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.84204 13.1414C5.84204 12.719 6.20108 12.3766 6.64398 12.3766H15.198C15.6409 12.3766 16 12.719 16 13.1414C16 13.5638 15.6409 13.9062 15.198 13.9062H6.64398C6.20108 13.9062 5.84204 13.5638 5.84204 13.1414Z"
        fill={color}
      />
    </svg>
  );
}
