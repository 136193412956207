import type { MediaGroupDTO } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import { exists } from '@spaceduck/utils';
import Tippy from '@tippyjs/react';
import { type Editor, Node } from '@tiptap/core';
import {
  type NodeViewRendererProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  mergeAttributes,
} from '@tiptap/react';
import clsx from 'clsx';

import { urlFor } from '@/urls';
import { useMediaGroupSummary } from '@api/mediaGroup';
import NoAccessCard from '@components/NoAccessCard';
import { useSidebarModal } from '@components/detailsModal/sidebar/SidebarModal';
import { useIsInWebViewRoute } from '@hooks/useIsWebView';
import Menu from '@ui/Menu';
import Spinner from '@ui/Spinner';
import { copyTextToClipboard } from '@utils/copyToClipboard';
import { downloadUrl } from '@utils/download';
import CardView from './CardView';
import { OutgoingLink } from './OutgoingLink';
import styles from './ContentBlock.module.scss';

const { Copy, Download, Link, LinkCard, Open, TrashDelete } = Icon16;
const { AlertInfo } = Icon24;

export default Node.create({
  name: 'content-block',
  priority: 1000,
  group: 'block',
  atom: true,

  addAttributes() {
    return {
      'data-ref': {
        default: null,
      },
      id: {
        default: null,
      },
      label: {
        default: '',
      },
      contentType: {
        default: '',
      },
      'data-minimal-view': {
        default: false,
      },
      loading: {
        default: false,
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },

  parseHTML() {
    return [
      {
        tag: 'content-block',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['content-block', mergeAttributes(HTMLAttributes)];
  },
});

const Component = (props: NodeViewRendererProps & { deleteNode?: () => void }) => {
  const attrsLoading = props.node.attrs.loading || false;
  const { data, error, isLoading } = useMediaGroupSummary(props.node?.attrs.id ?? null);
  const isCardView =
    props.editor.view.props.attributes &&
    'data-is-card-view' in props.editor.view.props.attributes
      ? props.editor.view.props.attributes['data-is-card-view'] === 'true'
      : false;

  const toggleView: ToggleViewHandler = (displayAsMinimalView: boolean) => {
    const editor: Editor = props.editor;
    if (typeof props.getPos === 'function') {
      editor
        .chain()
        .setNodeSelection(props.getPos())
        .updateAttributes('content-block', {
          'data-minimal-view': displayAsMinimalView,
        })
        .run();
    }
  };

  const switchToOutgoingLink = () => {
    const editor: Editor = props.editor;
    if (typeof props.getPos === 'function') {
      editor
        .chain()
        .setNodeSelection(props.getPos())
        .deleteCurrentNode()
        .insertContent({
          type: 'outgoing-link',
          attrs: {
            id: props.node.attrs.id,
            label: props.node.attrs.label,
            contentType: props.node.attrs.contentType,
          },
        })
        .run();
    }
  };

  if (isLoading || attrsLoading) {
    return (
      <NodeViewWrapper>
        <div>
          <Spinner />
        </div>
      </NodeViewWrapper>
    );
  }

  if (error || !data?.mediaGroup) {
    console.error('Could not load media group', error);

    return (
      <NodeViewWrapper>
        <div className={styles.block}>
          <div className={styles.cardWrapper}>
            <NoAccessCard />
          </div>
        </div>
      </NodeViewWrapper>
    );
  }

  if (data.mediaGroup.contentType === 'document') {
    return (
      <OutgoingLink
        isEditable={props.editor.isEditable}
        mediaGroup={data.mediaGroup}
        onDelete={() => props.deleteNode?.()}
      />
    );
  }

  return (
    <NodeViewWrapper>
      <ContentBlock
        editor={props.editor}
        isCardView={isCardView}
        isEditable={props.editor.isEditable}
        mediaGroup={data.mediaGroup}
        onDelete={() => props.deleteNode?.()}
        toggleView={toggleView}
        switchToOutgoingLink={switchToOutgoingLink}
      />
    </NodeViewWrapper>
  );
};

type ToggleViewHandler = (displayAsMinimalView: boolean) => void;

const ContentBlock = ({
  editor,
  isCardView,
  isEditable,
  mediaGroup,
  onDelete,
  toggleView,
  switchToOutgoingLink,
}: {
  editor: Editor;
  isCardView?: boolean;
  isEditable: boolean;
  mediaGroup: MediaGroupDTO;
  onDelete?: () => void;
  toggleView: ToggleViewHandler;
  switchToOutgoingLink: () => void;
}) => {
  return (
    <ContentBlockCard
      className={clsx(isCardView && styles.cardView)}
      editor={editor}
      isCardView={isCardView}
      isEditable={isEditable}
      mediaGroup={mediaGroup}
      onDelete={onDelete}
      switchToOutgoingLink={switchToOutgoingLink}
      toggleView={toggleView}
    >
      <CardView
        containerClassName={styles.noPadding}
        mediaGroupId={mediaGroup.id}
        deleteNode={onDelete}
      />
    </ContentBlockCard>
  );
};

const ContentBlockCard = ({
  children,
  className,
  editor,
  isCardView = false,
  isEditable,
  mediaGroup,
  onDelete,
  switchToOutgoingLink,
  toggleView,
}: {
  children: React.ReactNode;
  className?: string;
  isCardView?: boolean;
  editor: Editor;
  isEditable: boolean;
  mediaGroup: MediaGroupDTO;
  onDelete?: () => void;
  switchToOutgoingLink: () => void;
  toggleView?: ToggleViewHandler;
}) => {
  const isInWebViewRoute = useIsInWebViewRoute();
  const { open: openSidebarModal } = useSidebarModal();
  const assetUrl = mediaGroup.media[0]?.assetUrl;

  if (isInWebViewRoute || isCardView) {
    return <div className={clsx(styles.block, className)}>{children}</div>;
  }

  return (
    <Tippy
      allowHTML
      duration={[0, 500]}
      interactive={true}
      content={
        <div className={styles.menuBar}>
          <Menu
            menuItems={[
              {
                icon: <Open />,
                onClick: () =>
                  window.open(urlFor('mediaGroup', { mediaGroupId: mediaGroup.id })),
                tooltip: 'View',
              },
              {
                isSeparator: true,
              },
              {
                icon: <AlertInfo size={16} />,
                tooltip: 'Info',
                onClick: () => {
                  editor.chain().blur().run();
                  queueMicrotask(() => {
                    openSidebarModal({ mediaGroupId: mediaGroup.id });
                  });
                },
              },
              assetUrl
                ? {
                    icon: <Download />,
                    onClick: () => downloadUrl(assetUrl),
                    tooltip: 'Download',
                  }
                : null,
              {
                icon: <Copy />,
                tooltip: 'Copy URL',
                onClick: () => {
                  copyTextToClipboard(
                    new URL(
                      urlFor('mediaGroup', { mediaGroupId: mediaGroup.id }),
                      window.location.origin
                    ).toString(),
                    {
                      bodyText: 'Share link copied to clipboard!',
                    }
                  );
                },
              },
              isEditable
                ? {
                    icon: <TrashDelete />,
                    onClick: () => onDelete?.(),
                    tooltip: 'Delete',
                  }
                : null,
              {
                isSeparator: true,
              },
              {
                title: 'View',
                tooltip: 'View',
                icon: <Link />,
                menuItems: [
                  {
                    icon: <Link />,
                    label: 'Inline view',
                    onClick: switchToOutgoingLink,
                  },
                  {
                    icon: <LinkCard />,
                    label: 'Card view',
                    onClick: () => toggleView?.(true),
                    showCheck: true,
                  },
                ].filter(exists),
              },
            ].filter(exists)}
          />
        </div>
      }
    >
      <div className={clsx(styles.block)}>{children}</div>
    </Tippy>
  );
};
