import { useRef, useState } from 'react';

export const useHorizontalSwipeDetection = (
  handleLeftSwipe?: () => void,
  handleRightSwipe?: () => void,
  minSwipeDistance = 50
) => {
  const ref = useRef<HTMLDivElement>(null);

  const [position, setPosition] = useState<{
    start: number | null;
    end: number | null;
  }>({ start: null, end: null });

  const onTouchStart = (ev: React.TouchEvent) => {
    const touch = ev.targetTouches[0];
    if (!touch) {
      return;
    }
    setPosition(() => ({ start: touch.clientX, end: null }));
  };

  const onTouchMove = (ev: React.TouchEvent) => {
    const touch = ev.targetTouches[0];
    if (!touch) {
      return;
    }
    setPosition((position) => ({
      ...position,
      end: touch.clientX,
    }));
  };

  const onTouchEnd = () => {
    if (!(position.start && position.end)) return;

    const distance = position.end - position.start;
    if (Math.abs(distance) < minSwipeDistance) return;

    if (distance > 0 && handleRightSwipe) {
      handleRightSwipe();
    }

    if (distance < 0 && handleLeftSwipe) {
      handleLeftSwipe();
    }
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    ref,
  };
};
