import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.88171 4.28571C6.88171 3.86612 7.22833 3.52597 7.65591 3.52597H15.2258C15.6534 3.52597 16 3.86612 16 4.28571C16 4.7053 15.6534 5.04545 15.2258 5.04545H7.65591C7.22833 5.04545 6.88171 4.7053 6.88171 4.28571Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.25974C0 1.84015 0.346618 1.5 0.774194 1.5H4.90323C5.3308 1.5 5.67742 1.84015 5.67742 2.25974V6.31169C5.67742 6.73128 5.3308 7.07143 4.90323 7.07143H0.774194C0.346618 7.07143 0 6.73128 0 6.31169V2.25974ZM1.54839 3.01948V5.55195H4.12903V3.01948H1.54839Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.88171 12.3896C6.88171 11.97 7.22833 11.6299 7.65591 11.6299H15.2258C15.6534 11.6299 16 11.97 16 12.3896C16 12.8092 15.6534 13.1493 15.2258 13.1493H7.65591C7.22833 13.1493 6.88171 12.8092 6.88171 12.3896Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.82701 9.1511C7.12935 9.44779 7.12935 9.92883 6.82701 10.2255L2.69798 14.2775C2.39563 14.5742 1.90544 14.5742 1.6031 14.2775L0.226756 12.9268C-0.0755854 12.6301 -0.0755854 12.1491 0.226756 11.8524C0.529097 11.5557 1.01929 11.5557 1.32163 11.8524L2.15054 12.6658L5.73213 9.1511C6.03447 8.8544 6.52467 8.8544 6.82701 9.1511Z"
        fill={color}
      />
    </svg>
  );
}
