import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3652_33531)">
        <path
          d="M15 5H8C7.4 5 7 5.4 7 6V15C7 15.6 7.4 16 8 16H15C15.6 16 16 15.6 16 15V6C16 5.4 15.6 5 15 5Z"
          fill={color}
        />
        <path
          d="M5 4C5 3.4 5.4 3 6 3H12V1C12 0.4 11.6 0 11 0H1C0.4 0 0 0.4 0 1V13C0 13.6 0.4 14 1 14H5V4Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3652_33531">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
