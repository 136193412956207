import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1617_3931)">
        <path
          d="M8.85695 5.357L5.85195 8.362C5.16895 9.045 5.16895 10.153 5.85195 10.837C6.53495 11.52 7.64295 11.52 8.32695 10.837L12.393 6.771C13.76 5.404 13.76 3.188 12.393 1.821C11.026 0.453996 8.80995 0.453996 7.44295 1.821L3.02395 6.241C0.97395 8.291 0.97395 11.615 3.02395 13.666C5.07395 15.716 8.39795 15.716 10.449 13.666L14.514 9.6"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1617_3931">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
