import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { type Node, Position, useReactFlow, type NodeProps } from '@xyflow/react';
import { EditorContent, useEditor } from '@tiptap/react';

import { exists } from '@spaceduck/utils';

import { useOnClickOutside } from '@hooks/useOnClickOutside';
import ContextMenu, { type ContextMenuItemProps } from '@ui/ContextMenu';
import Handle from '../components/Handle';
import Resizer from '../components/Resizer';
import {
  DEFAULT_FLOATING_NOTE_HEIGHT,
  DEFAULT_FLOATING_NOTE_WIDTH,
  useNodes,
} from '../hooks/useNodes';
import { boardFloatingTextExtensions } from '../../tiptap/extensions';
import { BoardSelectionMenu } from '../../tiptap/SelectionMenu';
import type { FloatingTextNodeType } from '../types/board';
import styles from './FloatingTextNode.module.scss';
import { useMetaKey } from '@/hooks/useMetaKey';

const findRelativePosition = (
  currentNode: Node,
  allNodes: Node[]
): { x: number; y: number } => {
  if (!currentNode?.parentId) {
    return { x: currentNode.position.x, y: currentNode.position.y };
  }

  const parentNode = allNodes.find((n) => n.id === currentNode.parentId);
  if (!parentNode) {
    return { x: currentNode.position.x, y: currentNode.position.y };
  }

  const parentPosition = findRelativePosition(parentNode, allNodes);

  return {
    x: parentPosition.x + currentNode.position.x,
    y: parentPosition.y + currentNode.position.y,
  };
};

export const FloatingTextNode = (props: NodeProps<FloatingTextNodeType>) => {
  const [showResizer, setShowResizer] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(props.data.isInEditMode ?? false);
  const editor = useEditor(
    {
      extensions: boardFloatingTextExtensions,
      content: props.data.content ?? undefined,
      editable: isInEditMode,
    },
    [isInEditMode, props.data.content]
  );

  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: () => {
      setIsInEditMode(false);
    },
  });

  useEffect(() => {
    const editorContent = editor?.getJSON();
    if (editor && !isInEditMode && props.data.content !== editorContent) {
      updateNode(props.id, {
        data: {
          content: editorContent ?? null,
        },
      });
    }
  }, [isInEditMode]);

  useEffect(() => {
    if (isInEditMode && editor) {
      editor.commands.focus();
    }
  }, [editor, isInEditMode]);

  const { copyNode, cutNode, deleteNode, duplicateNode, selectedNodes, updateNode } =
    useNodes();
  const { getNode, setNodes } = useReactFlow();

  const node = getNode(props.id);
  const isSelected = selectedNodes.includes(props.id);

  const removeFromFrame = useCallback(() => {
    setNodes((nodes) => {
      return nodes.map((node) => {
        if (selectedNodes.includes(node.id) && node.type !== 'groupNode') {
          const { x, y } = node ? findRelativePosition(node, nodes) : { x: 0, y: 0 };

          return {
            ...node,
            extent: undefined,
            parentId: undefined,
            position: {
              x,
              y,
            },
          };
        }

        return node;
      });
    });
  }, [selectedNodes, setNodes]);

  const copy = useCallback(() => copyNode(props.id), [props.id]);
  const cut = useCallback(() => cutNode(props.id), [props.id]);
  const deleteSelf = useCallback(() => deleteNode(props.id), [props.id]);

  const duplicate = useCallback(() => {
    duplicateNode(props.id);
    setShowResizer(false);
  }, [duplicateNode, setShowResizer]);

  const contextMenu = createContextMenu({
    copy,
    cut,
    deleteSelf,
    duplicate,
    isInFrame: !!node?.parentId,
    removeFromFrame,
  });

  return (
    <div
      className={clsx(
        'nowheel',
        styles.container,
        isInEditMode && 'nodrag',
        isInEditMode && styles.isInEditMode,
        isSelected && styles.overflowYAuto
      )}
      onMouseOver={() => setShowResizer(true)}
      onMouseOut={() => setShowResizer(false)}
      onFocus={() => setShowResizer(true)}
      onBlur={() => setShowResizer(false)}
      ref={containerRef}
      style={{
        width: node?.width ?? DEFAULT_FLOATING_NOTE_WIDTH,
        height: node?.height ?? DEFAULT_FLOATING_NOTE_HEIGHT,
      }}
    >
      <Resizer isVisible={isSelected} />
      <ContextMenu contentClassName={styles.menu} items={contextMenu}>
        <Handle
          id={`${props.id}--handle-source-top`}
          type="source"
          position={Position.Top}
          className={clsx(
            styles.handle,
            !isSelected && showResizer && styles.isVisible
          )}
        />
        <Handle
          id={`${props.id}--handle-source-right`}
          type="source"
          position={Position.Right}
          className={clsx(
            styles.handle,
            !isSelected && showResizer && styles.isVisible
          )}
        />
        <Handle
          id={`${props.id}--handle-source-bottom`}
          type="source"
          position={Position.Bottom}
          className={clsx(
            styles.handle,
            !isSelected && showResizer && styles.isVisible
          )}
        />
        <Handle
          id={`${props.id}--handle-source-left`}
          type="source"
          position={Position.Left}
          className={clsx(
            styles.handle,
            !isSelected && showResizer && styles.isVisible
          )}
        />
        <Handle
          id={`${props.id}--handle-target-top`}
          type="target"
          position={Position.Top}
          className={clsx(
            styles.handle,
            !isSelected && showResizer && styles.isVisible
          )}
        />
        <Handle
          id={`${props.id}--handle-target-right`}
          type="target"
          position={Position.Right}
          className={clsx(
            styles.handle,
            !isSelected && showResizer && styles.isVisible
          )}
        />
        <Handle
          id={`${props.id}--handle-target-bottom`}
          type="target"
          position={Position.Bottom}
          className={clsx(
            styles.handle,
            !isSelected && showResizer && styles.isVisible
          )}
        />
        <Handle
          id={`${props.id}--handle-target-left`}
          type="target"
          position={Position.Left}
          className={clsx(
            styles.handle,
            !isSelected && showResizer && styles.isVisible
          )}
        />
        <div className={styles.textbox} onDoubleClick={() => setIsInEditMode(true)}>
          <div className={styles.formatMenu}>
            <BoardSelectionMenu editor={editor} />
          </div>
          <div className={styles.content}>
            <EditorContent className={styles.editor} editor={editor} />
          </div>
        </div>
      </ContextMenu>
    </div>
  );
};

function createContextMenu({
  copy,
  cut,
  deleteSelf,
  duplicate,
  isInFrame,
  removeFromFrame,
}: {
  copy: () => void;
  cut: () => void;
  deleteSelf: () => void;
  duplicate: () => void;
  isInFrame: boolean;
  removeFromFrame: () => void;
}) {
  const metaKey = useMetaKey();

  const contextMenu: ContextMenuItemProps[] = [
    {
      content: 'Cut',
      onClick: cut,
      shortcut: `${metaKey} X`,
    },
    {
      content: 'Copy',
      onClick: copy,
      shortcut: `${metaKey} C`,
    },
    {
      content: 'Duplicate',
      onClick: duplicate,
      shortcut: `${metaKey} D`,
    },
    {
      content: 'Remove from board',
      onClick: deleteSelf,
      shortcut: 'Del',
    },
    isInFrame
      ? {
          content: 'Remove from Frame',
          onClick: removeFromFrame,
        }
      : undefined,
  ].filter(exists);

  return contextMenu;
}
