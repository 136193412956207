import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C17.525 2 22 6.58819 22 12.2529C21.9995 14.4012 21.3419 16.4952 20.1198 18.2402C18.8977 19.9852 17.1727 21.2933 15.1875 21.9804C14.6875 22.0829 14.5 21.7625 14.5 21.4934C14.5 21.1474 14.5125 20.0452 14.5125 18.6738C14.5125 17.7126 14.2 17.0975 13.8375 16.777C16.0625 16.5207 18.4 15.6492 18.4 11.7147C18.4 10.5868 18.0125 9.67689 17.375 8.95918C17.475 8.70286 17.825 7.65193 17.275 6.24215C17.275 6.24215 16.4375 5.9602 14.525 7.29308C13.725 7.06239 12.875 6.94704 12.025 6.94704C11.175 6.94704 10.325 7.06239 9.525 7.29308C7.6125 5.97301 6.775 6.24215 6.775 6.24215C6.225 7.65193 6.575 8.70286 6.675 8.95918C6.0375 9.67689 5.65 10.5997 5.65 11.7147C5.65 15.6364 7.975 16.5207 10.2 16.777C9.9125 17.0334 9.65 17.4819 9.5625 18.1484C8.9875 18.4175 7.55 18.8533 6.65 17.3025C6.4625 16.9949 5.9 16.2388 5.1125 16.2516C4.275 16.2644 4.775 16.7386 5.125 16.9308C5.55 17.1743 6.0375 18.0843 6.15 18.3791C6.35 18.9558 7 20.058 9.5125 19.5838C9.5125 20.4425 9.525 21.2499 9.525 21.4934C9.525 21.7625 9.3375 22.0701 8.8375 21.9804C6.8458 21.3007 5.11342 19.9952 3.88611 18.2492C2.65881 16.5031 1.9989 14.4052 2 12.2529C2 6.58819 6.475 2 12 2Z"
        fill={color}
      />
    </svg>
  );
}
