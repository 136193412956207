import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Icon16, Icon24 } from '@spaceduck/icons';

import Button, { ButtonLink } from '@/components/ui/Button';
import useWorkspaceId from '@/hooks/useWorkspaceId';
import { urlFor } from '@/urls';
import { activeProjectKeys } from '@/const';
import { useCreateAndNavigateToMediaGroup } from '@api/mediaGroup';
import { isCapable } from '@api/util';
import { useWorkspace, useWorkspaceProjects } from '@api/workspace';
import { useCreateProjectModal } from '@components/admin/CreateProjectsModal';
import { useFileUploadWrapper } from '@hooks/useFileUploadWrapper';
import sharedProjectStyles from '@pages/projects/Shared.module.scss';
import DropdownMenu from '@ui/DropdownMenu';
import Tooltip from '@/components/ui/Tooltip';

import styles from './ContentManagementSection.module.scss';

const { Delete, Support, Add, Project, Document } = Icon16;
const { ImageUpload, Board } = Icon24;

export const ContentManagementSection = () => {
  const navigate = useNavigate();
  const workspaceId = useWorkspaceId();
  return (
    <div className={styles.contentManagement}>
      <CreateItemMenu />
      <div className={styles.leftNavActions}>
        {workspaceId && (
          <Tooltip side="bottom" content="Trash">
            <Button
              variant="ghost"
              onClick={() => navigate(urlFor('workspaceTrashItems', { workspaceId }))}
            >
              <Delete />
            </Button>
          </Tooltip>
        )}
        <Tooltip side="bottom" content="Help">
          <ButtonLink variant="ghost" to="https://spaceduck.com/learn" target="_blank">
            <Support />
          </ButtonLink>
        </Tooltip>
      </div>
    </div>
  );
};

export default function CreateItemMenu() {
  const projectId = useParams<{ projectId: string }>().projectId!;

  const workspaceId = useWorkspaceId();
  const { data: workspace } = useWorkspace(workspaceId);
  const { data: workspaceProjects } = useWorkspaceProjects(workspaceId, {
    status: activeProjectKeys,
  });
  const createDocument = useCreateAndNavigateToMediaGroup('document');
  const createBoard = useCreateAndNavigateToMediaGroup('board');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { handleChange } = useFileUploadWrapper({ workspaceId, projectId });

  const { open: openCreateProjectModal } = useCreateProjectModal({
    canCreateProject: isCapable('create', workspaceProjects?.capabilities),
    redirectOnCreate: true,
  });

  const createProjectCapable = isCapable('create', workspaceProjects?.capabilities);
  const createMediaGroupCapable = isCapable(
    'create',
    workspace?.workspace.capabilities
  );
  const canRoleCreateProject =
    createProjectCapable.capable || createProjectCapable.reason !== 'role';
  const canRoleCreateMediaGroup =
    createMediaGroupCapable.capable || createMediaGroupCapable.reason !== 'role';

  return (
    <div className={styles.creationSection}>
      {(canRoleCreateMediaGroup || canRoleCreateProject) && (
        <DropdownMenu
          align="start"
          className={clsx('menu', sharedProjectStyles.dropdownMenu)}
          triggerContent={
            <div>
              <Button
                className={clsx(sharedProjectStyles.createButton, styles.button)}
                iconBefore={<Add size={16} />}
                size="sm"
                type="button"
                variant="ghost"
              >
                New item
              </Button>
              <input
                type="file"
                multiple
                ref={fileInputRef}
                onChange={handleChange}
                style={{ display: 'none' }}
              />
            </div>
          }
        >
          {canRoleCreateMediaGroup && (
            <>
              <DropdownMenuItem
                className={sharedProjectStyles.dropdownMenuItem}
                onSelect={() => fileInputRef?.current?.click()}
              >
                <div className={sharedProjectStyles.icon}>
                  <ImageUpload />
                </div>
                <div className={sharedProjectStyles.text}>
                  <div className={sharedProjectStyles.title}>Media & Files</div>
                  <div className={sharedProjectStyles.description}>
                    Images, Videos, PDFs, etc
                  </div>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem
                className={sharedProjectStyles.dropdownMenuItem}
                onSelect={() =>
                  createDocument({
                    projectId,
                    workspaceId: workspace?.workspace.id,
                  })
                }
              >
                <div className={sharedProjectStyles.icon}>
                  <Document />
                </div>
                <div className={sharedProjectStyles.text}>
                  <div className={sharedProjectStyles.title}>Document</div>
                  <div className={sharedProjectStyles.description}>Notes</div>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem
                className={sharedProjectStyles.dropdownMenuItem}
                onSelect={() =>
                  createBoard({
                    projectId,
                    workspaceId: workspace?.workspace.id,
                  })
                }
              >
                <div className={sharedProjectStyles.icon}>
                  <Board />
                </div>
                <div className={sharedProjectStyles.text}>
                  <div className={sharedProjectStyles.title}>Board</div>
                  <div className={sharedProjectStyles.description}>
                    Integrated whiteboard
                  </div>
                </div>
              </DropdownMenuItem>
            </>
          )}
          {canRoleCreateProject && (
            <DropdownMenuItem
              className={sharedProjectStyles.dropdownMenuItem}
              onSelect={openCreateProjectModal}
            >
              <div className={sharedProjectStyles.icon}>
                <Project />
              </div>
              <div className={sharedProjectStyles.text}>
                <div className={sharedProjectStyles.title}>Space</div>
                <div className={sharedProjectStyles.description}>
                  New design or research space
                </div>
              </div>
            </DropdownMenuItem>
          )}
        </DropdownMenu>
      )}
    </div>
  );
}
