import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8C0 7.58578 0.275515 7.25 0.615385 7.25H15.3846C15.7245 7.25 16 7.58578 16 8C16 8.41424 15.7245 8.75 15.3846 8.75H0.615385C0.275515 8.75 0 8.41424 0 8Z"
        fill={color}
      />
    </svg>
  );
}
