import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';

import { urlFor } from '@/urls';
import { useListMediaGroups, useMediaGroupDetail } from '@api/mediaGroup';
import Searchbar from '@components/Searchbar';
import { useSearch } from '@hooks/useSearch';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import Card from '@ui/Card';
import Spinner from '@ui/Spinner';
import DraggableCard from './DraggableCard';
import ExtendedPreviewCard from './ExtendedPreviewCard';
import { useSidebarModal } from './SidebarModal';
import SimilarContent from './SimilarContent';
import styles from './BrowseRepository.module.scss';

export default function BrowseRepository({
  isLoading,
  mediaGroupId,
}: {
  isLoading: boolean;
  mediaGroupId: string;
}) {
  const navigate = useNavigate();
  const [anchorMediaGroupId, setAnchorMediaGroupId] = useState<string | null>(null);
  const {
    quickViewMediaGroupId,
    setQuickViewMediaGroupId,
    setSwapQuickViewMediaGroupId,
  } = useDetailsModalStore((store) => ({
    quickViewMediaGroupId: store.quickViewMediaGroupId,
    setQuickViewMediaGroupId: store.setQuickViewMediaGroupId,
    setSwapQuickViewMediaGroupId: store.setSwapQuickViewMediaGroupId,
  }));

  const {
    data,
    isLoading: quickViewIsLoading,
    error,
  } = useMediaGroupDetail(quickViewMediaGroupId ?? null);

  const { open: openSidebarModal } = useSidebarModal();

  if (!quickViewMediaGroupId) {
    return (
      <MediaGroupListing
        anchorMediaGroupId={anchorMediaGroupId}
        isLoading={isLoading}
        mediaGroupId={mediaGroupId}
        setAnchorMediaGroupId={setAnchorMediaGroupId}
      />
    );
  }

  if (error) return null;

  if (quickViewIsLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  if (!data) return null;

  const { mediaGroup } = data;

  return (
    <ExtendedPreviewCard
      containerClassName={styles.extendedPreviewCardContainer}
      className={styles.extendedPreviewCard}
      mediaGroup={mediaGroup}
      onClose={() => setQuickViewMediaGroupId(null)}
      onDelete={() => setQuickViewMediaGroupId(null)}
      onSwapClick={() => {
        setSwapQuickViewMediaGroupId(mediaGroupId);
        navigate(
          urlFor('mediaGroupBrowseRepository', {
            mediaGroupId: mediaGroup.id,
          })
        );
      }}
      onViewInfoClick={() => openSidebarModal({ mediaGroupId: mediaGroup.id })}
    />
  );
}

type MediaGroupListingProps = {
  anchorMediaGroupId?: string | null;
  isLoading: boolean;
  mediaGroupId: string;
  setAnchorMediaGroupId?: React.Dispatch<React.SetStateAction<string | null>>;
};

const MediaGroupListing = ({
  anchorMediaGroupId,
  isLoading,
  mediaGroupId,
  setAnchorMediaGroupId,
}: MediaGroupListingProps) => {
  const workspaceId = useWorkspaceId();
  const { data: mediaGroupDetailData } = useMediaGroupDetail(mediaGroupId);
  const projectId = mediaGroupDetailData?.mediaGroup.project?.id;

  const { debouncedSearchValue, hasQuery, mediaGroupFilters } = useSearch();

  const {
    data: mediaGroupData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    enabled,
  } = useListMediaGroups(workspaceId, {
    query: debouncedSearchValue,
    ...mediaGroupFilters,
    excludeProjectLibraries: false,
  });

  const { setQuickViewMediaGroupId } = useDetailsModalStore((store) => ({
    setQuickViewMediaGroupId: store.setQuickViewMediaGroupId,
  }));

  const { ref: loadMoreRef, inView } = useInView();

  useEffect(() => {
    if (enabled && inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [enabled, inView, isFetchingNextPage, hasNextPage]);

  const mediaGroups = mediaGroupData?.pages
    .flatMap((page) => page.mediaGroups)
    .filter(({ id }) => id !== mediaGroupId);

  useEffect(() => {
    if (anchorMediaGroupId) {
      if (
        !mediaGroups?.find(({ id }) => id === anchorMediaGroupId) &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      } else if (!hasNextPage) {
        setAnchorMediaGroupId?.(null);
      }
    }
  }, [anchorMediaGroupId, mediaGroups]);

  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <Searchbar
          className={styles.searchbar}
          defaultFilters={{ project: projectId ? [projectId] : undefined }}
          placeholder="Search content"
        />
      </div>
      {!hasQuery ? (
        <SimilarContent mediaGroupId={mediaGroupId} />
      ) : (
        <>
          {!isLoading && mediaGroups && mediaGroups.length === 0 && (
            <div className={styles.container}>
              <p>No entries found.</p>
            </div>
          )}
          {!isLoading && mediaGroups && (
            <div className={styles.list}>
              {mediaGroups.map((mg) => {
                return (
                  <DraggableCard
                    className={styles.draggableItem}
                    key={mg.id}
                    mediaGroup={mg}
                    onClick={() => {
                      setAnchorMediaGroupId?.(mg.id);
                      setQuickViewMediaGroupId(mg.id);
                    }}
                    anchorMediaGroupId={anchorMediaGroupId}
                    setAnchorMediaGroupId={setAnchorMediaGroupId}
                  >
                    <Card mediaGroup={mg} className={styles.card} />
                  </DraggableCard>
                );
              })}
              <div ref={loadMoreRef} style={{ width: '100%', height: '10px' }}>
                {/* Triggers page fetch when in view */}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
