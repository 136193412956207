import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 48 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 48 48"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0029 46.0002C12.9029 46.0002 12.8039 45.9932 12.7029 45.9782C11.6109 45.8142 10.8579 44.7962 11.0219 43.7032L17.0219 3.70323C17.1849 2.61023 18.2109 1.85923 19.2959 2.02223C20.3879 2.18623 21.1409 3.20422 20.9769 4.29722L14.9769 44.2972C14.8289 45.2892 13.9749 46.0012 13.0019 46.0012L13.0029 46.0002Z"
        fill="url(#paint0_linear_2090_233879)"
      />
      <path
        d="M29.0029 46.0002C28.9029 46.0002 28.8039 45.9932 28.7029 45.9782C27.6109 45.8142 26.8579 44.7962 27.0219 43.7032L33.0219 3.70323C33.1849 2.61023 34.2109 1.85923 35.2959 2.02223C36.3879 2.18623 37.1409 3.20422 36.9769 4.29722L30.9769 44.2972C30.8289 45.2892 29.9749 46.0012 29.0019 46.0012L29.0029 46.0002Z"
        fill="url(#paint1_linear_2090_233879)"
      />
      <path
        opacity="0.15"
        d="M14.5781 20H18.6221L19.2221 16H15.1781L14.5781 20Z"
        fill="black"
      />
      <path
        opacity="0.15"
        d="M30.5781 20H34.6221L35.2221 16H31.1781L30.5781 20Z"
        fill="black"
      />
      <path
        opacity="0.15"
        d="M16.2222 36L16.8222 32H12.7782L12.1782 36H16.2222Z"
        fill="black"
      />
      <path
        opacity="0.15"
        d="M32.2222 36L32.8222 32H28.7782L28.1782 36H32.2222Z"
        fill="black"
      />
      <path
        d="M40 18H10C8.896 18 8 17.104 8 16C8 14.896 8.896 14 10 14H40C41.104 14 42 14.896 42 16C42 17.104 41.104 18 40 18Z"
        fill="url(#paint2_linear_2090_233879)"
      />
      <path
        d="M38 34H8C6.896 34 6 33.104 6 32C6 30.896 6.896 30 8 30H38C39.104 30 40 30.896 40 32C40 33.104 39.104 34 38 34Z"
        fill="url(#paint3_linear_2090_233879)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2090_233879"
          x1="15.9994"
          y1="2.00001"
          x2="15.9994"
          y2="46.0012"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#707792" />
          <stop offset="1" stopColor="#565A6C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2090_233879"
          x1="31.9994"
          y1="2.00001"
          x2="31.9994"
          y2="46.0012"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#707792" />
          <stop offset="1" stopColor="#565A6C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2090_233879"
          x1="25"
          y1="14"
          x2="25"
          y2="18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9C9FB0" />
          <stop offset="1" stopColor="#707192" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2090_233879"
          x1="23"
          y1="30"
          x2="23"
          y2="34"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9C9FB0" />
          <stop offset="1" stopColor="#707192" />
        </linearGradient>
      </defs>
    </svg>
  );
}
