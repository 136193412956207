import type { ProjectStatus } from '@spaceduck/api';

import Badge, { type BadgeProps } from '@ui/Badge';

type StatusBadgeProps = {
  status?: ProjectStatus;
} & Omit<BadgeProps, 'children' | 'preset'>;

const statuses = new Map(
  Object.entries({
    completed: 'Completed',
    canceled: 'Canceled',
    inProgress: 'In progress',
    none: 'No status',
    paused: 'Paused',
    planned: 'Planned',
  })
);

const getStatusText = (status: ProjectStatus) => {
  if (statuses.has(status)) {
    return statuses.get(status);
  }

  return null;
};

export default function StatusBadge(props: StatusBadgeProps) {
  const {
    background = 3,
    color = 2,
    outline = 0,
    size = 'sm',
    status,
    ...badgeProps
  } = props;
  const preset = status && statuses.has(status) ? status : null;

  if (!preset) return null;

  return (
    <Badge
      background={background}
      color={color}
      outline={outline}
      preset={preset}
      size={size}
      {...badgeProps}
    >
      {getStatusText(preset)}
    </Badge>
  );
}
