import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3C5.448 3 5 3.448 5 4V7H2V4C2 3.448 1.552 3 1 3C0.448 3 0 3.448 0 4V12C0 12.552 0.448 13 1 13C1.552 13 2 12.552 2 12V9H5V12C5 12.552 5.448 13 6 13C6.552 13 7 12.552 7 12V4C7 3.448 6.552 3 6 3Z"
        fill={color}
      />
      <path
        d="M13.8456 6.43305C12.8156 6.03105 11.7706 6.22905 10.9426 6.69605C11.2076 5.82505 11.7646 5.02505 12.5266 5.00105C13.2116 4.99005 13.6136 5.31605 13.8286 5.60205C14.1606 6.04205 14.7866 6.13305 15.2286 5.79905C15.6696 5.46705 15.7586 4.84005 15.4256 4.39905C15.0966 3.96205 14.1476 2.94905 12.4656 3.00305C10.1816 3.07205 8.76758 5.54705 8.76758 7.79905C8.76758 8.63605 8.86158 9.35505 9.02758 9.96805C9.03958 10.0291 9.05658 10.0881 9.07858 10.1441C9.69358 12.1701 11.1246 12.9711 12.4856 12.9991C12.5046 12.9991 12.5226 12.9991 12.5416 12.9991C14.1116 12.9991 15.9076 11.6151 15.9956 9.72105C16.0606 8.30405 15.1766 6.95205 13.8456 6.43305ZM12.5286 10.9991C11.7116 10.9821 11.2696 10.3881 11.0326 9.68505C11.0776 9.43605 11.1876 9.09605 11.4546 8.80605C11.7456 8.48905 12.4246 8.02405 13.1196 8.29705C13.6546 8.50605 14.0246 9.06705 13.9986 9.63005C13.9616 10.4281 13.0726 11.0111 12.5286 11.0001V10.9991Z"
        fill={color}
      />
    </svg>
  );
}
