import type { JSONContent } from '@tiptap/react';
import { debounce } from 'lodash';
import { useCallback } from 'react';

import { usePatchMediaGroup } from '@api/mediaGroup';
import { catchApiErrorIntoToast } from '@api/util';
import { useNotesStore } from '@stores/useNotesStore';

export const useNotesSave = (asQuickView?: boolean) => {
  const { setIsNotesSaving, setIsQuickViewNotesSaving } = useNotesStore();

  const { mutateAsync: updateMediaGroup } = usePatchMediaGroup();

  const save = catchApiErrorIntoToast(
    async (mediaGroupId: string, content?: JSONContent, plainText?: string) => {
      if (!content) {
        return;
      }
      asQuickView ? setIsQuickViewNotesSaving(true) : setIsNotesSaving(true);
      await updateMediaGroup({
        mediaGroupId,
        patch: {
          document: content,
          plainText,
        },
      });
    }
  );

  const debounceSave = useCallback(
    debounce(async (mediaGroupId, value?: JSONContent, plainText?: string) => {
      try {
        await save(mediaGroupId, value, plainText);
      } catch (e) {}
      asQuickView ? setIsQuickViewNotesSaving(false) : setIsNotesSaving(false);
    }, 2000),
    []
  );

  return {
    debounceSave,
    save,
  };
};
