import { useCallback, useContext } from 'react';
import type { NodeProps } from '@xyflow/react';

import type { MediaGroupDetailDTO } from '@spaceduck/api';

import { useMediaGroupDetail } from '@api/mediaGroup';
import { AudioPlayer } from '@detailsModal/media/AudioPlayer';
import { FlowCard } from '../components/FlowCard';
import { BoardStoreContext } from '../context/boardContext';
import { useCardUtils } from '../hooks/useCardUtils';
import type { AudioNodeType } from '../types/board';

export function AudioNode(
  props: NodeProps<AudioNodeType> & { children?: React.ReactNode }
) {
  const { mediaGroupId } = props.data;
  const {
    data: mediaGroupData,
    isError,
    isLoading,
    refetch,
  } = useMediaGroupDetail(mediaGroupId, {
    retry: false,
  });

  const context = useContext(BoardStoreContext);
  const { openCardInSidebar } = useCardUtils();
  const handleDoubleClick = () => {
    openCardInSidebar({
      cardMediaGroupId: props.data.mediaGroupId,
      boardMediaGroupId: context?.mediaGroupId,
    });
  };

  const mediaGroup = mediaGroupData?.mediaGroup;

  const handleRefetch = useCallback(() => refetch(), []);

  return (
    <FlowCard
      {...props}
      isError={isError}
      isLoading={isLoading}
      mediaGroup={mediaGroupData?.mediaGroup}
      refetch={handleRefetch}
    >
      {!mediaGroup ? null : (
        <AudioMedia onDoubleClick={handleDoubleClick} mediaGroup={mediaGroup} />
      )}
    </FlowCard>
  );
}

const AudioMedia = ({
  mediaGroup,
  onDoubleClick,
}: { mediaGroup: MediaGroupDetailDTO; onDoubleClick?: () => void }) => {
  const [firstItem] = mediaGroup.media;
  if (!firstItem?.mediaType.startsWith('audio/')) return null;

  const { assetUrl } = firstItem;

  return (
    <div onDoubleClick={onDoubleClick}>
      <AudioPlayer src={assetUrl} />
    </div>
  );
};
