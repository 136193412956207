import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1616_3924)">
        <path
          d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.59997 10.5333C8.40999 10.5333 9.06663 9.87669 9.06663 9.06667C9.06663 8.25666 8.40999 7.60001 7.59997 7.60001C6.78995 7.60001 6.1333 8.25666 6.1333 9.06667C6.1333 9.87669 6.78995 10.5333 7.59997 10.5333Z"
          fill={color}
        />
        <path
          d="M16.4 10.5333C17.21 10.5333 17.8667 9.87669 17.8667 9.06667C17.8667 8.25666 17.21 7.60001 16.4 7.60001C15.59 7.60001 14.9333 8.25666 14.9333 9.06667C14.9333 9.87669 15.59 10.5333 16.4 10.5333Z"
          fill={color}
        />
        <path
          d="M16.4 14.64C15.9437 15.3993 15.2987 16.0276 14.5276 16.4638C13.7566 16.9 12.8858 17.1292 12 17.1292C11.1141 17.1292 10.2433 16.9 9.47231 16.4638C8.70128 16.0276 8.05626 15.3993 7.59998 14.64"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1616_3924">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
