import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1596_12081)">
        <path
          d="M8 3.5V7.5"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 9C7.448 9 7 9.448 7 10C7 10.552 7.448 11 8 11C8.552 11 9 10.552 9 10C9 9.448 8.552 9 8 9Z"
          fill={color}
        />
        <path
          d="M15.5 7C15.5 3.41 12.142 0.5 8 0.5C3.858 0.5 0.5 3.41 0.5 7C0.5 10.59 3.858 13.5 8 13.5C8.558 13.5 9.1 13.443 9.624 13.343L12.5 15.5V12.191C14.319 11.005 15.5 9.124 15.5 7Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1596_12081">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
