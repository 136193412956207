import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 64 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 64 64"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="31.9999" cy="18.6667" r="9.66667" stroke="#B5B7CA" strokeWidth="2" />
      <circle cx="15.9999" cy="45.3334" r="9.66667" stroke="#4B4E68" strokeWidth="2" />
      <circle cx="47.9999" cy="45.3334" r="9.66667" stroke="#7A7E9F" strokeWidth="2" />
    </svg>
  );
}
