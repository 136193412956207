import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1632_29190)">
        <path
          d="M14 1H2C1.44772 1 1 1.41787 1 1.93333V12.2C1 12.7155 1.44772 13.1333 2 13.1333H14C14.5523 13.1333 15 12.7155 15 12.2V1.93333C15 1.41787 14.5523 1 14 1Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 8.46667C9.10457 8.46667 10 7.63094 10 6.6C10 5.56907 9.10457 4.73334 8 4.73334C6.89543 4.73334 6 5.56907 6 6.6C6 7.63094 6.89543 8.46667 8 8.46667Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 10.3333V8.46667"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 13.1333V15"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 13.1333V15"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1632_29190">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
