import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7819 7.45473V14.7274C15.7819 16.871 19.2955 17.1792 21.0694 14.282C22.5728 11.8292 22.2041 8.08927 20.3262 5.62838C17.5633 2.00386 11.1752 0.649327 6.53537 3.64385C2.27095 6.39656 0.7524 11.9656 3.10871 16.4665C5.44043 20.921 10.9133 23.0792 15.867 21.4683"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7766 16.0383C13.9887 16.0383 15.7819 14.1685 15.7819 11.862C15.7819 9.55545 13.9887 7.68564 11.7766 7.68564C9.56449 7.68564 7.77124 9.55545 7.77124 11.862C7.77124 14.1685 9.56449 16.0383 11.7766 16.0383Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
