import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9999 18.3636C12.502 18.3636 12.909 17.9566 12.909 17.4545C12.909 16.9525 12.502 16.5455 11.9999 16.5455C11.4978 16.5455 11.0908 16.9525 11.0908 17.4545C11.0908 17.9566 11.4978 18.3636 11.9999 18.3636Z"
        fill={color}
      />
      <path
        d="M10.0481 7.05455C11.8481 6.25909 14.1772 6.35364 14.9726 7.64091C15.7681 8.92818 15.219 10.4255 13.8554 11.5809C12.4917 12.7364 11.9999 13.3636 11.9999 14.2727"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
