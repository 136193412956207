import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 17.5V5C1 3.3 2.3 2 4 2H5"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 17.5V5C23 3.3 21.7 2 20 2H19"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 17C10 15.9 10.9 15 12 15C13.1 15 14 15.9 14 17"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 22C7.98528 22 10 19.9853 10 17.5C10 15.0147 7.98528 13 5.5 13C3.01472 13 1 15.0147 1 17.5C1 19.9853 3.01472 22 5.5 22Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 22C20.9853 22 23 19.9853 23 17.5C23 15.0147 20.9853 13 18.5 13C16.0147 13 14 15.0147 14 17.5C14 19.9853 16.0147 22 18.5 22Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
