import type { TextFieldInstance } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import { useRef, useState } from 'react';

import Button from '@ui/Button';
import type { CommonProps } from '../InfoCategories';
import Empty from './Empty';
import styles from './Phone.module.scss';

const { Add } = Icon16;
const { TrashCan } = Icon24;

export default function PhoneField(commonProps: CommonProps) {
  const { instances, updateField } = commonProps;
  const [showCreateEntry, setShowCreateEntry] = useState(false);
  const phoneInstances = instances as TextFieldInstance[];

  const handleUpdate = async (
    instance: TextFieldInstance | null,
    instanceId?: string
  ) => {
    if (!instanceId && instance) {
      // Add
      await updateField?.([...instances, instance]);
    } else if (instanceId && !instance) {
      // Remove
      await updateField?.(
        instances.filter(
          (existingInstance) => existingInstance?.instanceId !== instanceId
        )
      );
    } else {
      // Edit
      await updateField?.(
        instances.map((existingInstance) =>
          existingInstance?.instanceId === instanceId ? instance : existingInstance
        )
      );
    }
  };

  const addEntry = () => {
    setShowCreateEntry(true);
  };

  return (
    <div className={styles.container}>
      <Button
        className={styles.addButton}
        onClick={addEntry}
        size="xs"
        title="Add a new entry"
        variant="icon"
      >
        <Add size={10} />
      </Button>
      {phoneInstances.length
        ? phoneInstances
            .filter((textInstance) => !!textInstance.instanceId)
            .map((textInstance) => {
              return (
                <PhoneEntry
                  key={textInstance.instanceId}
                  textInstance={textInstance}
                  updateField={handleUpdate}
                />
              );
            })
        : !showCreateEntry && (
            <div className={styles.hoverBg}>
              <Empty onClick={addEntry} />
            </div>
          )}
      {showCreateEntry && (
        <PhoneEntry
          onHide={() => setShowCreateEntry(false)}
          showInputOnRender={true}
          textInstance={{ value: '' }}
          updateField={handleUpdate}
        />
      )}
    </div>
  );
}

const PhoneEntry = ({
  onHide,
  showInputOnRender = false,
  textInstance,
  updateField,
}: {
  onHide?: () => void;
  showInputOnRender?: boolean;
  textInstance: TextFieldInstance;
  updateField?: (
    instance: TextFieldInstance | null,
    instanceId?: string
  ) => Promise<void>;
}) => {
  const _localValue = useRef(textInstance?.value ?? '');
  const [localValue, setLocalValue] = useState(textInstance?.value ?? '');
  const [shouldShowEditView, setShouldShowEditView] = useState(showInputOnRender);

  const updateWithCheck = async () => {
    if (
      textInstance.value !== _localValue.current &&
      !(!textInstance.instanceId && !_localValue.current)
    ) {
      await updateField?.(
        _localValue.current ? { ...textInstance, value: _localValue.current } : null,
        textInstance.instanceId
      );
    }
    onHide?.();
    setShouldShowEditView(false);
  };

  const deleteEntry = async () => {
    if (textInstance.instanceId) {
      await updateField?.(null, textInstance.instanceId);
    }
    onHide?.();
    setShouldShowEditView(false);
  };

  const handleBlur = () => {
    updateWithCheck();
  };

  const handleKeyDown = async (ev: React.KeyboardEvent) => {
    if (ev.key === 'Escape') {
      ev.preventDefault();
      ev.stopPropagation();
      setShouldShowEditView(false);
    } else if (ev.key === 'Enter') {
      updateWithCheck();
    }
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.currentTarget;
    _localValue.current = value;
    setLocalValue(value);
  };

  return (
    <div className={styles.wrapper}>
      {shouldShowEditView ? (
        <div className={styles.entry}>
          <input
            autoComplete="off"
            className={styles.textarea}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Phone number"
            type="text"
            value={localValue}
          />
          <Button
            className={styles.removeButton}
            onClick={deleteEntry}
            size="xs"
            title="Remove"
            variant="icon"
          >
            <TrashCan size={16} />
          </Button>
        </div>
      ) : (
        <div className={styles.hoverBg}>
          <div className={styles.overflowHidden}>
            <div
              className={styles.truncated}
              onClick={() => setShouldShowEditView(true)}
            >
              {localValue}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
