import React, { type ReactNode, useEffect, useRef } from 'react';
import type { Edge, Node } from '@xyflow/react';
import { isEqual } from 'lodash';
import { autoUpdate, autoPlacement, useFloating } from '@floating-ui/react-dom';

import { addClass } from '@utils/string';
import { useCreateBoardStore } from '../stores/boardStore';

export const BoardStoreContext = React.createContext<{
  boardLastUpdated: Date | null;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  floatingUIEdgeMenu: ReturnType<typeof useFloating>;
  mediaGroupId: string;
  store: ReturnType<typeof useCreateBoardStore> | null;
} | null>(null);

type BoardStoreProviderProps = {
  boardLastUpdated: Date | null;
  children: ReactNode;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  mediaGroupId: string;
  initialNodes: Node[];
  initialEdges: Edge[];
};

export const BoardStoreProvider = ({
  boardLastUpdated,
  children,
  containerRef,
  mediaGroupId,
  initialNodes,
  initialEdges,
}: BoardStoreProviderProps) => {
  const floatingUIEdgeMenu = useFloating({
    middleware: [autoPlacement()],
    placement: 'top',
    whileElementsMounted: autoUpdate,
  });

  const store = useRef(
    useCreateBoardStore({
      initialNodes: embellishNodes(normalizeInitialNodes(initialNodes)),
      initialEdges: removeDuplicateEdges(
        removeUnusedEdges({ edges: initialEdges, nodes: initialNodes })
      ),
    })
  );

  const data = {
    boardLastUpdated,
    containerRef,
    floatingUIEdgeMenu,
    mediaGroupId,
    store: store.current,
  };

  const selfCorrectingEdge = useRef<ReturnType<typeof setTimeout> | undefined>(
    undefined
  );

  useEffect(() => {
    // Sync with server data
    const state = store.current?.getState();
    const normalizedStoredNodes = normalizeStoredNodes(
      state?.nodes.filter((node) => node.type !== 'unknownNode')
    );
    const normalizedStoredEdges = normalizeStoredEdges(state?.edges);
    const normalizedInitialNodes = normalizeInitialNodes(initialNodes);
    const embellishedStoredNodes = embellishNodes(normalizedStoredNodes);
    const embellishedInitialNodes = embellishNodes(normalizedInitialNodes);

    if (
      // Short circuit if isEqual is not needed
      normalizedStoredNodes.length !== normalizedInitialNodes.length ||
      !isEqual(embellishedStoredNodes, embellishedInitialNodes)
    ) {
      if (normalizedStoredNodes.length !== normalizedInitialNodes.length) {
        console.log(
          'Nodes length mismatch',
          normalizedInitialNodes,
          normalizedStoredNodes
        );
      } else {
        const mismatchDetails: (Node | undefined)[][] = [];
        for (let i = 0; i < normalizedStoredNodes.length; i++) {
          const serverNode = normalizedInitialNodes[i];
          const clientNode = normalizedStoredNodes[i];

          if (isEqual(serverNode, clientNode)) continue;

          if (
            (clientNode?.type === 'unknownNode' ||
              serverNode?.type === 'unknownNode') &&
            clientNode?.data.mediaGroupId === serverNode?.data.mediaGroupId
          ) {
            continue;
          }

          mismatchDetails.push([serverNode, clientNode]);
        }

        if (!mismatchDetails.length) return;

        console.log('Nodes mismatch: ', mismatchDetails);

        // Assuming non-critical states will resolve as user progresses
        const positionAndWidthDifferenceOnly = mismatchDetails.filter(
          ([server, client]) => {
            if (!server || !client) return false;

            const {
              expanded: _sDataExpanded,
              height: _sDataHeight,
              width: _sDataWidth,
              ...otherServerPropsData
            } = server.data;

            const {
              position: _sPos,
              width: _sWidth,
              height: _sHeight,
              ...otherServerProps
            } = server;

            otherServerProps.data = otherServerPropsData;

            const {
              expanded: _cDataExpanded,
              height: _cDataHeight,
              width: _cDataWidth,
              ...otherClientPropsData
            } = client.data;

            const {
              position: _cPos,
              width: _cWidth,
              height: _cHeight,
              ...otherClientProps
            } = client;

            otherClientProps.data = otherClientPropsData;

            return isEqual(otherServerProps, otherClientProps);
          }
        );

        if (positionAndWidthDifferenceOnly.length === mismatchDetails.length) {
          console.log('Position or dimension difference only');
          return;
        }
      }

      if (selfCorrectingEdge.current) {
        clearTimeout(selfCorrectingEdge.current);
        console.log('Cancelling Reloading for initialEdges...');
      }

      console.log('Reloading for initialNodes...');

      store.current = useCreateBoardStore({
        initialNodes: addSelectedState(normalizedStoredNodes, embellishedInitialNodes),
        initialEdges: addSelectedState(
          normalizedStoredEdges,
          removeDuplicateEdges(
            removeUnusedEdges({ edges: initialEdges, nodes: initialNodes })
          )
        ),
      });

      return;
    }

    const processedInitialEdges = removeDuplicateEdges(
      removeUnusedEdges({ edges: initialEdges, nodes: initialNodes })
    );

    if (
      // Short circuit if isEqual is not needed
      normalizedStoredEdges.length !== processedInitialEdges.length ||
      !isEqual(normalizedStoredEdges, processedInitialEdges)
    ) {
      if (normalizedStoredEdges.length !== processedInitialEdges.length) {
        console.log('Edges length mismatch: ', initialEdges, normalizedStoredEdges);
      } else {
        const mismatchDetails = [];
        for (let i = 0; i < normalizedStoredNodes.length; i++) {
          if (!isEqual(normalizedStoredEdges[i], processedInitialEdges[i])) {
            mismatchDetails.push([processedInitialEdges[i], normalizedStoredEdges[i]]);
          }
        }
        console.log('Edges mismatch: ', mismatchDetails);
      }
      console.log('Reloading for initialEdges...');

      selfCorrectingEdge.current = setTimeout(() => {
        store.current = useCreateBoardStore({
          initialNodes: addSelectedState(
            normalizedStoredNodes,
            embellishedInitialNodes
          ),
          initialEdges: addSelectedState(normalizedStoredEdges, processedInitialEdges),
        });
      }, 750);
    }

    if (selfCorrectingEdge.current) {
      clearTimeout(selfCorrectingEdge.current);
      console.log('Cancelling Reloading for initialEdges...');
    }
  }, [initialNodes, initialEdges]);

  return (
    <BoardStoreContext.Provider value={data}>{children}</BoardStoreContext.Provider>
  );
};

function cloneNode(node: Node) {
  return {
    ...node,
    data: { ...node.data },
  };
}

function addSelectedState<T extends { id: string; selected?: boolean }>(
  oldElements: T[],
  newElements: T[]
) {
  const oldSelectedElementIds = oldElements
    .filter((el) => el.selected)
    .map((el) => el.id);

  return newElements.map((el) => {
    if (oldSelectedElementIds.includes(el.id)) {
      el.selected = true;
    }

    return el;
  });
}

function normalizeStoredNodes(nodes: Node[] | undefined) {
  if (!nodes) return [];

  // Remove non-determinant props
  return nodes
    .filter(
      (node) => node.id !== 'menuPlaceholder' && node.type !== 'tempFileUploadNode'
    )
    .map((node) => {
      const { className, dragging, measured, resizing, selected, style, ...coreProps } =
        node;

      const { isInEditMode, ...corePropsData } = node.data;

      // biome-ignore lint/performance/noDelete: undefined property removed for compare
      delete coreProps.hidden;

      if (!coreProps.parentId || !coreProps.extent) {
        // biome-ignore lint/performance/noDelete: undefined property removed for compare
        delete coreProps.parentId;
        // biome-ignore lint/performance/noDelete: undefined property removed for compare
        delete coreProps.extent;
      }

      if (coreProps.type === 'floatingTextNode') {
        if (typeof corePropsData.height === 'number') {
          coreProps.height = corePropsData.height;
          corePropsData.height = null;
        }
      } else {
        if (!corePropsData.expanded) {
          coreProps.height = undefined;
        }
      }

      if (!corePropsData.width) {
        corePropsData.width = null;
      }

      if (!corePropsData.height) {
        corePropsData.height = null;
      }

      if (coreProps.type === 'groupNode') {
        if (corePropsData.label === null) {
          // biome-ignore lint/performance/noDelete: undefined property removed for compare
          delete corePropsData.label;
        }
      }

      if (coreProps.type === 'fileNode') {
        corePropsData.height = null;
        // biome-ignore lint/performance/noDelete: undefined property removed for compare
        delete corePropsData.expanded;
      }

      return { ...coreProps, data: corePropsData };
    });
}

function normalizeStoredEdges(edges: Edge[] | undefined) {
  if (!edges) return [];

  // Remove non-determinant props
  return edges.map((edge) => {
    const { selected, ...newEdge } = edge;

    if (newEdge.label === undefined || newEdge.label === null) {
      // biome-ignore lint/performance/noDelete: undefined property removed for compare
      delete newEdge.label;
    }

    if (newEdge.markerStart === undefined) {
      // biome-ignore lint/performance/noDelete: undefined property removed for compare
      delete newEdge.markerStart;
    }

    if (newEdge.markerEnd === undefined) {
      // biome-ignore lint/performance/noDelete: undefined property removed for compare
      delete newEdge.markerEnd;
    }

    return newEdge;
  });
}

function normalizeInitialNodes(nodes: Node[]) {
  return nodes.map((node) => {
    if (node.type === 'groupNode') {
      const { parentId, extent, ...newNode } = node;

      newNode.data = {
        ...node.data,
        expanded: node.data.expanded ?? true,
        height: node.data.height ?? null,
        width: node.data.width ?? null,
      };

      return newNode;
    }

    const { hidden, ...newNode } = { ...cloneNode(node) };

    if (node.type === 'floatingTextNode') {
      if (node.data.content) {
        const newContent = { ...node.data.content };
        removeNestedNullProperties(newContent);
        newNode.data.content = newContent;
      }

      if (typeof node.data.height === 'number') {
        newNode.height = node.data.height;
      }

      newNode.data.height = null;
      newNode.data.width = null;

      return newNode;
    }

    if (!newNode.parentId || !newNode.extent) {
      // biome-ignore lint/performance/noDelete: undefined property removed for compare
      delete newNode.parentId;
      // biome-ignore lint/performance/noDelete: undefined property removed for compare
      delete newNode.extent;
    }

    if (node.type === 'fileNode') {
      // biome-ignore lint/performance/noDelete: undefined property removed for compare
      delete newNode.data.height;
    }

    if (!node.data.expanded) {
      newNode.height = undefined;
    }

    if (!node.data.height) {
      newNode.data.height = null;
    }

    if (!node.data.width) {
      newNode.data.width = null;
    }

    return newNode;
  });
}

function removeNestedNullProperties(obj: Record<string, any>) {
  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      removeNestedNullProperties(obj[key]);
    }
  }
}

function embellishNodes(nodes: Node[]) {
  // Add derived properties
  return nodes.map((_node) => {
    const node = cloneNode(_node);

    if (node.type === 'groupNode') {
      if (!node.data.expanded) {
        node.className = addClass('collapsed', node.className);
      }

      if (node.data.color) {
        const color = node.data.color as string;
        node.className = addClass(color, node.className);
      }
    }

    node.hidden = findHiddenState(_node, nodes);

    return node;
  });
}

function findHiddenState(node: Node, allNodes: Node[]) {
  const parentFrame = allNodes.find(
    (frame) => frame.type === 'groupNode' && frame.id === node.parentId
  );

  if (parentFrame) {
    return parentFrame.data.expanded === false;
  }

  return false;
}

function removeUnusedEdges({ edges, nodes }: { edges: Edge[]; nodes: Node[] }) {
  const nodeIds = nodes.map((node) => node.id);

  return edges.filter((edge) => {
    return nodeIds.includes(edge.source) && nodeIds.includes(edge.target);
  });
}

function removeDuplicateEdges(edges: Edge[]): Edge[] {
  return edges.filter((edge, idx) => {
    return edges.findIndex((_edge) => _edge.id === edge.id) === idx;
  });
}
