// TODO(@hill): do we make util a separate package and share?
export const exists = <T>(item: T | null | undefined): item is T =>
  item !== null && item !== undefined;

const API_BASE_URL = '/_';

/**
 *  Safely interpolate values into a URL.
 *  This is useful for interpolating IDs and other values into URLs.
 *  @example safeUrl`/api/${id}/` === '/api/123/'
 */
export const safeUrl = (values: TemplateStringsArray, ...params: string[]) =>
  [
    ...values.map(
      (value, index) =>
        `${index > 0 ? encodeURIComponent(params[index - 1]!) : ''}${value}`
    ),
  ].join('');

const throwIfAnyNullish = (params: (string | null | undefined)[]): string[] => {
  if (params.some((param) => param === null || param === undefined)) {
    throw new Error('Null parameter found');
  }
  return params as string[];
};

export const apiUrl = (
  values: TemplateStringsArray,
  ...params: (string | null | undefined)[]
) => `${API_BASE_URL}${safeUrl(values, ...throwIfAnyNullish(params))}`;
