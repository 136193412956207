import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.6418 5.17574C2.88205 4.94142 3.27176 4.94142 3.51201 5.17574L5.79336 7.40002H15.3846C15.7245 7.40002 16 7.66865 16 8.00001C16 8.3314 15.7245 8.60001 15.3846 8.60001H5.79336L3.51201 10.8242C3.27176 11.0586 2.88205 11.0586 2.6418 10.8242L0.180259 8.42424C-0.0600853 8.19 -0.0600853 7.81007 0.180259 7.57575L2.6418 5.17574Z"
        fill={color}
      />
    </svg>
  );
}
