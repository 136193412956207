import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 1C6.44772 1 6 1.44772 6 2V2.07873C6 2.58753 6.41247 3 6.92127 3C7.55311 3 7.99743 3.62155 7.79297 4.21939L5.02133 12.3236C4.88297 12.7282 4.5027 13 4.07513 13H3C2.44771 13 2 13.4477 2 14C2 14.5523 2.44772 15 3 15H9C9.55228 15 10 14.5523 10 14V13.9213C10 13.4125 9.58753 13 9.07873 13C8.44689 13 8.00257 12.3785 8.20703 11.7806L10.9787 3.6764C11.117 3.27184 11.4973 3 11.9249 3H13C13.5523 3 14 2.55228 14 2C14 1.44772 13.5523 1 13 1H7Z"
        fill={color}
      />
    </svg>
  );
}
