import { useCallback, useContext } from 'react';
import { type Edge, type Node, useReactFlow } from '@xyflow/react';
import { now } from 'lodash';

import { exists } from '@spaceduck/utils';

import { BoardStoreContext } from '../context/boardContext';
import { useBoardStore } from './useBoardStore';
import type {
  ArticleNodeType,
  AudioNodeType,
  DocumentNodeType,
  FileNodeType,
  FloatingTextNodeType,
  HighlightNodeType,
  ImageNodeType,
  PdfNodeType,
  TempFileUploadType,
  UnknownNodeType,
  VideoNodeType,
} from '../types/board';
import { usePersist } from './usePersist';
import { useHistory } from './useHistory';

export const COPY_INDICATOR = '--copy--';
export const DEFAULT_CARD_WIDTH = 400;
export const DEFAULT_CARD_HEIGHT = 400;
export const DEFAULT_AUDIO_CARD_HEIGHT = 168;
export const DEFAULT_FLOATING_NOTE_HEIGHT = 200;
export const DEFAULT_FLOATING_NOTE_WIDTH = 400;
export const DEFAULT_X_OFFSET = 50;
export const DEFAULT_Y_OFFSET = 50;
export const EDGE_DELIMITER = '->';
export const EDGE_PLACEMENT_DELIMITER = '--';
export const ID_DELIMITER = '__';

export const useNodes = () => {
  const {
    addEdges,
    addNodes,
    deleteElements,
    getEdges,
    setEdges,
    getNodes,
    setNodes,
    screenToFlowPosition,
    getIntersectingNodes,
  } = useReactFlow();
  const boardContext = useContext(BoardStoreContext);
  const { createEntry, updateEntry } = usePersist({
    mediaGroupId: boardContext?.mediaGroupId,
  });

  const clipboardItems = useBoardStore(({ clipboardItems }) => clipboardItems);
  const getEdgeById = useBoardStore(({ getEdgeById }) => getEdgeById);
  const getNodeById = useBoardStore(({ getNodeById }) => getNodeById);
  const onNodesChange = useBoardStore(({ onNodesChange }) => onNodesChange);
  const setClipboardItems = useBoardStore(({ setClipboardItems }) => setClipboardItems);
  const selectedEdges = useBoardStore(({ selectedEdges }) => selectedEdges);
  const selectedNodes = useBoardStore(({ selectedNodes }) => selectedNodes);
  const setSelectedEdges = useBoardStore(({ setSelectedEdges }) => setSelectedEdges);
  const setSelectedNodes = useBoardStore(({ setSelectedNodes }) => setSelectedNodes);

  const { clear, pause, resume } = useHistory();

  const addArticleNode = (
    mediaGroupId: string,
    x: number,
    y: number,
    options?: {
      appendNode?: boolean;
      parentId?: string;
    }
  ) => {
    const node: ArticleNodeType = {
      id: `${mediaGroupId}${ID_DELIMITER}${now()}`,
      data: {
        mediaGroupId,
        color: 'grey',
        expanded: true,
      },
      position: screenToFlowPosition({
        x,
        y,
      }),
      height: DEFAULT_CARD_HEIGHT,
      width: DEFAULT_CARD_WIDTH,
      type: 'articleNode',
      parentId: options?.parentId,
      extent: options?.parentId ? 'parent' : undefined,
    };

    if (options?.appendNode ?? true) {
      addNodes([node]);
    }

    return node;
  };

  const addAudioNode = (
    mediaGroupId: string,
    x: number,
    y: number,
    options?: {
      appendNode?: boolean;
      parentId?: string;
    }
  ) => {
    const node: AudioNodeType = {
      id: `${mediaGroupId}${ID_DELIMITER}${now()}`,
      data: {
        mediaGroupId,
        color: 'grey',
        expanded: true,
      },
      position: screenToFlowPosition({
        x,
        y,
      }),
      height: DEFAULT_AUDIO_CARD_HEIGHT,
      width: DEFAULT_CARD_WIDTH,
      type: 'audioNode',
      parentId: options?.parentId,
      extent: options?.parentId ? 'parent' : undefined,
    };

    if (options?.appendNode ?? true) {
      addNodes([node]);
    }

    return node;
  };

  const addDocumentNode = async (
    mediaGroupId: string,
    x: number,
    y: number,
    options?: { parentId?: string }
  ) => {
    const node: DocumentNodeType = {
      id: `${mediaGroupId}${ID_DELIMITER}${now()}`,
      data: {
        mediaGroupId,
        color: 'grey',
        expanded: true,
      },
      position: screenToFlowPosition({
        x,
        y,
      }),
      height: DEFAULT_CARD_HEIGHT,
      width: DEFAULT_CARD_WIDTH,
      type: 'documentNode',
      parentId: options?.parentId,
      extent: options?.parentId ? 'parent' : undefined,
    };
    addNodes([node]);

    return node;
  };

  const createDocumentNode = async (
    x: number,
    y: number,
    options?: { parentId?: string }
  ) => {
    const res = await createEntry({
      kind: 'document',
      showToast: false,
    });

    if (!res?.mediaGroupId) return;

    const mediaGroupId = res.mediaGroupId;
    const node: DocumentNodeType = {
      id: `${mediaGroupId}${ID_DELIMITER}${now()}`,
      data: {
        mediaGroupId,
        color: 'grey',
        expanded: true,
      },
      position: screenToFlowPosition({
        x,
        y,
      }),
      height: DEFAULT_CARD_HEIGHT,
      width: DEFAULT_CARD_WIDTH,
      type: 'documentNode',
      parentId: options?.parentId,
      extent: options?.parentId ? 'parent' : undefined,
    };
    addNodes([node]);

    return node;
  };

  const addFileNode = (
    mediaGroupId: string,
    x: number,
    y: number,
    options?: { appendNode?: boolean; parentId?: string }
  ) => {
    const node: FileNodeType = {
      id: `${mediaGroupId}${ID_DELIMITER}${now()}`,
      data: {
        mediaGroupId,
      },
      position: screenToFlowPosition({
        x,
        y,
      }),
      height: undefined,
      width: 146,
      type: 'fileNode',
      parentId: options?.parentId,
      extent: options?.parentId ? 'parent' : undefined,
    };

    if (options?.appendNode ?? true) {
      addNodes([node]);
    }

    return node;
  };

  const addFloatingTextNode = (
    x: number,
    y: number,
    options?: { parentId?: string }
  ) => {
    const node: FloatingTextNodeType = {
      id: `floatingTextNode${ID_DELIMITER}${now()}`,
      data: {
        isInEditMode: true,
      },
      position: screenToFlowPosition({
        x,
        y,
      }),
      height: DEFAULT_FLOATING_NOTE_HEIGHT,
      width: DEFAULT_FLOATING_NOTE_WIDTH,
      type: 'floatingTextNode',
      parentId: options?.parentId,
      extent: options?.parentId ? 'parent' : undefined,
    };

    addNodes([node]);

    return node;
  };

  const addHighlightNode = (
    mediaGroupId: string,
    x: number,
    y: number,
    options?: { appendNode?: boolean; parentId?: string }
  ) => {
    const node: HighlightNodeType = {
      id: `${mediaGroupId}${ID_DELIMITER}${now()}`,
      data: {
        mediaGroupId,
        color: 'grey',
        expanded: true,
      },
      position: screenToFlowPosition({
        x,
        y,
      }),
      height: DEFAULT_CARD_HEIGHT,
      width: DEFAULT_CARD_WIDTH,
      type: 'highlightNode',
      parentId: options?.parentId,
      extent: options?.parentId ? 'parent' : undefined,
    };

    if (options?.appendNode ?? true) {
      addNodes([node]);
    }

    return node;
  };

  const addImageNode = (
    mediaGroupId: string,
    x: number,
    y: number,
    options?: { appendNode?: boolean; parentId?: string }
  ) => {
    const node: ImageNodeType = {
      id: `${mediaGroupId}${ID_DELIMITER}${now()}`,
      data: {
        mediaGroupId,
        color: 'grey',
        expanded: true,
      },
      position: screenToFlowPosition({
        x,
        y,
      }),
      height: DEFAULT_CARD_HEIGHT,
      width: DEFAULT_CARD_WIDTH,
      type: 'imageNode',
      parentId: options?.parentId,
      extent: options?.parentId ? 'parent' : undefined,
    };

    if (options?.appendNode ?? true) {
      addNodes([node]);
    }

    return node;
  };

  const addMediaNode = (
    mediaGroupId: string,
    x: number,
    y: number,
    options?: { appendNode?: boolean; parentId?: string }
  ) => {
    const node: UnknownNodeType = {
      id: `${mediaGroupId}${ID_DELIMITER}${now()}`,
      data: {
        mediaGroupId,
        color: 'grey',
        expanded: true,
      },
      position: screenToFlowPosition({
        x,
        y,
      }),
      height: DEFAULT_CARD_HEIGHT,
      width: DEFAULT_CARD_WIDTH,
      type: 'unknownNode',
      parentId: options?.parentId,
      extent: options?.parentId ? 'parent' : undefined,
    };

    if (options?.appendNode ?? true) {
      addNodes([node]);
    }

    return node;
  };

  const addPdfNode = (
    mediaGroupId: string,
    x: number,
    y: number,
    options?: { appendNode?: boolean; parentId?: string }
  ) => {
    const node: PdfNodeType = {
      id: `${mediaGroupId}${ID_DELIMITER}${now()}`,
      data: {
        mediaGroupId,
        color: 'grey',
        expanded: true,
      },
      position: screenToFlowPosition({
        x,
        y,
      }),
      height: DEFAULT_CARD_HEIGHT,
      width: DEFAULT_CARD_WIDTH,
      type: 'pdfNode',
      parentId: options?.parentId,
      extent: options?.parentId ? 'parent' : undefined,
    };

    if (options?.appendNode ?? true) {
      addNodes([node]);
    }

    return node;
  };

  const addSelectedNode = (id: string) => {
    if (!selectedNodes.includes(id)) {
      setSelectedNodes([...selectedNodes, id]);
    }
  };

  const addTempFileUploadNode = (
    id: string,
    x: number,
    y: number,
    options?: { parentId?: string }
  ) => {
    const node: TempFileUploadType = {
      id: `${id}${ID_DELIMITER}${now()}`,
      data: {
        id,
      },
      position: screenToFlowPosition({
        x,
        y,
      }),
      height: DEFAULT_CARD_HEIGHT,
      width: DEFAULT_CARD_WIDTH,
      type: 'tempFileUploadNode',
      parentId: options?.parentId,
      extent: options?.parentId ? 'parent' : undefined,
    };

    addNodes([node]);

    return node;
  };

  const addVideoNode = (
    mediaGroupId: string,
    x: number,
    y: number,
    options?: { appendNode?: boolean; parentId?: string }
  ) => {
    const node: VideoNodeType = {
      id: `${mediaGroupId}${ID_DELIMITER}${now()}`,
      data: {
        mediaGroupId,
        color: 'grey',
        expanded: true,
      },
      position: screenToFlowPosition({
        x,
        y,
      }),
      height: DEFAULT_CARD_HEIGHT,
      width: DEFAULT_CARD_WIDTH,
      type: 'videoNode',
      parentId: options?.parentId,
      extent: options?.parentId ? 'parent' : undefined,
    };

    if (options?.appendNode ?? true) {
      addNodes([node]);
    }

    return node;
  };

  const copyNode = (nodeId: string) => {
    const node = getNodeById(nodeId);

    if (!node) return;
    if (node.type === 'unknownNode' || node.type === 'tempFileUploadNode') return;

    setClipboardItems({
      nodes: [{ ...node, id: `${node.id}${COPY_INDICATOR}` }],
      edges: [],
    });
  };

  const copyNodes = () => {
    if (!selectedNodes?.length) return;

    setClipboardItems({
      nodes: selectedNodes
        .map((nodeId) => {
          const node = getNodeById(nodeId);
          if (!node) return undefined;
          if (node.type === 'unknownNode' || node.type === 'tempFileUploadNode')
            return undefined;

          return {
            ...node,
            id: `${node.id}${COPY_INDICATOR}`,
          };
        })
        .filter(exists),
      edges: selectedEdges
        .map((edgeId) => {
          const edge = getEdgeById(edgeId);
          if (!edge) return undefined;

          return edge;
        })
        .filter(exists),
    });
  };

  const getRemainingNodesAndEdges = useCallback(
    ({
      deletedEdges,
      deletedNodes,
    }: { deletedEdges: Edge[]; deletedNodes: Node[] }) => {
      const edges = getEdges();
      const nodes = getNodes();

      const deletedEdgesIds = deletedEdges.map((edge) => edge.id);
      const deletedNodesIds = deletedNodes.map((node) => node.id);

      const remainingEdges = edges.filter((edge) => !deletedEdgesIds.includes(edge.id));
      const remainingNodes = nodes.filter((node) => !deletedNodesIds.includes(node.id));

      return {
        edges: remainingEdges,
        nodes: remainingNodes,
      };
    },
    [getEdges, getNodes]
  );

  const cutNode = useCallback(
    async (id: string) => {
      const node = getNodeById(id);
      if (!node) return;

      setClipboardItems({ nodes: [node], edges: [] });

      const { deletedEdges, deletedNodes } = await deleteElements({
        nodes: [node],
      });

      const { nodes, edges } = getRemainingNodesAndEdges({
        deletedEdges,
        deletedNodes,
      });

      updateEntry({
        patch: {
          board: {
            nodes,
            edges,
          },
        },
        showToast: false,
      });
    },
    [
      getNodeById,
      setClipboardItems,
      updateEntry,
      deleteElements,
      getRemainingNodesAndEdges,
    ]
  );

  const cutNodes = useCallback(async () => {
    if (!selectedNodes?.length) return;

    const nodesToRemove = selectedNodes
      .map((nodeId) => {
        const node = getNodeById(nodeId);
        if (!node) return undefined;

        return node;
      })
      .filter(exists);

    const edgesToRemove = selectedEdges
      .map((edgeId) => {
        const edge = getEdgeById(edgeId);
        if (!edge) return undefined;

        return edge;
      })
      .filter(exists);

    setClipboardItems({ nodes: nodesToRemove, edges: edgesToRemove });

    const { deletedEdges, deletedNodes } = await deleteElements({
      nodes: nodesToRemove,
      edges: edgesToRemove,
    });

    const { nodes, edges } = getRemainingNodesAndEdges({
      deletedEdges,
      deletedNodes,
    });

    updateEntry({
      patch: {
        board: {
          nodes,
          edges,
        },
      },
      showToast: false,
    });
  }, [
    getNodeById,
    setClipboardItems,
    updateEntry,
    deleteElements,
    getRemainingNodesAndEdges,
    selectedNodes,
    selectedEdges,
  ]);

  const deleteNode = useCallback(
    async (id: string) => {
      const node = getNodeById(id);

      if (!node) return;

      const { deletedEdges, deletedNodes } = await deleteElements({
        nodes: [node],
      });

      const { nodes, edges } = getRemainingNodesAndEdges({
        deletedEdges,
        deletedNodes,
      });

      updateEntry({
        patch: {
          board: {
            nodes,
            edges,
          },
        },
        showToast: false,
      });
    },
    [deleteElements, getNodeById, getRemainingNodesAndEdges, updateEntry]
  );

  const deleteNodes = async ({ nodes, edges }: { nodes: Node[]; edges?: Edge[] }) => {
    const { deletedEdges, deletedNodes } = await deleteElements({
      nodes,
      edges,
    });

    const { nodes: remainingNodes, edges: remainingEdges } = getRemainingNodesAndEdges({
      deletedEdges,
      deletedNodes,
    });

    updateEntry({
      patch: {
        board: {
          nodes: remainingNodes,
          edges: remainingEdges,
        },
      },
      showToast: false,
    });

    pause();
    setNodes(remainingNodes);
    setEdges(remainingEdges);
    // Cannot recover deleted media group
    clear();
    resume();
  };

  const duplicateNode = async (id: string) => {
    const node = getNodeById(id);

    if (!node) {
      console.error('Node copy failed - could not find node');
      return;
    }

    const clone = modernizeNodeId({
      ...node,
      position: {
        x: node.position.x + DEFAULT_X_OFFSET,
        y: node.position.y + DEFAULT_Y_OFFSET,
      },
      data: { ...node.data },
    });

    if (clone.type === 'floatingTextNode') {
      clone.data.isInEditMode = false;
    }

    addNodes([clone]);

    // Work around for selecting cloned node
    Promise.resolve().then(() => {
      setNodes((nodes) =>
        nodes.map((node) => {
          return { ...node, selected: node.id === clone.id };
        })
      );
      setSelectedEdges([]);
      setSelectedNodes([clone.id]);
    });
  };

  const getNewParentId = (
    parentId: string | undefined | null,
    originalNodes: Node[],
    timeStamp: string
  ) => {
    if (!parentId) return undefined;

    const idx = originalNodes.findIndex((node) => node.id === parentId);
    if (idx < 0) return undefined;

    return `${parentId.split(ID_DELIMITER)[0]}${ID_DELIMITER}${timeStamp}+${idx}`;
  };

  const pasteNodes = async (coordinates?: { x: number; y: number }) => {
    if (!clipboardItems?.nodes.length) return;

    const firstNode = clipboardItems.nodes[0];
    if (!firstNode) return;

    const possibleParent = selectedNodes[0] ? getNodeById(selectedNodes[0]) : null;
    const targetParentId =
      possibleParent?.type === 'groupNode' ? possibleParent.id : undefined;

    const timeStamp = String(now());
    const pattern = new RegExp(COPY_INDICATOR);
    const isCopy = pattern.test(firstNode.id);
    const originalNodes = clipboardItems.nodes;
    const originalEdges = clipboardItems.edges;

    if (isCopy) {
      const newNodes = originalNodes.map((node, idx) => {
        const idArr = node.id.split(ID_DELIMITER);
        const newParentId = getNewParentId(
          `${node.parentId}${COPY_INDICATOR}`,
          originalNodes,
          timeStamp
        );

        const parentId =
          node.type === 'groupNode' ? undefined : (newParentId ?? targetParentId);

        return {
          ...node,
          id: `${idArr[0]}${ID_DELIMITER}${timeStamp}+${idx}`,
          parentId,
          extent: parentId ? 'parent' : node.extent,
          data:
            node.type === 'floatingTextNode'
              ? { ...node.data, isInEditMode: false }
              : { ...node.data },
        };
      });

      setNodes((nodes) => nodes.map((node) => ({ ...node, selected: false })));
      setEdges((edges) => edges.map((edge) => ({ ...edge, selected: false })));
      pause();
      addNodes(
        transformNodes(
          newNodes,
          coordinates ? screenToFlowPosition(coordinates) : undefined
        )
      );
      if (originalEdges?.length) {
        const newEdges = originalEdges
          .map((edge) => updateEdgeForCopy(edge, originalNodes, newNodes, timeStamp))
          .filter(exists);

        // Delay creation of edges and resuming state
        Promise.resolve()
          .then(() => {
            addEdges(
              checkEdges(
                newEdges,
                [...getNodes(), ...newNodes].map((node) => node.id)
              )
            );
          })
          .then(() => resume());
      } else {
        resume();
      }
    } else {
      pause();
      addNodes(
        transformNodes(
          clipboardItems.nodes.map((node) => {
            const parentId =
              node.type === 'groupNode'
                ? node.parentId
                : (node.parentId ?? targetParentId);
            const extent = parentId ? 'parent' : node.extent;
            return { ...node, parentId, extent };
          }),
          coordinates ? screenToFlowPosition(coordinates) : undefined
        )
      );

      // Delay creation of edges and resuming state
      Promise.resolve()
        .then(() => {
          addEdges(
            checkEdges(
              clipboardItems.edges,
              [...getNodes(), ...clipboardItems.nodes].map((node) => node.id)
            )
          );
        })
        .then(() => resume());
    }

    if (!isCopy) {
      setClipboardItems(undefined);
    }
  };

  const updateNode = (id: string, patch: Partial<Node>) => {
    const node = getNodeById(id);
    if (!node) return;

    const updatedNodes = getNodes().map((node) => {
      if (node.id !== id) return node;
      if (node.type === 'groupNode') return node;

      return {
        ...node,
        ...patch,
        data: { ...node.data, ...patch.data },
        height:
          (patch.height ||
            (patch.data?.height as number) ||
            node.height ||
            (node.data.height as number)) ??
          undefined,
      };
    });

    const transformedNodes = updatedNodes.map((node) => {
      if (node.data.expanded) return node;
      if (node.type === 'groupNode' || node.type === 'floatingTextNode') return node;

      return {
        ...node,
        height: undefined,
        data: { ...node.data, height: (node.height || node.measured?.height) ?? null },
      };
    });

    setNodes(transformedNodes);
  };

  const getNodesAtScreenPosition = useCallback(
    (x: number, y: number, options?: { size?: number; partially?: boolean }) => {
      const position = screenToFlowPosition({ x, y });
      const size = options?.size ?? 1;
      const offset = Math.floor(size / 2);

      const rect = {
        x: size > 1 ? position.x - offset : position.x,
        y: size > 1 ? position.y - offset : position.y,
        width: size,
        height: size,
      };

      return getIntersectingNodes(rect, options?.partially);
    },
    [screenToFlowPosition, getIntersectingNodes]
  );

  return {
    addArticleNode,
    addAudioNode,
    addDocumentNode,
    addTempFileUploadNode,
    addFileNode,
    addFloatingTextNode,
    addHighlightNode,
    addImageNode,
    addMediaNode,
    addPdfNode,
    addVideoNode,
    clipboardItems,
    copyNode,
    copyNodes,
    createDocumentNode,
    cutNode,
    cutNodes,
    deleteNode,
    deleteNodes,
    duplicateNode,
    getNodeById,
    onNodesChange,
    pasteNodes,
    selectedNodes,
    setSelectedNodes,
    addSelectedNode,
    updateNode,
    getNodesAtScreenPosition,
  };
};

function updateEdgeForCopy(
  edge: Edge,
  originalNodes: Node[],
  newNodes: Node[],
  suffix: string
): Edge | null {
  const id = edge.id;
  const idArr = id.split(EDGE_DELIMITER);

  if (idArr.length !== 2) {
    console.log(`Could not split node id: ${id}`);
    return null;
  }

  const [source, target] = idArr;
  if (!source || !target) {
    console.log(`Could not get source or target from ${id}`);
    return null;
  }

  const sourceDetails = source.split(EDGE_PLACEMENT_DELIMITER);
  const targetDetails = target.split(EDGE_PLACEMENT_DELIMITER);

  if (sourceDetails.length !== 2 || targetDetails.length !== 2) {
    console.log(`Could not get source details or target details from ${id}`);
    return null;
  }

  const [sourceId, sourceHandlePos] = sourceDetails;
  const [targetId, targetHandlePos] = targetDetails;

  if (!sourceId || !sourceHandlePos || !targetId || !targetHandlePos) {
    console.log(`Could not parse ID and handle from ${id}`);
    return null;
  }

  const originalSourceNodeIdx = originalNodes.findIndex(
    (node) => node.id.replace(COPY_INDICATOR, '') === sourceId
  );
  const newSourceNode =
    originalSourceNodeIdx > -1 ? newNodes[originalSourceNodeIdx] : null;
  const newSourceNodeIdx = newSourceNode?.id.split('+')[1];
  const newSourceNodeIdxStr = newSourceNodeIdx ? `+${newSourceNodeIdx}` : '';

  const originalTargetNodeIdx = originalNodes.findIndex(
    (node) => node.id.replace(COPY_INDICATOR, '') === targetId
  );
  const newTargetNode =
    originalTargetNodeIdx > -1 ? newNodes[originalTargetNodeIdx] : null;
  const newTargetNodeIdx = newTargetNode?.id.split('+')[1];
  const newTargetNodeIdxStr = newTargetNodeIdx ? `+${newTargetNodeIdx}` : '';

  const sourceIdDetails = sourceId.split(ID_DELIMITER);
  const targetIdDetails = targetId.split(ID_DELIMITER);

  if (sourceIdDetails.length !== 2 || targetIdDetails.length !== 2) {
    console.log(`Could split mediagroupId or type with timestamp from ${id}`);
    return null;
  }

  const [sourceMediaGroupId] = sourceIdDetails;
  const [targetMediaGroupId] = targetIdDetails;

  if (!sourceMediaGroupId || !targetMediaGroupId) {
    console.log(`MediagroupId or type does not exist in ${id}`);
    return null;
  }

  const newEdge: Edge = {
    ...edge,
    id: `${sourceMediaGroupId}${ID_DELIMITER}${suffix}${newSourceNodeIdxStr}${EDGE_PLACEMENT_DELIMITER}${sourceHandlePos}${EDGE_DELIMITER}${targetMediaGroupId}${ID_DELIMITER}${suffix}${newTargetNodeIdxStr}${EDGE_PLACEMENT_DELIMITER}${targetHandlePos}`,
    source: `${sourceMediaGroupId}${ID_DELIMITER}${suffix}${newSourceNodeIdxStr}`,
    sourceHandle: sourceHandlePos
      ? `${sourceMediaGroupId}${ID_DELIMITER}${suffix}${newSourceNodeIdxStr}${EDGE_PLACEMENT_DELIMITER}${sourceHandlePos}`
      : undefined,
    target: `${targetMediaGroupId}${ID_DELIMITER}${suffix}${newTargetNodeIdxStr}`,
    targetHandle: targetHandlePos
      ? `${targetMediaGroupId}${ID_DELIMITER}${suffix}${newTargetNodeIdxStr}${EDGE_PLACEMENT_DELIMITER}${targetHandlePos}`
      : undefined,
  };

  if (edge.markerStart && typeof edge.markerStart === 'object') {
    newEdge.markerStart = { ...edge.markerStart };
  }

  if (edge.markerEnd && typeof edge.markerEnd === 'object') {
    newEdge.markerEnd = { ...edge.markerEnd };
  }

  return newEdge;
}

function checkEdges(edges: Edge[], allNodeIds: string[]) {
  return edges.filter((edge) => {
    const handles = edge.id.split(EDGE_DELIMITER);
    if (handles.length !== 2) return false;

    const [source, target] = handles;
    if (!source || !target) return false;

    const sourceNodeId = source.split(EDGE_PLACEMENT_DELIMITER)[0];
    const targetNodeId = target.split(EDGE_PLACEMENT_DELIMITER)[0];

    if (!sourceNodeId || !targetNodeId) return false;

    return allNodeIds.includes(sourceNodeId) && allNodeIds.includes(targetNodeId);
  });
}

function shiftNodes(
  nodes: Node[],
  xOffset = DEFAULT_X_OFFSET,
  yOffset = DEFAULT_Y_OFFSET
): Node[] {
  return nodes.map((node) => {
    node.position = { x: node.position.x + xOffset, y: node.position.y + yOffset };
    return node;
  });
}

function transformNodes(nodes: Node[], coordinates?: { x: number; y: number }): Node[] {
  if (!coordinates) return shiftNodes(nodes);

  const { mostTop, xOfMostTop, mostLeft } = nodes.reduce<{
    mostTop: number | null;
    xOfMostTop: number | null;
    mostLeft: number | null;
  }>(
    (acc, curr) => {
      const marker = Object.assign({}, acc);
      if (acc.mostTop === null || acc.xOfMostTop === null || acc.mostLeft === null) {
        return {
          mostTop: curr.position.y,
          xOfMostTop: curr.position.x,
          mostLeft: curr.position.x,
        };
      }

      if (curr.position.y < acc.mostTop) {
        marker.mostTop = curr.position.y;
        marker.xOfMostTop = curr.position.x;
      }

      if (curr.position.x < acc.mostLeft) {
        marker.mostLeft = curr.position.x;
      }

      return marker;
    },
    { mostTop: null, xOfMostTop: null, mostLeft: null }
  );

  if (!mostTop || !xOfMostTop || !mostLeft) return nodes;

  const diffTop = mostTop - coordinates.y;
  const diffLeft = mostLeft - coordinates.x + (mostLeft - xOfMostTop);

  const transformedNodes = nodes.map((node) => {
    node.position = { x: node.position.x - diffLeft, y: node.position.y - diffTop };
    return node;
  });

  return transformedNodes;
}

function modernizeNodeId(node: Node): Node {
  node.id = `${node.id.split(ID_DELIMITER)[0]}${ID_DELIMITER}${now()}`;
  return node;
}
