import { useCallback, useEffect } from 'react';
import Spinner from '../ui/Spinner';
import sourceStyles from './InlineSource.module.scss';
import { useSourcesStore, type SelectedSourceType } from './useSourcesStore';
import useWorkspaceId from '@/hooks/useWorkspaceId';
import { type ProcessingResult, useProcessAssets } from '@/hooks/useProcessAssets';
import { useCreateTemporaryFile } from '@/api/ai';
import { catchApiErrorIntoToast } from '@/api/util';
import Tag from '../ui/Tag';

export const FileUpload = ({
  onTempFileUploaded,
  onDelete,
  fileKey,
  fileName,
}: {
  onTempFileUploaded: (source: SelectedSourceType) => void;
  onDelete: () => void;
  fileKey: string;
  fileName: string;
}) => {
  const { files, takeFile } = useSourcesStore();
  const workspaceId = useWorkspaceId();
  const { mutateAsync: uploadFile } = useCreateTemporaryFile();

  const onSuccess = useCallback(
    async (item: ProcessingResult) => {
      if (!item.request.result?.id) {
        return;
      }
      try {
        const tempFile = await catchApiErrorIntoToast(uploadFile)({
          assetId: item.request.result.id,
        });

        onTempFileUploaded({
          label: tempFile.file.filename,
          type: 'file',
          id: tempFile.file.id,
        });
      } catch (e) {
        onDelete();
      }
    },
    [onTempFileUploaded, onDelete]
  );

  const { insert } = useProcessAssets({ onSuccess, pollInterval: 1000 });

  useEffect(() => {
    const file = takeFile(fileKey);
    if (file && workspaceId) {
      insert({
        files: [file],
        workspaceId,
      });
    }
  }, [files]);

  return (
    <Tag
      className={sourceStyles.selectedSource}
      onRemoveClick={onDelete}
      removeIconIsHidden
      size="lg"
      variant="quaternary"
    >
      {' '}
      <Spinner size={20} />
      <span className={sourceStyles.label}>{fileName}</span>
    </Tag>
  );
};
