const SPACEDUCK_FIGMA_PLUGIN_ID = '1303852111524529492';
const FIGMA_TARGET_ORIGIN = 'https://www.figma.com';

export function requestToken() {
  parent.postMessage(
    {
      pluginMessage: {
        type: 'request-token',
      },
      pluginId: SPACEDUCK_FIGMA_PLUGIN_ID,
    },
    FIGMA_TARGET_ORIGIN
  );
}

export function saveToken(token: string) {
  parent.postMessage(
    {
      pluginMessage: {
        type: 'authenticate',
        token,
      },
      pluginId: SPACEDUCK_FIGMA_PLUGIN_ID,
    },
    FIGMA_TARGET_ORIGIN
  );
}

export function clearToken() {
  parent.postMessage(
    {
      pluginMessage: {
        type: 'clear-token',
      },
      pluginId: SPACEDUCK_FIGMA_PLUGIN_ID,
    },
    FIGMA_TARGET_ORIGIN
  );
}
