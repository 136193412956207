import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { urlFor } from '@/urls';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Spinner from '@ui/Spinner';

export default function ChatSessionsRedirectPage() {
  const workspaceId = useWorkspaceId();
  const navigate = useNavigate();

  useEffect(() => {
    if (workspaceId === null) {
      navigate(urlFor('home'), { replace: true });
      return;
    }
    if (workspaceId) {
      navigate(urlFor('workspaceResearchAssistant', { workspaceId }), {
        replace: true,
      });
      return;
    }
  }, [workspaceId]);

  return <Spinner />;
}
