import { useContext } from 'react';
import { useStore } from 'zustand';

import { BoardStoreContext } from '../context/boardContext';
import type { BoardState } from '../stores/boardStore';

export const useBoardStore = <T>(selector: (state: BoardState) => T) => {
  const data = useContext(BoardStoreContext);

  if (!data?.store || !data.mediaGroupId) {
    throw new Error('Missing BoardStoreProvider');
  }

  return useStore(data.store, selector);
};
