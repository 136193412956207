import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 0.5V4.5C9.5 5.05228 9.94772 5.5 10.5 5.5H14.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 12.5V5.91421C14.5 5.649 14.3946 5.39464 14.2071 5.20711L9.79289 0.792893C9.60536 0.605357 9.351 0.5 9.08579 0.5H2.5C1.94772 0.5 1.5 0.947715 1.5 1.5V14.5C1.5 15.0523 1.94772 15.5 2.5 15.5H9.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 13.5C10.1569 13.5 11.5 12.1569 11.5 10.5C11.5 8.84315 10.1569 7.5 8.5 7.5C6.84315 7.5 5.5 8.84315 5.5 10.5C5.5 12.1569 6.84315 13.5 8.5 13.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 15.5L10.62 12.62"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
