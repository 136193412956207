import { Handle as FlowHandle, type HandleProps } from '@xyflow/react';
import clsx from 'clsx';

import styles from './Handle.module.scss';

export default function Handle(props: HandleProps) {
  const { className, ...handleProps } = props;

  return <FlowHandle {...handleProps} className={clsx(styles.handle, className)} />;
}
