import { useForm } from 'react-hook-form';
import { DialogDescription, DialogTitle } from '@radix-ui/react-dialog';

import { useModalManager } from '@context/ModalManagerContext';
import Button from '@ui/Button';
import Dialog from '@ui/Dialog';
import styles from './CreateBookmarkModal.module.scss';

export type CreateBookmarkData = {
  url: string;
};

type OnCreateBookmarkSubmit = (data: CreateBookmarkData) => void;

const CreateBookmarkModal = ({
  closeModal,
  onSubmit,
}: {
  closeModal: () => void;
  onSubmit: OnCreateBookmarkSubmit;
}) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm<CreateBookmarkData>({
    defaultValues: {
      url: '',
    },
  });

  const formSubmit = (data: CreateBookmarkData) => {
    onSubmit(data);
    reset();
    closeModal();
  };

  return (
    <Dialog
      className={styles.bookmarkModal}
      closeModal={closeModal}
      headerPadding={0}
      isOpen={true}
      maxWidth="32.5rem"
      modalHeading={'Create link'}
      onOpenAutoFocus={() => {
        setTimeout(() => {
          setFocus('url');
        }, 0);
      }}
      padding="lg"
    >
      <DialogTitle />
      <DialogDescription />
      <form className={styles.bookmarkForm} onSubmit={handleSubmit(formSubmit)}>
        <div className="formGroup">
          <input
            autoComplete="off"
            placeholder="e.g. https://spaceduck.com"
            type="text"
            {...register('url', {
              required: 'URL is required',
              validate: {
                isValid: (url) => {
                  if (!url) return true;
                  try {
                    new URL(url);
                    return true;
                  } catch {
                    return 'URL is invalid';
                  }
                },
              },
            })}
          />
          {errors?.url?.message && <p className="errorMessage">{errors.url.message}</p>}
        </div>
        <div className={styles.bookmarkFooter}>
          <Button
            type="button"
            variant="secondary"
            size="sm"
            onClick={() => {
              reset();
              closeModal();
            }}
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary" size="sm">
            Submit
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export function useBookmarkModal() {
  const { openModal, closeModal } = useModalManager();
  return {
    open: ({
      onSubmit,
    }: {
      onSubmit: OnCreateBookmarkSubmit;
    }) => {
      openModal({
        component: <CreateBookmarkModal closeModal={closeModal} onSubmit={onSubmit} />,
      });
    },
    close: closeModal,
  };
}
