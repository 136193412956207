import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 21H23V16.677C22.9999 16.4772 22.9399 16.282 22.8278 16.1166C22.7157 15.9512 22.5566 15.8231 22.371 15.749L18.629 14.249C18.4438 14.175 18.2849 14.0473 18.1728 13.8823C18.0607 13.7172 18.0005 13.5225 18 13.323V12.445C18.6065 12.0969 19.1107 11.5953 19.4617 10.9906C19.8128 10.3858 19.9985 9.69927 20 9V7C20.0002 6.29778 19.8154 5.60789 19.4644 4.99968C19.1134 4.39148 18.6085 3.8864 18.0004 3.53522C17.3923 3.18404 16.7024 2.99914 16.0002 2.9991C15.298 2.99906 14.6081 3.18389 14 3.535"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.371 16.749L10.629 15.249C10.4438 15.175 10.2849 15.0473 10.1728 14.8823C10.0607 14.7172 10.0005 14.5225 10 14.323V13.445C10.6065 13.0969 11.1107 12.5953 11.4617 11.9906C11.8128 11.3858 11.9985 10.6993 12 10V8C12 6.93913 11.5786 5.92172 10.8284 5.17157C10.0783 4.42143 9.06087 4 8 4C6.93913 4 5.92172 4.42143 5.17157 5.17157C4.42143 5.92172 4 6.93913 4 8V10C4.00153 10.6993 4.18717 11.3858 4.53826 11.9906C4.88935 12.5953 5.3935 13.0969 6 13.445V14.323C5.99987 14.5228 5.93989 14.718 5.82777 14.8834C5.71566 15.0488 5.55656 15.1769 5.371 15.251L1.629 16.751C1.44375 16.825 1.28488 16.9527 1.17279 17.1177C1.0607 17.2828 1.00053 17.4775 1 17.677V21H15V17.677C14.9999 17.4772 14.9399 17.282 14.8278 17.1166C14.7157 16.9512 14.5566 16.8231 14.371 16.749Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
