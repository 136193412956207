import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8182 16.5455H2.90909C2.40727 16.5455 2 16.1382 2 15.6364V2.90909C2 2.40727 2.40727 2 2.90909 2H19.2727C19.7745 2 20.1818 2.40727 20.1818 2.90909V9.27273"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0909 22H15.6364C15.1346 22 14.7273 21.5927 14.7273 21.0909V10.1818C14.7273 9.67999 15.1346 9.27272 15.6364 9.27272H21.0909C21.5927 9.27272 22 9.67999 22 10.1818V21.0909C22 21.5927 21.5927 22 21.0909 22Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
