import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2874 18.0722L21.9999 11.3757L19.9864 9.36607H17.3009V6.68784L12.6018 2L5.88928 8.69651"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.21823 9.36606C4.07716 10.5044 4.07716 12.246 5.21823 13.3843L6.56054 14.7235L2.60005 18.6745C1.92889 19.3441 1.79409 20.4152 2.3314 21.2192C3.06995 22.224 4.41227 22.224 5.21823 21.42L9.24612 17.4017L10.5884 18.7408C11.7295 19.8791 13.4753 19.8791 14.6163 18.7408L15.2875 18.0712L5.88938 8.6965L5.21823 9.36606Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
