import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.81812 18.3636V22H20.1818V18.3636"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8182 12V5.63636L16.5455 4.72727V2H7.45455V4.72727L10.1818 5.63636V12L3.24364 14.3127C2.50091 14.56 2 15.2555 2 16.0382V18.3636H22V16.0382C22 15.2555 21.4991 14.5609 20.7564 14.3136L13.8182 12Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
