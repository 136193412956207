import MediaGroupPreviewList from '@/components/MediaGroupPreviewList';
import * as Popover from '@radix-ui/react-popover';
import type { MediaGroupDTO } from '@spaceduck/api';
import { type RefObject, useEffect, useState } from 'react';

type MediaGroupSourcePopupProps = {
  inputRef: RefObject<HTMLInputElement>;
  searchTitle: string;
  handleSelectItem: (item: MediaGroupDTO) => void;
  onEscapeKeyDown?: () => void;
  excludeMediaGroupIds: string[];
};

export default function MediaGroupSourcePopup(props: MediaGroupSourcePopupProps) {
  const [onKeyDown, setOnKeyDown] = useState<(event: KeyboardEvent) => boolean>();
  const { inputRef, searchTitle, handleSelectItem, onEscapeKeyDown } = props;

  useEffect(() => {
    if (onKeyDown) {
      inputRef.current?.addEventListener('keydown', onKeyDown);
      return () => inputRef.current?.removeEventListener('keydown', onKeyDown);
    }
  }, [onKeyDown, inputRef.current]);

  return (
    <Popover.Root open={true}>
      <Popover.Anchor />
      <Popover.Portal>
        <Popover.Content
          onEscapeKeyDown={onEscapeKeyDown}
          align="start"
          onOpenAutoFocus={(e) => {
            e.preventDefault();
            inputRef?.current?.focus();
          }}
        >
          <MediaGroupPreviewList
            searchTitle={searchTitle}
            handleSelectItem={handleSelectItem}
            setOnKeyDown={setOnKeyDown}
            defaultFilters={{
              contentTypeNot: ['video', 'audio', 'board'], // TODO: Support videos
            }}
            excludeMediaGroupIds={props.excludeMediaGroupIds}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
