import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.05564 7C0.677028 7 0.370117 7.3148 0.370117 7.70312C0.370117 8.09145 0.677028 8.40625 1.05564 8.40625H3.79771C4.17631 8.40625 4.48323 8.09145 4.48323 7.70312C4.48323 7.3148 4.17631 7 3.79771 7H1.05564Z"
        fill={color}
      />
      <path
        d="M6.63119 7C6.2526 7 5.94567 7.3148 5.94567 7.70312C5.94567 8.09145 6.2526 8.40625 6.63119 8.40625H9.37327C9.75186 8.40625 10.0588 8.09145 10.0588 7.70312C10.0588 7.3148 9.75186 7 9.37327 7H6.63119Z"
        fill={color}
      />
      <path
        d="M11.704 7.70312C11.704 7.3148 12.011 7 12.3895 7H15.1316C15.5102 7 15.8171 7.3148 15.8171 7.70312C15.8171 8.09145 15.5102 8.40625 15.1316 8.40625H12.3895C12.011 8.40625 11.704 8.09145 11.704 7.70312Z"
        fill={color}
      />
    </svg>
  );
}
