import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8605_25161)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.71883 5.27832C3.12877 5.27832 3.4611 5.61064 3.4611 6.02059V13.9381C3.4611 14.0912 3.52192 14.2381 3.63019 14.3463C3.73846 14.4546 3.8853 14.5154 4.03842 14.5154H11.9559C12.1091 14.5154 12.2559 14.4546 12.3642 14.3463C12.4724 14.2381 12.5333 14.0912 12.5333 13.9381V6.02059C12.5333 5.61064 12.8656 5.27832 13.2755 5.27832C13.6855 5.27832 14.0178 5.61064 14.0178 6.02059V13.9381C14.0178 14.485 13.8006 15.0094 13.4139 15.3961C13.0272 15.7827 12.5028 16 11.9559 16H4.03842C3.49158 16 2.96714 15.7827 2.58047 15.3961C2.19379 15.0094 1.97656 14.485 1.97656 13.9381V6.02059C1.97656 5.61064 2.30889 5.27832 2.71883 5.27832Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 3.38143C0 2.97148 0.332325 2.63916 0.742268 2.63916H15.2577C15.6677 2.63916 16 2.97148 16 3.38143C16 3.79137 15.6677 4.1237 15.2577 4.1237H0.742268C0.332325 4.1237 0 3.79137 0 3.38143Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00008 7.25781C8.41002 7.25781 8.74235 7.59014 8.74235 8.00008V11.9588C8.74235 12.3688 8.41002 12.7011 8.00008 12.7011C7.59014 12.7011 7.25781 12.3688 7.25781 11.9588V8.00008C7.25781 7.59014 7.59014 7.25781 8.00008 7.25781Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.35946 7.25781C5.7694 7.25781 6.10172 7.59014 6.10172 8.00008V11.9588C6.10172 12.3688 5.7694 12.7011 5.35946 12.7011C4.94951 12.7011 4.61719 12.3688 4.61719 11.9588V8.00008C4.61719 7.59014 4.94951 7.25781 5.35946 7.25781Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6407 7.25781C11.0506 7.25781 11.383 7.59014 11.383 8.00008V11.9588C11.383 12.3688 11.0506 12.7011 10.6407 12.7011C10.2308 12.7011 9.89844 12.3688 9.89844 11.9588V8.00008C9.89844 7.59014 10.2308 7.25781 10.6407 7.25781Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.61719 0.742268C4.61719 0.332325 4.94951 0 5.35946 0H10.6378C11.0477 0 11.3801 0.332325 11.3801 0.742268V3.38144C11.3801 3.79139 11.0477 4.12371 10.6378 4.12371C10.2279 4.12371 9.89554 3.79139 9.89554 3.38144V1.48454H6.10172V3.38144C6.10172 3.79139 5.7694 4.12371 5.35946 4.12371C4.94951 4.12371 4.61719 3.79139 4.61719 3.38144V0.742268Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_8605_25161">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
