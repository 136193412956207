import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 0.5V2.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.84302 2.84299L4.25702 4.25699"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.157 2.84299L12.743 4.25699"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 8.5C12.5033 7.79706 12.3214 7.10564 11.9724 6.4954C11.6235 5.88517 11.1199 5.37766 10.5124 5.02401C9.90488 4.67035 9.21489 4.48304 8.51194 4.48094C7.80899 4.47885 7.1179 4.66203 6.50829 5.01205C5.89867 5.36207 5.39207 5.86656 5.0395 6.47471C4.68693 7.08285 4.50085 7.77317 4.5 8.47612C4.49916 9.17907 4.68358 9.86983 5.03468 10.4788C5.38579 11.0878 5.89118 11.5935 6.49995 11.945V15.5H10.5V11.945C11.1065 11.5969 11.6106 11.0953 11.9617 10.4906C12.3128 9.88582 12.4984 9.19927 12.5 8.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 8.5H15.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 8.5H2.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
