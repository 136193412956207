import { createContext, type ReactNode } from 'react';

export const NotesContext = createContext<{
  containerRefId?: string;
  mediaGroupId: string;
} | null>(null);

export const NotesContextProvider = ({
  children,
  containerRefId,
  mediaGroupId,
}: {
  children: ReactNode;
  containerRefId?: string;
  mediaGroupId: string;
}) => {
  return (
    <NotesContext.Provider value={{ containerRefId, mediaGroupId }}>
      {children}
    </NotesContext.Provider>
  );
};
