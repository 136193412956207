import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="7" r="3.25" stroke={color} strokeWidth="1.5" />
      <circle cx="6" cy="17" r="3.25" stroke={color} strokeWidth="1.5" />
      <circle cx="18" cy="17" r="3.25" stroke={color} strokeWidth="1.5" />
    </svg>
  );
}
