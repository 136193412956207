import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1831_3622)">
        <path
          d="M11.5 5.5H4.5C3.94772 5.5 3.5 5.94772 3.5 6.5V10.5C3.5 11.0523 3.94772 11.5 4.5 11.5H11.5C12.0523 11.5 12.5 11.0523 12.5 10.5V6.5C12.5 5.94772 12.0523 5.5 11.5 5.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 9.5C8.55228 9.5 9 9.05228 9 8.5C9 7.94772 8.55228 7.5 8 7.5C7.44772 7.5 7 7.94772 7 8.5C7 9.05228 7.44772 9.5 8 9.5Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 5.50001V3.00001C10.5033 2.67498 10.4425 2.35249 10.3212 2.05094C10.1999 1.7494 10.0203 1.47471 9.7928 1.24256C9.5653 1.01041 9.29429 0.825345 8.99526 0.697937C8.69623 0.570529 8.37503 0.503272 8.05 0.500005H8C7.67497 0.496706 7.35248 0.557461 7.05094 0.678803C6.74939 0.800144 6.4747 0.979694 6.24255 1.2072C6.0104 1.43471 5.82534 1.70572 5.69793 2.00475C5.57052 2.30378 5.50327 2.62498 5.5 2.95001V5.50001"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 16C6.55228 16 7 15.5523 7 15C7 14.4477 6.55228 14 6 14C5.44772 14 5 14.4477 5 15C5 15.5523 5.44772 16 6 16Z"
          fill={color}
        />
        <path
          d="M10 16C10.5523 16 11 15.5523 11 15C11 14.4477 10.5523 14 10 14C9.44772 14 9 14.4477 9 15C9 15.5523 9.44772 16 10 16Z"
          fill={color}
        />
        <path
          d="M14 16C14.5523 16 15 15.5523 15 15C15 14.4477 14.5523 14 14 14C13.4477 14 13 14.4477 13 15C13 15.5523 13.4477 16 14 16Z"
          fill={color}
        />
        <path
          d="M2 16C2.55228 16 3 15.5523 3 15C3 14.4477 2.55228 14 2 14C1.44772 14 1 14.4477 1 15C1 15.5523 1.44772 16 2 16Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1831_3622">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
