import { useState } from 'react';
import clsx from 'clsx';
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  getStraightPath,
  getSmoothStepPath,
  type Position,
  useReactFlow,
} from '@xyflow/react';

import { Icon16 } from '@spaceduck/icons';

import type { BaseEdgeData } from '../types/board';
import { connectionLineColorsAsHex } from '../types/colors';
import styles from './Edge.module.scss';

type CommonEdgeProps = {
  data: BaseEdgeData;
  id: string;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
};

type BaseEdgeProps = {
  label?: string;
  markerEnd?: string;
  markerStart?: string;
} & CommonEdgeProps;

type CustomEdgeProps = {
  sourcePosition: Position | undefined;
  targetPosition: Position | undefined;
} & CommonEdgeProps;

const { Add } = Icon16;

export default function CustomEdge(props: CustomEdgeProps) {
  switch (props.data?.type) {
    case 'straight':
      return <StraightEdge {...props} />;
    case 'step':
      return <StepEdge {...props} />;
    default:
      return <DefaultEdge {...props} />;
  }
}

const DefaultEdge = ({
  data,
  id,
  label,
  markerEnd,
  markerStart,
  sourcePosition,
  sourceX,
  sourceY,
  targetPosition,
  targetX,
  targetY,
}: BaseEdgeProps & {
  sourcePosition?: Position;
  targetPosition?: Position;
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourcePosition,
    sourceX,
    sourceY,
    targetPosition,
    targetX,
    targetY,
  });

  return (
    <Edge
      data={data}
      id={id}
      label={label}
      labelX={labelX}
      labelY={labelY}
      edgePath={edgePath}
      markerEnd={markerEnd}
      markerStart={markerStart}
    />
  );
};

const StepEdge = ({
  data,
  id,
  label,
  markerEnd,
  markerStart,
  sourcePosition,
  sourceX,
  sourceY,
  targetPosition,
  targetX,
  targetY,
}: BaseEdgeProps & {
  sourcePosition?: Position;
  targetPosition?: Position;
}) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourcePosition,
    sourceX,
    sourceY,
    targetPosition,
    targetX,
    targetY,
  });

  return (
    <Edge
      data={data}
      id={id}
      label={label}
      labelX={labelX}
      labelY={labelY}
      edgePath={edgePath}
      markerEnd={markerEnd}
      markerStart={markerStart}
    />
  );
};

const StraightEdge = ({
  data,
  id,
  label,
  markerEnd,
  markerStart,
  sourceX,
  sourceY,
  targetX,
  targetY,
}: BaseEdgeProps) => {
  const [edgePath, labelX, labelY] = getStraightPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <Edge
      data={data}
      id={id}
      label={label}
      labelX={labelX}
      labelY={labelY}
      edgePath={edgePath}
      markerEnd={markerEnd}
      markerStart={markerStart}
    />
  );
};

type EdgeProps = {
  data: BaseEdgeData;
  edgePath: string;
  id: string;
  label?: string;
  labelX: number;
  labelY: number;
  markerEnd?: string;
  markerStart?: string;
};

const Edge = ({
  data,
  edgePath,
  id,
  label,
  labelX,
  labelY,
  markerEnd,
  markerStart,
}: EdgeProps) => {
  const { color = 'default', style = 'solid', width = 1 } = data;
  const [showInput, setShowInput] = useState<boolean>(false);
  const [displayLabel, setDisplayLabel] = useState<string>(label ?? '');
  const [previousLabel, setPreviousLabel] = useState<string>(label ?? '');

  const { getEdge, setEdges } = useReactFlow();
  const edge = getEdge(id);

  const handleBlur = () => {
    if (edge && displayLabel !== previousLabel) {
      setShowInput(false);

      setEdges((edges) =>
        edges.map((edge) => {
          if (edge.id === id) return { ...edge, label: displayLabel };
          return edge;
        })
      );

      setPreviousLabel(displayLabel);
    }
  };

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        className={clsx(styles.edge, styles[color], styles[style], styles[width])}
        markerStart={markerStart}
        markerEnd={markerEnd}
        style={{
          stroke: connectionLineColorsAsHex[color],
        }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
          className={clsx('nodrag', 'nopan', styles.label)}
        >
          <input
            // biome-ignore lint/a11y/noAutofocus: non-disruptive and expected behavior
            autoFocus
            className={clsx(!showInput && styles.hidden)}
            onBlur={handleBlur}
            onClick={(ev) => ev.stopPropagation()}
            onChange={(ev) => {
              setDisplayLabel(ev.target.value);
            }}
            type="text"
            value={displayLabel}
          />
          <button
            className={clsx(
              styles.labelDisplay,
              (!displayLabel || showInput) && styles.hidden
            )}
            type="button"
            onDoubleClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              setShowInput(true);
            }}
          >
            {displayLabel}
          </button>
          <button
            className={clsx(
              styles.labelDisplayPlaceholder,
              (displayLabel || showInput) && styles.hidden
            )}
            type="button"
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              setShowInput(true);
            }}
          >
            <Add />
          </button>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};
