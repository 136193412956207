import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3742_46953)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0674 5.12359C10.0674 4.6768 10.4296 4.31461 10.8764 4.31461H15.191C15.6378 4.31461 16 4.6768 16 5.12359V15.191C16 15.6378 15.6378 16 15.191 16H5.12356C4.67677 16 4.31458 15.6378 4.31458 15.191V10.8764C4.31458 10.4296 4.67677 10.0674 5.12356 10.0674C5.57036 10.0674 5.93255 10.4296 5.93255 10.8764V14.382H14.382V5.93258H10.8764C10.4296 5.93258 10.0674 5.57039 10.0674 5.12359Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.808989C0 0.362197 0.362197 0 0.808989 0H10.8764C11.3232 0 11.6854 0.362197 11.6854 0.808989V10.8764C11.6854 11.3232 11.3232 11.6854 10.8764 11.6854H0.808989C0.362197 11.6854 0 11.3232 0 10.8764V0.808989ZM1.61798 1.61798V10.0674H10.0674V1.61798H1.61798Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3742_46953">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
