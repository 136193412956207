import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 24,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 4.6C23.1 5 22.2 5.3 21.2 5.4C22.2 4.8 23 3.8 23.4 2.7C22.4 3.3 21.4 3.7 20.3 3.9C19.4 2.9 18.1 2.3 16.7 2.3C14 2.3 11.8 4.5 11.8 7.2C11.8 7.6 11.8 8 11.9 8.3C7.7 8.1 4.1 6.1 1.7 3.1C1.2 3.9 1 4.7 1 5.6C1 7.3 1.9 8.8 3.2 9.7C2.4 9.7 1.6 9.5 1 9.1C1 9.1 1 9.1 1 9.2C1 11.6 2.7 13.6 4.9 14C4.5 14.1 4.1 14.2 3.6 14.2C3.3 14.2 3 14.2 2.7 14.1C3.3 16.1 5.1 17.5 7.3 17.5C5.6 18.8 3.5 19.6 1.2 19.6C0.8 19.6 0.4 19.6 0 19.5C2.2 20.9 4.8 21.7 7.5 21.7C16.6 21.7 21.5 14.2 21.5 7.7C21.5 7.5 21.5 7.3 21.5 7.1C22.5 6.4 23.3 5.5 24 4.6Z"
        fill={color}
      />
    </svg>
  );
}
