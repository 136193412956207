import { init, replayIntegration, captureConsoleIntegration } from '@sentry/react';
import { ApiClient } from '@spaceduck/api';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'inter-ui/inter.css';

import './index.scss';
import App from './App.tsx';

import {
  getReplaySessionSampleRate,
  getReplaysOnErrorSampleRate,
  getTracesSampleRate,
} from '@lib/sentry/sentry.utils.config';
import { sessionStore } from './singleton.ts';

init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? 'development',
  integrations: [replayIntegration(), captureConsoleIntegration({ levels: ['error'] })],
  tracesSampleRate: getTracesSampleRate(),
  replaysSessionSampleRate: getReplaySessionSampleRate(),
  replaysOnErrorSampleRate: getReplaysOnErrorSampleRate(),
});

ApiClient.init(window.location.protocol + window.location.hostname, sessionStore, 'fe');

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
