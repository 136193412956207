import type { MediaGroupDTO } from '@spaceduck/api';
import { Icon24 } from '@spaceduck/icons';
import type { Editor } from '@tiptap/react';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { useListMediaGroups } from '@api/mediaGroup';
import Spinner from '@components/Spinner';
import { ContentType } from '@components/icons';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { css } from '@lib/css';
import ScrollArea from '@ui/ScrollArea';
import Button from './Button';
import styles from './MediaGroupMenu.module.scss';
import type { MenuView } from '../DocumentWrapper';

const { LeftArrow } = Icon24;

export default function MediaGroupMenu({
  editor,
  setView,
}: {
  editor: Editor;
  setView: React.Dispatch<React.SetStateAction<MenuView>>;
}) {
  const { ref: loadMoreRef, inView } = useInView();
  const workspaceId = useWorkspaceId();
  const { data, enabled, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useListMediaGroups(workspaceId, {});

  useEffect(() => {
    if (enabled && inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [enabled, inView, isFetchingNextPage, hasNextPage]);

  if (!data)
    return <div className={styles.statusContainer}>No Spaceduck items available</div>;
  if (isLoading)
    return (
      <div className={styles.statusContainer}>
        <Spinner />
      </div>
    );

  const mediaGroups = data.pages.flatMap((page) => page.mediaGroups);

  const insertBackLink = (mediaGroup: MediaGroupDTO) => {
    const { id, label, contentType } = mediaGroup;
    editor
      .chain()
      .insertContent([
        {
          type: 'outgoing-link',
          attrs: {
            id,
            label,
            contentType,
          },
        },
        {
          type: 'paragraph',
        },
      ])
      .run();
  };

  const insertContentBlock = (mediaGroup: MediaGroupDTO) => {
    const { id, label, contentType } = mediaGroup;
    editor
      .chain()
      .insertContent([
        {
          type: 'content-block',
          attrs: {
            id,
            label,
            contentType,
          },
        },
        {
          type: 'paragraph',
        },
      ])
      .run();
  };

  const handleClick = (mediaGroup: MediaGroupDTO) => {
    setView('blockMenu');
    if (mediaGroup.contentType === 'document') {
      insertBackLink(mediaGroup);
    } else {
      insertContentBlock(mediaGroup);
    }
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Button onClick={() => setView('blockMenu')}>
          <LeftArrow size={20} />
        </Button>
        <h3>Spaceduck items</h3>
      </header>
      <ScrollArea
        orientation="vertical"
        style={css({
          width: '100%',
          maxHeight: '100dvh',
          height: '33.33dvh',
        })}
      >
        {mediaGroups.map((mediaGroup) => (
          <MediaGroup
            key={mediaGroup.id}
            mediaGroup={mediaGroup}
            onClick={handleClick}
          />
        ))}
        {hasNextPage && !isFetchingNextPage && (
          <div ref={loadMoreRef} style={{ width: '100%', height: '10px' }}>
            {/* Triggers page fetch when in view */}
          </div>
        )}
      </ScrollArea>
    </div>
  );
}

const MediaGroup = ({
  mediaGroup,
  onClick,
}: {
  mediaGroup: MediaGroupDTO;
  onClick: (MediaGroup: MediaGroupDTO) => void;
}) => {
  return (
    <div className={styles.item} onClick={() => onClick(mediaGroup)}>
      <ContentType contentType={mediaGroup.contentType} size={15} />
      <div className={styles.label}>{mediaGroup.label}</div>
    </div>
  );
};
