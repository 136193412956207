import styles from './ResearchAssistantIndex.module.scss';
import sharedStyles from './SharedStyles.module.scss';
import { Icon16 } from '../../../packages/icons/src';
import { Icon24 } from '../../../packages/icons/src';
import { TopNav } from '../common';
import ChatMessageInput from '@/components/ai/ChatMessageInput';
import { useCreateChatSession } from '@/api/ai';
import useWorkspaceId from '@/hooks/useWorkspaceId';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { urlFor } from '@/urls';
import { catchApiErrorIntoToast } from '@/api/util';
import { ChatContent } from './ResearchAssistant';
import {
  type AiModel,
  type Chat,
  type ChatTemporaryLinkSource,
  type ChatMediaGroupSource,
  type ChatSpaceSource,
  type ChatTemporaryFileSource,
  exists,
} from '@spaceduck/api';
import Button from '@/components/ui/Button';
import { ChatHistory } from '@components/ai/ChatHistory';
import Sidebar from '@components/ai/Sidebar';

const { ResearchChatAI } = Icon16;
const { DrawerRight } = Icon24;

export default function ResearchAssistantIndex() {
  const { mutateAsync, status, data: chatSessionData, reset } = useCreateChatSession();

  const createChatSession = catchApiErrorIntoToast(mutateAsync);
  const workspaceId = useWorkspaceId();
  const navigate = useNavigate();
  const [temporaryChatSession, setTemporaryChatSession] = useState<Chat>();
  const [showChatHistory, setShowChatHistory] = useState(false);

  const submitChatSession = async (
    query: string,
    mediaGroups: ChatMediaGroupSource[],
    projects: ChatSpaceSource[],
    links: ChatTemporaryLinkSource[],
    temporaryFiles: ChatTemporaryFileSource[],
    model: AiModel
  ) => {
    if (!workspaceId) {
      return;
    }
    setTemporaryChatSession({
      id: 'temp',
      label: query,
      mediaGroups,
      messages: [
        {
          id: '',
          pending: true,
          query,
          response: {
            parts: [],
          },
        },
      ],
      model,
    });
    try {
      await createChatSession({
        workspaceId,
        model,
        projectIds: projects.map((project) => project.id),
        mediaGroupIds: mediaGroups.map((mediaGroup) => mediaGroup.id),
        links: links.map((link) => link.id).filter(exists),
        temporaryFiles: temporaryFiles.map((file) => file.id),
        initialQuery: query,
      });
    } catch (e) {
      setTemporaryChatSession(undefined);
      reset();
    }
  };

  useEffect(() => {
    if (chatSessionData) {
      navigate(
        urlFor('researchAssistantChat', {
          chatId: chatSessionData.chat.id,
        }),
        {
          state: { fromIndex: true },
        }
      );
    }
  }, [chatSessionData]);

  if (!workspaceId) {
    return;
  }

  return (
    <>
      <TopNav
        title="Research assistant"
        breadcrumb={[
          {
            icon: <ResearchChatAI />,
            text: 'Research assistant',
          },
          {
            text: 'All',
          },
        ]}
      >
        <div className={sharedStyles.chatNavButtons}>
          <Button size="sm" variant="outlined">
            New chat
          </Button>
          <Button
            onClick={() => setShowChatHistory(!showChatHistory)}
            size="sm"
            variant="outlined"
            isSquare
          >
            <DrawerRight size={20} />
          </Button>
        </div>
      </TopNav>
      <div className={styles.content}>
        <div
          className={clsx(
            styles.chatContent,
            temporaryChatSession ? styles.tempChat : undefined
          )}
        >
          {temporaryChatSession && (
            <div className={styles.tempChat}>
              <ChatContent
                messages={temporaryChatSession.messages}
                sources={temporaryChatSession?.mediaGroups}
                chatId={temporaryChatSession.id}
              />
            </div>
          )}
          {!temporaryChatSession && (
            <div className={styles.createChat}>
              {status === 'idle' && !temporaryChatSession && (
                <>
                  <h1>Start your research here</h1>

                  <ChatMessageInput
                    readOnly={status !== 'idle'}
                    onSubmit={submitChatSession}
                    showTools
                  />
                </>
              )}
            </div>
          )}
        </div>
        <Sidebar open={showChatHistory}>
          <h5 className={sharedStyles.chatHistoryTitle}>Chat history</h5>
          <ChatHistory workspaceId={workspaceId} />
        </Sidebar>
      </div>
    </>
  );
}
