import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 64 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 64 64"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.6158 36.2715L54.3229 35.5644L53.6158 36.2715C53.8688 36.5245 54.0695 36.8248 54.2064 37.1553C54.3433 37.4858 54.4138 37.8401 54.4138 38.1978C54.4138 38.5556 54.3433 38.9098 54.2064 39.2403C54.0695 39.5709 53.8688 39.8712 53.6158 40.1241L54.3229 40.8313L53.6158 40.1242L36.5377 57.2022L37.2448 57.9093L36.5377 57.2022C36.2847 57.4552 35.9844 57.6559 35.6539 57.7928C35.3234 57.9297 34.9692 58.0002 34.6114 58.0002C34.2536 58.0002 33.8994 57.9297 33.5689 57.7928C33.2383 57.6559 32.938 57.4552 32.6851 57.2022L31.9779 57.9093L32.6851 57.2022L8 32.5172V11.5864H28.9307L53.6158 36.2715Z"
        stroke="#7A7E9F"
        strokeWidth="2"
      />
      <mask id="path-2-inside-1_6101_4970" fill="white">
        <path d="M57.276 30.1381L32.1379 5H9.79297" />
      </mask>
      <path
        d="M32.1379 5L33.5521 3.58579L32.9664 3H32.1379V5ZM58.6902 28.7239L33.5521 3.58579L30.7237 6.41421L55.8618 31.5523L58.6902 28.7239ZM32.1379 3H9.79297V7H32.1379V3Z"
        fill="#4B4E68"
        mask="url(#path-2-inside-1_6101_4970)"
      />
      <path
        d="M23.6898 23.621C23.6898 25.6397 22.0533 27.2762 20.0346 27.2762C18.0159 27.2762 16.3794 25.6397 16.3794 23.621C16.3794 21.6023 18.0159 19.9658 20.0346 19.9658C22.0533 19.9658 23.6898 21.6023 23.6898 23.621Z"
        stroke="#B5B7CA"
        strokeWidth="2"
      />
    </svg>
  );
}
