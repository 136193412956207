import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3582 5.17574C13.118 4.94142 12.7282 4.94142 12.488 5.17574L10.2066 7.40002H0.615386C0.275515 7.40002 0 7.66865 0 8.00001C0 8.3314 0.275515 8.60001 0.615386 8.60001H10.2066L12.488 10.8242C12.7282 11.0586 13.118 11.0586 13.3582 10.8242L15.8197 8.42424C16.0601 8.19 16.0601 7.81007 15.8197 7.57575L13.3582 5.17574Z"
        fill={color}
      />
    </svg>
  );
}
